export const FlipFront = () => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M464,16H144a32.036,32.036,0,0,0-32,32V368a32.036,32.036,0,0,0,32,32H464a32.036,32.036,0,0,0,32-32V48A32.036,32.036,0,0,0,464,16Zm0,352H144V48H464l.021,320Z"/>
            <rect width="30.77" height="32" x="77.846" y="464"/>
            <rect width="30.77" height="32" x="384" y="464"/>
            <rect width="30.77" height="32" x="200.308" y="464"/>
            <rect width="30.769" height="32" x="139.077" y="464"/>
            <rect width="30.769" height="32" x="261.539" y="464"/>
            <rect width="30.77" height="32" x="322.769" y="464"/>
            <rect width="32" height="32" x="16" y="464"/>
            <rect width="32" height="30.154" x="16" y="224.308"/>
            <rect width="32" height="30.154" x="16" y="284.461"/>
            <rect width="32" height="30.154" x="16" y="404.769"/>
            <rect width="32" height="30.154" x="16" y="344.615"/>
            <rect width="32" height="30.154" x="16" y="164.154"/>
            <rect width="32" height="30.154" x="16" y="104"/>
        </svg>

    )
}

export const FlipBottom = () => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M64,496H400V464H64a16.019,16.019,0,0,1-16-16V120H16V448A48.055,48.055,0,0,0,64,496Z"/>
            <rect width="32" height="32" x="464" y="368"/>
            <rect width="29.091" height="32" x="173.091" y="368"/>
            <rect width="29.091" height="32" x="405.818" y="368"/>
            <rect width="29.091" height="32" x="231.272" y="368"/>
            <rect width="29.091" height="32" x="289.455" y="368"/>
            <rect width="29.091" height="32" x="347.637" y="368"/>
            <rect width="32" height="32" x="112" y="368"/>
            <rect width="32" height="29.091" x="112" y="135.272"/>
            <rect width="32" height="29.091" x="112" y="77.091"/>
            <rect width="32" height="29.091" x="112" y="309.818"/>
            <rect width="32" height="29.091" x="112" y="251.637"/>
            <rect width="32" height="29.091" x="112" y="193.455"/>
            <rect width="32" height="32" x="112" y="16"/>
            <rect width="29.091" height="32" x="289.454" y="16"/>
            <rect width="29.091" height="32" x="231.272" y="16"/>
            <rect width="29.091" height="32" x="405.818" y="16"/>
            <rect width="29.091" height="32" x="173.091" y="16"/>
            <rect width="29.091" height="32" x="347.637" y="16"/>
            <rect width="32" height="32" x="464" y="16"/>
            <rect width="32" height="29.091" x="464" y="309.818"/>
            <rect width="32" height="29.091" x="464" y="77.091"/>
            <rect width="32" height="29.091" x="464" y="193.454"/>
            <rect width="32" height="29.091" x="464" y="135.272"/>
            <rect width="32" height="29.091" x="464" y="251.637"/>
        </svg>
    )
}


export const LeftAlignIcon = ({fill})=><svg width="19" height="19" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 18H0.8L0.8 0.4H0L0 18ZM1.6 16.075C1.63594 16.2656 1.80469 16.4031 2 16.4H17.6C17.8203 16.4 18 16.2203 18 16V10.4C18 10.1797 17.8203 10 17.6 10H2C1.77969 10 1.6 10.1797 1.6 10.4L1.6 16C1.6 16.0125 1.6 16.025 1.6 16.0375C1.6 16.05 1.6 16.0625 1.6 16.075ZM1.6 8C1.6 8.22188 1.77969 8.4 2 8.4H13.6C13.8219 8.4 14 8.22188 14 8L14 2.4C14 2.17812 13.8219 2 13.6 2H2C1.77969 2 1.6 2.17812 1.6 2.4L1.6 8ZM2.4 15.6L2.4 10.8H17.2V15.6H2.4Z" fill={fill}/>
</svg>
export const RightAlignIcon = ({fill})=><svg width="19" height="19" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 0.400391L17.2 0.400391V18.0004H18V0.400391ZM16.4 2.32539C16.3641 2.13477 16.1953 1.99727 16 2.00039L0.4 2.00039C0.179688 2.00039 0 2.18008 0 2.40039V8.00039C0 8.2207 0.179688 8.40039 0.4 8.40039L16 8.40039C16.2203 8.40039 16.4 8.2207 16.4 8.00039V2.40039C16.4 2.38789 16.4 2.37539 16.4 2.36289C16.4 2.35039 16.4 2.33789 16.4 2.32539ZM16.4 10.4004C16.4 10.1785 16.2203 10.0004 16 10.0004H4.4C4.17812 10.0004 4 10.1785 4 10.4004L4 16.0004C4 16.2223 4.17812 16.4004 4.4 16.4004H16C16.2203 16.4004 16.4 16.2223 16.4 16.0004V10.4004ZM15.6 2.80039V7.60039L0.799999 7.60039V2.80039L15.6 2.80039Z" fill={fill}/>
</svg>

export const TopAlignIcon = ({fill})=><svg width="19" height="19" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0L0 0.8L17.6 0.8V0L0 0ZM1.925 1.6C1.73438 1.63594 1.59688 1.80469 1.6 2L1.6 17.6C1.6 17.8203 1.77969 18 2 18L7.6 18C7.82031 18 8 17.8203 8 17.6L8 2C8 1.77969 7.82031 1.6 7.6 1.6L2 1.6C1.9875 1.6 1.975 1.6 1.9625 1.6C1.95 1.6 1.9375 1.6 1.925 1.6ZM10 1.6C9.77813 1.6 9.6 1.77969 9.6 2L9.6 13.6C9.6 13.8219 9.77813 14 10 14L15.6 14C15.8219 14 16 13.8219 16 13.6L16 2C16 1.77969 15.8219 1.6 15.6 1.6L10 1.6ZM2.4 2.4L7.2 2.4L7.2 17.2H2.4L2.4 2.4Z" fill={fill}/>
</svg>

export const BottomAlignIcon = ({fill})=><svg width="19" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6 18.4004V17.6004L-2.47955e-05 17.6004V18.4004L17.6 18.4004ZM15.675 16.8004C15.8656 16.7645 16.0031 16.5957 16 16.4004L16 0.80039C16 0.580078 15.8203 0.400391 15.6 0.400391L9.99998 0.400391C9.77966 0.400391 9.59998 0.580078 9.59998 0.80039L9.59998 16.4004C9.59998 16.6207 9.77966 16.8004 9.99998 16.8004L15.6 16.8004C15.6125 16.8004 15.625 16.8004 15.6375 16.8004C15.65 16.8004 15.6625 16.8004 15.675 16.8004ZM7.59998 16.8004C7.82185 16.8004 7.99998 16.6207 7.99998 16.4004L7.99998 4.80039C7.99998 4.57852 7.82185 4.40039 7.59998 4.40039L1.99998 4.40039C1.7781 4.40039 1.59998 4.57852 1.59998 4.80039L1.59998 16.4004C1.59998 16.6207 1.7781 16.8004 1.99998 16.8004L7.59998 16.8004ZM15.2 16.0004L10.4 16.0004L10.4 1.20039L15.2 1.20039L15.2 16.0004Z" fill={fill}/>
</svg>

export const AlignHorizontalObject = ({fill}) => (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.36 13.1088H7.7712V7.0344H10.9104V10.3368H15.3216V7.0344H18.6816V6.0744H15.3216V2.772H10.9104V6.0744H7.7712V0H3.36V6.0744H0V7.0344H3.36V13.1088ZM11.8704 3.732H14.3616V9.3768H11.8704V3.732ZM4.32 0.96H6.8112V12.1488H4.32V0.96Z" fill="black"/>
    </svg>

)

export const AlignVerticalObject = ({fill}) => (
    <svg width="19" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8952 15.535V11.1238H7.82078V7.98462H11.1232L11.1232 3.57342H7.82078V0.213421H6.86078V3.57342H3.55838V7.98462H6.86078V11.1238H0.786377L0.786377 15.535H6.86078V18.895H7.82078V15.535H13.8952ZM4.51838 7.02462V4.53342H10.1632V7.02462H4.51838ZM1.74638 14.575L1.74638 12.0838H12.9352V14.575H1.74638Z" fill="black"/>
    </svg>
)
export const ArrowUpIcon = ({fill}) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 12 20" width="10"><path d="m25.70725 15.2881579-5-5.001c-.009-.009-.021-.012-.031-.02-.085-.081-.181-.151-.293-.197-.001-.001-.003-.001-.004-.001-.008-.004-.017-.003-.025-.007-.105-.04-.212-.058-.321-.0621579-.03-.00084214-.059.0031579-.089.0051579-.091.005-.177.021-.262.051-.031.01-.059.018-.089.032-.109.049-.213.111-.3.199l-5 5.001c-.391.392-.391 1.024 0 1.415s1.023.391 1.414 0l3.293-3.294v15.591c0 .553.448 1 1 1s1-.447 1-1v-15.591l3.293 3.294c.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023 0-1.415" fillRule="evenodd" transform="translate(-14 -10)"/></svg>
)
export const ArrowFrontIcon = ({fill}) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 20 20" width="17"><path d="m29 27.9990579c.553 0 1 .447 1 1s-.447 1.001-1 1.001h-18c-.553 0-1-.448-1-1.001s.447-1 1-1zm-8.963-17.9989c.107.004.214.022.316.061.008.003.018.003.026.007.116.047.212.117.298.197.009.009.021.011.03.021l5 5.001c.391.391.391 1.024 0 1.415-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-3.293-3.294v10.589c0 .554-.447 1.001-1 1.001s-1-.447-1-1.001v-10.589l-3.293 3.294c-.391.391-1.023.391-1.414 0s-.391-1.024 0-1.415l5-5.001c.088-.088.19-.149.297-.198.032-.014.065-.024.097-.034.082-.028.166-.043.249-.049.034-.002.067-.00600004.101-.005z" fillRule="evenodd" transform="translate(-10 -10)"/></svg>
)
export const LockIcon = ({fill}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px"><path fill={fill} d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9069372 8 4 8.9069372 4 10 L 4 20 C 4 21.093063 4.9069372 22 6 22 L 18 22 C 19.093063 22 20 21.093063 20 20 L 20 10 C 20 8.9069372 19.093063 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 6 10 L 18 10 L 18 20 L 6 20 L 6 10 z M 12 13 C 10.9 13 10 13.9 10 15 C 10 16.1 10.9 17 12 17 C 13.1 17 14 16.1 14 15 C 14 13.9 13.1 13 12 13 z"/></svg>)
export const ArrowDownIcon = ({fill}) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 12 20" width="10"><path d="m24.29325 23.293-3.293 3.293v-15.586c0-.553-.447-1-1-1s-1 .447-1 1v15.586l-3.293-3.293c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414l5 5c.009.009.021.012.03.02.086.08.182.15.294.197.001 0 .002 0 .003.001.122.05.251.075.38.075s.257-.025.378-.074c.121-.05.233-.123.329-.219l5-5c.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0" fillRule="evenodd" transform="translate(-14 -10)"/></svg>)
export const ArrowBackwardIcon = ({fill}) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 20 20" width="17"><path d="m29 28c.553 0 1 .447 1 1s-.447 1-1 1h-18c-.553 0-1-.447-1-1s.447-1 1-1zm-9.0001-18c.553 0 1 .447 1 1v10.586l3.293-3.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-5 5c-.096.096-.208.169-.329.219-.121.049-.249.074-.378.074s-.259-.025-.38-.075c-.001-.001-.002-.001-.003-.001-.112-.047-.208-.117-.294-.197l-.03-.02-5-5c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l3.293 3.293v-10.586c0-.553.447-1 1-1z" fillRule="evenodd" transform="translate(-10 -10)"/></svg>)