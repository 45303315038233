import React, { useState } from 'react';
import { useEffect } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { IconButton, Tooltip } from '@material-ui/core';


function Index(props) {
  const [starSelected, setStarSelected] = useState(false)
  const [mouseHovered, setMouseHovered] = useState(false)
  const mouseOnTopHandler = (action) => {
    const status = action === 'entered'
    setStarSelected(status)
  }
  useEffect(() => {
    if (props.selected) {
      setStarSelected(true)
    }
    else {
      setStarSelected(mouseHovered)
    }
  }, [props.selected, mouseHovered])
  return (
    <Tooltip title='Make Favourite' arrow >
      <IconButton onMouseEnter={() => setMouseHovered(true)} onMouseLeave={() => setMouseHovered(false)}>
        <StarIcon color={starSelected ? "warning" :"disabled"} />
      </IconButton>
    </Tooltip>






  )
}

export default Index;