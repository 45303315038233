import React, { useEffect } from "react";
import urlMaker from "../../helpers/urlMaker";
import "./VirtualTemplate.css";
export default function VirtualTemplateNew(props) {
  const [screenWidth, setScreenWidth] = React.useState(1920);
  class Css {
    constructor(cssObj, virtualizationFactor) {
      this.cssObj = cssObj;
      this.virtualizationFactor = virtualizationFactor;
    }
    convertToOriginal = () => ({
      position: this.cssObj.position,
      marginTop: this.cssObj.marginTop / this.virtualizationFactor,
      marginLeft:
        screenWidth / 2 + this.cssObj.marginLeft / this.virtualizationFactor,
      height: this.cssObj.height / this.virtualizationFactor,
      width: this.cssObj.width / this.virtualizationFactor,
      backgroundColor: this.cssObj.backgroundColor,
      zIndex: this.cssObj.zIndex,
    });
    convertToVirtual = () => ({
      position: this.cssObj.position,
      marginTop: this.cssObj.marginTop * this.virtualizationFactor,
      marginLeft: this.cssObj.marginLeft * this.virtualizationFactor,
      height: this.cssObj.height * this.virtualizationFactor,
      width: this.cssObj.width * this.virtualizationFactor,
      backgroundColor: this.cssObj.backgroundColor,
      border:
        this.cssObj.border === 0
          ? "1px solid"
          : String(this.cssObj.border) + "px solid",
      borderRadius: this.cssObj.borderRadius,
      borderColor:
        this.cssObj.border === 0 ? "light grey" : this.cssObj.borderColor,
      zIndex: this.cssObj.zIndex,
    });
  }
  // console.log(card.id)
  const virtualizationFactor =
    props.card?.resolution_width > props.card?.resolution_height
      ? 180 / props.card?.resolution_width
      : (180 / props.card?.resolution_height) * 0.7;
  let divs = props.card?.divs_templates;
  if (typeof divs === "undefined") {
    divs = props.card?.divclone_templateclone;
  }
  const tempDivs = divs?.map((divMapped, index) => {
    const divObj = new Css(divMapped, virtualizationFactor);
    // const divStyle = divObj.convertToVirtual();
    const divComponent = (
      <div key={index} style={divObj.convertToVirtual()}></div>
    );
    return () => divComponent;
  });
  const cardId = props.card?.id;

  // useEffect(() => {
  //   // console.log(props)
  // }, [])
  
  //   if we pass withoutcard = true in props, this component will return only the templates without card
  if (props.withoutcard) {
    return (
      <>
          <img src={urlMaker(props?.card?.thumbnail,'templates')} alt=''  style={{
              width: props.card?.resolution_width * virtualizationFactor,
              height: props.card?.resolution_height * virtualizationFactor,
              borderRadius: 20 * virtualizationFactor,
             
               }}
            />
      </>
    );
  } else {
    return (
      <div className="template-card-schedules" onClick={props.onClick}>
        <div className="container-screen-schedules">
        <img src={urlMaker(props?.card?.thumbnail,'templates')} alt=''  style={{
              width: props.card?.resolution_width * virtualizationFactor,
              height: props.card?.resolution_height * virtualizationFactor,
              borderRadius: 20 * virtualizationFactor,
             
               }}
              
            />
        </div>
        <span style={{ color: "#67818f", fontSize: "1rem" }}>
          {props.card?.template_name}
        </span>
        <br />
        <span style={{ color: "#67818f", fontSize: "0.8rem" }}>
          {"Screen orientation: " + props.card?.orientation}
        </span>
        <br />
        <span style={{ color: "#67818f", fontSize: ".6rem" }}>
          {"Resolution :  " +
            props.card?.resolution_width +
            " x " +
            props.card?.resolution_height +
            " pixels"}
        </span>
      </div>
    );
  }
}
