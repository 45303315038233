import React, { useEffect, useState } from "react";

// import Lottie from './Lottie';
import {
  ModalBody,
  Button,
  ModalFooter,
  Spinner,
  Card,
  Col,
  Row,
} from "reactstrap";
import { ReactComponent as LinkIcon } from "../../../assets/icons/link.svg";
import EmptyList from "../../../assets/SVG/Search Not Found 2.svg";

import NoImage from "../../../assets/images/Noimage.png";
import Modals from "../../../components/Modal";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import { useDispatch } from "react-redux";
import { addScreenGroup, screenListUpdater } from "../../../store/screenSlice";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MovieIcon from "@mui/icons-material/Movie";
import LayersIcon from "@mui/icons-material/Layers";
import TvIcon from "@mui/icons-material/Tv";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import GridViewIcon from "@mui/icons-material/GridView";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import InfiniteScroll from "react-infinite-scroller";
import urlMaker from "../../../helpers/urlMaker";
import { removeExtension } from "../../../helpers/Filename";
import TextBoard from "../Apps/icons/textBoard.svg";
import Clockimage from "../Apps/icons/clock.svg";
import youtubePic from "../Apps/icons/youtube.svg";
import WeatherPic from "../Apps/icons/weather.svg";

export default function CastScreenModal(props) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [slectedOption, setSelectedOption] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [pageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [files, setFiles] = useState([]);
  const [limit, setLimit] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [slectedItem, setSelectedItem] = useState(null);
  const [waitingForAxios, setWaitingForAxios] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    console.log(props);
  }, [props]);

  const urls = [
    `api/signage/scheduleslistcreateview?limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }`,
    `/api/signage/fileslistcreateview?limit=${pageSize}&offset=${
      pageSize * (page - 1)
    } &file_type=image`,
    `/api/signage/fileslistcreateview?limit=${pageSize}&offset=${
      pageSize * (page - 1)
    } &file_type=video`,
    `/api/signage/objectlistcreate?object_type=canvas&limit=${limit}&offset=${
      limit * (page - 1)
    }&search=&file_type=canvas`,
    `/api/signage/objectlistcreate?object_type=link&limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }`,
    `/api/signage/objectlistcreate?object_type=clock&object_type=weather&object_type=youtube&object_type=text&limit=${limit}&offset=${
      limit * (page - 1)
    }&search=&file_type=youtubeweatherclock`,
  ];

  useEffect(() => {
    setLoader(true);
    const url = urls[slectedOption];
    authAxios
      .get(url)
      .then((response) => {
        if (files.length === 0) {
          setFiles(response.data.results);
        } else {
          setFiles([...files, ...response.data.results]);
        }

        setTotalCount(response.data.count);

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  }, [page, slectedOption]);

  useEffect(() => {
    console.log(slectedItem);
  }, [slectedItem]);
  // function used inorder to change page and load more data upon user scrolling if there is more data
  const loadFunc = () => {
    if (hasNextPage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
  };

  const optionHandler = (id) => {
    setPage(1);
    setTotalCount(0);
    setHasNextPage(false);
    setSelectedOption(id);
    setFiles([]);
    setSelectedItem(null);
  };

  const handleCancel = () => {
    props.setIsModalVisible(false);
  };

  const submitHandler = () => {
    setWaitingForAxios(true);
    const dataToBeSend = {
      id: props.item.id,
      content_id: slectedItem.id,
      is_screen_group: props.isScreen ? false : true,
      content_type:
        slectedOption === 0
          ? "scheduler"
          : slectedOption === 4
          ? "template"
          : slectedItem.file_type,
    };

    const url = "/api/signage/casttoscreen";
    authAxios
      .post(url, dataToBeSend)
      .then((response) => {
        Toastr("success", "Screen Updated successfully !");
        handleCancel();
        setWaitingForAxios(false);
      })
      .catch((error) => {
        setWaitingForAxios(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const options = [
    {
      id: 0,
      icon: <CalendarMonthIcon className="icons-screen-cast-modal" />,
      name: "Scheduler",
    },
    {
      id: 1,
      icon: <PhotoSizeSelectActualIcon className="icons-screen-cast-modal" />,
      name: "Images",
    },
    {
      id: 2,
      icon: <MovieIcon className="icons-screen-cast-modal" />,
      name: "Videos",
    },
    {
      id: 3,
      icon: <TvIcon className="icons-screen-cast-modal" />,
      name: "Canvas",
    },

    {
      id: 4,
      icon: <InsertLinkIcon className="icons-screen-cast-modal" />,
      name: "Weblinks",
    },
    {
      id: 5,
      icon: <GridViewIcon className="icons-screen-cast-modal" />,
      name: "Apps",
    },
  ];
  return (
    <div>
      <Modals
        title="Cast"
        isOpen={props.isModalVisible}
        toggle={() => props.setIsModalVisible(false)}
        size="xl"
      >
        <ModalBody>
          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                width: "30%",
                paddingLeft: "20px",
                paddingRight: "30px",
              }}
            >
              <p style={{}}>Please select an option </p>

              <div style={{ height: "400px" }}>
                {options.map((data, index) => (
                  <Card
                    style={{
                      width: "100%",
                      height: "50px",
                      border: `${
                        data.id === slectedOption
                          ? "1.5px solid #505D69"
                          : "1px solid #DCDADA"
                      }`,
                      display: "flex",
                      marginBottom: "8px",
                      cursor: "pointer",
                      // backgroundColor: `${
                      //   data.id === slectedOption ? "red" : "white"
                      // }`,
                    }}
                    className="cast-screen-option-card"
                    onClick={() => optionHandler(data.id)}
                  >
                    {data.icon}
                    <p
                      style={{
                        position: "absolute",
                        top: "13px",
                        left: "50px",
                      }}
                    >
                      {data.name}
                    </p>
                  </Card>
                ))}
              </div>
            </div>

            <div
              style={{
                width: "70%",
                backgroundColor: "#F5F5F5",
                height: "450px",
              }}
            >
              <InfiniteScroll
                pageStart={0}
                // dataLength={totalCount}
                // next={loadFunc}
                loadMore={loadFunc}
                hasMore={hasNextPage}
                loader={
                  <div
                    style={{
                      position: "absolute",
                      left: "64%",
                      bottom: "15%",
                    }}
                    className="loader"
                    key={0}
                  >
                    <Spinner
                      style={{
                        fontSize: "8px",
                        width: "1rem",
                        height: "1rem",
                      }}
                      animation="grow"
                    />
                  </div>
                }
              >
                <div className="cast-screen-content-container">
                  {loader ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "63%",
                        top: "40%",
                      }}
                    >
                      <Spinner animation="grow" />
                    </div>
                  ) : files.length === 0 ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "70%",
                        top: "45%",
                        transform: "translate(-70%,-60%)",
                      }}
                    >
                      <img
                        src={EmptyList}
                        style={{ width: "350px", height: "250px" }}
                      ></img>
                    </div>
                  ) : (
                    <>
                      {files?.map((data, index) => (
                        <Card
                          key={index}
                          style={{
                            height: "100px",
                            width: "340px",
                            cursor: "pointer",
                            border: `${
                              slectedItem !== null && data.id === slectedItem.id
                                ? "2px solid 	#BEBEBE"
                                : "1px solid #DCDADA"
                            }`,
                          }}
                          className="cast-screen-item-card"
                          onClick={() => setSelectedItem(data)}
                        >
                          <div
                            style={{
                              width: "100px",
                              height: "80px",
                              objectFit: "contain",
                              marginTop: "10px",
                              marginLeft: "10px",
                              border: "1px solid #DCDADA",
                              borderRadius: "5px",
                            }}
                          >
                            {slectedOption === 4 ? (
                              <div
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  backgroundColor: data.object.color_code,
                                  borderRadius: "5px",
                                  position: "absolute",
                                  top: "20px",
                                  left: "30px",
                                }}
                              >
                                <LinkIcon
                                  style={{
                                    margin: "20px",
                                    fill: data.object.light_or_dark,
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              </div>
                            ) : (
                              <img
                                src={
                                  data?.template_clone?.thumbnail
                                    ? urlMaker(
                                        data.template_clone.thumbnail,
                                        "templates"
                                      )
                                    : data.file_type === "image" ||
                                      data.file_type === "video"
                                    ? urlMaker(data.thumbnail, "image")
                                    : data.file_type === "canvas"
                                    ? urlMaker(data.thumbnail, "canvas")
                                    : data.file_type === "youtube"
                                    ? urlMaker(data.thumbnail, "youtube")
                                    : data.file_type === "weather"
                                    ? WeatherPic
                                    : data.file_type === "clock"
                                    ? Clockimage
                                    : data.file_type === "text"
                                    ? TextBoard
                                    : NoImage
                                }
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "5px",
                                }}
                              />
                            )}

                            <div
                              style={{
                                position: "absolute",
                                left: "38%",
                                top: "15%",
                                fontWeight: "bold",
                                fontSize: "14px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: "180px",

                                color: "#8D9091",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: "-6px",
                                  textTransform: "capitalize",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {data.schedule_name
                                  ? data.schedule_name
                                  : data.name && removeExtension(data.name)}
                              </p>

                              <p
                                style={{
                                  fontSize: "12px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  marginBottom: "-7px",
                                }}
                              >
                                {data.file_size
                                  ? "File size  :  " + data.file_size
                                  : data.object?.link
                                  ? data.object.link
                                  : data.template_clone?.orientation &&
                                    "Orientation :  " +
                                      data.template_clone.orientation}
                              </p>
                              <p
                                style={{
                                  fontSize: "12px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {data.template_clone?.resolution_height &&
                                data.template_clone?.resolution_width
                                  ? "Resolution :  " +
                                    data.template_clone.resolution_width +
                                    "x" +
                                    data.template_clone.resolution_height
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>

          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              className="w-xs waves-effect waves-light me-1"
              onClick={submitHandler}
              disabled={slectedItem === null || waitingForAxios}
              style={{ width: "120px" }}
            >
              {waitingForAxios ? (
                <Spinner
                  style={{ marginTop: "2px" }}
                  animation="grow"
                  size="sm"
                />
              ) : (
                "Set to Screen"
              )}
            </Button>
            <Button
              type="button"
              color="danger"
              className="w-xs waves-effect waves-light me-1"
              onClick={handleCancel}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modals>
    </div>
  );
}
