import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customer: null,
};
const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    AddCustomerDetails(state, action) {
      state.customer = action.payload;
    },
    UpdateCustomerDetails(state, action) {
      state.customer = { ...state.customer, client_data: action.payload };
    },
  },
});

export const { AddCustomerDetails, UpdateCustomerDetails } =
  customerSlice.actions;
export default customerSlice.reducer;
