import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  helpCenter: null,
  trainingHub: null,
  trainingHubHeading: null,
};
const supportSlice = createSlice({
  name: "Support",
  initialState,
  reducers: {
    AddHelpCenter(state, action) {
      state.helpCenter = action.payload;
    },
    AddNewHelpCenter(state, action) {
      state.helpCenter = [...state.helpCenter, action.payload];
    },
    DeleteHelpCenter(state, action) {
      state.helpCenter = state.helpCenter.filter(
        (data) => data.id !== action.payload
      );
    },
    EditHelpCenter(state, action) {
      state.helpCenter = state.helpCenter.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },

    AddTrainingHub(state, action) {
      console.log(action.payload)
      state.trainingHub = action.payload;
    },
    AddNewTrainingHub(state, action) {
      state.trainingHub = [...state.trainingHub, action.payload];
    },
    DeleteTrainingHub(state, action) {
      state.trainingHub = state.trainingHub.filter(
        (data) => data.id !== action.payload
      );
    },
    EditTrainingHub(state, action) {
      state.trainingHub = state.trainingHub.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },
    AddTrainingHubHeading(state, action) {
      console.log(action.payload)
      state.trainingHubHeading = action.payload;
    },
    AddNewTrainingHubHeading(state, action) {
      state.trainingHubHeading = [...state.trainingHubHeading, action.payload];
    },
    DeleteTrainingHubHeading(state, action) {
      state.trainingHubHeading = state.trainingHubHeading.filter(
        (data) => data.id !== action.payload
      );
    },
    EditTrainingHubHeading(state, action) {
      state.trainingHubHeading = state.trainingHubHeading.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },
  },
});

export const {
  EditTrainingHub,
  DeleteTrainingHub,
  AddNewTrainingHub,
  AddTrainingHub,
  EditHelpCenter,
  AddNewHelpCenter,
  AddHelpCenter,
  DeleteHelpCenter,
  EditTrainingHubHeading,
  DeleteTrainingHubHeading,
  AddNewTrainingHubHeading,
  AddTrainingHubHeading
} = supportSlice.actions;
export default supportSlice.reducer;
