import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Button, Container } from "reactstrap";
// Redux
import IconButton from "@mui/material/Button";
// import GoogleIcon from "@mui/icons-material/Google";
// import FacebookIcon from "@mui/icons-material/Facebook";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import animationData from "./lottie.json";
// import { FaFacebook } from "react-icons/fa";
import google from "../../../assets/images/google.png";
import { Player } from "@lottiefiles/react-lottie-player";
import "./Registration.css";
import logodark from "../../../assets/images/signage logo dark.png";
// import axios from "axios";
import { publicAxios } from "../../../axiosInstance";
// import Lottie from "react-lottie";
// import MultipleSelectPlaceholder from "../../../components/SelectBox/selectBox";
import { Toastr } from "../../../helpers/Alert";
import { country_list } from "../../../helpers/CountriesList";
import { industryList } from "../../../helpers/IndustryList";
import CustomizedCheckbox from "../../../components/CustomCheckBox";
import Error from "../../../helpers/Error";
// import { display } from "@mui/system";

export default function Registration() {
  let navigateto = useNavigate();
  const [waitingForGoogleAxis, setWaitingForGoodleAxis] = useState(false);
  const [waitingForEmailAxios, setWaitingForEmailAxios] = useState(false);
  const [waitingForOTPAxios, setWaitingForOTPAxios] = useState(false);
  const [waitingForNewPasswordAxios, setWaitingForNewPasswordAxios] =
    useState(false);
  const [navigate, setNavigate] = useState({ otp: false, password: false });
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [allClear, setallClear] = useState(false);
  // const [registrationkey, setRegistrationkey] = useState(null);
  const [errorMessage, seterrorMessage] = useState(null);
  const [email, setEmail] = useState(null);
  const [otpValidation, setOtpvalidation] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [timeZone, setTimeZone] = useState(null);
  const [enteredOtp, setEnteredOtp] = useState(null);
  const [resendOtpData, setResendOtpData] = useState({});
  const [checkValidData, setCheckValidData] = useState({});
  const [isAllOk, setIsAllOk] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleFormData = (e) => {
    // console.log("wrked");
    setCheckValidData((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    // console.log(checkValidData);
  }, [checkValidData]);

  useEffect(() => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          // console.log(result.state);
          //If granted then you can directly call your function here
        } else if (result.state === "prompt") {
          // console.log(result.state);
        } else if (result.state === "denied") {
          //If denied then you have to show instructions to enable location
        }
        result.onchange = function () {
          // console.log(result.state);
        };
      });
  }, []);

  useEffect(() => {
    window.onpopstate = (event) => {
      event.preventDefault();
      // console.log(event);
    };
  });

  useEffect(() => {
    reset({ password: "", password1: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate.password]);

  useEffect(() => {
    if (newPassword) {
      const re = new RegExp(
        `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`
      );
      const isOk = re.test(newPassword);

      if (isOk) {
        seterrorMessage("Passwords should match");
        if (newPassword === confirmNewPassword) {
          seterrorMessage(null);
        } else {
          setallClear(false);
        }
      } else {
        seterrorMessage(
          "The password should contain minimum 8 characters with a mix of alphanumeric,atleast 1 Uppercase letter and special characters"
        );
      }
    } else {
      seterrorMessage(null);
    }
  }, [newPassword, confirmNewPassword]);
  // email verification handler
  // const forgetPasswordSubmitHandler = (data) => {
  //   reset({
  //     otp: "",
  //   });

  //   setEmail(data.email);
  //   // setNavigate({ otp: true, password: false })
  //   setWaitingForEmailAxios(true);
  //   // console.log(data)
  //   // const url = 'users_api/send_forgot_password_otp';
  //   // axios.post(url, data).then((res) => {
  //   setNavigate({ otp: true, password: false });
  //   setWaitingForEmailAxios(false);

  //   // }).catch((error) => {
  //   //     setWaitingForEmailAxios(false)
  //   //     // const error_msg = Error(error)
  //   // })
  // };

  const checkBoxHandleChange1 = (event) => {
    setChecked1(event.target.checked);
  };
  const checkBoxHandleChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const otpValidator = (otp) => {
    // console.log(otp.length);
    if (otp.length === 6) {
      setOtpvalidation(true);
      handleSubmit(sentOtpHandler)();
    }
  };

  // OTP verification handler
  const sentOtpHandler = (data) => {
    setWaitingForOTPAxios(true);
    // console.log(data)
    const dataToBeSend = {
      otp: data.otp,
      email: email,
    };

    const url = "/public_api/validate_signup_otp";
    publicAxios
      .post(url, dataToBeSend)
      .then((res) => {
        Toastr("success", "OTP verified Successfully");
        setNavigate({ otp: true, password: true });
        setWaitingForOTPAxios(false);

        setEnteredOtp(data.otp);

        setOtpvalidation(false);
      })
      .catch((error) => {
        setWaitingForOTPAxios(false);
        setOtpvalidation(false);
        let err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };
  const passwordSubmitHandler = (data) => {
    // setWaitingForNewPasswordAxios(true);

    const dataToBeSend = {
      password: data.password,
      address: data.address,
      organization: data.name_of_organization,
      industry: data.industry,
      country: data.country,
      otp: enteredOtp,
      email: email,
      timezone: timeZone,
      is_social_signup: false,
      acceptance_to_updates: checked1 ? true : false,
      acceptance_to_tc: checked2 ? true : false,
    };
    // console.log(data)
    const url = "/public_api/tenant_creation";

    publicAxios
      .post(url, dataToBeSend)
      .then((response) => {
        // console.log("Worked");
        setWaitingForNewPasswordAxios(false);
        navigateto("/login");
        Toastr("success", "Account created Successfully");
      })
      .catch((error) => {
        setWaitingForNewPasswordAxios(false);
        let err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const ResendOtpHandler = () => {
    setWaitingForEmailAxios(true);

    const url = "/public_api/emailcheck";

    publicAxios
      .post(url, resendOtpData)
      .then((response) => {
        setWaitingForEmailAxios(false);
        // console.log(response);
        Toastr("success", "OTP send successfully");
        setNavigate({ otp: true, password: false });
      })
      .catch((error) => {
        setWaitingForEmailAxios(false);
        let err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const signUpHandler = (signup_type, data) => {
    let url = "";
    switch (signup_type) {
      case "google":
        setWaitingForGoodleAxis(true);
        url = "signup_api/google";
        publicAxios
          .get(url)
          .then((response) => {
            window.location.href = response.data.url;
            setWaitingForGoodleAxis(false);
          })
          .catch((error) => {
            let err_msg = Error(error);
            Toastr("error", err_msg);
            setWaitingForGoodleAxis(false);
          });
        break;
      case "email":
        setWaitingForEmailAxios(true);
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        url = "/public_api/emailcheck";

        const dataToBeSend = {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          users_timezone: tz,
        };
        setResendOtpData(dataToBeSend);

        publicAxios
          .post(url, dataToBeSend)
          .then((response) => {
            setWaitingForEmailAxios(false);
            // console.log(response);
            Toastr("success", "OTP send successfully");
            setEmail(data.email);
            setTimeZone(tz);
            setNavigate({ otp: true, password: false });
          })
          .catch((error) => {
            let err_msg = Error(error);
            Toastr("error", err_msg);
            setWaitingForEmailAxios(false);
          });

        // console.log(data, "emailwrked");
        break;
    }
  };

  useEffect(() => {
    if (
      checkValidData.name_of_organization &&
      checkValidData.country &&
      checkValidData.industry &&
      checked1 &&
      checked2 &&
      newPassword &&
      confirmNewPassword
    ) {
      setIsAllOk(false);
    } else {
      setIsAllOk(true);
    }
  }, [checkValidData, newPassword, confirmNewPassword, checked1, checked2]);

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={12}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={4}>
                      <div>
                        {navigate.otp && !navigate.password ? (
                          <>
                            <>
                              <div className="text-center">
                                <div>
                                  <Link to="#" className="logo">
                                    <img
                                      src={logodark}
                                      style={{
                                        height: "100px",
                                        // marginTop: "50px",
                                        marginBottom:'20px'
                                      }}
                                      alt="logo"
                                    />
                                  </Link>
                                </div>
                                <div className="text-center">
                                  <Player
                                    autoplay
                                    loop
                                    src={animationData}
                                    style={{ height: "150px", width: "150px" }}
                                  ></Player>
                                </div>

                                <p
                                  style={{ marginTop: "5px" }}
                                  className="text-muted"
                                >
                                  We need to verify your email
                                </p>
                                <p
                                  style={{ marginTop: "-10px" }}
                                  className="text-muted"
                                >
                                  We've sent a 6-digit confirmation code to your
                                  email{" "}
                                </p>
                                <p style={{ marginTop: "-15px" }}>{email}</p>

                                <p className="text-muted">
                                  Please enter it below to confirm your email
                                  address
                                </p>
                              </div>

                              <div className="p-2 mt-1">
                                {/* {this.props.forgetError && this.props.forgetError ?
                                                        <Alert color="danger" className="mb-4">{this.props.forgetError}</Alert> : null}
                                                    {
                                                        this.props.message ?
                                                            <Alert color="success" className="mb-4">{this.props.message}</Alert> : null
                                                    } */}

                                <form
                                  className="form-horizontal"
                                  onSubmit={handleSubmit(sentOtpHandler)}
                                >
                                  <div className=" mb-4 d-flex justify-content-center">
                                    {otpValidation ? (
                                      <Spinner animation="grow" size="lg" />
                                    ) : (
                                      <input
                                        style={{
                                          paddingLeft: "80px",
                                          paddingRight: "80px",
                                          width: "225px",
                                        }}
                                        type="number"
                                        maxLength={6}
                                        disabled={otpValidation}
                                        className="form-control  font-size-17"
                                        placeholder=""
                                        {...register("otp", {
                                          required: true,
                                          onChange: (event) => {
                                            otpValidator(event.target.value);
                                          },
                                        })}
                                      />
                                    )}
                                  </div>

                                  <div className="mt-2 text-center">
                                    <p
                                      className="text-muted"
                                      style={{
                                        fontSize: "12px",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      Did not received it?{" "}
                                      <Link
                                        to=""
                                        style={{ color: "gray" }}
                                        onClick={ResendOtpHandler}
                                      >
                                        <u>Resend email</u>
                                      </Link>
                                    </p>
                                    <p
                                      className="text-muted mt-5 text-center"
                                      style={{ fontSize: "11px" }}
                                    >
                                      The email might be in your spam folder. If
                                      you are still not getting an email, don't
                                      hesitate to{" "}
                                      <Link
                                        style={{ color: "gray" }}
                                        to="/contact"
                                      >
                                        <u>contact </u>support
                                      </Link>{" "}
                                    </p>
                                  </div>
                                </form>
                              </div>
                            </>
                          </>
                        ) : navigate.otp && navigate.password ? (
                          <>
                            <div className="text-center">
                              <div>
                                <Link to="#" className="logo">
                                  <img
                                    src={logodark}
                                    style={{ height: "70px", marginTop: "130px" }}
                                    alt="logo"
                                  />
                                </Link>
                              </div>

                              <p className="text-muted ">
                                Choose your password.{" "}
                              </p>

                              <p className="text-muted text-center  ">
                                Passwords must contain both uppercase and
                                lowercase characters{" "}
                              </p>
                              <p
                                className="text-muted text-center"
                                style={{ marginTop: "-19px" }}
                              >
                                (e.g., a-z and A-Z)
                              </p>
                              <p
                                className="text-muted text-center"
                                style={{ marginTop: "-18px" }}
                              >
                                {" "}
                                Passwords must contain at least one number
                                (e.g., 0-9){" "}
                              </p>
                            </div>

                            <div className="p-2 mt-4 d-flex justify-content-center">
                              <form
                                className="form-horizontal"
                                onSubmit={handleSubmit(passwordSubmitHandler)}
                              >
                                <div className="d-flex justify-content-center mb-1  mt-3">
                                  <input
                                    style={{ height: "40px", width: "370px" }}
                                    className="form-control  "
                                    // disabled={waitingForNewPasswordAxios}
                                    placeholder="New Password"
                                    type="password"
                                    {...register("password", {
                                      required: true,
                                      onChange: (event) => {
                                        setNewPassword(event.target.value);
                                      },
                                    })}
                                  />
                                </div>
                                {errors.password?.type === "required" && (
                                  <span className="last-page-error">
                                    This field is required
                                  </span>
                                )}

                                <div className=" d-flex justify-content-center mb-1 mt-4">
                                  <input
                                    style={{ height: "40px", width: "370px" }}
                                    className="form-control"
                                    // disabled={waitingForNewPasswordAxios}
                                    placeholder="Confirm Password"
                                    type="password"
                                    {...register("password2", {
                                      required: true,
                                      onChange: (event) => {
                                        setConfirmNewPassword(
                                          event.target.value
                                        );
                                      },
                                    })}
                                  />
                                </div>

                                <span
                                  style={{
                                    color: "red",
                                    display: "block",
                                    marginLeft: "5px",
                                    width: "362px",
                                  }}
                                >
                                  {errorMessage ? errorMessage : null}
                                </span>

                                <p className="text-muted text-center mt-5  mb-4">
                                  Tell us a little more about your organization
                                  so that we can <br /> help tailor your trial
                                  experience to your business needs.
                                </p>
                                <div className="d-flex justify-content-center  mb-1 ">
                                  {/* <i className="ri-building-fill auti-custom-input-icon"></i> */}
                                  {/* <Label htmlFor="Confirm password" className="font-size-12">Name of your Organization</Label> */}
                                  <input
                                    style={{ height: "40px", width: "370px" }}
                                    className="form-control"
                                    name="name_of_organization"
                                    // onChange={handleFormData}
                                    // disabled={waitingForNewPasswordAxios}
                                    placeholder="Name of your Organization"
                                    type="text"
                                    {...register("name_of_organization", {
                                      required: true,
                                      onChange: (event) => {
                                        handleFormData(event);
                                      },
                                    })}
                                  />
                                </div>
                                {errors.name_of_organization?.type ===
                                  "required" && (
                                  <span className="last-page-error">
                                    This field is required
                                  </span>
                                )}
                                <div className="d-flex justify-content-center mt-3 mb-1 ">
                                  {/* <i className="ri-building-fill auti-custom-input-icon"></i> */}
                                  {/* <Label htmlFor="Confirm password" className="font-size-12">Name of your Organization</Label> */}
                                  <input
                                    style={{ height: "40px", width: "370px" }}
                                    className="form-control"
                                    name="Address"
                                    // onChange={handleFormData}
                                    // disabled={waitingForNewPasswordAxios}
                                    placeholder="Address"
                                    type="text"
                                    {...register("address", {
                                      required: true,
                                      onChange: (event) => {
                                        handleFormData(event);
                                      },
                                    })}
                                  />
                                </div>
                                {errors.address?.type === "required" && (
                                  <span className="last-page-error">
                                    This field is required
                                  </span>
                                )}
                                <div className="d-flex justify-content-center mt-3 mb-1">
                                  {/* <i className="ri-arrow-down-s-fill auti-custom-input-icon"></i> */}
                                  {/* <Label htmlFor="Confirm password" className="font-size-12">Select Industry</Label> */}

                                  <select
                                    className="select_box"
                                    style={{ height: "40px", width: "370px" }}
                                    name="industry"
                                    {...register("industry", {
                                      required: true,
                                      onChange: (event) => {
                                        handleFormData(event);
                                      },
                                    })}
                                  >
                                    <option value="">Select Industry</option>
                                    {industryList.map((item, index) => {
                                      return (
                                        <option key={index} value={item.name}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.industry?.type === "required" && (
                                  <span className="last-page-error">
                                    This field is required
                                  </span>
                                )}
                                <div className="d-flex justify-content-center mt-3  mb-1">
                                  {/* <i className="ri-arrow-down-s-fill auti-custom-input-icon"></i> */}
                                  {/* <Label htmlFor="Confirm password">Select Country</Label> */}

                                  <select
                                    className="select_box"
                                    name="country"
                                    style={{ height: "40px", width: "370px" }}
                                    {...register("country", {
                                      required: true,
                                      onChange: (event) => {
                                        handleFormData(event);
                                      },
                                    })}
                                  >
                                    <option value="">Select Country </option>
                                    {country_list.map((item, index) => {
                                      return (
                                        <option key={index} value={item.name}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.country?.type === "required" && (
                                  <span className="last-page-error">
                                    This field is required
                                  </span>
                                )}

                                <div
                                  className="d-flex"
                                  style={{ marginTop: "20px" }}
                                >
                                  <CustomizedCheckbox
                                    checked={checked1}
                                    onChange={checkBoxHandleChange1}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <p
                                    style={{
                                      marginRight: "0px",
                                      marginTop: "7px",
                                    }}
                                  >
                                    Accept terms and conditions
                                  </p>
                                </div>

                                <div className="d-flex">
                                  <CustomizedCheckbox
                                    checked={checked2}
                                    onChange={checkBoxHandleChange2}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <p
                                    style={{
                                      marginRight: "0px",
                                      marginTop: "7px",
                                    }}
                                  >
                                    Accept updates
                                  </p>
                                </div>

                                <div className=" text-center">
                                  <Button
                                    style={{
                                      textTransform: "none",
                                      marginTop: "40px",
                                      backgroundColor: "#5664d2",
                                    }}
                                    disabled={
                                      isAllOk || waitingForNewPasswordAxios
                                    }
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    {" "}
                                    {waitingForNewPasswordAxios ? (
                                      <Spinner animation="grow" size="sm" />
                                    ) : (
                                      "Create your digital signage account "
                                    )}
                                  </Button>
                                </div>
                              </form>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-center">
                              <div>
                                <Link to="#" className="logo mb-5">
                                  <img
                                    src={logodark}
                                    style={{
                                      height: "100px",
                                      marginTop: "0px",
                                     
                                    }}
                                    
                                    alt="logo"
                                  />
                                </Link>
                              </div>

                              <h4
                                className="font-size-15 "
                                style={{ marginTop: "10px" }}
                              >
                                Create Your Free Trail Account
                              </h4>

                              <p className="text-muted font-size-12">
                                Already have an account ?{" "}
                                <Link
                                  to="/login"
                                  style={{ color: "gray" }}
                                  className="fw-medium "
                                >
                                  {" "}
                                  <u>Log in </u>
                                </Link>{" "}
                              </p>
                            </div>
                            <div className="d-flex justify-content-center mb-3">
                              <div className="d-flex flex-column ">
                                {/* <IconButton className="btn    " style={{ backgroundColor: '#4267B2', color: 'white', textTransform: 'none', fontWeight: '600', border: 'none', boxShadow: "10px" }} variant="outlined" startIcon={<FaFacebook />}>
                                                                    Signup with Facebook
                                                                </IconButton> */}
                                <IconButton
                                  onClick={() => signUpHandler("google")}
                                  className="btn"
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "black",
                                    marginTop: "10px",
                                    borderColor: "#CED4DA",
                                    textTransform: "none",
                                    boxShadow: "30px",
                                  }}
                                  variant="outlined"
                                  startIcon={
                                    waitingForGoogleAxis ? (
                                      <div
                                        style={{
                                          width: "26px",
                                          height: "26px",
                                        }}
                                      >
                                        <Spinner
                                          animation="grow"
                                          size="sm"
                                          style={{
                                            marginBottom: "3px",
                                            color: "green",
                                            fontSize: "10px",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        style={{
                                          width: "26px",
                                          height: "26px",
                                        }}
                                        src={google}
                                        alt="Stroke"
                                      />
                                    )
                                  }
                                >
                                  Signup with Google
                                </IconButton>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                              }}
                            >
                              <p className="text-muted font-size-12">
                                Or use your email to sign up
                              </p>
                            </div>
                            <div className="p-2 mt-2 d-flex justify-content-center ">
                              <form
                                className="form-horizontal "
                                onSubmit={handleSubmit((e) =>
                                  signUpHandler("email", e)
                                )}
                              >
                                <div className="mb-3">
                                  <input
                                    style={{ height: "40px", width: "370px" }}
                                    className="form-control"
                                    placeholder="First Name"
                                    {...register("first_name", {
                                      required: true,
                                    })}
                                  />

                                  {errors.first_name?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                      This field is required
                                    </span>
                                  )}
                                </div>

                                <div className="mb-3">
                                  {/* <i className="ri-user-2-line auti-custom-input-icon"></i> */}
                                  {/* <Label htmlFor="useremail" className="font-size-12">Last Name</Label> */}
                                  <input
                                    style={{
                                      height: "40px",
                                      width: "370px",
                                      marginTop: "5px",
                                    }}
                                    className="form-control"
                                    placeholder="Last Name"
                                    {...register("last_name", {
                                      required: true,
                                    })}
                                  />
                                  {errors.last_name?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                      This field is required
                                    </span>
                                  )}
                                </div>

                                <>
                                  <div className="mb-3">
                                    {/* <i className="ri-mail-line auti-custom-input-icon"></i> */}
                                    {/* <Label htmlFor="useremail" className="font-size-12">Email</Label> */}
                                    <input
                                      style={{
                                        height: "40px",
                                        width: "370px",
                                        marginTop: "5px",
                                      }}
                                      placeholder="Email"
                                      className="form-control"
                                      // placeholder="Enter Email"
                                      {...register("email", {
                                        required: true,
                                        pattern:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      })}
                                    />
                                    <div>
                                      {errors.email?.type === "required" && (
                                        <span style={{ color: "red" }}>
                                          This field is required
                                        </span>
                                      )}
                                      {errors.email?.type === "pattern" && (
                                        <span style={{ color: "red" }}>
                                          Please provide a valid email address
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </>

                                <div className="mt-1 text-center">
                                  {/* <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Reset"}</Button> */}
                                  <Button
                                    style={{
                                      backgroundColor: "#5664d2",
                                      marginTop: "30px",
                                      border: "none",
                                      textTransform: "none",
                                    }}
                                    className="w-md waves-effect waves-light "
                                    type="submit"
                                  >
                                    {" "}
                                    {waitingForEmailAxios ? (
                                      <Spinner animation="grow" size="sm" />
                                    ) : (
                                      "Continue with email"
                                    )}
                                  </Button>
                                </div>
                              </form>
                              {/* <div className=" text-center" style={{marginRight:'12px'}}>
                                                  
                                                    <p>  © 2023 Signage </p>
                                                </div> */}
                            </div>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
