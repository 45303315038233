import React, { useEffect, useState } from "react";
import {
  // Card,
  // CardBody,
  // Row,
  Col,
  // Container,
  Form,
  // Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { authAxios } from "../../../axiosInstance";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";

import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../assets/lottie/canvas_lottie.json";
import { updateCanvas } from "../../../store/canvasSlice";
export default function CanvasEdit(props) {
  const dispatch=useDispatch();
  const [loadingOnButton,setLoadingOnButton] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

    useEffect(() => {
      if(props.itemToBeEdited !== null){
        const temp={...props.itemToBeEdited,
            width:props?.itemToBeEdited?.object?.width,
            height:props?.itemToBeEdited?.object?.height
        }
        reset(temp)
      }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    


  const handleCancel = () => {
    // props.setIsModalVisible(false);
  };
 
  const submitHandler = (data) => {
    setLoadingOnButton(true);
    const url = `/api/signage/objectrud/${props.itemToBeEdited.id}`;
    const dataToBeSend = {
      name:data.name,
      file_type:"canvas",
      object: {
       width:data.width,
       height:data.height,
      }
    };
    authAxios.patch(url, dataToBeSend).then((response) => {
      dispatch(updateCanvas(response.data))
      setLoadingOnButton(false);
      Toastr('success','Canvas Edit Succesfully')
      // props.setLinkList((prevState) => [...prevState, response.data]);
      props.toggle();
      handleCancel();
    })
    .catch((error) => {
      setLoadingOnButton(false)
      const err_msg=Error(error)
      Toastr('error',err_msg)
    })
  };


 


  return (
    <div>
      <Modal
        size={'lg'}
        centered
        isOpen={props.isOpen}
        toggle={props.toggle}
      >
        <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
        <ModalBody>
          {/* <Form > */}
          <Form onSubmit={handleSubmit(submitHandler)}>
            <div className='parent-weblink'>
              <div className='div1-weblink'>
   
        <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Name" sm={3}>
        Name
    </Label>
    <Col sm={9}>
        <input
            placeholder="Name"
            type="text"
            className="form-control"
            {...register("name", {
                required: true,
            })}
        />
        {errors.name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
    
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Height" sm={3}>
        Height
    </Label>
    <Col sm={9}>
        <input
            placeholder="Height"
            type="number"
            onWheel={(e) => e.target.blur()}
            className="form-control"
            {...register("height", {
                required: true,
            })}
        />
        {errors.height?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
    
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Width" sm={3}>
    Width
    </Label>
    <Col sm={9}>
        <input
            placeholder="Width"
            type="number"
            onWheel={(e) => e.target.blur()}
            className="form-control"
            {...register("width", {
                required: true,
            })}
        />
        {errors.width?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
    
</FormGroup>

     
        <br/>
        <br/><br/><br/>

        <p>* Please provide the canvas name and dimension details here.</p>
        <p>Please select the size suitable for container you may select for better fit.</p>

              </div>
              <div className='div2-weblink'>
              <Player
              autoplay
              loop
              src={animationData}
              style={{ height: "450px", width: "450px" }}
            ></Player>
              </div>
            </div>

            <ModalFooter>
              <Button
                type="submit"
                color="primary"
                className="w-xs waves-effect waves-light me-1"
                // onClick={submitHandler}
                // disabled={linkIsValid !== "" ? false : true}
              >
                   {loadingOnButton ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Save"
                                )}
                
              </Button>
              <Button
                type="button"
                color="danger"
                className="w-xs waves-effect waves-light me-1"
                onClick={props.toggle}
              >
                Close
              </Button>
            </ModalFooter>
            </Form> 
          {/* </Form> */}
        </ModalBody>
      </Modal>
    </div>

  );
}
