import React, { Component } from "react";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import { } from "../../store/actions";
//Simple bar
import SimpleBar from "simplebar-react";
import SidebarContent from "./SidebarContent";


export default function Sidebar(props) {
    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div data-simplebar className="h-100">
                      {props.type !== "condensed" ? (
                        <SimpleBar style={{ maxHeight: "100%" }}>
                            <SidebarContent />
                        </SimpleBar>
                    ) : <SidebarContent />}  
                {/* <SidebarContent /> */}
                </div>

            </div>
        </React.Fragment>
    );
}




