import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../Apps.css";
import {
  setYouTubes,

  
  deleteYouTube,
} from "../../../.././store/youTubeSlice";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";

import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../../components/CustomButton";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Col, Container, Spinner } from "reactstrap";
import YoutubeCard from "./YoutubeCard";
import { authAxios } from "../../../../axiosInstance";
import { Toastr } from "../../../../helpers/Alert";
import InfiniteScroll from "react-infinite-scroller";
import Error from "../../../../helpers/Error";
export default function Youtube() {
  const youTubeList = useSelector((state) => state.youTubeSlice.user_data);
  const [totalCount, setTotalCount] = useState(0);
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);
  const [searchKeyCount,setSearchKeyCount] = useState(0)
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasNextpage, setHasNextPage] = useState(false);
  const [pageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "YouTube Instance", link: "#" },
    ],
  });

  
  useEffect(() => {
  
    setPage(1);
    setHasNextPage(false);
    dispatch(setYouTubes(null));

    if(searchKey !== prevSearchKey){
      setPrevSearchKey(searchKey)
    }

    setSearchKeyCount(searchKey !==null ? searchKey.length : 0)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[searchKey])


  useEffect(() => {
    const url = `/api/signage/objectlistcreate?object_type=youtube&limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}`;

    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);
        if (youTubeList === null) {
          dispatch(setYouTubes(response.data.results));
        } else {
          dispatch(setYouTubes([...youTubeList, ...response.data.results]));
        }

        setTotalCount(response.data.count);

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        Toastr("error", "Oops!,Something Went Wrong");
      });
          // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,prevSearchKey]);

  const loadFunc = () => {
    if (hasNextpage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
    // setTemp(state => [...state, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
  };
  useEffect(() => {
    return () => {
      dispatch(setYouTubes(null));
    };
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteHandler = (id) => {
    const url = `api/signage/objectrud/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
          dispatch(deleteYouTube(id));
        // }
        Toastr("success", "Clock Instance Deleted Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="YouTube Instance"
          breadcrumbItems={dashboardState.breadcrumbItems}
        ></Breadcrumbs>
        <Col xl={24}>
          <div style={{ minHeight: "calc(100vh - 300px" }}>
            {youTubeList?.length === 0 ? (
              <div className="weblinkEmptyImage text-center">
                <img
                  src={EmptyList}
                  alt=""
                  style={{ width: "450px", height: "450px" }}
                />
                <h6 style={{}}>No Instance Found !</h6>
              </div>
            ) : (
              <>
                {youTubeList === null || loader ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                    }}
                  >
                    <Spinner animation="grow" />
                  </div>
                ) : (
                  <InfiniteScroll
                    pageStart={0}
                    // dataLength={totalCount}
                    // next={loadFunc}
                    loadMore={loadFunc}
                    hasMore={hasNextpage}
                    loader={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="loader"
                        key={0}
                      >
                        <Spinner animation="grow" />
                      </div>
                    }
                  >
                    {youTubeList?.map((item, index) => (
                      <YoutubeCard
                        deleteHandler={deleteHandler}
                        key={index}
                        item={item}
                      />
                    ))}
                  </InfiniteScroll>
                )}
              </>
            )}
          </div>
          {/* {youTubeList &&
            youTubeList.map((item, index) => (
              <div>
                <YoutubeCard key={index} item={item} />
              </div>
            ))} */}
        </Col>
        <div>
          <CustomButton
            title={"+"}
            onClick={() => {
              navigate("/createyoutube");
            }}
          />
        </div>
      </Container>
    </div>
  );
}
