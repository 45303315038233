import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import EmptyList from "../../../assets/SVG/Search Not Found 2.svg";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import { CustomButton } from "../../../components/CustomButton";
import CanvasCard from "./CanvasCard";
import StockCard from "./StockCard";

import InfiniteScroll from "react-infinite-scroller";
import {
  addNewCanvas,
  deleteCanvas,
  setCanvases,
} from "../../../store/canvasSlice";
import { authAxios } from "../../../axiosInstance";
import { Toastr } from "../../../helpers/Alert";
import CanvasAdd from "./AddCanvas";
import Error from "../../../helpers/Error";
import CanvasEdit from "./EditCanvas";
import CanvasModal from "./CanvasInfoModal";
// import CanvasList from "./CanvasList";
export default function Canvas() {
  const dispatch = useDispatch();
  const canvasData = useSelector((state) => state.canvasSlice.user_data);
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);

  const [addModalShow, setAddModalShow] = useState(false);
  const [limit] = useState(20);
  const [page, setPage] = useState(1);
  const [unmounted, setUnMounted] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);
  const [searchKeyCount,setSearchKeyCount] = useState(0)
  
  const [editModalShow, setEditMdoalShow] = useState(false);
  const [itemToBeEdited, setItemToBeEdited] = useState(null);
  const [currentActiveTab, setCurrentActiveTab] = useState(
    localStorage.getItem("canvasTabKey") != null
      ? localStorage.getItem("canvasTabKey")
      : "1"
  );
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "Canvas", link: "#" },
    ],
  });
  const [hasNextPage, setHasNextPage] = useState(false);

  const [modalData, setModalData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const ModalToggle = (dt) => {
    setModalData(dt);
    setIsModalVisible(!isModalVisible);
  };
  const addModalToggle = () => {
    setAddModalShow(!addModalShow);
  };
  const editModalToggle = () => {
    setEditMdoalShow(!editModalShow);
  };
  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  
  useEffect(() => {
  
    setPage(1);
    setHasNextPage(false);
    dispatch(setCanvases(null));

    if(searchKey !== prevSearchKey){
      setPrevSearchKey(searchKey)
    }

    setSearchKeyCount(searchKey !==null ? searchKey.length : 0)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[searchKey])

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    let url;
    if (tenantDetails?.app_type === "Admin") {
      url = `/stock/api/signage/objectlistcreate?object_type=canvas&limit=${limit}&offset=${
        limit * (page - 1)
      }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}&file_type=canvas`;
    } else {
      url = `/api/signage/objectlistcreate?object_type=canvas&limit=${limit}&offset=${
        limit * (page - 1)
      }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}&file_type=canvas`;
    }

    if (currentActiveTab === "1") {
      // const url = `/api/signage/objectlistcreate?object_type=canvas&limit=${limit}&offset=${
      //   limit * (page - 1)
      // }&search=&file_type=canvas`;

      authAxios
        .get(url)
        .then((response) => {
          if (!unmounted) {
            if (canvasData === null) {
              dispatch(setCanvases(response.data.results));
            } else {
              dispatch(addNewCanvas(response.data.results));
            }

            setTotalCount(response.data.count);

            if (response.data.next) {
              setHasNextPage(true);
            } else {
              setHasNextPage(false);
            }
          }
        })
        .catch((error) => {
          Toastr("error", "Oops!,Something Went Wrong");
        });
    } else if (currentActiveTab === "2") {
      const stockUrl = `/api/signage/stockobjectlistcreate/public?object_type=canvas&limit=${limit}&offset=${
        limit * (page - 1)
      }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}&file_type=canvas`;
      authAxios
        .get(stockUrl)
        .then((response) => {
          if (!unmounted) {
            if (canvasData === null) {
              dispatch(setCanvases(response.data.results));
            } else {
              dispatch(addNewCanvas(response.data.results));
            }

            setTotalCount(response.data.count);

            if (response.data.next) {
              setHasNextPage(true);
            } else {
              setHasNextPage(false);
            }
          }
        })
        .catch((error) => {
          Toastr("error", "Oops!,Something Went Wrong");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveTab, page,prevSearchKey]);

  const toggleCustomJustified = (tab) => {
    if (currentActiveTab !== tab) {
      localStorage.setItem("canvasTabKey", tab);
      setCurrentActiveTab(tab);
      setPage(1);
      // setTotalCount(0);
      dispatch(setCanvases(null));
    }
  };

  useEffect(() => {
    return function () {
      setUnMounted(true);
      dispatch(setCanvases(null));
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFunc = () => {
    if (hasNextPage) {
      // console.log('wrked')
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
    // setTemp(state => [...state, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
  };

  // State for current active Tab

  const deleteHandler = (id) => {
    let url1;
    if (tenantDetails?.app_type === "Admin") {
      url1 = `/stock/api/signage/objectrud/${id}`;
    } else {
      url1 = `/api/signage/objectrud/${id}`;
    }

    authAxios
      .delete(url1)
      .then((res) => {
        dispatch(deleteCanvas(id));
        Toastr("success", "Canvas Deleted Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const editHandler = (data) => {
    setItemToBeEdited(data);

    editModalToggle();
  };

  //Template Copy Handler
  const copyCanvasHandler = (id) => {
    const url = `/api/signage/copystockobjectcreate?tenant=public&stockfile_id=${id}`;
    authAxios
      .post(url)
      .then((response) => {
        Toastr("success", "Canvas Copied Successfully!");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <>
      <React.Fragment>
        {addModalShow && (
          <CanvasAdd
            isOpen={addModalShow}
            toggle={addModalToggle}
            title={"Add Canvas"}
          />
        )}
        {editModalShow && (
          <CanvasEdit
            isOpen={editModalShow}
            toggle={editModalToggle}
            title={"Edit Canvas"}
            itemToBeEdited={itemToBeEdited}
          />
        )}
        {isModalVisible && (
          <CanvasModal
            isOpen={isModalVisible}
            toggle={setIsModalVisible}
            data={modalData}
          />
        )}

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Canvas"
              breadcrumbItems={dashboardState.breadcrumbItems}
            ></Breadcrumbs>
            <Col xl={24}>
              <Card style={{ minHeight: "70vh" }}>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className="d-sm-block">My Canvas</span>
                      </NavLink>
                    </NavItem>

                    {tenantDetails?.app_type !== "Admin" && (
                      <>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: currentActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleCustomJustified("2");
                            }}
                          >
                            <span className="d-sm-block">Canvas Gallery</span>

                            {/* <span style={{ fontSize: 14, fontFamily: "Lato" }}>
                        <img
                          src={Gallery}
                          style={{ width: "15px", marginRight: "0.5rem" }}
                          alt=""
                        />
                        Custom Templates
                      </span> */}
                          </NavLink>
                        </NavItem>
                      </>
                    )}
                  </Nav>

                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="1">
                      {/* <div className="assetList-holder"> */}
                      {currentActiveTab === "1" && (
                        <>
                          {canvasData === null ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Spinner animation="grow" />
                            </div>
                          ) : canvasData?.length === 0 ? (
                            <>
                              <div className=" text-center">
                                <img
                                  src={EmptyList}
                                  alt=""
                                  style={{ width: "450px", height: "450px" }}
                                />
                                <h6 style={{}}>No Canvas Found !</h6>
                              </div>
                            </>
                          ) : (
                            <>
                              <InfiniteScroll
                                pageStart={0}
                                // dataLength={totalCount}
                                // next={loadFunc}
                                loadMore={loadFunc}
                                hasMore={hasNextPage}
                                loader={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    className="loader"
                                    key={0}
                                  >
                                    <Spinner animation="grow" />
                                  </div>
                                }
                              >
                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    maxHeight: Math.round(windowHeight - 313),

                                    // maxHeight:Math.round( windowHeight > 1250 ? windowHeight * 0.78 :  windowHeight < 900 ? windowHeight * 0.625 :  windowHeight * 0.71),
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                  }}
                                >
                                  {canvasData?.map((items, index) => (
                                    <CanvasCard
                                      key={index}
                                      item={items}
                                      editModalHandler={editHandler}
                                      deleteHandler={deleteHandler}
                                      modalToggle={ModalToggle}
                                      is_admin={
                                        tenantDetails?.app_type === "Admin"
                                          ? true
                                          : false
                                      }
                                    />
                                  ))}
                                </Row>
                              </InfiniteScroll>
                            </>
                          )}
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      {currentActiveTab === "2" && (
                        <>
                          {canvasData === null ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Spinner animation="grow" />
                            </div>
                          ) : canvasData?.length === 0 ? (
                            <>
                              <div className=" text-center">
                                <img
                                  src={EmptyList}
                                  alt=""
                                  style={{ width: "450px", height: "450px" }}
                                />
                                <h6 style={{}}>No Stock Canvas Found !</h6>
                              </div>
                            </>
                          ) : (
                            <>
                              <InfiniteScroll
                                pageStart={0}
                                // dataLength={totalCount}
                                // next={loadFunc}
                                loadMore={loadFunc}
                                hasMore={hasNextPage}
                                loader={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    className="loader"
                                    key={0}
                                  >
                                    <Spinner animation="grow" />
                                  </div>
                                }
                              >
                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    maxHeight: Math.round(windowHeight - 313),

                                    // maxHeight:Math.round( windowHeight > 1250 ? windowHeight * 0.78 :  windowHeight < 900 ? windowHeight * 0.625 :  windowHeight * 0.71),
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                  }}
                                >
                                  {canvasData?.map((items, index) => (
                                    <StockCard
                                      key={index}
                                      item={items}
                                      modalToggle={ModalToggle}
                                      copyCanvasHandler={copyCanvasHandler}
                                    />
                                  ))}
                                </Row>
                              </InfiniteScroll>
                            </>
                          )}
                        </>
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            {currentActiveTab === "1" && (
              <div>
                <CustomButton
                  onClick={() => {
                    addModalToggle();
                  }}
                  title={"+"}
                />
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
