import React, { useEffect, useState } from "react";
import Modals from "../../../components/Modal";
import { Col } from "reactstrap";
import urlMaker from "../../../helpers/urlMaker";
import { Extension, removeExtension } from "../../../helpers/Filename";
// import formatBytes from '../../../helpers/formatBytes';
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import { useDispatch } from "react-redux";
import { addMediaTag, editImage, editVideo } from "../../../store/mediaSlice";
function MediaViewer(props) {
  const [imageLoader, setImageLoader] = useState(true);
  // const [tags,setTags]=useState([])
  // const [loading,setLoading] = useState(true)
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();
  // const[waitingForAxios,setWaitingForAxios]=useState(false)

  console.log(props.data);

  useEffect(() => {
    const tmp = props?.data?.tag_file?.map((data) => ({
      ...data,
      label: data.tag,
      value: data.tag,
    }));

    setValue(tmp);

    console.log(props.data);
  }, [props.data]);

  const handleCreate = (inputValue) => {
    const url = "/api/signage/tagslistcreateview";
    const data = {
      tag: inputValue,
      type: "file",
      file: props.data.id,
    };

    authAxios
      .post(url, data)
      .then((res) => {
        const temp = { ...res.data, label: res.data.tag, value: res.data.tag };
        // setTags((state) => [...state,temp])
        setValue((state) => [...state, temp]);
        dispatch(addMediaTag({ ...res.data, type: props?.data?.file_type }));

        // Toastr('success','Tag Added Successfully')
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const clearHandler = (newValue, oldValue) => {
    console.log("wrked");
    console.log(newValue);
    const id = oldValue.removedValue.id;
    const url = `/api/signage/tagsrudview/${id}`;
    authAxios
      .delete(url)
      .then((res) => {
        const data = { ...props.data, tag_file: newValue };
        if (props.data.file_type === "image") {
          dispatch(editImage(data));
        } else {
          dispatch(editVideo(data));
        }
        setValue(newValue);
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <>
      <Modals
        title={"Media Viewer"}
        isOpen={props.isModalVisible}
        toggle={() => props.setIsModalVisible(false)}
        padding={0}
        size="lg"
      >
        <div className="canvasInfo__Main_container">
          <div style={{}}>
            {props.data.file_type === "image" ? (
              <>
                <div
                  style={{
                    borderRadius: "10px",

                    display: "flex",
                    justifyContent: "center",
                    width: "600px",
                    height: "300px",
                  }}
                >
                  <img
                    src={
                      props.data.is_stock
                        ? urlMaker(props.data.file, "", true)
                        : urlMaker(props.data.file)
                    }
                    alt="Stroke"
                    class="fade-in-image"
                    style={{
                      maxWidth: "550px",
                      height: "300px",
                      objectFit: "contain",
                    }}
                  ></img>
                </div>
              </>
            ) : (
              <>
                <video
                  onLoad={() => setImageLoader(false)}
                  // height="100%"
                  style={{
                    height: "300px",
                    width: "550px",
                    borderRadius: "5px",
                    marginBottom: "20px",
                    objectFit: "contain",
                  }}
                  autoPlay
                  controls
                >
                  <source
                    src={
                      props.data.is_stock
                        ? urlMaker(props.data.file, "", true)
                        : urlMaker(props.data.file)
                    }
                    type={
                      props?.data.file_type +
                      "/" +
                      Extension(props?.data.uploaded_file_name)
                    }
                  />
                </video>
              </>
            )}
          </div>
        </div>
        <div className="canvasInfo_Detail_container">
          <div>
            <div className="image-viewer-details">
              <p className="image-viewer-details-head">Details</p>

              <table className="image-viewer-details-table ">
                <tbody>
                  <tr>
                    <td className="image-viewer-details-table-td">Name</td>
                    <td
                      className="image-viewer-details-table-td1"
                      style={{
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                        overflowWrap: " break-word",
                      }}
                    >
                      {removeExtension(props.data.uploaded_file_name)}
                    </td>
                  </tr>
                  <tr>
                    <td className="image-viewer-details-table-td">Format</td>
                    <td className="image-viewer-details-table-td1">
                      {props?.data.file_type +
                        "/" +
                        Extension(props?.data.uploaded_file_name)}
                    </td>
                  </tr>
                  <tr>
                    <td className="image-viewer-details-table-td">File size</td>
                    <td className="image-viewer-details-table-td1">
                      {props?.data.file_size}
                    </td>
                  </tr>
                  <tr>
                    <td className="image-viewer-details-table-td">
                      Uploaded by
                    </td>
                    <td
                      className="image-viewer-details-table-td1"
                      style={{ textTransform: "capitalize" }}
                    >
                      {props?.data.created_by}
                    </td>
                  </tr>
                  <tr>
                    <td className="image-viewer-details-table-td">
                      Uploaded date
                    </td>
                    <td className="image-viewer-details-table-td1">
                      {moment(props?.data.created_date).format("MMM DD,YYYY")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Col className="ml-3 mt-5" style={{ padding: "10px" }}>
              <span style={{ paddingLeft: "10px" }}>Tags </span>
              <CreatableSelect
                isMulti
                onCreateOption={handleCreate}
                placeholder="No Tags Added"
                value={value}
                isClearable={false}
                onChange={(newValue, oldValue) =>
                  clearHandler(newValue, oldValue)
                }
              />
            </Col>
          </div>
        </div>
      </Modals>
    </>
  );
}

export default MediaViewer;
