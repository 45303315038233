import React,{ useEffect, useState } from 'react'
import './ContentForSchedule.css'

function WeatherComponent({currentPlayingItem}) {

    const [weatherList, setWeatherList] = useState([{}, {}, {}]);
   
    let tempArray = [{},{},{}];

    const kelvinToFarenheit = (k) => {
        return (k - 273.15).toFixed(2);
      };

    useEffect(() => {
      
    
        currentPlayingItem?.object?.weatherlist.map((data, index) => {
              if (Object.keys(data).length !== 0) {
                const apiKey = "73da0bb73bd930dba81d9b24b7e974b2";
    
                const url = `https://api.openweathermap.org/data/2.5/weather?lat=${data.latitude}&lon=${data.longitude}&appid=${apiKey}`;
    
                fetch(url)
                  .then((res) => res.json())
                  .then((ndata) => {
                    tempArray[index] = ndata;
    
                    setWeatherList((state) => [...tempArray]);
                  });
              }
            });
     
        
      }, []);
  return (
    <React.Fragment>
    {weatherList.map((item, index) => (
      <>
        {Object.keys(item).length !== 0 && (
          <div className="weather_cardsList" key={item.id}>
            <div key={item.id}>
              <img
                src={`http://openweathermap.org/img/w/${item.weather[0]?.icon}.png`}
                alt="weather status icon"
                className="weather-icon"
              />
              {currentPlayingItem?.object?.unit_type === "Celsius" ? (
                <p className="h2">
                  {kelvinToFarenheit(item.main?.temp_min)}&deg; C
                </p>
              ) : (
                <p className="h2">{item.main?.temp_min}&deg; F</p>
              )}
              <p>{item.name}</p>
            </div>
          </div>
        )}
      </>
    ))}
  </React.Fragment>
  )
}

export default WeatherComponent