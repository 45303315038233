import React, { useState } from "react";
import Modals from "../../../components/Modal";

import { Extension, removeExtension } from "../../../helpers/Filename";
// import formatBytes from '../../../helpers/formatBytes';

import { useSelector } from "react-redux";
import adminUrlMaker from "../../../helpers/adminUrlMaker";
import moment from "moment";

function MediaViewerAdmin({ isModalVisible, setIsModalVisible, data }) {
  const customer = useSelector((state) => state.customerSlice.customer);

  const [imageLoader, setImageLoader] = useState(true);

  return (
    <>
      <Modals
        title={"Media Viewer"}
        isOpen={isModalVisible}
        toggle={() => setIsModalVisible(false)}
        padding={0}
        size="lg"
      >
        <div className="canvasInfo__Main_container">
          <div style={{}}>
            {data?.file_type === "image" || data?.file_type === "canvas" ? (
              <>
                <div
                  style={{
                    borderRadius: "10px",

                    display: "flex",
                    justifyContent: "center",
                    width: "600px",
                    height: "300px",
                  }}
                >
                  <img
                    src={
                      data?.file_type === "image"
                        ? adminUrlMaker(data?.file, "", customer?.name)
                        : adminUrlMaker(data?.file, "canvas", customer?.name)
                    }
                    alt="Image"
                    className="fade-in-image"
                    style={{
                      maxWidth: "550px",
                      height: "300px",
                      objectFit: "contain",
                    }}
                  ></img>
                </div>
              </>
            ) : (
              <>
                <video
                  onLoad={() => setImageLoader(false)}
                  // height="100%"
                  style={{
                    height: "300px",
                    width: "550px",
                    borderRadius: "5px",
                    marginBottom: "20px",
                    objectFit: "contain",
                  }}
                  autoPlay
                  controls
                >
                  <source
                    src={adminUrlMaker(data.file, "", customer?.name)}
                    type={
                      data.file_type + "/" + Extension(data.uploaded_file_name)
                    }
                  />
                </video>
              </>
            )}
          </div>
        </div>
        <div className="canvasInfo_Detail_container">
          <div>
            <div className="image-viewer-details">
              <p className="image-viewer-details-head">Details</p>

              <table className="image-viewer-details-table">
                <tbody>
                  <tr>
                    <td className="image-viewer-details-table-td">Name</td>
                    <td
                      className="image-viewer-details-table-td1"
                      style={{
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                        overflowWrap: " break-word",
                      }}
                    >
                      {data?.file_type === "canvas"
                        ? data.name
                        : removeExtension(data.uploaded_file_name)}
                    </td>
                  </tr>
                  <tr>
                    <td className="image-viewer-details-table-td">Format</td>
                    <td className="image-viewer-details-table-td1">
                      {data?.file_type === "canvas"
                        ? data.file_type
                        : data.file_type +
                          "/" +
                          Extension(data.uploaded_file_name)}
                    </td>
                  </tr>
                  {data?.file_type !== "canvas" && (
                    <tr>
                      <td className="image-viewer-details-table-td">
                        File size
                      </td>
                      <td className="image-viewer-details-table-td1">
                        {data?.file_size}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="image-viewer-details-table-td">
                      Uploaded by
                    </td>
                    <td
                      className="image-viewer-details-table-td1"
                      style={{ textTransform: "capitalize" }}
                    >
                      {data?.created_by}
                    </td>
                  </tr>
                  <tr>
                    <td className="image-viewer-details-table-td">
                      Uploaded date
                    </td>
                    <td className="image-viewer-details-table-td1">
                      {moment(data?.created_date).format("MMM DD,YYYY")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modals>
    </>
  );
}

export default MediaViewerAdmin;
