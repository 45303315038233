
import { ReactComponent as Animation } from '../../../assets/icons/animation.svg'
import { ReactComponent as Calendar } from '../../../assets/icons/calendar.svg'
import moment from "moment";
import React, { useState, useEffect } from "react";
import AnimationIcon from '@mui/icons-material/Animation';
import "./ScheduleCreator.css";
import WeatherImage from "../../Pages/Apps/icons/weather.svg"
import ClockImage from "../../Pages/Apps/icons/clock.svg"
import YoutubeImage from "../../Pages/Apps/icons/youtube.svg"
import TextImage from "../../Pages/Apps/icons/textBoard.svg"
import { Droppable, Draggable } from "react-beautiful-dnd";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { baseURL } from "../../../baseURL";
import { ReactComponent as Speaker } from "../../../assets/icons/speaker.svg";
import { ReactComponent as Muted } from "../../../assets/icons/mute.svg";
import ScheduleModalContent from "./ScheduleModalContent";
import Duration from "./Duration";
import { setDroppableBagItems } from "../../../store/bagItems";
import { useSelector, useDispatch } from "react-redux";
import ScheduleEditModal from "./ScheduleEditModal"
import ScheduleAnimationModal from "./ScheduleAnimationModal";
import { animationDictionary } from "./animationDictionary";
import linkThumb from '../../../assets/images/weblinkThumb.png'
import { Card, CardBody } from 'reactstrap';
import urlMaker from '../../../helpers/urlMaker';
import { IconButton, Tooltip } from '@material-ui/core';
import Delete from '@mui/icons-material/Delete';
export default function DroppableComponent(props) {
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [isAnimationModalVisible, setIsAnimationModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({})
  const [fitContent, setFitContent] = useState(true)
  const dispatch = useDispatch();
  const droppableBagItems = useSelector(
    (state) => state.bagItems.droppableBagItems
  );


  // This object is to select user readable animaiton type







  const pickThumb = (item) => {
    if (item.file_type === "link") {
      return linkThumb;
    } else if (
      item.file_type === "image" ||
      item.file_type === "video" 
    ) {
      return urlMaker(item.thumbnail, 'image');
    }
    else if(item.file_type === "canvas" ){
      return urlMaker(item.thumbnail,'canvas');
    }
    else if(item.file_type === 'weather'){
      return WeatherImage
    }
    else if(item.file_type === 'clock'){
      return ClockImage
    }
    else if(item.file_type === 'youtube'){
      if (item.thumbnail !== null)
      return urlMaker(item.thumbnail,'youtube');
      else return YoutubeImage
    }
    else if(item.file_type === 'text'){
      return TextImage
    }
  };




  const scheduleHandler = (index, item) => {
    setIsScheduleModalVisible(true);
    setSelectedItem({ ...item, "index": index })
  };


  const animationHandler = (index, item) => {
    setIsAnimationModalVisible(true);
    setSelectedItem({ ...item, "index": index })
  };









  const deleteHandler = (index) => {
    // We need to take the copy of items array
    const ItemsArray = [...props.items[props.items.id]];
    // Now we need to delete the clicked item
    ItemsArray.splice(index, 1);
    // Now we need to make a copy of props.items with the new values
    const ItemsAfterDeletion = {
      ...props.items,
      [props.items.id]: ItemsArray,
    };
    // Now we need to recreate the droppable bag item with new values
    const tempDroppableBagItems = [...droppableBagItems];
    tempDroppableBagItems.splice(props.divIndex, 1, ItemsAfterDeletion);
    dispatch(
      setDroppableBagItems({ droppableBagItems: tempDroppableBagItems })
    );
  };

  const objectFitHandler = (index, fitType) => {
    // We need to take the copy of items array
    const ItemsArray = [...props.items[props.items.id]];
    let selectedItem = ItemsArray[index]
    selectedItem = { ...selectedItem, object_fit: fitType }
    // Now we need to delete the clicked item
    ItemsArray.splice(index, 1, selectedItem);
    // Now we need to make a copy of props.items with the new values
    const itemsAfterObjectFitHandling = {
      ...props.items,
      [props.items.id]: ItemsArray,
    };
    // Now we need to recreate the droppable bag item with new values
    const tempDroppableBagItems = [...droppableBagItems];
    tempDroppableBagItems.splice(props.divIndex, 1, itemsAfterObjectFitHandling);
    dispatch(
      setDroppableBagItems({ droppableBagItems: tempDroppableBagItems })
    );
  }


  const muteHandler = (index) => {
    // We need to take the copy of items array
    const ItemsArray = [...props.items[props.items.id]];
    let selectedItem = ItemsArray[index]
    const currentMuteStatus = selectedItem.muted
    selectedItem = { ...selectedItem, muted: !currentMuteStatus }
    // Now we need to delete the clicked item
    ItemsArray.splice(index, 1, selectedItem);
    // Now we need to make a copy of props.items with the new values
    const ItemsAfterMuteHandling = {
      ...props.items,
      [props.items.id]: ItemsArray,
    };
    // Now we need to recreate the droppable bag item with new values
    const tempDroppableBagItems = [...droppableBagItems];
    tempDroppableBagItems.splice(props.divIndex, 1, ItemsAfterMuteHandling);
    dispatch(
      setDroppableBagItems({ droppableBagItems: tempDroppableBagItems })
    );
  }

  const scheduleModalToggle = () => {
    setIsScheduleModalVisible(!isScheduleModalVisible)
  }

  return (

    <React.Fragment>
        {isScheduleModalVisible &&
                        <ScheduleEditModal
                          visible={isScheduleModalVisible}
                          setVisible={setIsScheduleModalVisible}
                          divIndex={props.divIndex}
                          selectedItem={selectedItem}
                          items={props.items}
                          toggle={scheduleModalToggle}
                        />
                        }
                          {isAnimationModalVisible &&
                        <ScheduleAnimationModal
                          visible={isAnimationModalVisible}
                          setVisible={setIsAnimationModalVisible}
                          divIndex={props.divIndex}
                          selectedItem={selectedItem}
                          items={props.items}
                        />
                          }


    <Droppable droppableId={props.droppableId}>
      {(provided, snapshot) => (
        <ul ref={provided.innerRef} className='droppable-bag'>
          {props.items[props.droppableId].map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <li
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={provided.draggableProps.style}>
                  <Card key={index} style={{ border:'.1px solid rgb(220, 218, 218)'}}>
                    <CardBody>
                      <div className='media_card'>
                        <img
                          src={pickThumb(item)}

                          alt='thumbnail'
                          className='media_card_thumbnail'
                        />
                        <div className='media_card_file_name'>
                          {item.uploaded_file_name}
                          <div className={"scheduler-content-type-wrapper" + (item.object_fit === "contain" ? " content-selected" : "")}
                            onClick={() => objectFitHandler(index, "contain")}
                          >
                            Fit container
                          </div>
                          <div className={"scheduler-content-type-wrapper" + (item.object_fit === "fill" ? " content-selected" : "")}
                            onClick={() => objectFitHandler(index, "fill")}
                          >
                            Fill container
                          </div>
                        </div>
                         <div className='file_details'>
                          <div className='media_card_details'>
                            {"Duration (Seconds): " +
                              Number(item.scheduled_duration).toFixed(2)}
                          </div>
                          <div className='media_card_details'>
                            <div className='duration-wrapper'>
                              <Duration
                                initialDuration={Number(item.scheduled_duration)}
                                item={item}
                                items={props.items}
                                divIndex={props.divIndex}
                                index={index}
                                file_type={item.file_type}
                              />
                            </div>
                            <div className="icon-wrapper-schedule">
                              {item.file_type === "video" ? (

                                item.muted ?
                                  <Muted style={{ height: "18px", width: "18px", marginTop: "15px", marginLeft: "-20px" }}
                                    onClick={() => muteHandler(index)} />

                                  :
                                  <Speaker style={{ height: "20px", width: "20px", marginTop: "15px", marginLeft: "-20px" }}
                                    onClick={() => muteHandler(index)} />
                              ) : (
                                null
                              )}
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className='file_details'>
                          <div className='icon-wrapper-schedule'>
                            <Tooltip title='Edit Schedule' arrow>
                              <CalendarMonthIcon style={{ width: "20px", height: "20px" }} onClick={() => scheduleHandler(index, item)} />
                            </Tooltip>
                          </div>
                          <div className='media_card_details'>
                            Scheduled Time
                          </div>
                          <div className='media_card_details'>
                            {item.start_date} <span style={{ textTransform: "lowercase" }}>&ensp;to&ensp;</span> {item.end_date}
                          </div>
                          <div className='media_card_details'>
                            {item.start_time}
                            <span style={{ textTransform: "lowercase" }}>&ensp;to&ensp;</span>
                            {item.end_time}

                          </div>

                        </div>
                        <div className='file_details'>
                          <div className='icon-wrapper-schedule'>
                            <Tooltip title='Edit Animation' arrow>
                              <AnimationIcon style={{ width: "20px", height: "20px" }} onClick={() => animationHandler(index, item)} />
                            </Tooltip>
                          </div>
                          <div className='media_card_details'>
                            {"Style :"}{animationDictionary[item.animation]}
                          </div>
                          <div className='media_card_details' >
                            {"Duration : "}{item.animation_duration}<span style={{ textTransform: "lowercase" }}>{" s"}</span>
                          </div>

                        </div>
                        <div className='icon-wrapper-schedule'>
                          <Tooltip title='Remove' arrow>
                            <IconButton onClick={() => deleteHandler(index)}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </div>

                      
                      </div>
                    </CardBody>
                  </Card>
                </li>

              )}

            </Draggable>
          ))}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
    </React.Fragment>
  );
}
