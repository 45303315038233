import {
 
  ModalFooter,
  // ModalHeader,
  // FormGroup,
  // Label,
  // Form,
  // CardBody,
  Button,
  Spinner,
  Col,
} from "reactstrap";
import React from "react";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
// import { nanoid } from "nanoid";
// import { TimePicker, DatePicker, Button } from "antd";
import { useState } from "react";
import { setDroppableBagItems } from "../../../store/bagItems";
// import AbaciHorizontalLine from "../../../components/AbaciHorizontalLine/AbaciHorizontalLine";
// import AbaciButton from "../../../components/AbaciButton/AbaciButton";
import Modals from "../../../components/Modal";
// const { RangePicker } = DatePicker;
import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite.min.css";

export default function ScheduleModalContent(props) {
  const [dateRange, setDateRange] = useState();
  const [timeRange, setTimeRange] = useState()
  const [saveDisable, setSaveDisable] = useState(true);
  const [loadingOnButton, setLoadingOnButton] = React.useState(false);


  const droppableBagItems = useSelector(
    (state) => state.bagItems.droppableBagItems
  );
  const dispatch = useDispatch();
  const saveHandler = () => {
    // Getting the div name
    const div_name = droppableBagItems[props.divIndex].id;
    // Getting the items in the div of droppableBag
    const currentBagItems = [...droppableBagItems[props.divIndex][div_name]];
    // Now we need to change the date of the selected item with the date input
    const datesChangedItem = {
      ...props.selectedItem,
      start_date: moment(dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(dateRange[1]).format("YYYY-MM-DD"),
      start_time: moment(timeRange[0]).format("HH:mm:ss"),
      end_time: moment(timeRange[1]).format("HH:mm:ss"),
    };
    // Now we need to replace the above item with the same item inside the current droppable bag
    currentBagItems.splice(props.selectedItem.index, 1, datesChangedItem);
    // Now we need to make a copy of droppable bag items for current div with the new values
    const datesChangedItems = {
      ...droppableBagItems[props.divIndex],
      [div_name]: currentBagItems,
    };
    // Now we need to dispatch the above to the store
    const tempDroppableBagItems = [...droppableBagItems];
    tempDroppableBagItems.splice(props.divIndex, 1, datesChangedItems);
    dispatch(
      setDroppableBagItems({ droppableBagItems: tempDroppableBagItems })
    );
    handleCancel()
  };
  const handleCancel = () => {
    props.setVisible(false);
    setDateRange([])
    setTimeRange([])
  };
  React.useEffect(()=>{
    if (typeof dateRange !== "undefined" && typeof timeRange !=="undefined"){
        if(dateRange.length !== 0 && timeRange.length !== 0){
            setSaveDisable(false)
        }
        else {
            setSaveDisable(true)
        }
    }
    else {
        setSaveDisable(true)
    }
  
  },[timeRange, dateRange])


  const dateHandler = (e) => {
    setDateRange(e)
  }

  const timeHandler = (e) => {
    setTimeRange(e)
  }

  return (
    <Modals
    title='Schedule Edit'
    isOpen={props.visible}
    size={'md'}
    NotCentered={true}
    toggle={props.toggle}
    >

    <div style={{margin:"20px"}}>
      Select the Start date and End Date
      <br />
      <Col xl={8}>
      <DateRangePicker 
       ranges={[]}
       onChange={(e) => dateHandler(e)}
       placeholder="Select Date Range"
       />
       </Col>
      {/* <RangePicker 
        value = {dateRange}
        bordered={false} onChange={setDateRange} /> */}
      <br />
      <br />
      Select the Start time and End time
      <br />
    
      <Col xl={8}>
      <DateRangePicker
      format="HH:mm:ss"
      placeholder="Select Time Range"
      ranges={[]}
      defaultCalendarValue={[new Date('2022-02-01 00:00:00'), new Date('2022-05-01 23:59:59')]}
      onChange={(e) => timeHandler(e)}
      style={{width:'230px'}}
    />
    </Col>
      {/* <TimePicker.RangePicker 
        value = {timeRange}

      bordered={false} onChange={setTimeRange} /> */}
      <br />
      <br />
    </div>
    <ModalFooter>
    <Button
                // type="submit"
                color="primary"
                className="w-xs waves-effect waves-light me-1"
                onClick={saveHandler}
                disabled={saveDisable} >
                   {loadingOnButton ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Save"
                                )}
                
              </Button>
              <Button
                type="button"
                color="danger"
                className="w-xs waves-effect waves-light me-1"
                onClick={props.toggle}
              >
                Close
              </Button>
    </ModalFooter>
  
    </Modals>
  );
}
