import React from "react";
// import AbaciCard from "../../../components/AbaciCard/AbaciCard"

// import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./ScheduleContents.css";
// import { Tooltip, Popconfirm } from "antd";
// import moment from "moment";
import { useSelector } from "react-redux";
// import DivContents from "./DivContents";
// import axios from "axios";
import EmptyList from "../../../assets/SVG/Search Not Found 1.svg";
// import AbaciLoader from "../../../components/AbaciLoader/AbaciLoader";
// import { deleteSchedule } from "../../../store/scheduleSlice";
import { Spinner } from "reactstrap";
import { SchedulerCard } from "./SchedulerCard";
import InfiniteScroll from "react-infinite-scroller";

export default function ScheduleContents({
  loadFunc,
  hasMore,
  castScreenModalToggle,
}) {
  const schedules = useSelector((state) => state.scheduleSlice.data);

  // const dispatch = useDispatch();

  // const history = useHistory()

  // const editHandler = (id) => {
  //   history.push({
  //     pathname: "/home/scheduler/" + id,
  //   });
  // };
  // const deleteHandler = (id, index) => {
  //   const deleteUrl = "api/signage/schedules/" + id;
  //   axios.delete(deleteUrl).then((response) => {
  //     let tempList = [...schedules];
  //     tempList.splice(index, 1);
  //     // message.success("The schedule has bee deleted !");
  //     dispatch(deleteSchedule(index));
  //   });
  // };

  return (
    <>
      {/* {schedules === null ? (
        <AbaciLoader />
      ) : */}

      {schedules?.length === 0 ? (
        <div className="text-center">
          <img
            src={EmptyList}
            alt=""
            style={{ width: "450px", height: "450px" }}
          />
          <p>No Schedules Found !</p>
        </div>
      ) : (
        <InfiniteScroll
          pageStart={0}
          // dataLength={totalCount}
          // next={loadFunc}
          loadMore={loadFunc}
          hasMore={hasMore}
          loader={
            <div
              style={{ display: "flex", justifyContent: "center" }}
              className="loader"
              key={0}
            >
              <Spinner animation="grow" />
            </div>
          }
        >
          {schedules?.map((item, index) => (
            <div key={index}>
              <SchedulerCard
                item={item}
                castScreenModalToggle={castScreenModalToggle}
              />
            </div>
          ))}
        </InfiniteScroll>
      )}
    </>
  );
}
