import axios from 'axios'
import { authAxios } from '../../../axiosInstance'
import { ReactComponent as Close } from "../../../assets/icons/close.svg";

import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import ScheduleCreator from './ScheduleCreator'
import { nanoid } from "nanoid";
import { useSelector, useDispatch } from "react-redux";
import { setDroppableBagItems, setCollections, addToCollections } from '../../../store/bagItems'
import "./Scheduler.css"
import SavePreviewAndPublish from './SavePreviewAndPublish';
import moment from 'moment'
import { baseURL } from "../../../baseURL";
import AbaciLoader from '../../../components/AbaciLoader/AbaciLoader';
import { storageBucket } from '../../../baseURL';
import {
  Card,
  CardBody,
  Container,
  TabContent,
  TabPane,
} from 'reactstrap'
import { setCurrentPage, setSearchDisabled, setSearchkeyWord } from '../../../store/searchSlice';
// import VirtualTemplate from "../../../components/VirtualTemplate/VirtualTemplate";

export default function Scheduler() {
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(20)
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    // This is to clear the redux store at begenning
    dispatch(setCollections({ collections: [] }))
  }, [])
  const { id } = useParams()
  const [activeTab, setActiveTab] = useState('1')


  const toggle = (tab) => {
    setActiveTab(tab)
    setHasNextPage(false);
    setFileCollections([])
    setLimit(20)
   
  }

  const loadFunc = () => {
    // if (fileCollections?.length !== totalCount) {
      if (hasNextpage) {
    setTimeout(() => {
      setLimit(limit + 5);
    }, 1500);
  }
  };


  let navigate = useNavigate();
  const [scheduleDetails, setScheduleDetails] = useState(null)
  const [fileCollections, setFileCollections] = useState([])
  const [hasNextpage,setHasNextPage]=useState(false)
  const [objectCollections, setObjectCollections] = useState([])
  const items = useSelector(state => state.bagItems.droppableBagItems)
  const collections = useSelector(state => state.bagItems.collections)
  const urlCreatorForAsset = (asset, assetLink) => {
    // const assetLink = asset.
    const type = asset.file_type
    if (type === "image" | type === "video" | type === "canvas") {
      if (storageBucket === 's3') {
        return assetLink
      }
      else {
        return baseURL + assetLink
      }
    }
    else if (type === "link") {
      // If the element does not have a thumb link, we need to set it at later component based on the type of element.
      return asset.object
      // return JSON.parse(asset)
    }
  }
  useEffect(() => {

    dispatch(setCurrentPage('schedule_creator'))
    dispatch(setSearchDisabled(true))
    dispatch(setSearchkeyWord(null))

    const urlSchedule = `api/signage/schedulesrudview/${id}`
    authAxios.get(urlSchedule).then((response) => {
      setScheduleDetails(response.data)
      const tempItems = response.data?.template_clone?.divclone_templateclone.map(element => {
        const tempDroppableBagItems = element?.scheduleprops_saved_divclone.map((element, index) => {
          // let scheduled_file = null
          // let scheduled_json = null
          // if (element.scheduled_file !== null) {
          //   scheduled_file = { ...element.scheduled_file }
          // }
          // else {
          //   scheduled_file = element.scheduled_json
          // }
          const new_element = {
            id: "schedules" + nanoid(),
            schedule: element.schedule,            
            schedule_prop_id: element.file_type === "image" || element.file_type === "video" ? element.scheduled_file : element.scheduled_json,
            file_type: element.file_type,
            div: element.div,
            priority: element.priority,
            scheduled_file: element.scheduled_file,
            scheduled_json: element.scheduled_json,
            scheduled_duration: element.scheduled_duration,
            start_date: element.start_date,
            end_date: element.end_date,
            start_time: element.start_time,
            end_time: element.end_time,
            file_url: element.file_type ==='image' || element.file_type ==='video' ? element.file_url : element?.json?.file,
            thumbnail: element.file_type ==='image' || element.file_type ==='video'  ? element.thumbnail : element?.json?.thumbnail,
            uploaded_file_name: element.file_type ==='image' || element.file_type ==='video'  ? element.uploaded_file_name : element?.json?.name,
            object_fit: element.object_fit,
            animation: element.animation,
            animation_duration: element.animation_duration,
            muted: element.muted,
            all_days: null,
            week_days: null,
            publish_status: false,
            object:  element.file_type ==='weather' || element.file_type ==='clock' || element.file_type ==='youtube' || element.file_type ==='text' ? element?.json?.object : null
          }
          return new_element
        }
        )
        return ({
          id: String(element.id),
          div_id: element.id,
          div_name: element.div_name,
          [String(element.id)]: tempDroppableBagItems
        })
      })
      dispatch(setDroppableBagItems({ droppableBagItems: tempItems }))
      // droppableBagItems = useSelector(state=> state.droppableBagItems)
    })

    return () => {
      dispatch(setSearchDisabled(false))
    }
  }, [])
  useEffect(() => {
    if (activeTab === '1') {
      const urlFiles = `api/signage/fileslistcreateview?limit=${limit}&offset=0&search=${searchText}&file_type=image`
      authAxios.get(urlFiles).then(response => {

        if (response.data.next !==null) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }

        setTotalCount(response.data.count)

    

        const combinedUserFiles = [...response.data.results]
        // ...response.data.user_videos]
        const tempCollectionUser = combinedUserFiles.map((element) => (
          {
            id: "files" + nanoid(),
            schedule_prop_id: element.id,
            priority: null,
            scheduled_duration: element.duration,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().add(1, 'days').format("YYYY-MM-DD"),
            start_time: "00:00:00",
            end_time: "23:59:59",
            div: null,
            thumbnail: urlCreatorForAsset(element, element.thumbnail),
            file: urlCreatorForAsset(element, element.file),
            file_type: element.file_type,
            file_size: element.file_size,
            uploaded_file_name: element.uploaded_file_name,
            system_file_name: element.system_file_name,
            object_fit: "contain",
            animation: "fadeIn",
            animation_duration: 1,
            muted: false,
            created_date: moment(element.created_date).format("YYYY-MM-DD"),
            created_by_full_name: element.created_by_full_name,
            created_by: element.created_by,
            source: "user"
          }
        )
        )
        const tempCollectionStock = []
        setFileCollections([...tempCollectionUser, ...tempCollectionStock])
      })
    }
    else if (activeTab === '2') {
      const urlFiles = `api/signage/fileslistcreateview?limit=${limit}&offset=0&search=${searchText}&file_type=video`
      authAxios.get(urlFiles).then(response => {

        if (response.data.next !==null) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }

        setTotalCount(response.data.count)
        const combinedUserFiles = [...response.data.results]
        // ...response.data.user_videos]
        const tempCollectionUser = combinedUserFiles.map((element) => (
          {
            id: "files" + nanoid(),
            schedule_prop_id: element.id,
            priority: null,
            scheduled_duration: element.duration,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().add(1, 'days').format("YYYY-MM-DD"),
            start_time: "00:00:00",
            end_time: "23:59:59",
            div: null,
            thumbnail: urlCreatorForAsset(element, element.thumbnail),
            file: urlCreatorForAsset(element, element.file),
            file_type: element.file_type,
            file_size: element.file_size,
            uploaded_file_name: element.uploaded_file_name,
            system_file_name: element.system_file_name,
            object_fit: "contain",
            animation: "fadeIn",
            animation_duration: 1,
            muted: false,
            created_date: moment(element.created_date).format("YYYY-MM-DD"),
            created_by_full_name: element.created_by_full_name,
            created_by: element.created_by,
            source: "user"
          }
        )
        )
        const tempCollectionStock = []
        setFileCollections([...tempCollectionUser, ...tempCollectionStock])
      })
    }
    else if (activeTab === '3') {
      const urlFiles = `/api/signage/objectlistcreate?object_type=canvas&limit=${limit}&offset=0&search=${searchText}&file_type=canvas`
      authAxios.get(urlFiles).then(response => {

        if (response.data.next !==null) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }

        setTotalCount(response.data.count)
        const combinedUserFiles = [...response.data.results]
        // ...response.data.user_videos]
        const tempCollectionUser = combinedUserFiles.map((element) => (
          {
            id: "files" + nanoid(),
            schedule_prop_id: element.id,
            priority: null,
            scheduled_duration: element.duration  ? element.duration : 10,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().add(1, 'days').format("YYYY-MM-DD"),
            start_time: "00:00:00",
            end_time: "23:59:59",
            div: null,
            thumbnail: urlCreatorForAsset(element, element.thumbnail),
            file: urlCreatorForAsset(element, element.file),
            file_type: element.file_type,
            file_size: element.file_size,
            uploaded_file_name: element.name,
            system_file_name: element.system_file_name,
            object_fit: "contain",
            animation: "fadeIn",
            animation_duration: 1,
            muted: false,
            created_date: moment(element.created_date).format("YYYY-MM-DD"),
            created_by_full_name: element.created_by,
            created_by: element.created_by,
            source: "user"
          }
        )
        )
        const tempCollectionStock = []
        setFileCollections([...tempCollectionUser, ...tempCollectionStock])
      })
    }
    else if (activeTab === '4') {
      const urlFiles = `/api/signage/objectlistcreate?object_type=clock&object_type=weather&object_type=youtube&object_type=text&limit=${limit}&offset=0&search=${searchText}`;
      
      authAxios.get(urlFiles).then(response => {

        if (response.data.next !==null) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }

        setTotalCount(response.data.count)
        const combinedUserFiles = [...response.data.results]
        // ...response.data.user_videos]
        const tempCollectionUser = combinedUserFiles.map((element) => (
          {
            ...element,
            id: "files" + nanoid(),
            schedule_prop_id: element.id,
            priority: null,
            scheduled_duration: element.duration  ? element.duration : 10,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().add(1, 'days').format("YYYY-MM-DD"),
            start_time: "00:00:00",
            end_time: "23:59:59",
            div: null,
            thumbnail: element.thumbnail,
            file: element.file,
            file_type: element.file_type,
            file_size: element.file_size,
            uploaded_file_name: element.name,
            system_file_name: element.system_file_name,
            object_fit: "contain",
            animation: "fadeIn",
            animation_duration: 1,
            muted: false,
            created_date: moment(element.created_date).format("YYYY-MM-DD"),
            created_by_full_name: element.created_by,
            created_by: element.created_by,
            source: "user"
          }
        )
        )
        const tempCollectionStock = []
        setFileCollections([...tempCollectionUser, ...tempCollectionStock])
      })
      // setFileCollections([])
    }
  }, [limit, activeTab, searchText])


  useEffect(() => {
  }, [collections])
  
  // useEffect(() => {
  //   const urlObjects = 'api/signage/objectlistcreate/all'
  //   authAxios.get(urlObjects).then(response=>{

  //     // const combinedFiles = [...response.data.user_data,...response.data.stock_data]
  //     const tempCollectionUser = response.data.user_data.map((element)=>{
  //       return(
  //       {
  //         id:"json"+nanoid(),
  //         schedule_prop_id: null,
  //         priority: null,
  //         scheduled_duration: element.duration,
  //         start_date: moment().format("YYYY-MM-DD"),
  //         end_date: moment().add(1, 'days').format("YYYY-MM-DD"),
  //         start_time: "00:00:00",
  //         end_time: "23:59:59",
  //         div: null,
  //         thumbnail: urlCreatorForAsset(element, element.thumbnail),
  //         file: element.object,
  //         file_type: element.file_type,
  //         file_size: null,
  //         uploaded_file_name: element.name,
  //         system_file_name: element.name,
  //         object_fit:"contain",
  //         animation:"fadeIn",
  //         animation_duration:1,
  //         muted: false,
  //         created_date: moment(element.created_date).format("YYYY-MM-DD"),
  //         created_by_full_name: element.created_by_full_name,
  //         created_by: element.created_by,
  //         source: "user"
  //       }
  //       )})
  //     const tempCollectionStock = []
  //   setObjectCollections([...tempCollectionUser,...tempCollectionStock])
  // })
  // }, [])

  useEffect(() => {
    const totalCollections = [...fileCollections, ...objectCollections]
    dispatch(setCollections({ collections: totalCollections }))
  }, [fileCollections, objectCollections])
  // Old return statement
  // return (
  //   <div>

  //   </div>
  // )
  const handleClose = () => {
 
    navigate("/schedulers");
  };

  useEffect(() => {
    // console.log(fileCollections)
  }, [fileCollections])
  



  return (

    <React.Fragment>
    <div className="scheduler_container" style={{ height: '90vh' }}>

      <Card style={{ height: '90px', marginTop: '71px', marginBottom: '1px' }}>
        <CardBody>
          <SavePreviewAndPublish scheduleDetails={scheduleDetails} setScheduleDetails={setScheduleDetails} />
        </CardBody>
      </Card>

      {(items === []) | (collections === []) | (scheduleDetails === null) ? <AbaciLoader /> :
        <>
          <ScheduleCreator scheduleDetails={scheduleDetails} items={items} collection={collections}
            loadFunc={loadFunc} toggle={toggle} currentActiveTab={activeTab} totalCount={totalCount}
            setSearchText={setSearchText}
          />

        </>
      }

    </div>
    
    
    <div className='lowres_warning text-center'>
    <div className='close-icon-container1'> 
        <Close className='closeicon' onClick={handleClose}/>
      </div>
    <h4 className='lowres_text'>Please Use An Higher Resolution Device For Viewing this Page !</h4>
    </div>
    </React.Fragment>
    )
    
}