import React, { useState, useEffect } from "react";
// import { useDispatch} from "react-redux";
import { ReactComponent as Close } from "../../../../assets/icons/close.svg";
import AbaciButton from "../../../../components/AbaciButton/AbaciButton";
import AbaciHorizontalLine from "../../../../components/AbaciHorizontalLine/AbaciHorizontalLine";
import { Input, Toggle } from 'rsuite';
import { SelectPicker } from 'rsuite';
import Styles from "./ClockCreater.module.css";
// import { addClock, editClock, setNewClock } from "../../../.././store/clockSlice";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { SuccessAlert, Toastr } from "../../../../helpers/Alert";
import { authAxios } from "../../../../axiosInstance";
import { Spinner } from "reactstrap";
import Error from "../../../../helpers/Error";
import { setSearchkeyWord } from "../../../../store/searchSlice";
import { useDispatch } from "react-redux";
export default function CreateClock(props) {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const [displayDate,setDisplayDate]=useState(false)
  const [displaySeconds,setDisplaySeconds] = useState(false)
  const [isTwelveHours,setIsTwelveHours] = useState(false)
  // const dispatch = useDispatch();
  const [name,setName] = useState('')
  const [loadingOnButton,setLoadingOnButton] = useState(false)
  const [clockData] = useState([]);
  const clock_id=useLocation()
  const [clockType, setClockType] = useState("Analogue");
  var [time,setTime] = useState(new Date());


    
  useEffect(() => {
      var timer = setInterval(()=>setTime(new Date()), 1000 )
      return function cleanup() {
          clearInterval(timer)
      }
  
  },[]);

  useEffect(() => {

    dispatch(setSearchkeyWord(null))

    if(clock_id?.state?.id){

      const url=`/api/signage/objectrud/${clock_id?.state?.id}`

      authAxios.get(url).then((response) => {
       

        setName(response.data.name)
        setClockType(response.data.object.type)
        setDisplayDate(response?.data?.object?.displayDate ? true : false)
        setDisplaySeconds(response?.data?.object?.displaySeconds ? true : false)
        setIsTwelveHours(response?.data?.object?.isTwelveHours ? true : false)
       
  
      })
      .catch((error) => {
        const err_msg=Error(error)
        Toastr('error',err_msg)
      })
   
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  



  const handleClose = () => {

    if(clock_id?.state?.isApps){
      navigate("/apps");
    }
    else{
    navigate('/clock'); 
    }
    // setShowModal(false);
  };
  
  const handleSave = () => {

    const data = {
      name:name,
      file_type:"clock",
      object: {
        title:name,
        type:clockType,
        isTwelveHours:isTwelveHours,
        displayDate:displayDate,
        displaySeconds:displaySeconds
      }};


    if(clock_id?.state?.id){
      setLoadingOnButton(true)
      const url=`/api/signage/objectrud/${clock_id.state.id}`

     
      authAxios.patch(url, data).then((response) => {
        // dispatch(editClock(response.data))
        setLoadingOnButton(false);
        Toastr('success','Instance Edited Succesfully')
  
      })
      .catch((error) => {
        setLoadingOnButton(false)
        const err_msg=Error(error)
        Toastr('error',err_msg)
      })
    }

    else{

            if(name !==''){
              setLoadingOnButton(true)
              const url = "api/signage/objectlistcreate";

            authAxios.post(url, data).then((response) => {
              // dispatch(addClock(response.data))
              setLoadingOnButton(false);
              SuccessAlert('New Instance Created Succesfully')

            })
            .catch((error) => {
              setLoadingOnButton(false)
              const err_msg=Error(error)
              Toastr('error',err_msg)
            })
        
          

          }
          else{
            Toastr('info','Please Provide an name for the Instance')
          }
        }
  };

  const clockOptions=[{ label: 'Analogue', value: 'Analogue' },{label: 'Digital', value: 'Digital' }]

    return (
      <div className={Styles.preview_backdrop}>
        <div className={Styles.close_icon_container}>
          <Close
            className={Styles.close_icon}
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        <div>
          <div
            className={Styles.monitor}
            style={{
              height: 400 + 20 + "px",
              width: 700 + 10 + "px",
            }}
          >
            {/* <canvas
            id='canvas'
            style={{ margin: "5px" }}
            ref={canvasRef}></canvas> */}
            <div
              className={Styles.clock_container}
              style={{
                height: "400px",
                width: "700px",
                backgroundColor: "white",
                marginTop: "1%",
              }}
            >
              {clockType === 'Analogue' ?
 <div className={Styles.clock}>
        <div
          className={Styles.hour_hand}
          style={{
            transform: `rotateZ(${time.getHours() * 30}deg)`
          }}
        />
        <div
          className={Styles.min_hand}
          style={{
            transform: `rotateZ(${time.getMinutes() * 6}deg)`
          }}
        />
        <div
          className={Styles.sec_hand}
          style={{
            transform: `rotateZ(${time.getSeconds() * 6}deg)`
          }}
        />
        <span className={Styles.twelve}>12</span>
        <span className={Styles.one}>1</span>
        <span className={Styles.two}>2</span>
        <span className={Styles.three}>3</span>
        <span className={Styles.four}>4</span>
        <span className={Styles.five}>5</span>
        <span className={Styles.six}>6</span>
        <span className={Styles.seven}>7</span>
        <span className={Styles.eight}>8</span>
        <span className={Styles.nine}>9</span>
        <span className={Styles.ten}>10</span>
        <span className={Styles.eleven}>11</span>
      </div>
  :
      (
                      <>
                     <div id={Styles.digital_clock}>
                      {displayDate &&
    <p className={Styles.date}>{moment(time).format('MMM DD,YYYY - dd')}</p>}
    { displaySeconds ? 
    <p className={Styles.time}>{isTwelveHours ? moment(time).format('h:mm:ss a') :  moment(time).format('HH:mm:ss a')}</p>
    :
    <p className={Styles.time}>{isTwelveHours ? moment(time).format('h:mm a'):  moment(time).format('HH:mm a')}</p>

}
    </div>
                      </>
                    )}
             </div>

            <div className={Styles.tvscreen_logo}>
              ABACI
              <div className={Styles.icon_led_green}></div>
            </div>
          </div>
        </div>
        <div className={Styles.template_settings_holder_div}>
          <div className={Styles.clock_settings}>
            <div className={Styles.template_settings_header}>
              <p style={{ fontFamily: "lato", fontSize: "20px" }}>
                Instance Properties
              </p>
            </div>
            <AbaciHorizontalLine />

            {/* start */}
            <div className={Styles.clock_container}>

          

            <table
            
              style={{ margin: "0px",marginTop:'10px' ,color: "white", width: "200px"}}
            >
               <tbody >
           
            <tr >
              <th>
                <div className={Styles.table_head}>Name</div>
              </th>
              <td>
                <div>
                <Input placeholder="Clock Name" onChange={setName} value={name}/>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div className={Styles.table_head}>Clock Style</div>
              </th>
              <td>
                <div>
                <SelectPicker data={clockOptions} value={clockType} onChange={setClockType} appearance="default" cleanable={false} placeholder="Select Clock Face" style={{ width: 180 }} searchable={false}/>
                </div>
              </td>
            </tr>
            {clockType === 'Digital' &&
            <>
                    <tr>
              <th>
                <div className={Styles.table_head}>12 hours format</div>
              </th>
              <td>
                <div style={{paddingTop:'14px'}}>
                <Toggle  checked={isTwelveHours} onChange={setIsTwelveHours}/>
                {/* <Switch defaultChecked /> */}
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div className={Styles.table_head}>Display date</div>
              </th>
              <td>
                <div style={{paddingTop:'14px'}}>
                <Toggle  checked={displayDate} onChange={setDisplayDate}/>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div className={Styles.table_head}>Display seconds</div>
              </th>
              <td>
                <div style={{paddingTop:'14px'}}>
                <Toggle   checked={displaySeconds} onChange={setDisplaySeconds}/>
                </div>
              </td>
            </tr>
            </>
      }
            </tbody>
            </table>
            {/* end */}

            {/* <AbaciHorizontalLine /> */}
            {/* container list data mapping */}
          </div>
          </div>
          <div className={Styles.container_add_button}>
            <AbaciButton
              disabled={clockData.length > 5}
              onClick={() => {
                handleSave();
              }}
            >
                {loadingOnButton ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Save"
                                )}
                
            </AbaciButton>
          </div>
        </div>

        {/* <div className={Styles.button_holder}>
          <div className="flex-footer-container-modal">
            <div className="button-container-modal">
              <AbaciButton
                width="100px"
                height="40px"
                textcolor="white"
                color="#FF8585"
                waveColor="#F5B9B9"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </AbaciButton>
            </div>
            <div className="button-container-modal">
              <AbaciButton
                width="100px"
                height="40px"
                textcolor="white"
                color="#3CA1B1"
                waveColor="#3BCBE2"
                onClick={() => {
                  handleSave();
                }}
              >
                Save
              </AbaciButton>
            </div>
          </div>
        </div> */}
       
      </div>
    );
  
}
