import React from 'react'
import WebPage from "../../assets/images/webpage.png";
import './PreviewModal.css'
export default function PageNotAvailable(props) {
    return (
        <div className='weblinkImageWrapper-preview' style = {{width:props.width, height:props.height}}>
        <img src={WebPage} alt='' style = {{width:'300px', marginTop:'50px'}}/>
        <p>This website does not support iframe !<br/>However, if the page is live, the same will be shown on your digital signage player.</p>
      </div>
    )
}
