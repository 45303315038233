import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import {  Form } from "reactstrap";
import DropZoneCard from "./DropZoneCard";

function Index() {
  const [selectedFiles, setSelectedFile] = useState([]);
  const htmlFormatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleAcceptedFiles = (files) => {
    // console.log(files)
    const temp = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: htmlFormatBytes(file.size),
      })
    );
    setSelectedFile((state) => [...state, ...temp]);
  };

  useEffect(() => {
    // console.log(selectedFiles)
  
  }, [selectedFiles])
  

  return (
    <>
      <Form className="dropzone">
        <Dropzone
          onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <div>
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                {/* 
                {selectedFiles.length === 0 ? (
                  <> */}
                <div className="mb-3" style={{cursor:'pointer'}}>
                  {selectedFiles.length === 0 ? (
                    <>
                      <i className="display-4 text-muted ri-upload-cloud-2-line" />
                      <h4>Drop files here or click to upload.</h4>
                    </>
                  ) : (
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((file, index) => {
                        return <DropZoneCard file={file} key={index} />;
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </Form>
    </>
  );
}

export default Index;
