import React, { useEffect } from 'react'
import { Card } from 'reactstrap'

function TextComponent({currentPlayingItem,width,height}) {


  useEffect(() => {
   
  }, [height])
  

  return (
  <div style={{  width: width,
    height: height,   }}>
    <p
      className={`animate__animated animate__${currentPlayingItem?.object?.text_properties?.animation} infinite`}
      style={{
      
        animationDelay: `${currentPlayingItem?.object?.text_properties?.animationDelay}`,
        animationDuration: `${currentPlayingItem?.object?.text_properties?.animationDuration}`,
        animationIterationCount: `${currentPlayingItem?.object?.text_properties?.animationLoop}`,
        animationFillMode: "both",
        fontSize: `${currentPlayingItem?.object?.text_properties?.fontSize}px`,
        textAlign: `${currentPlayingItem?.object?.text_properties?.textAlign}`,
        fontWeight: `${currentPlayingItem?.object?.text_properties?.fontWeight}`,
        fontStyle: `${currentPlayingItem?.object?.text_properties?.fontStyle}`,
        color: `${currentPlayingItem?.object?.text_properties?.color}`,
        fontFamily: `${currentPlayingItem?.object?.text_properties?.fontFamily}`,
        textDecoration: `${currentPlayingItem?.object?.text_properties?.textDecoration}`,
        // marginTop: `${((currentPlayingItem?.object?.text_properties?.marginTop / 504) * height)}px`,
        // marginTop: `${(504/currentPlayingItem?.object?.text_properties?.marginTop) /height +50}px`,

        marginLeft: `${currentPlayingItem?.object?.text_properties?.marginLeft}px`,
        marginRight: `${currentPlayingItem?.object?.text_properties?.marginRight}px`,
      }}
    >
      {currentPlayingItem?.object?.text_properties?.text}
    </p>
    </div>
  )
}

export default TextComponent