import React, { useEffect } from "react";
// { Component }
import { Col, Card, CardBody } from "reactstrap";
import CountUp from "react-countup";

export default function MiniWidgets(props) {
  return (
    <React.Fragment>
      {props.count.map((data, key) => (
        <Col key={key}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <p
                    className="text-truncate font-size-13 mb-2"
                    style={{ minHeight: "50px" }}
                  >
                    {data.title}
                  </p>

                  <h5 className="mb-0 ">
                    <CountUp end={data.value} duration={3} delay={1} />
                    {/* / <CountUp end={data.total} duration={3} delay={1} /> */}
                    {/* {data.value} */}
                  </h5>
                </div>
                <div>
                  <i className={data.icon + " font-size-24"}></i>
                </div>
              </div>
            </CardBody>

            <CardBody className="border-top py-3">
              <div className="text-truncate" style={{ color: "#e4e4e4" }}>
                {/* <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up"> </i> {data.rate}</span> */}
                <span className="text-muted font-size-10 ">{data.desc}</span>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
}
