// import swal from "sweetalert2";
import AbaciHorizontalLine from "../../../components/AbaciHorizontalLine/AbaciHorizontalLine";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { authAxios } from "../../../axiosInstance";
// import { UpCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AbaciButton from "../../../components/AbaciButton/AbaciButton";
import React, { useState, useEffect, useRef } from "react";
import Styles from "./TemplateCreater.module.css";
import { useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import "./TemplateAddModal.css";
import { fabric } from "fabric";
import { nanoid } from "nanoid";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Spinner,
} from "reactstrap";
// import axios from "axios";
import { useParams } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import b64toBlob from "../../../helpers/base64ToBlob";
import { useSelector } from "react-redux";

export default function TemplateCreator() {
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  let history = useNavigate();
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const blankProps = {
    containerName: "",
    containerIndex: 0,
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    color: "rgba(255, 255, 255, 0)",
    thickness: 0,
    radius: 0,
    borderColor: "rgba(255, 255, 255, 0)",
  };
  const [templateId, setTemplateId] = useState(2);
  const [templateDetails, setTemplateDetails] = useState(null);
  const [objectPropsForTable, setObjectPropsForTable] = useState(blankProps);
  const [loadingOnButton, setLoadingOnButton] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [canvasObjects, setCanvasObjects] = useState({});
  const [canvas, setCanvas] = useState();
  const [containerColor, setContainerColor] = useState("white");
  const [divBorderColor, setDivBorderColor] = useState(
    "rgba(255, 255, 255, 0)"
  );
  const [jsonForCanvas, setJsonForCanvas] = useState(null);

  const [scalingFactor, setScalingFactor] = useState(1);
  const [canvasHeight, setCanvasHeight] = useState();
  const [canvasWidth, setCanvasWidth] = useState();
  const [selectedObject, setSelectedObject] = useState(null);
  const canvasRef = useRef();
  const test = useRef();
  const params = useParams();

  class canvasJsonCreator {
    constructor(cssObj, virtualizationFactor) {
      this.cssObj = cssObj;
      this.virtualizationFactor = virtualizationFactor;
    }
    convertToVirtual = () => ({
      name: this.cssObj.div_name,
      type: "rect",
      originX: "left",
      originY: "top",
      left: this.cssObj.marginLeft * this.virtualizationFactor,
      top: this.cssObj.marginTop * this.virtualizationFactor,
      width: this.cssObj.width * this.virtualizationFactor,
      height: this.cssObj.height * this.virtualizationFactor,
      fill: this.cssObj.backgroundColor,
      stroke: this.cssObj.border === 0 ? null : this.cssObj.borderColor,
      rx: this.cssObj.borderRadius * this.virtualizationFactor,
      ry: this.cssObj.borderRadius * this.virtualizationFactor,
    });
  }

  useEffect(() => {
    console.log(canvasObjects)
  }, [canvasObjects])
  

  useEffect(() => {
    const tempTemplateId = params?.id;
    if (tempTemplateId !== null) {
      setTemplateId(tempTemplateId);
      let url;
      if (tenantDetails?.app_type === "Admin") {
        url = `/stock/api/signage/templatesrud/${tempTemplateId}`;
      } else {
        url = `api/signage/templatesrud/${tempTemplateId}`;
      }

      // const url = `api/signage/templatesrud/${tempTemplateId}`;
      authAxios.get(url).then((response) => {
        setTemplateDetails(response.data);

        const tempScalingFactor =
          response?.data?.orientation == "Landscape"
            ? window.innerWidth / response.data.resolution_width / 2.3
            : window.innerWidth / response.data.resolution_height / 4;
        setScalingFactor(tempScalingFactor);
        if (response?.data?.divs_templates.length === 0) {
          setJsonForCanvas([]);
        } else {
          const tempJsonPerDivArray = [];
          response.data.divs_templates.map((item) => {
            const jsonPerDiv = new canvasJsonCreator(
              item,
              tempScalingFactor
            ).convertToVirtual();
            tempJsonPerDivArray.push(jsonPerDiv);
          });
          setJsonForCanvas(tempJsonPerDivArray);
          // console.log(tempJsonPerDivArray);
        }
      });
    }
  }, [params]);

  const canvasDataSetter = (data) => {
    // console.log(data)
    const temp = data.getObjects();
    if (temp !== null) {
      // console.log(tempCanvasObjs)
      setCanvasObjects(temp);
    }
  };

  useEffect(() => {
    // console.log(canvasObjects)
  }, [canvasObjects]);

  useEffect(() => {
    // console.log('innerwidth',window.innerWidth)

    if (templateDetails !== null) {
      const tempScalingFactor =
        window.innerHeight / templateDetails.resolution_height / 2;
      // console.log('scale',tempScalingFactor)
      setScalingFactor(tempScalingFactor);
      const newWidth = templateDetails.resolution_width * tempScalingFactor;
      setCanvasWidth(newWidth);
      const newHeight = templateDetails.resolution_height * tempScalingFactor;
      setCanvasHeight(newHeight);
      var tempcanvas = new fabric.Canvas("canvas", {
        height: newHeight,
        width: newWidth,
        backgroundColor: "white",
        selection: true,
        renderOnAddRemove: true,
        preserveObjectStacking: true,
      });

      const newjson = { objects: jsonForCanvas, background: "white" };
      tempcanvas.loadFromJSON(
        newjson,
        tempcanvas.renderAll.bind(tempcanvas),
        function (o, object) {
          fabric.log(o, object);
          object.on("scaling", function () {
            // console.log('object modified again')
            this.set({
              width: this.width * this.scaleX,
              height: this.height * this.scaleY,
              scaleX: 1,
              scaleY: 1,
              noScaleCache: false,
            });
            tempcanvas.renderAll();
          });
          object.on("deselected", function (options) {
            setSelectedObject(null);
            setObjectPropsForTable(blankProps);
            setCheckboxChecked(false);
            setDivBorderColor("rgba(255, 255, 255, 0)");
          });
        }
      );

      // console.log(tempcanvas)
      //   const newjson = { objects: props.jsonForCanvas, background: "white" };
      //   tempcanvas.loadFromJSON(  newjson,
      //     tempcanvas.renderAll.bind(tempcanvas), function (o, object) {
      //     fabric.log(o, object);
      //     object.on("scaling", function () {
      //       this.set({
      //         width: this.width * this.scaleX,
      //         height: this.height * this.scaleY,
      //         scaleX: 1,
      //         scaleY: 1,
      //         noScaleCache: false,
      //       });
      //       tempcanvas.renderAll();

      //     });
      //     object.on("deselected", function (options) {
      //       setSelectedObject(null);
      //       setObjectPropsForTable(blankProps);
      //       setCheckboxChecked(false);
      //       setDivBorderColor("rgba(255, 255, 255, 0)");
      //     });
      //   });

      tempcanvas.on("selection:created", function (e) {
        // console.log('wrked')
        // setSelectedObject(e.target);
        setSelectedObject(e?.selected[0]);
      });
      tempcanvas.on("selection:updated", function (e) {
        // console.log(e,'wrked')
        if (e?.selected) {
          setSelectedObject(e.selected[0]);
        } else {
          setSelectedObject(e.target);
        }
      });
      tempcanvas.on("object:added", (e) => {
        canvasDataSetter(e?.target?.canvas);
        // localStorage.setItem('tempcanvas',e?.target?.canvas)

        // borderRestrictHandler(e, tempcanvas);
        // setSelectedObject(null);
        // setSelectedObject(e.target);
      });

      tempcanvas.on("object:modified", (e) => {
        // console.log(e)

        borderRestrictHandler(e, tempcanvas);
        setSelectedObject(null);
        setSelectedObject(e.target);
      });

      test.current = tempcanvas;
      setCanvas(tempcanvas);
    }
  }, [templateDetails]);

  // useEffect(() => {
  //   if (templateDetails !== null) {
  //   const tempScalingFactor = window.innerHeight  / templateDetails?.resolution_height / 2
  //   const newWidth =
  //   templateDetails?.resolution_width * tempScalingFactor;
  //   const newHeight =
  //   templateDetails?.resolution_height * tempScalingFactor;

  //   setCanvasWidth(newWidth);
  //   setCanvasHeight(newHeight);

  //   canvas.setDimensions({width: newWidth, height: newHeight})
  //   }

  // }, [templateDetails, windowDimensions.height , windowDimensions.width])

  document.onkeydown = function (e) {
    // console.log('wrked')
    if (selectedObject !== null) {
      if (e.key === "Delete") {
        // console.log(selectedObject)
        // canvas.remove(selectedObject);
        canvas.remove(canvas.getActiveObject());

        setCanvasObjects(
          canvasObjects.filter((data) => data.ownMatrixCache.key !== selectedObject.ownMatrixCache.key)
        );
        // setSelectedObject(null);
        // setObjectPropsForTable(blankProps);
      }
    }
  };
  
  class selectedObjectConverter {
    constructor(selectedObject) {
      this.selectedObject = selectedObject;
    }

    convertForTable() {
      // console.log(this.selectedObject)
      const strokeAdjustmentValue =
        this.selectedObject?.stroke === null ? 0 : 1;
      // console.log(this.selectedObject?.width, this.selectedObject?.height)
      return {
        containerName: this.selectedObject?.name,
        containerZIndex: canvasObjects.indexOf(this.selectedObject),
        width: parseInt(this.selectedObject?.width / scalingFactor),
        height: parseInt(this.selectedObject?.height / scalingFactor),
        left: parseInt(this.selectedObject?.left / scalingFactor),
        top: parseInt(this.selectedObject?.top / scalingFactor),
        color: this.selectedObject?.fill,
        thickness: 0,
        radius: parseInt(this.selectedObject?.rx / scalingFactor),
        borderColor:
          this.selectedObject?.stroke === null
            ? "rgba(255, 255, 255, 0)"
            : this.selectedObject?.stroke,
      };
    }
    convertForSaving() {
      const strokeAdjustmentValueTemp =
        this.selectedObject?.stroke === null ? 0 : 1;
      return {
        div_name: this.selectedObject?.name,
        position: "absolute",
        marginTop: parseInt(this.selectedObject?.top / scalingFactor),
        marginLeft: parseInt(this.selectedObject?.left / scalingFactor),
        height: parseInt(this.selectedObject?.height / scalingFactor),
        width: parseInt(this.selectedObject?.width / scalingFactor),
        backgroundColor: this.selectedObject?.fill,
        border: this.selectedObject?.stroke === null ? 0 : 1,
        borderRadius: parseInt(this.selectedObject?.rx / scalingFactor),
        borderColor:
          this.selectedObject?.stroke === null
            ? "rgba(255, 255, 255, 0)"
            : this.selectedObject?.stroke,
        zIndex: canvasObjects.indexOf(this.selectedObject),
        template: templateId,
      };
    }
  }
  useEffect(() => {
    if (selectedObject !== null) {
      if (selectedObject?.fill !== containerColor) {
        selectedObject?.set({ fill: containerColor });
        setObjectPropsForTable((state) => ({
          ...state,
          color: containerColor,
        }));
        canvas.renderAll();
      }
    }
  }, [containerColor]);

  useEffect(() => {
    if (selectedObject !== null) {
      const tempObject = new selectedObjectConverter(selectedObject);
      const tempObjectPropsForTable = tempObject.convertForTable();
      setContainerColor(selectedObject?.fill);
      setDivBorderColor(
        selectedObject?.stroke === null
          ? "rgba(255, 255, 255, 0)"
          : selectedObject?.stroke
      );
      setCheckboxChecked(selectedObject?.stroke === null ? false : true);
      setObjectPropsForTable(tempObjectPropsForTable);
    }
  }, [selectedObject]);

  const borderRestrictHandler = (e, canvas) => {
    const obj = e.target;
    const topSide = obj.getBoundingRect().top;
    const leftSide = obj.getBoundingRect().left;
    const rightSide = obj.getBoundingRect().left + obj.getBoundingRect().width;
    const bottomSide = obj.getBoundingRect().top + obj.getBoundingRect().height;
    // reduce 1 px if the object have border
    const reducingPixel = obj.stroke ? 1 : 0;
    if (topSide < 0) {
      obj.top = 0;
    }
    if (leftSide < 0) {
      obj.left = 0;
    }
    if (rightSide > canvas.width) {
      obj.left = canvas.width - (obj.getBoundingRect().width - 1);
    }
    if (bottomSide > canvas.height) {
      obj.top = canvas.height - (obj.getBoundingRect().height - 1);
    }

    if (obj.getBoundingRect().width > canvas.width) {
      const scale = obj.getObjectScaling();
      obj.set({ width: canvas.width / scale.scaleX });
      obj.left = 0;
    }
    if (obj.getBoundingRect().height > canvas.height) {
      const scale = obj.getObjectScaling();
      obj.set("height", canvas.height / scale.scaleY);
      obj.top = 0;
    }
    obj.setCoords();
    obj.saveState();
  };

  const divAddHandler = () => {
    let rect = new fabric.Rect({
      id: nanoid(),
      name: "New Container",
      width: 100,
      height: 100,
      // width: (props.templateDetails.resolution_width * scalingFactor) / 2,
      // height: (props.templateDetails.resolution_height * scalingFactor) / 2,
      fill: getRandomColor(),
      borderOpacityWhenMoving: 0,
      borderColor: getRandomColor(),
      lockScalingFlip: true,
      stroke: "black",
      strokeDashOffset: 0,
      strokeUniform: true,
      hasRotatingPoint: false,
      noScaleCache: false,
    });
    rect.setControlsVisibility({
      tl: false,
      tr: false,
      br: false,
      bl: false,
      mtr: false,
    });
    rect.on("scaling", function () {
      // console.log('wrked')
      this.set({
        width: this.width * this.scaleX,
        height: this.height * this.scaleY,
        scaleX: 1,
        scaleY: 1,
      });
    });
    rect.on("deselected", function (options) {
      setSelectedObject(null);
      setObjectPropsForTable(blankProps);
      setCheckboxChecked(false);
      setDivBorderColor("rgba(255, 255, 255, 0)");
    });
    canvas.add(rect);
  };

  const colorMenuBorder = (
    <SketchPicker
      color={divBorderColor}
      onChange={(value) => {
        let newColor = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
        setDivBorderColor(newColor);
        borderColorChangeHandler(newColor);
      }}
    />
  );
  const borderColorChangeHandler = (newColor) => {
    if (selectedObject !== null) {
      if (checkboxChecked === true) {
        selectedObject.set({ stroke: newColor });
        canvas.renderAll();
        selectedObject.saveState();
      }
    }
  };
  const colorMenuContainer = (
    <SketchPicker
      color={containerColor}
      onChange={(value) => {
        let newColor = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
        setContainerColor(newColor);
      }}
    />
  );

  const handleClose = () => {
    window.onresize = null;
    document.onkeydown = null;
    history("/templates");
  };

  window.onresize = (event) => {
    // console.log('wrked')
    // console.log(window.innerWidth)
    // console.log(window.innerHeight)
    // canvas.setHeight(900)
    // canvas.setWidth( 900 )
    const tempScalingFactor =
      window.innerHeight / templateDetails.resolution_height / 2;
    // const tempwidthFactor = window.innerWidth / templateDetails.resolution_width /
    // console.log('scale',tempwidthFactor)
    setScalingFactor(tempScalingFactor);
    const newWidth = templateDetails.resolution_width * tempScalingFactor;
    setCanvasWidth(newWidth);
    const newHeight = templateDetails.resolution_height * tempScalingFactor;
    setCanvasHeight(newHeight);

    // console.log(newHeight,newWidth)
    canvas.setHeight(newHeight);
    canvas.setWidth(newWidth);
  };

  // useEffect(() => {

  //   return(() => {
  //     window.removeEventListener('resize')
  //   })
  // }, [])

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  const saveHandler = () => {
    // console.log(canvasObjects.length)
    if (canvasObjects) {
      console.log(canvasObjects)
      if (canvasObjects.length === 0) {
        Swal.fire({
          title: "The template is empty",
          text: "Please add atleast one container to proceed",
          icon: "error",
        });
      } else {
        setLoadingOnButton(true);

        // const templateData=canvas.toDataURL({
        //   format: "png"
        // });

        let containerArrayForSaving = [];

        for (let i = 0; i < canvasObjects.length; i++) {
          const obj = canvasObjects[i];
          const tempObj = new selectedObjectConverter(obj).convertForSaving();
          containerArrayForSaving.push(tempObj);
        }
        const data = containerArrayForSaving.map((item) => ({
          ...item,
          template: templateId,
        }));

        let urlDivs;
        if (tenantDetails?.app_type === "Admin") {
          urlDivs = "/stock/api/signage/divslistcreate";
        } else {
          urlDivs = "api/signage/divslistcreate";
        }
        const formData = new FormData();
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        let png = b64toBlob(
          canvas.toDataURL({
            format: "png",
          })
        );
        formData.append("thumbnail", png, `template${templateId}.png`);
        formData.append("data", JSON.stringify(data));
        formData.append("templateId", templateId);
        authAxios
          .post(urlDivs, formData, config)
          .then((res) => {
            setLoadingOnButton(false);
            Toastr("success", "Template Created Successfully");
          })
          .catch((error) => {
            // console.log(error)
            setLoadingOnButton(false);
          });
      }
    }
  };
  // const strokeCheckBoxHandler = () => {
  //   const checkedStatus = !checkboxChecked;
  //   setCheckboxChecked(checkedStatus);
  //   if (selectedObject !== null) {
  //     if (checkedStatus) {

  //       const widthBefore = canvas.getActiveObject().width;
  //       const heightBefore = canvas.getActiveObject().height;

  //       canvas
  //         .getActiveObject()
  //         .set({
  //           stroke: "black",
  //           strokeWidth: 1,
  //         });
  //       canvas.getActiveObject().saveState();
  //       setDivBorderColor("black");

  //     } else {
  //       const widthBefore = canvas.getActiveObject().width;
  //       const heightBefore = canvas.getActiveObject().height;

  //       canvas
  //         .getActiveObject()
  //         .set({
  //           stroke: null,
  //         });
  //       canvas.getActiveObject().saveState();
  //       setDivBorderColor("rgba(255, 255, 255, 0)");

  //     }
  //     selectedObject.saveState();
  //     canvas.renderAll();
  //   }
  // };

  useEffect(() => {
    const tempCanvasObjs = canvas?.getObjects();

    if (tempCanvasObjs !== null) {
      // console.log(tempCanvasObjs)
      setCanvasObjects(tempCanvasObjs);
    }
  }, [canvas]);

  const valueChangeHandler = (type, value) => {
    if (selectedObject !== null) {
      switch (type) {
        case "width":
          setObjectPropsForTable((state) => ({
            ...state,
            width: state.width + value,
          }));
          const currentWidth =
            canvas.getActiveObject().width * canvas.getActiveObject().scaleX;
          canvas
            .getActiveObject()
            .set({ width: currentWidth + value * scalingFactor, scaleX: 1 });
          canvas.getActiveObject().saveState();
          canvas.renderAll();
          break;
        case "height":
          setObjectPropsForTable((state) => ({
            ...state,
            height: state.height + value,
          }));
          const currentHeight =
            canvas.getActiveObject().height * canvas.getActiveObject().scaleY;
          canvas
            .getActiveObject()
            .set({ height: currentHeight + value * scalingFactor, scaleY: 1 });
          canvas.getActiveObject().saveState();
          canvas.renderAll();
          break;
        case "top":
          setObjectPropsForTable((state) => ({
            ...state,
            top: state.top + value,
          }));
          const currentTop = canvas.getActiveObject().top;
          canvas
            .getActiveObject()
            .set("top", currentTop + value * scalingFactor);
          canvas.getActiveObject().saveState();
          canvas.renderAll();
          break;
        case "left":
          setObjectPropsForTable((state) => ({
            ...state,
            left: state.left + value,
          }));
          const currentLeft = canvas.getActiveObject().left;
          canvas
            .getActiveObject()
            .set("left", currentLeft + value * scalingFactor);
          canvas.getActiveObject().saveState();
          canvas.renderAll();
          break;
        case "radius":
          const newRadius =
            (objectPropsForTable.radius + value) * scalingFactor;
          selectedObject.set({ rx: newRadius, ry: newRadius });
          selectedObject.saveState();
          selectedObject.setCoords();
          canvas.renderAll();
          setObjectPropsForTable((state) => ({
            ...state,
            radius: state.radius + value,
          }));
          break;
      }
    }
  };

  useEffect(() => {
    // console.log(selectedObject)
  }, [selectedObject]);

  // return(
  //   <h1>halo</h1>
  // )

  // test.current.setDimensions({width: '100%', height: '100%'}, {cssOnly: true});

  // // test.current.setHeight('100%',);
  // // test.current.setWidth('100%');
  // // test.current.renderAll();

  // // test.current.setDimension({width:'600px',height:'600px'}, test.current.renderAll.bind(canvas));
  // // test.current.calcOffset();
  // // test.current.forEachObject(function(o) {
  // //   o.setCoords();
  // // });
  // // var test = document.getElementById('canvas');
  // // test.width  = window.innerWidth;
  // // test.height = window.innerHeight / 2;
  // // test.current.renderAll();
  // canvas.setHeight(document.getElementById("contCanvasLogo").clientHeight);
  // canvas.setWidth(document.getElementById("contCanvasLogo").clientWidth);
  // // canvas.renderAll();

  return (
    <div className={Styles.preview_backdrop}>
      <div className={Styles.close_icon_container}>
        <Close className={Styles.close_icon} onClick={handleClose} />
      </div>
      <div className={Styles.smallScreenWarningText + " text-center"}>
        <h4 style={{ color: "white" }}>
          Please Use An Higher Resolution Device For Viewing this Page !
        </h4>
      </div>
      <div className={Styles.main_container}>
        <div>
          <div
            className={Styles.monitor}
            style={{
              height: canvasHeight + 20 + "px",
              width: canvasWidth + 10 + "px",
            }}
          >
            <canvas
              id="canvas"
              style={{ margin: "5px" }}
              ref={canvasRef}
            ></canvas>
            <div className={Styles.tvscreen_logo}>
              ABACI
              <div className={Styles.icon_led_green}></div>
            </div>
          </div>
        </div>

        <div className={Styles.template_settings_holder}>
          <div className={Styles.template_settings}>
            <div className={Styles.template_settings_header}>
              <p
                style={{
                  fontFamily: "lato",
                  fontSize: "20px",
                  userSelect: "none",
                }}
              >
                Container Properties
              </p>
            </div>
            <AbaciHorizontalLine />
            {canvasObjects?.length > 0 && selectedObject ? (
              <table style={{ margin: "0px", color: "white", width: "200px" }}>
                <tbody>
                  <tr>
                    <th>
                      <div className={Styles.table_head}>Container Name</div>
                    </th>
                    <td>
                      <div>
                        <input
                          disabled={selectedObject === null}
                          className={Styles.input_conainer_name}
                          value={objectPropsForTable.containerName}
                          onChange={(e) => {
                            setObjectPropsForTable((state) => ({
                              ...state,
                              containerName: e.target.value,
                            }));
                            selectedObject.set({ name: e.target.value });
                            selectedObject.saveState();
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={Styles.table_head}>Width</div>
                    </th>
                    <td>
                      <div className={Styles.group_button_container}>
                        <div
                          className={Styles.increment_button}
                          onClick={() => valueChangeHandler("width", 1)}
                        >
                          +
                        </div>
                        <div className={Styles.valuebox}>
                          {objectPropsForTable.width}
                        </div>
                        <div
                          className={Styles.decrement_button}
                          onClick={() => valueChangeHandler("width", -1)}
                        >
                          -
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={Styles.table_head}>Height</div>
                    </th>
                    <td>
                      <div className={Styles.group_button_container}>
                        <div
                          className={Styles.increment_button}
                          onClick={() => valueChangeHandler("height", 1)}
                        >
                          +
                        </div>
                        <div className={Styles.valuebox}>
                          {objectPropsForTable.height}
                        </div>
                        <div
                          className={Styles.decrement_button}
                          onClick={() => valueChangeHandler("height", -1)}
                        >
                          -
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={Styles.table_head}>Top</div>
                    </th>
                    <td>
                      <div className={Styles.group_button_container}>
                        <div
                          className={Styles.increment_button}
                          onClick={() => valueChangeHandler("top", 1)}
                        >
                          +
                        </div>
                        <div className={Styles.valuebox}>
                          {objectPropsForTable.top}
                        </div>
                        <div
                          className={Styles.decrement_button}
                          onClick={() => valueChangeHandler("top", -1)}
                        >
                          -
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={Styles.table_head}>Left</div>
                    </th>
                    <td>
                      <div className={Styles.group_button_container}>
                        <div
                          className={Styles.increment_button}
                          onClick={() => valueChangeHandler("left", 1)}
                        >
                          +
                        </div>
                        <div className={Styles.valuebox}>
                          {objectPropsForTable.left}
                        </div>
                        <div
                          className={Styles.decrement_button}
                          onClick={() => valueChangeHandler("left", -1)}
                        >
                          -
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <div className={Styles.table_head}>Color</div>
                    </th>
                    <td>
                      <UncontrolledDropdown direction="down">
                        <DropdownToggle
                          style={{
                            border: "0",
                            color: "#60646B",
                            backgroundColor: "#60646B",
                            boxShadow: "none",
                            width: "1px",
                          }}
                        >
                          <div
                            className={Styles.color_picker}
                            style={{
                              backgroundColor: objectPropsForTable.color,
                              marginLeft: "55px",
                            }}
                          ></div>
                        </DropdownToggle>
                        <DropdownMenu>
                          <SketchPicker
                            color={containerColor}
                            onChange={(value) => {
                              let newColor = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
                              setContainerColor(newColor);
                            }}
                          />
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {/* <Dropdown toggle={}>
                <DropdownToggle>
                <div
                    className={Styles.color_picker}
                    style={{
                      backgroundColor: objectPropsForTable.color,
                    }}></div>
                </DropdownToggle>
                <DropdownMenu container="body">
                  testing
                  </DropdownMenu>
                </Dropdown> */}
                      {/* <Dropdown overlay={colorMenuContainer} trigger={["click"]} style={{zIndex:'9999'}} isOpen={true}>
                  <div
                    className={Styles.color_picker}
                    style={{
                      backgroundColor: objectPropsForTable.color,
                    }}></div>
                </Dropdown> */}
                    </td>
                  </tr>
                  {/* <tr>
              <th>
                <div className={Styles.table_head}>Show Border</div>
              </th>
              <td>
                <div className={Styles.checkbox_container}>
                  required */}
                  {/* <Checkbox
                    checked={checkboxChecked}
                    disabled={selectedObject === null}
                    onChange={strokeCheckBoxHandler}
                  />   */}
                  {/* </div>
              </td>
            </tr> */}
                  <tr>
                    <th>
                      <div className={Styles.table_head}>Border Radius</div>
                    </th>
                    <td>
                      <div className={Styles.group_button_container}>
                        <div
                          className={Styles.increment_button}
                          onClick={() => valueChangeHandler("radius", 1)}
                        >
                          +
                        </div>
                        <div className={Styles.valuebox}>
                          {objectPropsForTable.radius}
                        </div>
                        <div
                          className={Styles.decrement_button}
                          onClick={() => valueChangeHandler("radius", -1)}
                        >
                          -
                        </div>
                      </div>
                    </td>
                  </tr>

                  {/* {checkboxChecked ? (
              <tr>
                <th>
                  <div className={Styles.table_head}>Border Color</div>
                </th>
                <td>
                  <Dropdown overlay={colorMenuBorder} trigger={["click"]} style={{zIndex:'9999'}}>
                    <div
                      className={Styles.color_picker}
                      style={{
                        backgroundColor: divBorderColor,
                      }}></div>
                  </Dropdown>
                </td>
              </tr>
            ) : null} */}
                </tbody>
              </table>
            ) : null}
            {canvasObjects && canvasObjects.length > 1 && selectedObject ? (
              <div
                className={Styles.move_arrow_container}
                style={{ zIndex: "9999" }}
              >
                <Tooltip
                  // placement='top'
                  title={
                    // objectPropsForTable.containerZIndex ===
                    //   (canvas && canvasObjects?.length - 1)
                    //   ? "This is the top layer container"
                    "Bring container forward"
                  }
                >
                  <ArrowUpwardIcon
                    className={
                      // objectPropsForTable.containerZIndex ===
                      // (canvas && canvasObjects?.length - 1)
                      //   ? Styles.move_arrow_style_disabled
                      Styles.move_arrow_style
                    }
                    onClick={() => {
                      selectedObject.bringForward();
                      selectedObject.saveState();
                      canvas.renderAll();
                    }}
                  />
                </Tooltip>
                <Tooltip
                  // placement='top'
                  title={
                    // objectPropsForTable.containerZIndex === 0
                    //   ? "This is the bottom layer container"
                    "Send container backward"
                  }
                >
                  <ArrowDownwardIcon
                    className={
                      // objectPropsForTable.containerZIndex === 0
                      //   ? Styles.move_arrow_style_disabled
                      Styles.move_arrow_style
                    }
                    onClick={() => {
                      selectedObject.sendBackwards();
                      selectedObject.saveState();
                      canvas.renderAll();
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            <AbaciHorizontalLine />
            {/* container list data mapping */}

            <div className={Styles.dimension_note}>
              {canvasObjects?.length === 0 ? (
                <p>* Please add a Container to continue</p>
              ) : canvasObjects?.length > 0 && selectedObject ? null : (
                <p>* Please select the container to see the properties</p>
              )}
              <p>* All dimensions are in pixels</p>
            </div>
          </div>
          <div className={Styles.container_add_button}>
            <AbaciButton onClick={divAddHandler}>Add Container</AbaciButton>
            <div style={{ marginLeft: "10px" }}>
              {" "}
              <AbaciButton
                // width='100px'
                // height='40px'

                // textcolor='white'
                // color='#3CA1B1'
                // waveColor='#3BCBE2'
                // loadingOnButton={loadingOnButton}
                onClick={saveHandler}
              >
                {loadingOnButton ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  "Save"
                )}
              </AbaciButton>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={Styles.button_holder}>
        <div className='flex-footer-container-modal'>
          <div className='button-container-modal'>
            <AbaciButton
              width='100px'
              height='40px'
              textcolor='white'
              color='#FF8585'
              waveColor='#F5B9B9'
              onClick={handleClose}>
              Cancel
            </AbaciButton>
          </div>
          <div className='button-container-modal'>
          
          </div>
        </div>
      </div> */}
    </div>
  );
}

class CanvasToCSSMaker {
  constructor(cssObj, virtualizationFactor) {
    this.cssObj = cssObj;
    this.virtualizationFactor = virtualizationFactor;
  }
  convertToVirtual = () => ({
    position: this.cssObj.position,
    marginTop: this.cssObj.marginTop * this.virtualizationFactor,
    marginLeft: this.cssObj.marginLeft * this.virtualizationFactor,
    height: this.cssObj.height * this.virtualizationFactor,
    width: this.cssObj.width * this.virtualizationFactor,
    backgroundColor: this.cssObj.backgroundColor,
    border:
      this.cssObj.border === 0
        ? "1px solid"
        : String(this.cssObj.border) + "px solid",
    borderRadius: this.cssObj.borderRadius,
    borderColor:
      this.cssObj.border === 0 ? "light grey" : this.cssObj.borderColor,
    zIndex: this.cssObj.zIndex,
  });
}
