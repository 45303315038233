import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePanel:null,
  canvas:null,
  background:null,
  canvasObjectStates:null,
};
const fabricCanvasSlice = createSlice({
  name: "fabricCanvasSlice",
  initialState,
  reducers: {
    setActivePanel(state, action) {
      state.activePanel = action.payload;
    },
    setCanvas: (state, action) => {
      state.canvas = action.payload;
    },
    setBackground: (state, action) => {
      state.background = action.payload;
    },
    setObjectsState: (state, action) => {
      state.canvasObjectStates = action.payload;
    },
  },
});

export const { setActivePanel, setCanvas, setBackground, setObjectsState } =
fabricCanvasSlice.actions;
export default fabricCanvasSlice.reducer;
