import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import SimpleBar from 'simplebar-react'

function RecentActivityFeed({data,isScreenGroup,windowHeight}) {
  return (
<Card style={{maxHeight:'600px', minHeight: `${windowHeight ? `${windowHeight - 360}px` :"510px" }`}}>
        <CardBody>
            {!isScreenGroup &&
            <>
        <CardTitle>Recent Activity Feed</CardTitle>
        <div className="border-top py-3"/>
            </>
            }
        <SimpleBar style={{ maxHeight: "405px",minHeight: `${windowHeight ? `${windowHeight - 400}px` :"405px" }` }}>
                                <ul className="list-unstyled activity-wid">
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-edit-2-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">28 Apr, 2020 <small className="text-muted">12:07 am</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Responded to need “Volunteer Activities”</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-user-2-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">21 Apr, 2020 <small className="text-muted">08:01 pm</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Added an interest “Volunteer Activities”</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-bar-chart-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">17 Apr, 2020 <small className="text-muted">09:23 am</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Joined the group “Boardsmanship Forum”</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-mail-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">11 Apr, 2020 <small className="text-muted">05:10 pm</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Responded to need “In-Kind Opportunity”</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-calendar-2-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">07 Apr, 2020 <small className="text-muted">12:47 pm</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Created need “Volunteer Activities”</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-edit-2-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">05 Apr, 2020 <small className="text-muted">03:09 pm</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Attending the event “Some New Event”</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-user-2-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">02 Apr, 2020 <small className="text-muted">12:07 am</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Responded to need “In-Kind Opportunity”</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-calendar-2-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">07 Apr, 2020 <small className="text-muted">12:47 pm</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Created need “Volunteer Activities”</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-edit-2-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">05 Apr, 2020 <small className="text-muted">03:09 pm</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Attending the event “Some New Event”</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-warning text-dark rounded-circle">
                                                <i className="ri-user-2-fill"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">02 Apr, 2020 <small className="text-muted">12:07 am</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">Responded to need “In-Kind Opportunity”</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </SimpleBar>
        </CardBody>
    </Card>  )
}

export default RecentActivityFeed