import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import urlMaker from "../../helpers/urlMaker";

export const GetStartContent = ({ content }) => {
  const [headContent, setHeadContent] = useState({});
  const [gridContent, setGridContent] = useState([]);

  useEffect(() => {
    if (content.length !== 0) {
      setHeadContent(content[0]);
      const temp = content.filter((data) => data.order !== 1);
      console.log(temp);
      setGridContent(temp);
    }
  }, [content]);
  return (
    <>
      <div className="d-flex justify-content-center ">
        <h1 style={{ maxWidth: "950px" }}>{headContent.heading}</h1>
      </div>
      <div className="d-flex justify-content-center ">
        <p style={{ width: "850px" }}>{headContent.description}</p>
      </div>
      <h2 className="mt-2">{headContent.sub_heading}</h2>
      {/* <h2>{headContent.sub_heading}</h2> */}
      <div className="card-container-support-page">
        {gridContent.map((data, index) => (
          <div key={index} className="text-start d-flex ">
            <Card className="support-card ">
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
                <img
                  src={urlMaker(data.file, "training_hub_content", true)}
                  alt=""
                  className="support-card-img2 "
                />
              </div>
              <h4 className="mt-2">{data.heading}</h4>
              <p>{data.description}</p>
            </Card>
            <img
              src={urlMaker(data.file, "training_hub_content", true)}
              alt=""
              className="support-card-img"
            />
          </div>
        ))}
      </div>
    </>
  );
};
