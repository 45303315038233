import React from "react";
import { Card } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@material-ui/core";
import noImage from "../assets/images/Noimage1.png";
// import moment from "moment";
import Swal from "sweetalert2";
import Moments from "../helpers/Moment";
// import { useEffect } from "react";
import MessageIcon from "@mui/icons-material/Message";
import { colors } from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useEffect } from "react";
import urlMaker from "../helpers/urlMaker";

function SupportCard({
  data,
  editModalHandler,
  deleteHandler,
  enableDisableHandler,
}) {
  return (
    <Card className="abaci-support-card">
      <div>
        <div
          className="abaci-support-card-template"
          onClick={() => {
            editModalHandler(data);
          }}
        >
          <div className="abaci-support-card-template-child-1">
            <div
              // style={{
              //   width: "70px",
              //   border: "1px solid black",
              // }}
              className="support-card-image-container"
            >
              <img
                src={
                  data.thumbnail
                    ? urlMaker(data.thumbnail, "image", true)
                    : noImage
                }
                alt="images"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "5px",
                }}
                className=" fade-in-image"
              />
            </div>
          </div>

          <div className="abaci-support-card-template-child-2">
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "300px",
                color: "#8D9091",
                textTransform: "capitalize",
              }}
            >
              {data?.main_heading
                ? data?.main_heading
                : data?.heading
                ? data.heading
                : data.name && data.name}

              <div style={{ marginLeft: "5px", marginTop: "-3px" }}></div>
            </div>

            <div
              style={{
                fontWeight: "normal",
                fontSize: "13px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                textTransform: "capitalize",
                width: "300px",
                color: "#adadad",
              }}
            >
              {data?.sub_heading ? data.sub_heading : ""}
            </div>

            <div
              style={{
                fontWeight: "normal",
                fontSize: "13px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "300px",
                color: "#adadad",
                textTransform: "capitalize",
              }}
            >
              {data?.description ? data.description : ""}
            </div>
          </div>
          {/* {data.order ? (
            <div className="order_text">
              <p style={{ paddingTop: "5px", fontWeight: "bold" }}>
                {data.order}
              </p>
            </div>
          ) : null} */}
        </div>
      </div>

      <div className="abaci-support-card-action">
        <Tooltip title={data.status === "Active" ? "Disable" : "Enable"} arrow>
          <IconButton
            onClick={() => {
              enableDisableHandler(data);
            }}
          >
            {/* className="far fa-trash-alt"
                                                   style={{ cursor: "pointer" }} */}

            {data.status === "Active" ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit" arrow>
          <IconButton
            onClick={() => {
              editModalHandler(data);
            }}
          >
            {/* className="far fa-trash-alt"
                                                  style={{ cursor: "pointer" }} */}

            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete" arrow>
          <IconButton
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  // deleteClientData(rowData?.id)
                  deleteHandler(data.id);
                }
              });
            }}
          >
            {/* className="far fa-trash-alt"
                                        style={{ cursor: "pointer" }} */}

            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Card>
  );
}

export default SupportCard;
