import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from "react-router-dom";
export default function HelpMenu(props) {
  const profile = useSelector((state) => state.authSlice.profile);
  const [menu, setMenu] = useState(false);


  return (
    <React.Fragment>
   
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu((state) => !state)}
        className=""
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
            <SettingsIcon />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
        <Link to='/webtraininghub' target="_blank" style={{color:'black'}}>
          <DropdownItem href="" >
            <i className="ri-questionnaire-fill align-middle me-1"></i> {"Training Hub"}
          </DropdownItem>
        </Link>
        <Link to='/webhelpcenter' target="_blank" style={{color:'black'}}>
          <DropdownItem href="" >
            <i className="ri-question-line align-middle me-1"></i> {"Help Center"}
          </DropdownItem>
          </Link>
         
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}
