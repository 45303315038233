import React, { useState, useEffect } from "react";
import "../PreviewModal/PreviewModal.css";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import useWindowSize from "../../hooks/useWindowSize";
import { useSelector, useDispatch } from "react-redux";

import ScheduleContentWrapper from "./ScheduleContentWrapper"
import CSSMaker from "../../CSSMaker";
import AbaciHorizontalLine from "../AbaciHorizontalLine/AbaciHorizontalLine";
import ProgressingBar from "./ProgressingBar";
import { updateDroppableBagItems } from "../../store/bagItems";
import loading from '../../assets/lottie/loading.json'
import { Player } from "@lottiefiles/react-lottie-player";
export default function PreviewModal({
  isPreviewVisible,
  setIsPreviewVisible,
  previewType,
  previewData
}) {

  const dispatch=useDispatch();
  const [isLoading,setIsLoading]=useState(true)
  const droppableBagItems = useSelector(state => state.bagItems.droppableBagItems)
  const [delayedStateForModalAnimation, setDelayedStateForModalAnimation] =
    useState(false);
  const [width, setWidth] = useState(0);
  const [midNightDate]=useState(new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`).getTime())
  const [scalingFactorToTheScreen, setScalingFactorToTheScreen] = useState(2)
  const [virtualHieghtFactor, setVirtualHieghtFactor] = useState(0)
  const [template, setTemplate] = useState();
  // const [nowPlaying, setNowPlaying] = useState(droppableBagItems);

  useEffect(() => {
    if (previewData !== null) {
      // console.log(previewData?.template_clone.divclone_templateclone,'dt')
      setScalingFactorToTheScreen(previewData?.template_clone?.orientation === "Portrait" ? 4 : 2)
      setVirtualHieghtFactor(previewData?.template_clone?.resolution_height / previewData?.template_clone?.resolution_width)
      // setTemplate(previewData?.template_clone)
    }
  }, [previewData])


  const delayedFunc = (state) => {
    setDelayedStateForModalAnimation(state);
  };
  const window = useWindowSize();
  useEffect(() => {
    setTimeout(delayedFunc(true), 10);

    setTemplate(droppableBagItems)
  }, []);
  const handleClose = () => {
    delayedFunc(false);
    // setTimeout(() => {
      setIsPreviewVisible(false);
    // }, 500);

    // console.log(template)
    if(previewType === "schedule"){
    dispatch(updateDroppableBagItems(template))
    }
  };
  useEffect(() => { }, [width]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, [])
  
  useEffect(() => {
    // console.log(previewData)
  }, [])
  

  return (
    <div style={{ zIndex: '9999' }}>
      <div className='preview-backdrop'>
        <div className='close-icon-container'>
          <Close className='close-icon' onClick={handleClose} />
        </div>
        <div
          className={delayedStateForModalAnimation ? "monitor" : "monitor open"}
          style={{
            // width: window.width / scalingFactorToTheScreen + 8,
            height:
              (window.width / scalingFactorToTheScreen) *
              virtualHieghtFactor +
              18,
          }}>
          <div className='tvscreen-logo'>
            ABACI
            <div className='icon-led-green'></div>
          </div>
          <div
            style={{
              width: window.width / scalingFactorToTheScreen,
              height:
                (window.width / scalingFactorToTheScreen) *
                virtualHieghtFactor,
              backgroundColor: "white",
              margin: "3px",
              transition: "all ease-in-out 0.5s",
            }}>
                <div>
            {isLoading ?     <Player
              autoplay
              loop
              src={loading}
              style={{ height:(window.width / (scalingFactorToTheScreen*2)) *
              virtualHieghtFactor, width: window.width / (scalingFactorToTheScreen*2),position:'absolute',top: '50%',
              left: '50%',
              transform: "translate(-50%, -50%)"}}
            /> :
            
            
            previewData?.template_clone.divclone_templateclone.map((div, index) => {
                let styleOfDiv = new CSSMaker(
                    div,
                    window.width /
                    scalingFactorToTheScreen /
                    previewData?.template_clone.resolution_width
                ).convertToVirtual();
                styleOfDiv = {
                    ...styleOfDiv,
                    transition: "all ease-in-out 0.5s",
                    overflow: "hidden"
                };
              
                return (
                    <div style={styleOfDiv} key={index}>
                        
              <ScheduleContentWrapper 
              divId={div.id}
              divName={div.div_name} 
              scheduleDetail={previewData}
              width={styleOfDiv.width === null ? 0 : styleOfDiv.width}
              // divOriginalWidth={div.width}
              height={styleOfDiv.height}
              previewType={previewType}
              key={index}
              
              />

                    </div>
                );
            })}
        </div>
          </div>
        </div>
        {previewType === "schedule" ? <div className='progress-container'>
          {previewData?.template_clone?.divclone_templateclone?.map((div, index) => {
            const heightOfSubContainer = 100 / previewData?.template_clone?.divclone_templateclone?.length;

            let mediasForCurrentDiv = []
            droppableBagItems?.map((item, index) => {
              if (item.div_id == div.id) {
                mediasForCurrentDiv = item[div.id]
              }
              // console.log(mediasForCurrentDiv,'media')
            })
            let contents = []
            if (typeof mediasForCurrentDiv.length !== 0) {
              contents = mediasForCurrentDiv.map((content, index) => {
                const StartDate = new Date(content.start_date).setHours(0, 0, 0, 0);
                const EndDate = new Date(content.end_date).setHours(0, 0, 0, 0);      
                if(StartDate <= midNightDate && EndDate >= midNightDate){
                return (
                  <ProgressingBar
                    key={index}
                    scheduled_content={content}
                
                  />
                );
                }
              });
            }

            


            return (
              <div
                className="scrolling-percentage-container-per-div"
                style={{
                  width: "100%",
                  height: String(heightOfSubContainer) + "vh",
                }}
                key={index}
                >
                <AbaciHorizontalLine />
                <AbaciHorizontalLine />
                <div className="containger-heading">
                  {div.div_name} - Container

                </div>
                <AbaciHorizontalLine />
                <AbaciHorizontalLine />

                {contents.length !== 0 ? contents.map((item, index) => item) : null}
              </div>
            );
          })}
        </div> : null} 
      </div>
    </div>
  );
}
