import React, { useContext, useState } from "react";
import "./header.css";
import EditorButton from "../customComponents/fab-button/EditorButton";
import { useSelector } from "react-redux";
import { CanvasContext } from "../../../context/CanvasContext";
import b64toBlob from "../../../helpers/base64ToBlob";
import { Toastr } from "../../../helpers/Alert";
import { authAxios } from "../../../axiosInstance";

import Error from "../../../helpers/Error";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
const optionsToAdd = ["id", "name", "sub_type"];

const HeaderComponent = ({ canvasId }) => {
  const navigate = useNavigate();
  const { canvas, canvasObjFromBackend } = useContext(CanvasContext);
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);
  const [loader, setLoader] = useState(false);
  const exportJSON = () => {
    let png = b64toBlob(
      canvas.toDataURL({
        format: "png",
      })
    );
    let json = {
      canvasWidth: canvasObjFromBackend.canvasWidth,
      canvasHeight: canvasObjFromBackend.canvasHeight,
      canvasObject: canvas.toJSON(optionsToAdd),
    };
    let canvasString = JSON.stringify(json);
    // console.log(json)
    const formData = new FormData();
    formData.append("file", png, `canvas${canvasId}.png`);
    formData.append("file_type", "canvas");
    formData.append("object", canvasString);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    let url;
    if (tenantDetails?.app_type === "Admin") {
      url = `/stock/api/signage/objectrud/${canvasId}`;
    } else {
      url = `/api/signage/objectrud/${canvasId}`;
    }

    // const url = `api/signage/objectrud/${canvasId}`
    authAxios
      .patch(url, formData, config)
      .then((response) => {
        setLoader(false);
        // console.log(response.data)
        Toastr("success", "Canvas Updated Successfully");
      })
      .catch((error) => {
        setLoader(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <div className={"separate-section-baar"}>
      <div className="right-section">
        <IconButton
          onClick={() => navigate("/canvaspage")}
          style={{ marginRight: "5px" }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <span className={"section-sperator-text"}>CANVAS EDITOR</span>
      </div>
      <div className="left-section">
        <EditorButton
          loader={loader}
          customClass={"done-btn"}
          buttText={"Save"}
          onClicked={exportJSON}
          setLoader={setLoader}
        />
      </div>
    </div>
  );
};

export default HeaderComponent;
