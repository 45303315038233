
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useState, useEffect } from "react";
import "./ScheduleCreator.css";
// import CSSMaker from "./CSSMakerHTMLType";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DroppableComponent from "./ScheduleCreatorDroppableComponent";
import NonDroppableComponent from "./ScheduleCreatorNonDroppableComponent";
import { reorder, copy, move } from "./DragDropFunctions";
import { useSelector, useDispatch } from "react-redux";
import { setDroppableBagItems, setCollections } from "../../../store/bagItems";
// import { ReactComponent as Search } from "../../../assets/icons/search.svg";
// import AbaciCheckBox from "../../../components/AbaciCheckBox/AbaciCheckBox";
import { TabContent, TabPane, Nav, NavItem, NavLink,  Button, Row, Col, Form, Input, ButtonGroup } from 'reactstrap';
import classnames from "classnames";


// const { TabPane } = Tabs;
export default function ScheduleCreator(props) {
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState('')
  const [currentActiveTab, setCurrentActiveTab] = useState(props?.scheduleDetails?.template_clone?.divclone_templateclone[0]?.id);
  const toggle = (tab) => {
    // localStorage.setItem("templateTabKey", tab);

    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  useEffect(() => {
    const search = setTimeout(() => {
      props.setSearchText(filterText)
    }, 500);
    return () => clearTimeout(search)
  }, [filterText])

  // const [droppableBagItems, setdroppableBagItems] = useState(props.items);
  const [scheduleDetails, setScheduleDetails] = useState(props.scheduleDetails);
  
  const [searchText, setSearchText] = useState("");
  const droppableBagItems = useSelector(
    (state) => state.bagItems.droppableBagItems
  );
  const collections = useSelector((state) => state.bagItems.collections);
  const [copyOfCollecions,setCopyOfCollecions] = useState(collections);
  let virtualizationFactor = 0;
  const screenHeightOnTrack = 40;



  useEffect(() => {
    setCopyOfCollecions([...collections]);
  }, [collections]);



  virtualizationFactor =
    screenHeightOnTrack / scheduleDetails?.templates_clone?.resolution_height;
  const screenWidthOnTrack =
    scheduleDetails?.templates_clone?.resolution_width * virtualizationFactor;
  const tabChangeHandler = (value) => { };

  // const searchHander = (event) => {
  //   setSearchText(event.target.value);
  // };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      // If the component is dragged to non droppable area, this condition will run and nothing will happen
      return;
    } else if (source.droppableId === destination.droppableId) {
      // If the components are dragging and dropping inside the same droppable area, this will execute
      if (source.droppableId === "NonDroppable") {
        // if the components are dragged inside the collections area, no actions are required
        return;
      } else {
        // If the drag happened inside the droppable Bag items, the items will be reordered and the state will be updated
        let tempItems = droppableBagItems.map((element) => {
          if (element.id === destination.droppableId) {
            let tempBag = [...element[destination.droppableId]];
            tempBag = reorder(tempBag, source.index, destination.index);
            return { ...element, [destination.droppableId]: tempBag };
          } else {
            return element;
          }
        });
        // setdroppableBagItems(tempItems);
        dispatch(setDroppableBagItems({ droppableBagItems: tempItems }));

        //return
      }
    } else {
      // If the drag is between two droppable area, this will be executed
      if (destination.droppableId === "NonDroppable") {
        // if the drag is from the dropppable bag to collections, no actions are required
        return;
      } else {
        // This condition will be the dragging from collections to the droppable Bag.
        let tempBag = null;
        let tempItems = droppableBagItems.map((element, index) => {
          if (element.id === destination.droppableId) {
            tempBag = [...element[destination.droppableId]];
            tempBag = copy(copyOfCollecions, tempBag, source, destination);
            return { ...element, [destination.droppableId]: tempBag };
          } else {
            return element;
          }
        });
        // setdroppableBagItems(tempItems);
        dispatch(setDroppableBagItems({ droppableBagItems: tempItems }));
      }
    }
    // },
  };
  //   [droppableBagItems]
  // );
  const clickHandler = () => {
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div style={{
        width: "100%",
        height: "calc(100vh - 130px)",
        // backgroundColor: "red",
        // marginTop: '70px',
        display: "flex",
        flexDirection: "row"
      }}>
        <div className='droppable-component-wrapper'>
          <div style={{
            // backgroundColor: "gray",
            width: "100%",
            height: "100%",
          }}>
            <Nav tabs className="nav-tabs-custom nav-justified">
              {props?.scheduleDetails?.template_clone?.divclone_templateclone.map((div, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === div.id,
                    })}
                    onClick={() => {
                      toggle(div.id);
                    }}
                  >
                    {div.div_name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            {droppableBagItems.map((element, index) => (
              <TabContent activeTab={currentActiveTab} key={index}>
                <TabPane tabId={element.div_id}>
                  <Row>
                    <Col sm="12">
                      <div className='schedule-creator-tab-wrapper'>
                        <DroppableComponent
                          // The index position of the droppableBagItems will provide div details
                          divIndex={index}
                          // setdroppableBagItems = {setdroppableBagItems}
                          // droppableBagItems = {droppableBagItems}
                          items={element}
                          droppableId={element.id}
                        />
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            ))}
          </div></div>
        <div >
          <Form className="app-search d-none d-lg-block" style={{ marginRight: '15px' }}>
            <div className="position-relative" style={{ paddingLeft: "5px", zIndex: '0' }}>
              <div
                style={{
                  width: "300px",
                  backgroundColor: "#F1F5F7",
                  paddingLeft: "10px",
                  borderRadius: "30px",
                  zIndex: '0'
                }}
              >
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => { setFilterText(e.target.value) }}
                  style={{
                    borderWidth: "0px",
                    backgroundColor: "#fff",
                    borderRadius: "30px",

                  }}
                />
              </div>
            </div>
          </Form>
          <div style={{ display: 'flex', justifyContent: 'center' }}>

            <ButtonGroup style={{}}>
              <Button
                className={"button_group_class" + (props.currentActiveTab === '1' ? " class_active " : "")}
                color='secondary'
                outline
                onClick={() => props.toggle("1")}
              // active={props.currentActiveTab === '1'}
              >
                Image
              </Button>
              <Button
                color='secondary'

                outline
                // id='button_group_class'
                className={"button_group_class" + (props.currentActiveTab === '2' ? " class_active " : "")}
                onClick={() => props.toggle("2")}
              // active={props.currentActiveTab === '2'}
              >
                Video
              </Button>
              <Button
                color='secondary'

                outline
                className={"button_group_class" + (props.currentActiveTab === '3' ? " class_active " : "")}
                onClick={() => props.toggle("3")}
              // active={props.currentActiveTab === "3"}
              >
                Canvas
              </Button>
              <Button
                color='secondary'

                outline
                className={"button_group_class" + (props.currentActiveTab === '4' ? " class_active " : "")}
                onClick={() => props.toggle("4")}
              >
                Apps
              </Button>
            </ButtonGroup>



          </div>
          <div className='non-droppable-component-wrapper'>
            <NonDroppableComponent items={copyOfCollecions} loadFunc={props.loadFunc} totalCount={props.totalCount} />
          </div>
        </div>
      </div>
    </DragDropContext>

  );
}
