import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import ClockCard from "./clock/ClockCard";
import WeatherCard from "./weather/WeatherCard";
import YouTubeCard from "./youtube/YoutubeCard";
import TitleCard from './Titles/TitleCard'
import EmptyList from "../../../assets/SVG/Search Not Found 2.svg";
// import AppsCard from "./AppsCard";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { authAxios } from "../../../axiosInstance";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import { addNewApps, deleteApps, setApps } from "../../../store/appSlice";
// import AppTap from "./AppTap";
import AppGallery from "./AppGallery";
import AppViewModal from "./AppViewModal";
// import { YouTube } from "@material-ui/icons";
import TitleEditModal from "./Titles/TitleEditModal";
// import CanvasList from "./CanvasList";
export default function Apps() {
  const dispatch = useDispatch();
  const apps = useSelector((state) => state.appSlices.apps);
  const [limit] = useState(15);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);
  const [searchKeyCount,setSearchKeyCount] = useState(0)
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);

  const [windowHeight,setWindowHeight] =useState(window.innerHeight)
  const [editModalShow, setEditMdoalShow] = useState(false);
  const [itemToBeEdited, setItemToBeEdited] = useState(null);
  const [currentActiveTab, setCurrentActiveTab] = useState(
    localStorage.getItem("appTabKey") != null
      ? localStorage.getItem("appTabKey")
      : "1"
  );
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "Apps", link: "#" },
    ],
  });
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isAppModalVisible, setIsAppModalVisible] = useState(false);
  const editModalToggle = () => {
    setEditMdoalShow(!editModalShow);
  };

  const  editModalHandler=(item)=>{
    setItemToBeEdited(item)
    editModalToggle();
  }

  const handleResize = () => {
    setWindowHeight(window.innerHeight)
  };

  
  useEffect(() => {
  
    setPage(1);
    setHasNextPage(false);
    dispatch(setApps(null));

    if(searchKey !== prevSearchKey){
      setPrevSearchKey(searchKey)
    }

    setSearchKeyCount(searchKey !==null ? searchKey.length : 0)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[searchKey])


  useEffect(() => {

    window.addEventListener('resize',handleResize)


    if (currentActiveTab === "1") {
      const url = `/api/signage/objectlistcreate?object_type=clock&object_type=weather&object_type=youtube&object_type=text&limit=${limit}&offset=${
        limit * (page - 1)
      }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}&file_type=youtubeweatherclock`;

      authAxios
        .get(url)
        .then((response) => {
          if (apps === null) {
            dispatch(setApps(response.data.results));
          } else {
            dispatch(addNewApps(response.data.results));
          }

          setTotalCount(response.data.count);
          if (response.data.next) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }
        })
        .catch((error) => {
          Toastr("error", "Oops!,Something Went Wrong");
          dispatch(setApps([]));
        });
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveTab, page,prevSearchKey]);

  const toggleCustomJustified = (tab) => {
    if (currentActiveTab !== tab) {
      localStorage.setItem("appTabKey", tab);
      setCurrentActiveTab(tab);
      setPage(1);
      // setTotalCount(0);
      dispatch(setApps(null));
    }
  };

  const deleteHandler = (id)=>{

    const url = `api/signage/objectrud/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        dispatch(deleteApps(id));
        Toastr("success", "App Instance Deleted Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  }

  useEffect(() => {
    return () => {
      dispatch(setApps(null));
      window.removeEventListener('resize',handleResize)
    };
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFunc = () => {
    if (hasNextPage) {
      // console.log('wrked')
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
    // setTemp(state => [...state, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
  };



  return (
    <>
      <React.Fragment>
        {isAppModalVisible && (
          <AppViewModal
            isModalVisible={isAppModalVisible}
            setIsModalVisible={setIsAppModalVisible}
            // data={image}
          />
        )}
          {editModalShow &&
      <TitleEditModal isOpen={editModalShow} toggle={editModalToggle} item={itemToBeEdited}  isApps={true}/>

      }
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Apps"
              breadcrumbItems={dashboardState.breadcrumbItems}
            ></Breadcrumbs>
            <Col xl={24}>
              <Card style={{ minHeight: "70vh" }}>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className="d-sm-block">My Apps</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: currentActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustomJustified("2");
                        }}
                      >
                        <span className="d-sm-block">App Gallery</span>

                   
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="1">
                      {apps === null ? (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                          }}
                        >
                          <Spinner animation="grow" />
                        </div>
                      ) : apps?.length === 0 ? (
                        <>
                          <div className=" text-center">
                            <img
                              src={EmptyList}
                              alt=""
                              style={{ width: "450px", height: "450px" }}
                            />
                            <h6 style={{}}>No Apps Found !</h6>
                          </div>
                        </>
                      ) : (
                        <>
                          <InfiniteScroll
                            pageStart={0}
                            // dataLength={totalCount}
                            // next={loadFunc}
                            loadMore={loadFunc}
                            hasMore={hasNextPage}
                            loader={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  userSelect:'none'
                                }}
                                className="loader"
                                key={0}
                              >
                                <Spinner animation="grow" />
                              </div>
                            }
                          >
                            <div
                              style={{
                                marginTop: "10px",
                                userSelect:'none',
                                maxHeight:Math.round( windowHeight-313),

                                // maxHeight:Math.round( windowHeight > 1250 ? windowHeight * 0.78 :  windowHeight < 900 ? windowHeight * 0.625 :  windowHeight * 0.71),
                                overflowY:'scroll',
                                overflowX:'hidden'
                              
                              }}
                            >
                              {apps?.map((items, index) => {
                                if (items.file_type === "weather") {
                                  return (
                                    <WeatherCard
                                      item={items}
                                      deleteHandler={deleteHandler}
                                      app={true}
                                      
                                    />
                                  );
                                } else if (items.file_type === "clock") {
                                  return (
                                    <ClockCard
                                      item={items}
                                      deleteHandler={deleteHandler}
                                      app={true}
                                    />
                                  );
                                } else if (items.file_type === "youtube") {
                                  return (
                                    <YouTubeCard
                                      item={items}
                                      deleteHandler={deleteHandler}
                                      app={true}
                                    />
                                  );
                                }
                                else if (items.file_type === "text") {
                                  return (
                                    <TitleCard
                                      item={items}
                                      deleteHandler={deleteHandler}
                                      editModalHandler={editModalHandler}
                                      app={true}
                                    />
                                  );
                                }
                                else{
                                return null
                                }
                              } )}
                            </div>
                          </InfiniteScroll>
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          
                        }}
                      >
                    
                        <AppGallery />
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
