import React, { useState } from 'react'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import Modals from '../../../components/Modal';

export const SchedulerContainerModal = (props) => {
    const [currentActiveTab, setCurrentActiveTab] = useState(props?.data?.template_clone?.divclone_templateclone[0]?.id);


    const toggle = (tab) => {
        // localStorage.setItem("templateTabKey", tab);
    
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
      };
  return (
    <div sty>
        <Modals
         title='Container Viewer'
         isOpen={props.isOpen}
         size={'lg'}
        
         toggle={props.toggle}
        >
        <div>
        <Nav tabs className="nav-tabs-custom nav-justified">
    {props?.data?.template_clone?.divclone_templateclone.map((div, index) => (
        <NavItem key={index}>
          <NavLink
            className={classnames({
              active: currentActiveTab === div.id,
            })}
            onClick={() => {
              toggle(div.id);
            }}
          >
            {div.div_name}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
    {props?.data?.template_clone?.divclone_templateclone.map((element, index) => (
      <TabContent activeTab={currentActiveTab} key={index}>
        <TabPane tabId={element.div_id}>
          <Row>
            <Col sm="12">
              <div className='schedule-creator-tab-wrapper' >
                <h1>{index+1}</h1><h1>{index+1}</h1><h1>{index+1}</h1><h1>{index+1}</h1>
              </div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    ))}
  </div>
  </Modals></div>
    
  )
}
