import React from 'react'
import SimpleBar from 'simplebar-react'
import Timeline from "@mui/lab/Timeline";
import TimelineItem , { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Card, CardBody, CardTitle } from 'reactstrap';
import moment from 'moment';

function ScreenOnlineStatus({data,isScreenGroup}) {

    // const info=[
    //     {
    //         created_date:'12-12-2023 12:30pm' ,
    //         online_status:true
    //     },
    //     {
    //         created_date:'12-21-2023 12:30pm' ,
    //         online_status:false
    //     },
    //     {
    //         created_date:'10-12-2023 12:30pm' ,
    //         online_status:true
    //     },
    //     {
    //         created_date:'12-12-2023 12:30pm' ,
    //         online_status:false
    //     },
    //     {
    //         created_date:'12-12-2023 12:30pm' ,
    //         online_status:true
    //     },
    //     {
    //         created_date:'12-12-2023 12:30pm' ,
    //         online_status:false
    //     },
    //     {
    //         created_date:'12-12-2023 12:30pm' ,
    //         online_status:true
    //     },
    //     {
    //         created_date:'12-12-2023 12:30pm' ,
    //         online_status:false
    //     },
    //     {
    //         created_date:'12-12-2023 12:30pm' ,
    //         online_status:true
    //     },
    // ]
  return (
    <div>
        <Card  style={{maxHeight:'600px', minHeight: "510px"}}>
            <CardBody>
                {!isScreenGroup &&
                 <CardTitle>Screen Online Status</CardTitle>
                }
                    <div className="border-top py-3"/>
                <SimpleBar style={{ maxHeight: "405px" }}>
                <Timeline sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 3,
                                },
                            }}>
                    {data?.map((data) => (
                        <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                        <div className={data.status === 'Online' ? `badge badge-soft-success font-size-12` : `badge badge-soft-danger font-size-12`}>
                                            {moment(data.created_date).format('MMM DD,YYYY,h:mm:ss a')}
                                        </div>
                            </TimelineContent>
                      </TimelineItem>
                    ))}
                </Timeline>
                </SimpleBar>
            </CardBody>
        </Card>

    </div>
  )
}

export default ScreenOnlineStatus