import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import "animate.css";
import { SketchPicker } from "react-color";
import "./TitleAddModal.css";
import { Tooltip } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { Checkbox } from "@mui/material";
import Error from "../../../../helpers/Error";
import { Toastr } from "../../../../helpers/Alert";
import { authAxios } from "../../../../axiosInstance";
import { addText } from "../../../../store/TextSlice";
import { useDispatch } from "react-redux";
function TitleAddModal(props) {
  const dispatch=useDispatch();
  const [divWidth, setDivWidth] = useState("");
  const [divHeight, setDivHeight] = useState("");
  const [divSelectedColor, setDivSelectedColor] = useState("");
  // const [textMargin, setTextMargin] = useState();
  const [marginTop, setMarginTop] = useState(50);
  // const [marginBottom, setMarginBottom] = useState(10);
  const [marginLeft, setMarginLeft] = useState(10);
  const [marginRight, setMarginRight] = useState(10);
  const [infiniteLoop, setInfiniteLoop] = useState(false);
  const [text, setText] = useState("Your Title");
  // const [selected, setSelected] = useState("bounce");
  const [fontDelay, setFontDelay] = useState(0);
  const [fontDuration, setFontDuration] = useState(1);
  const [fontSize, setFontSize] = useState("12");
  const [textAlignment, setTextAlignment] = useState("center");
  const [fontWeight, setFontWeight] = useState(false);
  const [fontColor, setFontColor] = useState("black");
  const [fontStyle, setFontStyle] = useState(false);
  const [fontFamily, setFontFamily] = useState("Times New Roman");
  const [textDecoration, setTextDecoration] = useState(false);
  const [animationName, setAnimationName] = useState("bounce");
  const [canvasColor, setCanvasColor] = useState("#000000");
  const [name,setName]=useState('')
  // const [selectedDiv, setSelectedDiv] = useState("");
  const [loadingOnButton,setLoadingOnButton]=useState(false)

  const handleChange = (value) => {
    // setSelected(value);
    setAnimationName(value);
  };
  const handleFontStyle = (value) => {
    // console.log(value);
    setFontFamily(value);
  };
  // const handleClick = (e) => {
  //   setSelected(animationName);
  // };
  const fontDurationHandler = (value) => {
    setFontDuration(value);
  };
  const fontDelayHandler = (value) => {
    setFontDelay(value);
  };
  const fontSizeHandler = (value) => {
    setFontSize(value);
  };
  // const TransitionSaveHandler = () => {
  //   console.log({
  //     Text: text,
  //     color: fontColor,
  //     fontFamily: fontFamily,
  //     fontSize: fontSize,
  //     textAlign: textAlignment,
  //     fontWeight: fontWeight ? "bold" : "400",
  //     fontStyle: fontStyle ? "italic" : "normal",
  //     textDecoration: textDecoration ? "underline" : "",
  //     animation:animationName,
  //     animationDelay: `${fontDelay}ms`,
  //     animationDuration: `${fontDuration}s`,
  //     // selectedDiv: selectedDiv,
  //     animationLoop: infiniteLoop ? infiniteLoop : "1",
  //     marginTop: marginTop,
  //     marginLeft: marginLeft,
  //     marginRight: marginRight
  //   });
  // };

  const saveHandler= () => {

    const text_properties={
      text: text,
      color: fontColor,
      fontFamily: fontFamily,
      fontSize: fontSize,
      textAlign: textAlignment,
      fontWeight: fontWeight ? "bold" : "400",
      fontStyle: fontStyle ? "italic" : "normal",
      textDecoration: textDecoration ? "underline" : "",
      animation:animationName,
      animationDelay: `${fontDelay}s`,
      animationDuration: `${fontDuration}s`,
      // selectedDiv: selectedDiv,
      animationLoop: infiniteLoop ? infiniteLoop : "1",
      marginTop: marginTop,
      marginLeft: marginLeft,
      marginRight: marginRight
    }

    const object={
      name: name,
      file_type: "text",
      object: {
        title: name,
        text_properties:text_properties
      },
    }

    if(name !==''){
      setLoadingOnButton(true)

    const url = "api/signage/objectlistcreate";

    authAxios
      .post(url,object)
      .then((response) => {
        dispatch(addText(response.data))
        setLoadingOnButton(false);
        Toastr("success", "Text Instance Created Succesfully");
        props.toggle()
      })
      .catch((error) => {
        setLoadingOnButton(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
    }
    else{
      Toastr('info','Please Enter the Instance Name')
    }
  }

 
  // const handleDivChange = (value) => {
  //   setSelectedDiv(value);
  // };
  function onLoopChangeHandler(e) {
    setInfiniteLoop(e.target.checked);
  }

  const arrayFonts = [
    "Dennis Hill Speeding",
    "Acme",
    "Akshar",
    "Artifika",
    "Comic Neue",
    "Courier Prime",
    "EB Garamond",
    "Just Another Hand",
    "Black Han Sans",
    "Montserrat",
    "Playball",
    "Poppins",
    " Ultra",
    "Smythe",
    " Rock Salt",
    "Brush Script MT",
    "Times New Roman",
    "Roboto",
  ];

  const animationArray = [
    "fadeIn",
    "fadeInDown",
    "fadeInRight",
    "fadeInLeft",
    "fadeInUp",
    "fadeOut",
    'bounce',
    "fadeOutLeft",
    "fadeOutRight",
    "fadeOutUp",
    "fadeOutDown",
    "backInDown",
    "backInLeft",
    "backInRight",
    "backInUp",
    "zoomIn",
    "zoomInDown",
    "zoomInLeft",
    "zoomInRight",
    "zoomInUp",
    "zoomOut",
    "zoomOutDown",
    "zoomOutLeft",
    "zoomOutRight",
    "zoomOutUp",
  ];

  const durationAndDelayArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <Modal size={"xl"} centered isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>{"Text Add Modal"}</ModalHeader>
      <ModalBody>
        <div className="parent">
          <div className="left_div">
            <div className="mt-3">
              <Row>
                <Col span={24}>
                <div className="d-flex  ">
                    <label className="mt-2">Instance Name</label>
                    <Input
                    placeholder="Enter Instance Name"
                      style={{ width: "300px", marginLeft: "8px" }}
                      defaultValue={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="d-flex mt-3 ">
                    <label className="mt-2">Enter the text</label>
                    <Input
                      style={{ width: "300px", marginLeft: "15px" }}
                      defaultValue={text}
                      onChange={(e) => {
                        setText(e.target.value);
                      }}
                    />
                  </div>

                  <div className="d-flex mt-3">
                    <label className="mt-2">Font size</label>
                    <Form.Select
                      style={{ width: "160px", marginLeft: "45px" }}
                      value={fontSize}
                      placeholder="Font Size"
                      // onChange={changeFontSize}

                      onChange={(event) => fontSizeHandler(event.target.value)}
                    >
                      <option value="6">6</option>
                      <option value="8">8</option>
                      <option value="10">10</option>
                      <option value="12">12</option>
                      <option value="14">14</option>
                      <option value="16">16</option>
                      <option value="18">18</option>
                      <option value="20">20</option>
                      <option value="22">22</option>
                      <option value="24">24</option>
                      <option value="26">26</option>
                      <option value="28">28</option>
                      <option value="30">30</option>
                      <option value="32">32</option>
                      <option value="34">34</option>
                      <option value="36">36</option>
                      <option value="38">38</option>
                      <option value="40">40</option>
                      <option value="42">42</option>
                      <option value="44">44</option>
                      <option value="46">46</option>
                      <option value="48">48</option>
                      <option value="50">50</option>
                      <option value="52">52</option>
                      <option value="54">54</option>
                      <option value="56">56</option>
                      <option value="58">58</option>
                      <option value="60">60</option>
                      <option value="62">62</option>
                      <option value="64">64</option>
                      <option value="66">66</option>
                      <option value="68">68</option>
                      <option value="70">70</option>
                    </Form.Select>

                    <label
                      style={{
                        marginLeft: "20px",
                        marginTop: "5px",
                      }}
                    >
                      Color
                    </label>

                    <div
                      className="dropdownlist"
                      style={{
                        marginLeft: "32px",
                        marginTop: "5px",
                        backgroundColor: `${canvasColor}`,
                        width: "55px",
                        height: "23px",
                        borderRadius: "5px",
                        border: "1px solid #d9d9d9",
                      }}
                    >
                      <div className="dropdown-content">
                        <SketchPicker
                          color={canvasColor}
                          onChange={(value) => {
                            setFontColor(value.hex);
                            setCanvasColor(value.hex);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <label className="mt-2"> Font Family </label>

                    <Form.Select
                      onChange={(event) => handleFontStyle(event.target.value)}
                      value={fontFamily}
                      style={{ width: "300px", marginLeft: "27px" }}
                    >
                      {arrayFonts.map((item, index) => {
                        return (
                          <option style={{ fontFamily: item }} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  {/* <div style={{ marginTop: "1.4rem", display: "flex" }}>
                    <label>Text Position</label>

                    <Tooltip arrow title="Move Left">
                      <ArrowBackIcon
                        style={{
                          marginLeft: "1.3rem",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMarginLeft(
                            (prevActiveStep) => prevActiveStep - 4
                          );
                        }}
                      />
                    </Tooltip>
                    <Tooltip arrow title="Move Downward">
                      <ArrowDownwardIcon
                        style={{
                          marginLeft: "1.6rem",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMarginTop((prevActiveStep) => prevActiveStep + 4);
                        }}
                      />
                    </Tooltip>
                    <Tooltip arrow title="Move Upward">
                      <ArrowUpwardIcon
                        style={{
                          marginLeft: "1.6rem",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMarginTop((prevActiveStep) => prevActiveStep - 4);
                        }}
                      />
                    </Tooltip>
                    <Tooltip arrow title="Move Right">
                      <ArrowForwardIcon
                        style={{
                          marginLeft: "1.6rem",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMarginRight(
                            (prevActiveStep) => prevActiveStep - 4
                          );
                        }}
                      />
                    </Tooltip>
                  </div> */}
                  <div style={{ marginTop: "1.4rem" }}>
                    <label>Alignment</label>
                    <Tooltip arrow title="Align Left">
                      <FormatAlignLeftIcon
                        style={{
                          marginLeft: "2.2rem",
                          width: "20px",
                          height: "20px",
                          color: `${
                            textAlignment === "left" ? "blue" : "black"
                          }`,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTextAlignment("left");
                          // console.log(textAlignment);
                        }}
                      />
                    </Tooltip>
                    <Tooltip arrow title="Align Center">
                      <FormatAlignCenterIcon
                        style={{
                          marginLeft: "1.6rem",
                          width: "20px",
                          height: "20px",
                          color: `${
                            textAlignment === "center" ? "blue" : "black"
                          }`,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTextAlignment("center");
                        }}
                      />
                    </Tooltip>
                    <Tooltip arrow title="Align Right">
                      <FormatAlignRightIcon
                        style={{
                          marginLeft: "1.6rem",
                          width: "20px",
                          height: "20px",
                          color: `${
                            textAlignment === "right" ? "blue" : "black"
                          }`,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTextAlignment("right");
                        }}
                      />
                    </Tooltip>
                  </div>

                  <div style={{ marginTop: "1.4rem" }}>
                    <label>Text Style</label>

                    <FormatBoldIcon
                      style={{
                        marginLeft: "2.4rem",
                        width: "20px",
                        height: "20px",
                        color: `${fontWeight ? "blue" : "black"}`,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFontWeight(!fontWeight);
                      }}
                    />

                    <FormatItalicIcon
                      style={{
                        marginLeft: "1.6rem",
                        width: "20px",
                        height: "20px",
                        color: `${fontStyle ? "blue" : "black"}`,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFontStyle(!fontStyle);
                      }}
                    />

                    <FormatUnderlinedIcon
                      style={{
                        marginLeft: "1.6rem",
                        width: "20px",
                        height: "20px",
                        color: `${textDecoration ? "blue" : "black"}`,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setTextDecoration(!textDecoration);
                      }}
                    />
                  </div>
                  <div className="d-flex mt-3">
                    <label className="mt-1"> Transition </label>
                    <Form.Select
                      onChange={(event) => handleChange(event.target.value)}
                      value={animationName}
                      style={{
                        width: "200px",
                        marginLeft: "30px",
                      }}
                    >
                      {animationArray.map((item, index) => {
                        return <option key={index}>{item}</option>;
                      })}
                    </Form.Select>

                    <label
                      style={{
                        marginLeft: "25px",
                        marginTop: "8px",
                      }}
                    >
                      Loop
                    </label>
                    <Checkbox
                      style={{
                        marginLeft: "0.8rem",
                      }}
                      onChange={onLoopChangeHandler}
                      checked={infiniteLoop}
                    ></Checkbox>
                  </div>
                  <div className="d-flex mt-3 ">
                    <label className="mt-2 ">Duration</label>
                    <Form.Select
                      onChange={(event) =>
                        fontDurationHandler(event.target.value)
                      }
                      style={{
                        width: "100px",
                        marginLeft: "37px",
                      }}
                      value={fontDuration}
                    >
                      {durationAndDelayArray.map((item, index) => {
                        return <option key={index}>{item}</option>;
                      })}
                    </Form.Select>

                    <label style={{ marginTop: "10px", marginLeft: "35px" }}>
                      Delay
                    </label>
                    <Form.Select
                      onChange={(event) => fontDelayHandler(event.target.value)}
                      value={fontDelay}
                      style={{
                        width: "100px",
                        marginLeft: "30px",
                      }}
                    >
                      {durationAndDelayArray.map((item, index) => {
                        return <option key={index}>{item}</option>;
                      })}
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="right_div">
            <Col span={12}>
            {/* <Card
                size='small'
                style={{
                  width:'300px',
                  marginBottom:'5rem',
                  marginTop: "1rem",
                  marginLeft: "1.6rem",
                  height:divHeight?`${divHeight}px`:'200px',
               
                }}
                className='transition-text-card'> */}
                {/* <div style={{ marginTop: "3rem" }}> */}
                <p
                  className={`animate__animated animate__${animationName} infinite`}
                  style={{
                    animationDelay: `${fontDelay}s`,
                    animationDuration: `${fontDuration}s`,
                    animationIterationCount: infiniteLoop ? "infinite" : "1",
                    animationFillMode: "both",
                    fontSize: `${fontSize}px`,
                    textAlign: `${textAlignment}`,
                    fontWeight: fontWeight ? "bold" : "400",
                    fontStyle: fontStyle ? "italic" : "normal",
                    color: `${fontColor}`,
                    fontFamily: `${fontFamily}`,
                    textDecoration: textDecoration ? "underline" : "",
                    marginTop: `${marginTop}px`,

                    marginLeft: `${marginLeft}px`,
                    marginRight: `${marginRight}px`,
                  }}
                >
                  {text}
                </p>

                {/* </div> */}
         {/* </Card> */}
            </Col>
          </div>
        </div>

        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            className="w-xs waves-effect waves-light me-1"
            onClick={saveHandler}
            disabled={loadingOnButton}
          >
              {loadingOnButton ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Save"
                                )}
           
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}

export default TitleAddModal;
