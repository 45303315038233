import React from 'react'

export default function IframeContent(props) {
  const frame = React.useRef()
  const percentage = String(.9)
  React.useEffect(()=> {
    frame.current.style.overflow = 'hidden';
    frame.current.style.position = 'relative';
    frame.current.style.width = '640px';
    frame.current.style.height = '360px';
    frame.current.style.border = '0';
    frame.current.style.TransformOrigin='0 0';
    frame.current.style.MsTransformOrigin='0 0';
    frame.current.style.webkitTransformOrigin='0 0';
    frame.current.style.MozTransformOrigin='0 0';
    frame.current.style.oTransformOrigin='0 0';
    frame.current.style.MsTransform='scale('+percentage+')';
    frame.current.style.MozTransform='scale('+percentage+')';
    frame.current.style.oTransform='scale('+percentage+')';
    frame.current.style.webkitTransform='scale('+percentage+')';
    frame.current.style.Transform='scale('+percentage+')';
  })

  // frame.onLoad = () => {
  //   try {
  //     frame.contentWindow.name
  // } catch (e) {
  //     if (e.message.includes('cross-origin')) console.warn(e.message);
  //     else console.error(e.message);
  // }
  // }
  // const clickHandler = () => {
  //   console.log(frame)
  // }
  return (
       <iframe ref = {frame} src={props.link} scrolling="no"></iframe>
  )
}

