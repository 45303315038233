import React, { useEffect, useState } from 'react';
import urlMaker from '../../helpers/urlMaker';
import AbaciIFrame from '../AbaciIFrame/AbaciIFrame';
import PageNotAvailable from '../PreviewModal/PageNotAvailable';
import moment from 'moment';
import './ContentForSchedule.css'
import ClockComponent from './ClockComponent';
import WeatherComponent from './WeatherComponent';
import YoutubeComponent from './YoutubeComponent';
import TextComponent from './TextComponent';

function Index({ currentPlayingItem, templateDetail,width,height,startPositionOfFirstFile}) {




    
  useEffect(() => {
   
  console.log(startPositionOfFirstFile)
  },[startPositionOfFirstFile]);

    useEffect(() => {
     
    }, [templateDetail])
    
    useEffect(() => {
    //  console.log(currentPlayingItem)
    }, [currentPlayingItem])
    

    return (
        <div>
        <div style={{ overflow: "hidden" }}>
          {(currentPlayingItem?.file_type === "image") ? (
            <img
              className={`content-img animate__animated animate__${currentPlayingItem.animation}`}
              style={{
                animationDuration: currentPlayingItem.animation_duration,
                animationFillMode: "both",
                width: width,
                height: height,
                marginTop: "0px",
                imageRendering:' -webkit-optimize-contrast',
                objectFit: currentPlayingItem.object_fit,
              }}
              src={urlMaker(currentPlayingItem?.file_url ? currentPlayingItem?.file_url  : currentPlayingItem?.file)}
              alt=''></img>
          ) : (currentPlayingItem?.file_type === "canvas") ?(
            <img
            className={`content-img animate__animated animate__${currentPlayingItem.animation}`}
            style={{
              animationDuration: currentPlayingItem.animation_duration,
              animationFillMode: "both",
              width: width,
              height: height,
              marginTop: "0px",
              objectFit: currentPlayingItem.object_fit,
              imageRendering:' -webkit-optimize-contrast'
            }}
            src={ currentPlayingItem?.file_url ? urlMaker(currentPlayingItem?.file_url,'canvas'): urlMaker(currentPlayingItem?.file,'canvas')}
            alt=''></img>
          
          
          
          
          )
          :currentPlayingItem?.file_type === "video" ? (
            <>
              <video
                style={{
                  animationDuration: currentPlayingItem.animation_duration,
                  animationFillMode: "both",
                  width: width,
                  height: height,
                  marginTop: "0px",
                  objectFit: currentPlayingItem.object_fit,
                }}
                className={`content-video animate__animated animate__${currentPlayingItem.animation}`}
                height='100%'
                autoPlay
                loop
                muted={currentPlayingItem?.muted}>
                  {startPositionOfFirstFile !==null ? 
                <source src={urlMaker(currentPlayingItem?.file_url ? currentPlayingItem?.file_url  : currentPlayingItem?.file)+`#t=${startPositionOfFirstFile/1000}`} type='video/mp4'></source>
                :
                <source src={urlMaker(currentPlayingItem?.file_url ? currentPlayingItem?.file_url  : currentPlayingItem?.file)} type='video/mp4'></source>
                  }
              </video>
            </>
          ) : currentPlayingItem?.file_type === "clock" ? (
            <> <div
            className={`clock_container animate__animated animate__${currentPlayingItem.animation}`}
            // className='clock_container'
            style={{
              animationDuration: currentPlayingItem.animation_duration,
              animationFillMode: "both",
              width: width,
              height: height,
           
              objectFit: currentPlayingItem.object_fit,
         
              backgroundColor: "white",
              marginTop: "1px",
            }}>
             <ClockComponent currentPlayingItem={currentPlayingItem} />
                                 </div>
          
            
            </>
          ) :  currentPlayingItem?.file_type === "weather" ? (
            <> <div
            className={`clock_container animate__animated animate__${currentPlayingItem.animation}`}
            // className='clock_container'
            style={{
              animationDuration: currentPlayingItem.animation_duration,
              animationFillMode: "both",
              width: width,
              height: height,
           
              objectFit: currentPlayingItem.object_fit,
         
              backgroundColor: "white",
              marginTop: "1px",
            }}>
             <WeatherComponent currentPlayingItem={currentPlayingItem} />
                                 </div>
          
            
            </>
          ) : currentPlayingItem?.file_type === "youtube" ? (
            <> <div
            className={`clock_container animate__animated animate__${currentPlayingItem.animation}`}
            // className='clock_container'
            style={{
              animationDuration: currentPlayingItem.animation_duration,
              animationFillMode: "both",
              width: width,
              height: height,
           
              objectFit: currentPlayingItem.object_fit,
         
              backgroundColor: "white",
              marginTop: "1px",
            }}>
             {startPositionOfFirstFile !==null ? 
             <YoutubeComponent currentPlayingItem={{...currentPlayingItem,durationRemaining:startPositionOfFirstFile/1000}} width={width} height={height}/>
             :
             <YoutubeComponent currentPlayingItem={{...currentPlayingItem,durationRemaining:0}} width={width} height={height}/>
            }

                                 </div>
          
            
            </>
          ) :
          currentPlayingItem?.file_type === "text" ? (
            <> <div
            className={`clock_container animate__animated animate__${currentPlayingItem.animation}`}
            // className='clock_container'
            style={{
              animationDuration: currentPlayingItem.animation_duration,
              animationFillMode: "both",
            
              width: width,
              height: height,
              objectFit: currentPlayingItem.object_fit,
              backgroundColor: "white",
              marginTop: "1px",
            }}>
             <TextComponent currentPlayingItem={currentPlayingItem} width={width} height={height}/>
                                 </div>
          
            
            </>
          ) :
          currentPlayingItem?.file_type === "link" ? (
            <div
              style={{
                animationDuration: currentPlayingItem.animation_duration,
                animationFillMode: "both",
                marginTop: "0px",
                overflow: "hidden",
                // objectFit:currentPlayingItem.objectFit
              }}
              className={`content-video animate__animated animate__${currentPlayingItem.animation}`}
              // height='100%'
            >
              {
                currentPlayingItem?.file.iFramable ? 
                <AbaciIFrame
                // divOriginalWidth={divOriginalWidth}
                width={width}
                height={height}
                url={currentPlayingItem?.file.link}
                // monitorWidth={width}
              />
              : 
              <PageNotAvailable
              width={width}
              height={height}
              />
              }
              
            </div>
          ) : null}
        </div>
      </div>
    )
}

export default Index;