import React, { useEffect } from "react";

import { Droppable, Draggable } from "react-beautiful-dnd";

import { Spinner } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";
import OnlineIcon from "../../../../../assets/images/Group 1507.png";
import OfflineIcon from "../../../../../assets/images/Group 1567.png";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Cast from "@mui/icons-material/Cast";
import Swal from "sweetalert2";

export default function NonDroppableScreenList(props) {
  return (
    <InfiniteScroll
      pageStart={0}
      // dataLength={totalCount}
      //  next={props.loadFunc}
      loadMore={props.loadFunc}
      hasMore={props.hasNextpage}
      useWindow={false}
      loader={
        <div className="text-center" key={0}>
          <Spinner animation="grow" />
        </div>
      }
    >
      <Copyable
        droppableId="NonDroppable"
        className="non-droppable"
        items={props?.items}
        castScreenModalToggle={props.castScreenModalToggle}
        deleteHandler={props.deleteHandler}
      />
    </InfiniteScroll>
  );
}
// This method is needed for rendering clones of draggables
const getRenderItem = (items, className) => (provided, snapshot, rubric) => {
  const item = items[rubric.source.index];
  return (
    <React.Fragment>
      <li
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={provided.draggableProps.style}
        className={
          snapshot.isDragging ? "media_card_small dragging" : "media_card_small"
        }
      >
        <div className="media_card_thumbnail-wrapper">
          <img
            src={item?.online_status ? OnlineIcon : OfflineIcon}
            alt="thumbnail"
            className="media_card_thumbnail small"
          />
        </div>

        <div className="media_card_file_name-small">
          {item?.name ? item?.name : "OLD Screens"}
        </div>
        {/* <div className='media_card_file_type'>
          <MeidaIcons type={item.file_type} />
        </div> */}
      </li>
    </React.Fragment>
  );
};

// const pickThumb = (item) => {
//   if (item.file_type === "link") {
//     return linkThumb;
//   } else if (
//     item.file_type === "image" ||
//     item.file_type === "video"
//   ) {
//     return urlMaker(item.thumbnail,'image');
//   }
//   else if(item.file_type === "canvas"){
//     return urlMaker(item.thumbnail,'canvas');
//   }
// };

function Copyable(props) {
  return (
    <>
      <Droppable
        renderClone={getRenderItem(props?.items, props?.className)}
        droppableId={props?.droppableId}
        isDropDisabled={true}
      >
        {(provided, snapshot) => (
          <ul ref={provided.innerRef} className={props?.className}>
            {props?.items?.map((item, index) => {
              const shouldRenderClone = null;
              // String(index) === snapshot.draggingFromThisWith;
              return (
                <React.Fragment key={String(index)}>
                  {shouldRenderClone ? (
                    <li
                      className="react-beatiful-dnd-copy"
                      style={{ borderRadius: "10px" }}
                    >
                      <div
                        className="media_card_thumbnail-wrapper"
                        key={index}
                        style={{ borderRadius: "10px" }}
                      >
                        <img
                          src={item?.online_status ? OnlineIcon : OfflineIcon}
                          alt="thumbnail"
                          className="media_card_thumbnail small"
                        />
                      </div>
                      <div className="media_card_file_name-small">
                        {item?.name ? item?.name : "OLD Screens"}
                      </div>
                      {/* <div className='media_card_file_type'>
                        <MeidaIcons type={item.file_type} />
                      </div> */}
                    </li>
                  ) : (
                    <Draggable draggableId={String(index)} index={index}>
                      {(provided, snapshot) => (
                        <React.Fragment>
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={
                              snapshot.isDragging
                                ? "media_card_small dragging"
                                : "media_card_small"
                            }
                            style={{ borderRadius: "10px" }}
                          >
                            <div
                              className="media_card_thumbnail-wrapper"
                              key={index}
                              style={{ borderRadius: "10px", zIndex: "1" }}
                            >
                              <img
                                src={
                                  item?.online_status ? OnlineIcon : OfflineIcon
                                }
                                alt="thumbnail"
                                className="media_card_thumbnail small"
                              />
                            </div>
                            <div className="media_card_file_name-small">
                              {item?.name ? item?.name : "OLD Screens"}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {item?.online_status && (
                                <Tooltip title="Set to Screen" arrow>
                                  <IconButton
                                    onClick={() =>
                                      props.castScreenModalToggle(item)
                                    }
                                  >
                                    <Cast />
                                  </IconButton>
                                </Tooltip>
                              )}

                              <Tooltip title="Delete" arrow>
                                <IconButton
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        props.deleteHandler(item?.id);
                                        // deleteHandler(item.id);
                                      }
                                    });
                                  }}
                                >
                                  {/* className="far fa-trash-alt"
                                    style={{ cursor: "pointer" }} */}

                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>

                            {/* <div className='media_card_file_type'>
                              <MeidaIcons type={item.file_type} />
                            </div> */}
                          </li>
                        </React.Fragment>
                      )}
                    </Draggable>
                  )}
                </React.Fragment>
              );
            })}
            {provided.placeholder}
            {/* </InfiniteScroll> */}
          </ul>
        )}
      </Droppable>
    </>
  );
}
