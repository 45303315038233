import "./TemplateCard.css";
import React, { useState } from "react";
// import VirtualTemplate from "../../../../components/VirtualTemplate/VirtualTemplate";
import {
  // TabContent,
  // TabPane,
  // Nav,
  // NavItem,
  // NavLink,
  Card,
  // Button,
  // CardTitle,
  // CardText,
  // Row,
  Col,
  // Container,
  // CardSubtitle,
  CardImg,
  CardBody,
} from "reactstrap";

import { useSelector } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
// import urlMaker from "../../../helpers/urlMaker";
// import { height } from "@mui/system";
import urlMaker from "../../../../helpers/urlMaker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Error from "../../../../helpers/Error";
import { Toastr } from "../../../../helpers/Alert";
export default function TemplateCard({ copyTemplateHandler, item }) {
  const [isHovered, setIsHovered] = useState(false);
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  return (
    <Col md={4} xl={2} style={{ minWidth: "200px" }}>
      <Card
        className="abaci-item-card"
        style={{ marginTop: "10px" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardBody>
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "300px",
                display: "flex",
                flexDirection: "row",
                height: "24px",
                textTransform: "capitalize",
              }}
            >
              <h4
                style={{
                  fontSize: "14px",
                  color: " #8D9091",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.template_name}
              </h4>
            </div>

            <div
              style={{
                fontWeight: "normal",
                fontSize: "13px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "300px",
                color: "#adadad",
              }}
            >
              {/* {"item?.object?.link"} */}
            </div>
          </div>
        </CardBody>
        <CardImg
          className="img-fluid"
          src={urlMaker(item?.thumbnail, "templates", true)}
          alt="Template"
          //   onClick={() => clickHandler(item.id)}
        />
        <CardBody style={{ marginTop: "-20px" }}>
          <div className=" d-flex justify-content-center  template-card-orientation">
            {"Screen orientation: " + item?.orientation}
          </div>

          <div
            className=" d-flex justify-content-center mt-1"
            style={{ color: "#67818f", fontSize: ".5rem" }}
          >
            {"Resolution :  " +
              item?.resolution_width +
              " x " +
              item?.resolution_height +
              " px"}
          </div>
          <div
            style={{
              width: "100%",
              height: "47px",
              display: "flex",
              justifyContent: "center",
              paddingTop: "5px",
            }}
          >
            {isHovered && (
              <Tooltip title="Copy">
                <IconButton onClick={() => copyTemplateHandler(item.id)}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
