import React, { useState } from "react";
// import { ReactComponent as LinkIcon } from "../../../assets/icons/link.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import Cast from "@mui/icons-material/Cast";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Swal from "sweetalert2";
import { IconButton, Tooltip } from "@material-ui/core";
// import { Link } from "react-router-dom";
import moment from "moment";
import StarringStar from "../../../components/StarringStar";
// import img4 from "../../../assets/images/small/img-4.jpg";
import {
  // TabContent,
  // TabPane,
  // Nav,
  // NavItem,
  // NavLink,
  Card,
  // Button,
  // CardTitle,
  // CardText,
  // Row,
  Col,
  // Container,
  // CardSubtitle,
  CardImg,
  CardBody,
} from "reactstrap";
import Error from "../../../helpers/Error";
import {
  editImage,
  editVideo,
  favouriteMedia,
} from "../../../store/mediaSlice";
import { authAxios } from "../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { Toastr } from "../../../helpers/Alert";
import urlMaker from "../../../helpers/urlMaker";
// import { height } from "@mui/system";
import { removeExtension } from "../../../helpers/Filename";

function Index({
  item,
  deleteHandler,
  ModalToggle,
  is_admin,
  castScreenModalToggle,
}) {
  const dispatch = useDispatch();

  console.log(is_admin);
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  const [mouseOnTitle, setMouseOnTitle] = useState(false);
  const [mouseOnEdit, setMouseOnEdit] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [name, setName] = useState("");

  const editHandler = () => {
    setEditEnabled(true);
  };

  const editNameHandler = (data) => {
    const id = data.id;
    const type = data.file_type;

    let url;
    if (tenantDetails?.app_type === "Admin") {
      url = `/stock/api/signage/filesrudview/${id}`;
    } else {
      url = `/api/signage/filesrudview/${id}`;
    }

    if (name !== "") {
      // const url = `/api/signage/filesrudview/${id}`;
      authAxios
        .patch(url, { name: name })
        .then((response) => {
          // Toastr("success", "File Updated Successfully");
          if (type === "image") {
            dispatch(editImage(response.data));
          } else {
            dispatch(editVideo(response.data));
          }
          setEditEnabled(false);
          setName("");
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
          setEditEnabled(false);
          setName("");
        });
    } else {
      setEditEnabled(false);
    }
  };

  const favouriteHandler = (id, type, fvrt) => {
    dispatch(
      favouriteMedia({ is_favourite: fvrt === true ? false : true, type, id })
    );

    const url = `/api/signage/filesrudview/${id}`;
    authAxios
      .patch(url, { is_favourite: fvrt === true ? false : true })
      .then((response) => {
        // if (type === "image") {
        //   dispatch(editImage(response.data));
        // } else {
        //   dispatch(editVideo(response.data));
        // }
        // Toastr("success", "File Updated Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);

        dispatch(favouriteMedia({ is_favourite: fvrt, id }));
        Toastr("error", err_msg);
      });
  };
  return (
    <Col md={4} xl={2} style={{ minWidth: "200px" }}>
      <Card
        className="abaci-item-card"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        // style={{transition:'height 0.3s'}}
      >
        <CardBody>
          {/* <CardTitle className="h4">Card title</CardTitle> */}
          <div>
            <div
              onMouseEnter={() => setMouseOnTitle(true)}
              onMouseLeave={() => setMouseOnTitle(false)}
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "300px",
                display: "flex",
                flexDirection: "row",
                height: "24px",
                textTransform: "capitalize",
              }}
            >
              {editEnabled ? (
                <input
                  autoFocus
                  placeholder={removeExtension(item?.name)}
                  style={{
                    fontSize: "14px",
                    borderWidth: "0px",
                    borderBottomWidth: "2px",
                    borderBottomColor: "#E5E4E2",
                    textTransform: "capitalize",
                  }}
                  onBlurCapture={() => editNameHandler(item)}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                ></input>
              ) : (
                <h4
                  style={{
                    fontSize: "14px",
                    color: " #8D9091",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={editHandler}
                >
                  {removeExtension(item?.name)}
                </h4>
              )}
              <div
                style={{ marginLeft: "5px", marginTop: "-3px" }}
                onMouseEnter={() => setMouseOnEdit(true)}
                onMouseLeave={() => setMouseOnEdit(false)}
              >
                {mouseOnTitle && !editEnabled ? (
                  <EditOutlined
                    fontSize={"10px"}
                    color={mouseOnEdit ? "action" : "disabled"}
                    onClick={editHandler}
                  />
                ) : null}
              </div>
            </div>
            {item?.file_type === "video" ? (
              <div
                style={{
                  fontSize: "10px",
                  color: " #8D9091",
                  textAlign: "right",
                  marginTop: "3px",
                }}
              >
                {moment
                  .utc(
                    moment.duration(item?.duration, "seconds").asMilliseconds()
                  )
                  .format("HH:mm:ss")}
              </div>
            ) : null}
          </div>
        </CardBody>

        <CardImg
          style={{ marginTop: "-15px" }}
          className="img-fluid "
          src={urlMaker(item.thumbnail, "image", is_admin, "frommedia")}
          alt="media File"
          onClick={() =>
            is_admin
              ? ModalToggle({ ...item, is_stock: true })
              : ModalToggle(item)
          }
        />
        <CardBody>
          <div
            style={{
              marginTop: "-20px",
              fontSize: "13px",
              color: " #8D9091",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {"Uploded Name : " + item?.uploaded_file_name}
          </div>
          <div className=" d-flex justify-content-between mb-3">
            <span style={{ fontSize: "13px", color: " #8D9091" }}>
              {"File Size : " + item?.file_size}
            </span>
          </div>
          <div
            style={{
              marginTop: "-20px",
              width: "100%",
              display: "flex",
              height: "47px",
              justifyContent: `${is_admin ? "center" : "space-between"}`,
            }}
          >
            {/* flag for changing the button based on the user type  */}
            {!is_admin ? (
              <>
                <div
                  onClick={() =>
                    favouriteHandler(item.id, item.file_type, item.is_favourite)
                  }
                >
                  {/* if the item is favourited by the user the star will be shown 
               or else upon user hovering the card */}
                  {item.is_favourite || isHovered ? (
                    <StarringStar selected={item.is_favourite} />
                  ) : null}
                </div>
                {/* Button will be shown upon user hovering the card */}
                {isHovered && (
                  <>
                    <Tooltip title="Set to Screen" arrow>
                      <IconButton onClick={() => castScreenModalToggle(item)}>
                        <Cast />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete" arrow>
                      <IconButton
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              // deleteClientData(rowData?.id)
                              deleteHandler(item.id, item.file_type);
                            }
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            ) : (
              // if user type is admin this set of buttons are shown
              <>
                {isHovered && (
                  <div className="d-flex justify-content-center">
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              // deleteClientData(rowData?.id)
                              deleteHandler(item.id, item.file_type);
                            }
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default Index;
