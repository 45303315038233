import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { useDispatch } from "react-redux";
import Modals from "../../../../components/Modal";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { authAxios } from "../../../../axiosInstance";
import { useEffect } from "react";
import {
  AddNewTrainingHubHeading,
  EditTrainingHubHeading,
} from "../../../../store/supportSlice";

function AddEditTrainingHubHeading(props) {
  const [waitingForAxios, setwaitingForAxios] = useState(false);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props?.isEdit) {
      const temp = { ...props.item };
      reset(temp);
    }
  }, []);

  const onSubmit = (data) => {
    let formData = new FormData(); //formdata object
    // formData.append("heading", data.heading);
    formData.append("main_heading", data.main_heading);
    formData.append("order", data.order);

    setwaitingForAxios(true);

    const url = `/abaciadmin_api/traininghubmainheading${
      props?.isEdit ? `/${props?.item?.id}` : ""
    }`;

    authAxios[!props?.isEdit ? "post" : "patch"](url, formData)
      .then((response) => {
        setwaitingForAxios(false);
        if (props?.isEdit) {
          dispatch(EditTrainingHubHeading(response.data));
          Toastr("success", "Heading Edited succesfully");
        } else {
          dispatch(AddNewTrainingHubHeading(response.data));
          Toastr("success", "Heading Added succesfully");
        }
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };

  return (
    <Modals isOpen={props.isOpen} toggle={props.toggle} title={props.title}>
      <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row style={{ marginBottom: "10px" }}>
          <Label for="Title" sm={3}>
            Title
          </Label>
          <Col sm={9}>
            <input
              className="form-control"
              rows="5"
              cols="33"
              placeholder="Heading"
              {...register("main_heading", {
                required: true,
              })}
            />
            {errors.main_heading?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row style={{ marginBottom: "10px" }}>
          <Label for="Order No" sm={3}>
            Order No
          </Label>
          <Col sm={9}>
            <input
              placeholder="Order No"
              type="number"
              min="0"
              onKeyDown={(evt) =>
                evt.key === "-" || evt.key === "e" || evt.key === "."
                  ? evt.preventDefault()
                  : null
              }
              onWheel={(e) => e.target.blur()}
              className="form-control"
              {...register("order", {
                required: true,
              })}
            />
            {errors.order?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>

        <ModalFooter>
          <Button
            type="submit"
            disabled={waitingForAxios}
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modals>
  );
}
export default AddEditTrainingHubHeading;
