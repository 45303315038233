import React, { useState } from 'react'
import { Button, Card, CardBody, CardTitle } from 'reactstrap'
import defaultImage from '../../../../../assets/images/default.png';
import moment from 'moment';
function LeftScreenDetails({data}) {

    const [styles] =useState({
        data_style:{fontWeight:900,color:'black'}
    })
  return (
    <Card style={{maxHeight:'600px'}}>
    <CardBody>
    <CardTitle >
        <div className='d-flex justify-content-between'>
            Screen Details 
            <div className={data.screen_group !== null ? `badge badge-soft-success font-size-12`
             : data?.orchestration_group ? `badge badge-soft-info font-size-12`: null}
             
             style={{
                display: `${data.screen_group === null || data.orchestration_group === null ? "none" : ""}`,
              }}
             >
                   {data.screen_group !== null ? `Added To ${data?.screen_group?.name}` : data.orchestration_group !== null ? `Added To ${data?.orchestration_group?.name}` : null}
            </div>
           
        </div>
    </CardTitle>
    <div className="border-top py-3"/>

    <div style={{position:'relative',left:'5%'}}>
        <table cellSpacing="0" cellPadding="6"  style={{width:'100%'}} >
            <tbody>
                <tr className='text-muted'>
                    <td >Screen ID</td>
                    <td>Device Name</td>
                </tr>
                <tr style={styles.data_style}>
                    <td >{data?.screen_id}</td>
                    <td>{data?.device_name}</td>
                </tr>
                <tr className='text-muted'>
                    <td>Added By</td>
                    <td>Screen added on</td>
                </tr>
                <tr style={styles.data_style}>
                    <td>
                        <div className='d-flex' style={{gap:'10px'}}>
                        <img src={data?.created_by?.avatar ? data?.created_by?.avatar : defaultImage} alt='' style={{height:'40px',width:'40px'}}/>
                        <p className='mt-2'>{data?.created_by}</p>
                        </div>
                    </td>
                    <td>{moment(data?.registration_time).format('MMM DD,YYYY,h:mm:ss a')}</td>
                </tr>
                <tr className='text-muted'>
                    <td>Model Name</td>
                    <td>Manufacturer</td>
                </tr>
                <tr style={styles.data_style}>
                    <td >{data?.model_name}</td>
                    <td>{data?.manufacturer}</td>
                </tr>
                <tr className='text-muted'>
                    <td >OS Name</td>
                    <td>OS Version</td>
                </tr>
                <tr style={styles.data_style}>
                    <td >{data?.os_name}</td>
                    <td>{data?.os_version}</td>
                </tr>
                <tr className='text-muted'>
                    <td>Total Memory</td>
                    <td>Timezone</td>
                </tr>
                <tr style={styles.data_style}>
                    <td>{data?.totalMemory}</td>
                    <td>{data.timezone}</td>
                </tr>

            </tbody>
            </table>
        </div>

        <div className='d-flex justify-content-center mt-5' style={{gap:"10px"}}>
            <Button color="warning" type="button" className="waves-effect waves-light me-1" >
                <i className="ri-delete-bin-7-fill align-middle me-1"></i> Reset Cache
            </Button>
            <Button color="warning" type="button" className="waves-effect waves-light me-1" >
                <i className="ri-restart-line align-middle me-1"></i> Restart
            </Button>
        </div>
    </CardBody>
</Card>
  )
}

export default LeftScreenDetails