import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  deleteWeather } from "../../../.././store/weatherSlice";
import WeatherCard from "./WeatherCard";
import "../Apps.css";
import { useNavigate } from "react-router-dom";
import { setWeather } from "../../../.././store/weatherSlice";
import {  Container, Col, Spinner } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { CustomButton } from "../../../../components/CustomButton";
import { authAxios } from "../../../../axiosInstance";
import { Toastr } from "../../../../helpers/Alert";
import InfiniteScroll from "react-infinite-scroller";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";
import Error from "../../../../helpers/Error";

export default function Weather() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);
  const [searchKeyCount,setSearchKeyCount] = useState(0)
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);

  const [dashboardState, ] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "Weather Instance", link: "#" },
    ],
  });
  const weatherList = useSelector((state) => state.weatherSlice.user_data);
  const [hasNextpage, setHasNextPage] = useState(false);
  const [pageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  
      useEffect(() => {
      
        setPage(1);
        setHasNextPage(false);
        dispatch(setWeather(null));

        if(searchKey !== prevSearchKey){
          setPrevSearchKey(searchKey)
        }

        setSearchKeyCount(searchKey !==null ? searchKey.length : 0)
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchKey])
 
  useEffect(() => {
    const url = `/api/signage/objectlistcreate?object_type=weather&limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}`;

    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);
        if (weatherList === null) {
          dispatch(setWeather(response.data.results));
        } else {
          dispatch(setWeather([...weatherList, ...response.data.results]));
        }

        setTotalCount(response.data.count);

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        Toastr("error", "Oops!,Something Went Wrong");
      });
          // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,prevSearchKey]);

  const loadFunc = () => {
    if (hasNextpage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
  };
  useEffect(() => {
    return () => {
      dispatch(setWeather(null));
    };
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteHandler = (id) => {
    const url = `api/signage/objectrud/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        dispatch(deleteWeather(id));
        Toastr("success", "Weather Instance Deleted Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Weather Instance"
          breadcrumbItems={dashboardState.breadcrumbItems}
        ></Breadcrumbs>
        <Col xl={24}>
          <div style={{ minHeight: "calc(100vh - 300px" }}>
            {weatherList?.length === 0 ? (
              <div className="weblinkEmptyImage text-center">
                <img
                  src={EmptyList}
                  alt=""
                  style={{ width: "450px", height: "450px" }}
                />
                <h6 style={{}}>No Instance Found !</h6>
              </div>
            ) : (
              <>
                {weatherList === null || loader ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                    }}
                  >
                    <Spinner animation="grow" />
                  </div>
                ) : (
                  <InfiniteScroll
                    pageStart={0}
                    // dataLength={totalCount}
                    // next={loadFunc}
                    loadMore={loadFunc}
                    hasMore={hasNextpage}
                    loader={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="loader"
                        key={0}
                      >
                        <Spinner animation="grow" />
                      </div>
                    }
                  >
                    {weatherList?.map((item, index) => (
                      <WeatherCard
                        key={index}
                        item={item}
                        deleteHandler={deleteHandler}
                      />
                    ))}
                  </InfiniteScroll>
                )}
              </>
            )}
          </div>
        </Col>

        <div>
          <CustomButton
            title={"+"}
            onClick={() => {
              navigate("/createweather");
            }}
          />
        </div>
      </Container>
    </div>

    // <div>
    //   {/* <WeatherModal isModal={isWeatherModal} setIsModal={setIsWeatherModal} toggle={toggle} /> */}
    //   <div className="top-right-corner-button-wrap">
    //     <AbaciButton
    //       width="165px"
    //       onClick={() => {
    //         const data = {
    //           id: 104,
    //           name: "new clock data",
    //           Created_by: "just4danish@gmail.com",
    //           Created_on: " Oct 16th 21",
    //           data: null,
    //         };
    //         navigate("/createweather");
    //         dispatch(addWeather(data));
    //         dispatch(toStoreEditValue(data));
    //       }}
    //     >
    //       Add New Instance
    //     </AbaciButton>
    //   </div>

    // </div>
  );
}
