import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  templates: null,
  stockTemplates: null,
};
// const initialState = [];
const templateSlice = createSlice({
  name: "templateSlice",
  initialState,
  reducers: {
    setTemplates(state, action) {
      state.templates = action.payload;
    },
    addMoreTemplate(state, action) {
      state.templates = [...state.templates, ...action.payload];
    },
    setStockTemplates(state, action) {
      state.stockTemplates = action.payload;
    },
    addTemplate: (state, action) => {
      state.templates = [...state.templates, action.payload];
    },
    updateTemplate: (state, action) => {
      state.templates = state.templates.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteTemplate(state, action) {
      console.log(action.payload);
      console.log(state);
      const indexToBeDeleted = action.payload;

      const tempState = [...state.user_data];
      tempState.splice(indexToBeDeleted, 1);
      state.templates = tempState;
    },

    favouriteTemplate(state, action) {
      state.templates = state.templates.map((data) =>
        data.id === action.payload.id
          ? { ...data, is_favourite: action.payload.is_favourite }
          : data
      );
    },
    deleteTemplateNew(state, action) {
      console.log(state.templates);
      console.log(action.payload);
      state.templates = state.templates.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  favouriteTemplate,
  setTemplates,
  updateTemplate,
  deleteTemplate,
  deleteTemplateNew,
  addTemplate,
  addMoreTemplate,
} = templateSlice.actions;
export default templateSlice.reducer;
