export default class CSSMaker {
    constructor(cssObj, virtualizationFactor) {
      this.cssObj = cssObj;
      this.virtualizationFactor = virtualizationFactor;
    }
    convertToVirtual = () => ({
      position : this.cssObj.position,
      marginTop: this.cssObj.marginTop * this.virtualizationFactor,
      marginLeft: this.cssObj.marginLeft * this.virtualizationFactor,
      height: this.cssObj.height * this.virtualizationFactor,
      width: this.cssObj.width * this.virtualizationFactor,
      backgroundColor: this.cssObj.backgroundColor,
      border: this.cssObj.border === 0 ? "1px solid" : String(this.cssObj.border) + "px solid",
      borderRadius: this.cssObj.borderRadius,
      borderColor: this.cssObj.border === 0 ? "light grey" : this.cssObj.borderColor,
      zIndex: this.cssObj.zIndex,
    });
  }