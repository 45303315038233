import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PercentageCalculator,
  byteToGb,
} from "../../../../../helpers/byteToGb";
import Swal from "sweetalert2";
import Error from "../../../../../helpers/Error";
import { Toastr } from "../../../../../helpers/Alert";
import { Progress } from "reactstrap";
import { IconButton, Tooltip } from "@material-ui/core";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import DescriptionIcon from "@mui/icons-material/Description";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { authAxios } from "../../../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import Noimage from "../../../../../assets/images/profile.png";
import { UpdateCustomerDetails } from "../../../../../store/customersStore";
import HttpsIcon from "@mui/icons-material/Https";
import ChangePassword from "../../../../../components/CommonForBoth/TopbarDropdown/ChangePassword";

function LeftDetailComponent() {
  const navigate = useNavigate();

  const customer = useSelector((state) => state.customerSlice.customer);
  const dispatch = useDispatch();
  const [resetPassword, setResetPassword] = useState(false);
  const [tenantId, setTenantId] = useState(null);

  const customerStatusHandler = (status) => {
    const url = `/abaciadmin_api/update_client_status/${customer.client_data?.id}`;
    authAxios
      .put(url, { status: status })
      .then((response) => {
        dispatch(UpdateCustomerDetails(response.data));
        if (status === "Active") {
          Toastr("success", `Customer Activated Sucessfully`);
        } else if (status === "Deleted") {
          Toastr("success", `Customer Deleted Sucessfully`);
          navigate("/customer");
        } else {
          Toastr("success", `Customer Blocked Sucessfully`);
        }
      })
      .catch((error) => {
        let err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const resetPasswordToggle = (id) => {
    setResetPassword(!resetPassword);
    setTenantId(id);
  };

  return (
    <>
      {resetPassword && (
        <ChangePassword
          isOpen={resetPassword}
          toggle={resetPasswordToggle}
          title="Reset Password"
          size="md"
          tenant={tenantId}
        />
      )}
      <div className="left_div_container " style={{ marginTop: "65px" }}>
        <img src={Noimage} className="profile_img" alt="alt" />

        <div>
          <h5 className="text-center mt-3">
            {customer.client_data?.name
              ? customer.client_data?.name
              : "No Data"}
          </h5>
          <h6 className="text-center mt-3">
            (
            {customer.client_data?.app_type
              ? customer.client_data.app_type
              : "No Data"}
            )
          </h6>
          <div className="d-flex justify-content-center">
            <p style={{ fontWeight: "900" }}>{"Status : "}</p>
            <p
              style={{
                fontWeight: "900",
                marginTop: "0px",
                marginLeft: "2px",
                color: `${
                  customer.client_data?.status === "Active" ? "green" : "red"
                }`,
              }}
            >
              {customer.client_data?.status}
            </p>
          </div>

          <div className="d-flex flex-column text-center mt-4">
            <div className="d-flex justify-content-center">
              <Progress
                value={PercentageCalculator(
                  customer?.storage?.used_storage,
                  customer?.storage?.total_storage
                )}
              />
            </div>
            <h6 className="mt-2">
              {byteToGb(customer?.storage?.used_storage) +
                " of  " +
                byteToGb(customer?.storage?.total_storage) +
                " Used"}
              {/* 15.00 GB of 20.00 GB used */}
            </h6>
          </div>

          <div className="text-center mt-4">
            <Tooltip arrow title="Assets">
              <IconButton
                onClick={() =>
                  navigate(`/customerassets/${customer.client_data?.id}`, {
                    state: customer.client_data?.schema_name,
                  })
                }
              >
                <PermMediaIcon />
              </IconButton>
            </Tooltip>
            {!customer.client_data?.is_social_user && (
              <Tooltip arrow title="Reset Password">
                <IconButton
                  onClick={() => resetPasswordToggle(customer.client_data?.id)}
                >
                  <HttpsIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip arrow title="Invoices">
              <IconButton
                onClick={() =>
                  navigate(`/customerinvoices`, {
                    state: customer.client_data?.schema_name,
                  })
                }
              >
                <DescriptionIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              arrow
              title={
                customer.client_data?.status === "Active" ? "Block" : "Unblock"
              }
            >
              <IconButton
                onClick={() =>
                  customerStatusHandler(
                    customer.client_data?.status === "Active"
                      ? "Blocked"
                      : "Active"
                  )
                }
              >
                {customer.client_data?.status === "Active" ? (
                  <BlockIcon />
                ) : (
                  <LockOpenIcon />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Delete">
              <IconButton
                onClick={() =>
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      customerStatusHandler("Deleted");
                    }
                  })
                }
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeftDetailComponent;
