import React from "react";
import { Button } from "reactstrap";

export const CustomButton = (props) => {
  return (
    <div>
      <Button
        style={{
          position: "fixed",
          bottom: "73px",
          right: "24px",
          zIndex: "1",
          width: "55px",
          height: "55px",
          fontSize: "30px",
          paddingTop: "4px",
        }}
        onClick={props.onClick}
        color="primary"
        size="lg"
        className="waves-effect waves-light me-1 btn-rounded"
      >
        {props.title}
      </Button>
    </div>
  );
};
