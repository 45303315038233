import React, { useState } from "react";

// import PreviewModal from '../../../components/PreviewModal/PreviewModal'
// import { useDispatch, useSelector } from "react-redux";

// import { useNavigate } from "react-router-dom";
// // import axios from "axios";
// import { Toastr } from "../../../../../helpers/Alert";
import {
  Button,
  // DropdownItem, DropdownMenu, DropdownToggle,
  // Spinner,
  //  UncontrolledDropdown 
} from "reactstrap";
// import moment from "moment";
// // import EditIcon from "@mui/icons-material/Edit";
// import EditOutlined from "@mui/icons-material/EditOutlined";
// import Error from "../../../../../helpers/Error";
// import { authAxios } from "../../../../../axiosInstance";
// import PreviewModal from "../../../components/NewPreviewModal/NewPreviewModal";

export default function TopComponent({data}) {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  
    const [styles]=useState({

        maindiv:{ display: 'flex', justifyContent: 'space-between' },
        name_div:{
            fontWeight: "900",
            fontSize: "20px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "300px",
            display: "flex",
            flexDirection: "row",
            height: "28px",
            marginBottom: '5px',
            textTransform: 'capitalize'
          },
        button_style:{  marginRight: '5px', 
                        width: '98px', 
                        height: '41px',
                        borderRadius: '8px', 
                        fontSize: '14px', 
                        fontWeight: '900',
                        marginTop:'12px' },
        sub_text1:{ fontSize: '12px' },
        sub_text2:{ fontSize: '12px', fontWeight: "900" ,marginTop:'-12px'}

    })

  // const [mouseOnTitle, setMouseOnTitle] = useState(false);
  // const [name, setName] = useState('')
  // const [mouseOnEdit, setMouseOnEdit] = useState(false);
  // const [editEnabled, setEditEnabled] = useState(false);
  // const [deleteConfirmVisibile, setDeleteConfirmVisibile] = useState(false);
  // const [streamingStatus, setStreamingStatus] = useState(false);
  // const [confirmLoading, setConfirmLoading] = useState(false);
  // const [isPreviewVisible, setIsPreviewVisible] = useState(false)
  // // const [virtualHieghtFactor, setVirtualHieghtFactor] = useState(0)
  // const [previewData, setPreviewData] = useState(null)


//   const previewHandler = () => {
//     // Here we need to convert the schedule to same as per the schedule which the backend returns
//     if (props?.scheduleDetails !== null) {
//       const tempScheduleDivclone_templatecloneNew = props?.scheduleDetails?.template_clone.divclone_templateclone?.map(item => {
//         let tempscheduleprops_saved_divclone = null
//         droppableBagItems.forEach(innerItem => {
//           if (Number(innerItem.id) === item.id) {
//             tempscheduleprops_saved_divclone = innerItem[item.id]
//           }
//         })
//         return { ...item, scheduleprops_saved_divclone: tempscheduleprops_saved_divclone }
//       })


//       const tempdivclone_templateclone = { ...props?.scheduleDetails?.template_clone, divclone_templateclone: tempScheduleDivclone_templatecloneNew }
//       const tempScheduleDetails = { ...props?.scheduleDetails, template_clone: tempdivclone_templateclone }
//       console.log("droppableBagItems", tempScheduleDetails)
//       setPreviewData(tempScheduleDetails)
//       setIsPreviewVisible(true)
//     }
//   }

  return (
    <React.Fragment>
    <div style={styles.maindiv}>

      <div style={{ marginTop: "2px" }}>
        <div
          // onMouseEnter={() => setMouseOnTitle(true)}
          // onMouseLeave={() => setMouseOnTitle(false)}
          style={styles.name_div}
        >
          {/* {editEnabled ? (
            <input
              autoFocus
              placeholder={data?.name}
              style={{
                borderWidth: "0px",
                borderBottomWidth: "2px",
              }}
              onChange={(event) => setName(event.target.value)}
              onBlurCapture={() => blurHandler()}
            ></input>
          ) : ( */}
            <div >
              {data?.name}
            </div>
          {/* )} */}
          {/* <div
            style={{ marginLeft: "5px", marginTop: "-3px" }}
            onMouseEnter={() => setMouseOnEdit(true)}
            onMouseLeave={() => setMouseOnEdit(false)}
          >
            {mouseOnTitle && !editEnabled ? (
              <EditOutlined
                fontSize={"6px"}
                color={mouseOnEdit ? "action" : "disabled"}
                onClick={editHandler}
              />
            ) : null}
          </div> */}

          <div className={`${data?.online_status ? `badge badge-soft-success font-size-12`
             :`badge badge-soft-danger font-size-12`} mt-1 ml-3`} style={{height:'20px',marginLeft:'10px'}}>{data?.online_status ? 'Online' : 'Offline'}
            </div>
        </div>

        <div className="">
            <p style={styles.sub_text1} className="text-muted">Now Playing </p>
            <h3 style={styles.sub_text2} >{data?.now_playing?.content?.name ? data?.now_playing?.content?.name : 'No File Playing'}</h3>
        </div>
      </div>

      <div>
        <Button
          outline
          type="button"
          size="sm"
          color="dark"
          className="w-xs waves-effect waves-light me-1"

          style={styles.button_style}
        //   onClick={}

        >
          Preview
        </Button>
       


      </div>



      {/* {isPreviewVisible ? <PreviewModal
        isPreviewVisible={isPreviewVisible}
        setIsPreviewVisible={setIsPreviewVisible}
        previewData={previewData}
        previewType="schedule"
     
      /> : null} */}
    </div>
    </React.Fragment>
  );
}
