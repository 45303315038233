import React, { useState, useEffect } from "react";
import { ReactComponent as Close } from "../../../../assets/icons/close.svg";
import AbaciButton from "../../../../components/AbaciButton/AbaciButton";
import AbaciHorizontalLine from "../../../../components/AbaciHorizontalLine/AbaciHorizontalLine";
import YouTube from "react-youtube";
import Styles from "./youTubeCreater.module.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Error from "../../../../helpers/Error";
import {  Toastr } from "../../../../helpers/Alert";
import { Spinner } from "reactstrap";
import { authAxios } from "../../../../axiosInstance";
import { Toggle } from "rsuite";
import { setSearchkeyWord } from "../../../../store/searchSlice";
var getYouTubeID = require("get-youtube-id");

export default function CreateYouTubeInstance(props) {
  const [isMuted, setIsMuted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const  youtube  = useLocation();

  const [loadingOnButton, setLoadingOnButton] = useState(false);

  const handleClose = () => {



    if (youtube?.state?.isApps) {
      navigate("/apps");
    } else {
      navigate("/youtube/");
    }
  };

  useEffect(() => {
    // console.log(youtube)
    dispatch(setSearchkeyWord(null))


    if (youtube?.state?.id) {
      const url = `api/signage/objectrud/${youtube?.state?.id}`;
      authAxios
        .get(url)
        .then((response) => {
          // Toastr("success", "Link Updated Successfully");
          // console.log(response.data);
          setName(response.data.name);
          setIsMuted(response.data.object.isMuted)
          setYoutubeUrl(response.data.object.link);
          setId(getYouTubeID(response.data.object.link));
        })
        .catch((error) => {});
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const opts = {
    height: "400",
    width: "700",
    playerVars: {
      mute: `${isMuted ? 1 : 0}`,
      autoplay: 1,
    },
  };

  const submitHandler = () => {


    if ( youtubeUrl !== "" && id !== null) {

      setLoadingOnButton(true);

      const data = {
        // name: name,
        file_type: "youtube",
        object: {
          title: name,
          link: youtubeUrl,
          isMuted: isMuted,
        },
      };

      if(youtube?.state?.id){
        const url = `/api/signage/objectrud/${youtube.state.id}`
        authAxios
          .patch(url, data)
          .then((response) => {
            // dispatch(addYouTube(response.data))
            setLoadingOnButton(false);
            Toastr('success',"Youtube Instance Edited Succesfully");
          })
          .catch((error) => {
            setLoadingOnButton(false);
            const err_msg = Error(error);
            Toastr("error", err_msg);
          });

      }
      else{
      const url = "api/signage/objectlistcreate";
      authAxios
        .post(url, data)
        .then((response) => {
          // dispatch(addYouTube(response.data))
          setLoadingOnButton(false);
          Toastr('success',"Youtube Instace Created Succesfully");
        })
        .catch((error) => {
          setLoadingOnButton(false);
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
      }
    } else {
      if(id === null){
        Toastr("info", "Please Use an Valid Youtube Content Link !!");
      }
      else{
        Toastr("info", "Please Fill Out the Name & Link Fields");
      }
    }
  };

  // const handleSave = () => {
  //   // const data = { item: youtubeEditData, data: weatherList };

  //   // dispatch(setNewYouTube(data));
  //   navigate('/youtube/')
  // };

  const changeHandler = (url) => {
    // setYoutubeUrl(url);
    const temp = getYouTubeID(url);
    setId(temp);
  };

  // const checkFunction=(event)=>{
  //   if(isMuted){
  //     event.mute()
  //   }
  //   else{
  //     event.unMute()
  //   }
  // }

  return (
    <div className={Styles.preview_backdrop}>
      <div className={Styles.close_icon_container}>
        <Close
          className={Styles.close_icon}
          onClick={() => {
            handleClose();
          }}
        />
      </div>
      <div>
        <div
          className={Styles.monitor}
          style={{
            height: 400 + 20 + "px",
            width: 700 + 10 + "px",
          }}
        >
          <div
            className={Styles.clock_container}
            style={{
              height: "400px",
              width: "700px",
              // backgroundColor: "white",
              marginTop: "1%",
            }}
          >
            <div className="mt-4">
              <YouTube videoId={id} opts={opts} />;
            </div>
          </div>

          <div className={Styles.tvscreen_logo}>
            ABACI
            <div className={Styles.icon_led_green}></div>
          </div>
        </div>
      </div>
      <div className={Styles.template_settings_holder_div}>
        <div className={Styles.clock_settings}>
          <div className={Styles.template_settings_header}>
            <p style={{ fontSize: "20px" }}>Instance Properties</p>
          </div>
          <AbaciHorizontalLine />
          <table
            style={{
              margin: "0px",
              marginTop: "10px",
              color: "white",
              width: "200px",
            }}
          >
            <tbody>
              {/* <tr>
                <th>
                  <div className={Styles.table_head}>Name</div>
                </th>
                <td>
                  <div>
                    <input
                      placeholder="Name"
                      className={Styles.input_conainer_name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  </div>
                </td>
              </tr> */}
              <tr>
                <th>
                  <div className={Styles.table_head}>YouTube URL</div>
                </th>
                <td>
                  <div>
                    <input
                      placeholder="Please Enter the Url"
                      className={Styles.input_conainer_name}
                      onChange={(e) => setYoutubeUrl(e.target.value)}
                      onBlur={(e) => {
                        changeHandler(e.target.value);
                      }}
                      value={youtubeUrl}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div className={Styles.table_head}>Mute videos</div>
                </th>
                <td>
                  <div style={{ paddingTop: "14px" }}>
                    <Toggle checked={isMuted} onChange={setIsMuted} />
                    {/* <Switch defaultChecked /> */}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={Styles.container_add_button}>
          <AbaciButton
            onClick={() => {
              submitHandler();
            }}
          >
            {loadingOnButton ? <Spinner animation="grow" size="sm" /> : "Save"}
          </AbaciButton>
        </div>
      </div>
    </div>
  );
}
