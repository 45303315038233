import React, { useEffect, useState } from 'react'
import moment from 'moment';
import './ContentForSchedule.css'

function ClockComponent({currentPlayingItem}) {

    var [time,setTime] = useState(new Date());


    useEffect(() => {
      
    var timer = setInterval(()=>setTime(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
            }
    }, [])
    

  return (
    <React.Fragment>
    {currentPlayingItem?.object?.type === 'Analogue' ?
    <div className='clock'>
             <div
               className='hour_hand'
               style={{
                 transform: `rotateZ(${time.getHours() * 30}deg)`
               }}
             />
             <div
               className='min_hand'
               style={{
                 transform: `rotateZ(${time.getMinutes() * 6}deg)`
               }}
             />
             <div
               className='sec_hand'
               style={{
                 transform: `rotateZ(${time.getSeconds() * 6}deg)`
               }}
             />
             <span className='twelve'>12</span>
             <span className='one'>1</span>
             <span className='two'>2</span>
             <span className='three'>3</span>
             <span className='four'>4</span>
             <span className='five'>5</span>
             <span className='six'>6</span>
             <span className='seven'>7</span>
             <span className='eight'>8</span>
             <span className='nine'>9</span>
             <span className='ten'>10</span>
             <span className='eleven'>11</span>
           </div>
      
              : 
              <div id='digital_clock'>
              {currentPlayingItem?.object?.displayDate &&
<p className='date}'>{moment(time).format('MMM DD,YYYY - dd')}</p>}
{ currentPlayingItem?.object?.displaySeconds ? 
<p className='time'>{currentPlayingItem?.object?.isTwelveHours ? moment(time).format('h:mm:ss a') :  moment(time).format('HH:mm:ss a')}</p>
:
<p className='time'>{currentPlayingItem?.object?.isTwelveHours ? moment(time).format('h:mm a'):  moment(time).format('HH:mm a')}</p>

}
</div>
              
              }
              </React.Fragment>
  )
}

export default ClockComponent