import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSearchEnabled: false,
  searchKeyWord: null,
  currentPage: null,
};

const searchSlice = createSlice({
  name: "searchSlice",
  initialState,
  reducers: {

    setSearchDisabled(state, action) {
        state.isSearchEnabled = action.payload;   
    },
    setSearchkeyWord(state, action) {
        console.log(action.payload);
        state.searchKeyWord = action.payload;   
    },
    setCurrentPage(state, action) {
        state.currentPage = action.payload;   
    },
  },
});

export const {  setSearchDisabled, setCurrentPage, setSearchkeyWord  } = searchSlice.actions;
export default searchSlice.reducer;
