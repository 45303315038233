import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Clock from "./icons/clockSmall.svg";
import Weather from "./icons/weatherSmall.svg";
import Youtube from "./icons/youtubeSmall.svg";
import Text from "./icons/textSmall.svg";

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CarouselImage from "./Carousel";
import { useNavigate } from "react-router-dom";

function AppViewModal(props) {
  const navigate = useNavigate();
  const temp = [
    {
      img: Clock,
      title: "Clock",
      subTitle: "Office Tools",
      nav_Link: "/clock",
      shortContents: [
        "Choose from 2 clock styles",
        "Select how you want to display your clock, in 12 or 24-hour format (Digital Clock)",

        " Select To Display if u want to show Date & also Seconds(Digital Clock)",
      ],
      mainContant:
        " A simple and clean digital clock that displays current time,date and day of the week. You can choose from dark and light themes and select your clock style.",
    },
    {
      img: Weather,
      nav_Link: "/weather",
      title: "Weather",
      subTitle: "Internal Communications",
      shortContents: [
        " Share up to three cities",
        "Add different instances for different locations",
      ],
      mainContant:
        " The Weather app from Abaci Signage allows you to display accurate weather forecasts for up to three cities in one screen display. Choose your location(s), select from fahrenheit or celsius",
    },
    {
      img: Youtube,
      nav_Link: "/youtube",
      title: "Youtube",
      subTitle: "Internal Communications, Photos & Video",
      shortContents: ["Add videos by YouTube URL", "Mute videos"],
      mainContant:
        "Share videos from YouTube to your digital signage screens. With the YouTube app from Abaci Signage it's never been easier to add videos to your Playlists easily",
    },
    {
      img: Text,
      title: "Text",
      nav_Link: "/texts",
      subTitle: "Office Tools",
      shortContents: [],
      mainContant: " ",
    },
  ];

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        centered={props.NotCentered ? false : true}
        scrollable
        size="xl"
        toggle={props.toggle}
        innerRef={props.ref}
        unmountOnClose={true}
        zIndex={"1002"}
     
      >
        {/* {props.loader ? (
          <div style={{ zIndex: "9999" }}>
            {" "}
            <Spinner />{" "}
          </div>
        ) : null} */}

        <ModalBody>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="d-flex flex-row mt-2">
              <img
                src={temp[props?.app]?.img}
                alt=""
                style={{
                  width: "100px",
                  height: "80px",
                }}
              ></img>
              <div className="mt-2">
                <h3>{temp[props.app]?.title}</h3>
                <p style={{ fontSize: "18px", marginTop: "-5px" }}>
                  {temp[props.app]?.subTitle}
                </p>
              </div>
            </div>
            <div>
              <IconButton
                style={{ marginLeft: "60px" }}
                onClick={() => props.toggle()}
              >
                <CloseIcon />
              </IconButton>
              <div className="mt-3">
                {/* <Button>App</Button> */}
                <Button
                  style={{
                    color: "black",
                    backgroundColor: "#5664D2",
                    borderColor: "#5664D2",
                    borderRadius: "10px",
                  }}
                  onClick={() => navigate(temp[props.app]?.nav_Link)}
                >
                  Go to App
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <CarouselImage type={props.app} />
          </div>

          <div style={{ marginTop: "15px", marginLeft: "30px" }}>
            <p style={{ fontSize: "17px" }}>{temp[props.app]?.mainContant}</p>

            <ul>
              {temp[props.app]?.shortContents.map((data, index) => (
                <li style={{ fontSize: "16px" }} key={index}>
                  {"- " + data}
                </li>
              ))}
            </ul>
          </div>
        </ModalBody>
      </Modal>
      {/* <Modals
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              src={Clock}
              alt=""
              style={{ width: "150px", height: "100px", borderRadius: "10px" }}
            ></img>
            <div>
              <Button>App</Button>
              <Button>App</Button>
            </div>
          </div>
        }
        isOpen={props.isModalVisible}
        toggle={() => props.setIsModalVisible(false)}
        padding={0}
        size="xl"
      >
        <div style={{ backgroundColor: "gray", height: "600px" }}></div>
      </Modals> */}
    </>
  );
}

export default AppViewModal;
