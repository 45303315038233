import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ServiceCard from "../services/service-card";
import { SERVICE_DATA } from "../../../data/index";
const ServicesHome = () => {
  const { sectionTitle, posts } = SERVICE_DATA;
  return (
    <section id="features" className="service-one" style={{paddingTop:'100px',paddingBottom:'50px',marginTop:'50px',marginBottom:'100px'}}>
      <Container>
        <div className="block-title text-center">
          <h3 style={{ color:'var(--thm-primary)',fontFamily:'lato',fontSize:'52px'}}>{sectionTitle.text}</h3>
          <p style={{ color:'black',fontFamily:'lato',fontSize:'15px',marginTop:'20px'}}>{sectionTitle.title}</p>
        </div>
        <Row style={{justifyContent: 'center'}}>
          {posts.map(({ extraClassName, icon, title, text }, index) => (
            <Col xl={3} lg={3} md={6} key={`service-card-key-${index}`}>
              <ServiceCard
                extraClassName={extraClassName}
                icon={icon}
                title={title}
                text={text}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ServicesHome;
