import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ScheduleAddModal.css";
import AbaciButton from "../../../components/AbaciButton/AbaciButton";
import AbaciHorizontalLine from "../../../components/AbaciHorizontalLine/AbaciHorizontalLine";
import {
  
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Form,
  CardBody,
  Button,
  Spinner,
} from "reactstrap";
import AbaciLoader from "../../../components/AbaciLoader/AbaciLoader";
// import { message} from "antd";
// import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modals from "../../../components/Modal";
import { setTemplates } from "../../../store/templateSlice";
import { authAxios } from "../../../axiosInstance";
import { Toastr } from "../../../helpers/Alert";
import { useNavigate } from "react-router-dom";
import { addSchedule } from "../../../store/scheduleSlice";
import Error from "../../../helpers/Error";
import VirtualTemplateNew from "../../../components/VirtualTemplateNew/VirtualTemplateNew";

export default function ScheduleAddModal(props) {
  // const templatesCustom = useSelector(state => state.templateSlice.user_data)
  // const templatesSystem = useSelector(state => state.templateSlice.stock_data)
  // const [scheduleIsValid, setScheduleIsValid] = useState(false);
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const [loader,setLoader] = useState(true)
  const [loadingOnButton, setLoadingOnButton] = useState(false);
  const [scheduleName, setScheduleName] = useState("");
  const [seletedTemplate, setSeletedTemplate] = useState(null);
  const [templates,setTemplates] = useState([])
  // const history = useHistory();

  // useEffect(() => {
  //   return () => {
  //     setLoadingOnButton(null)
  //     setSeletedTemplate(null)
  //     setScheduleName(null)
  //   }
  // }, [templatesCustom])
  // useEffect(() => {
  //   const urlTemplates = "api/signage/templates";
  //   axios.get(urlTemplates).then((response) => {
  //     setTemplates(response.data);
  //   });
  // }, []);

  useEffect(() => {
    const url = "api/signage/templateslistcreate?limit=150";
    authAxios.get(url).then((response) => {
      // dispatch(setTemplates(response.data.results));
      setTemplates(response.data.results)
      setLoader(false)
    })
    .catch((error) => {
      setLoader(false)
    })
  }, [])
  
 
  // useEffect(()=>{
  //   if (scheduleName !== "" && seletedTemplate !==null){
  //     setScheduleIsValid(true)
  //   }
  // },[scheduleName, seletedTemplate])
  // const handleCancel = () => {
  //   props.setIsModalVisible(false);
  // };

  const inputHandlerOnChange = (event) => {
    setScheduleName(event.target.value);
  };

  const submitHandler = () => {
    setLoadingOnButton(true)
    const data = {
      template_clone: seletedTemplate.id,
      schedule_name: scheduleName,
    };
    const url = "api/signage/scheduleslistcreateview";
    authAxios.post(url, data).then((response) => {
    setLoadingOnButton(false)


    Toastr('success','Tempalate Created Successfully')
    navigate(`/scheduler/${response.data.id}`)
    dispatch(addSchedule(response.data))
   
    })
    .catch(error=>{
      setLoadingOnButton(false)
  
      const err_msg=Error(error)
      Toastr('error',err_msg)
        });;
  };
  return (
   
     <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} loader={loader} size={'xl'}>
      <div className='parent-schedule-add-modal'>
        <div className='div1-schedule-add-modal'>
          <p>Please provide the schedule name</p>
          <input
            className={"form-control form-control-sm"}
            type='text'
            style={{ width: "240px" }}
            value={scheduleName}
            onChange={inputHandlerOnChange}
            placeholder='Schedule Name'
          />
          
          <br />
          {seletedTemplate === null ? <p>* Please select a template</p>:
          <div><p>Selected Template</p><VirtualTemplateNew card = {seletedTemplate}/></div>}
        </div>
         <div className='div2-schedule-add-modal'>
          {/* {templates === null ? (
            <AbaciLoader/>
          ) : ( */}
            <>
              <div className='card-flex-container-schedules'>
               {templates.map((card, index) => (
                <VirtualTemplateNew key={index} card = {card} 
                onClick={() => {
                  setSeletedTemplate(card);
                }}
                />
                
                ))}
              </div>
            </>
          {/* )} */}
        </div>
      </div>
      <ModalFooter>
      <Button
                // type="submit"
                color="primary"
                className="w-xs waves-effect waves-light me-1"
                onClick={submitHandler}
                disabled={scheduleName !== "" && seletedTemplate !== null ? false : true ||  loadingOnButton}
              >
                   {loadingOnButton ? (
                                    <Spinner animation="grow" 
                                    size="sm" 
                                    />
                                ) : (
                                    "Save"
                                )}
                
              </Button>
              <Button
                type="button"
                color="danger"
                className="w-xs waves-effect waves-light me-1"
                onClick={props.toggle}
              >
                Close
              </Button>
        </ModalFooter>

        {/* <AbaciHorizontalLine />
        <div className='flex-footer-container-modal'>
          <div className='button-container-modal'>
            <AbaciButton
              width='100px'
              height='40px'
              textcolor='white'
              color='#FF8585'
              waveColor='#F5B9B9'
              onClick={handleCancel}>
              Cancel
            </AbaciButton>
          </div>
          <div className='button-container-modal'>
            <AbaciButton
              width='100px'
              height='40px'
              textcolor='white'
              color='#3CA1B1'
              waveColor='#3BCBE2'
              disabled={!scheduleIsValid}
              loading = {loadingOnButton}
              onClick = {submitHandler}
            >
              Save
            </AbaciButton>
          </div>
      </div> */}
    </Modals>
  );
}