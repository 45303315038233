import React from 'react'
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import Moments from '../../../../../helpers/Moment';

function ClientPaymentAndPlanComponent() {
    const customer = useSelector((state) => state.customerSlice.customer);

  return (
    <div
    className="payment-trail-container"
    style={{ width: "100%", gap: "20px" }}
  >
    <Card
      className="payment-card"
      style={{ backgroundColor: "#F1F5F7" }}
    >
      <CardBody style={{ padding: "10px" }}>
        <p style={{ fontWeight: "900" }}>Payment Details</p>

        <div style={{ width: "400px" }}>
          <table
            cellSpacing="0"
            cellPadding="6"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr style={{ verticalAlign: "top" }}>
                <td>Payment Status</td>
                <td style={{ width: "5%" }}> : </td>
                <td>
                  {" "}
                  {customer.client_data?.payment_status
                    ? customer.client_data?.payment_status
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Last Payment</td>
                <td> : </td>

                <td style={{ width: "60%" }}>
                  {" "}
                  {customer.client_data?.last_payment
                    ? Moments(
                        customer.client_data?.last_payment
                      )
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Paid Upto</td>
                <td> : </td>
                <td>
                  {" "}
                  {customer.client_data?.paid_upto
                    ? Moments(customer.client_data?.paid_upto)
                    : "No Data"}
                </td>
              </tr>
              <tr>
                <td>Next Payment</td>
                <td> : </td>
                <td>
                  {" "}
                  {customer.client_data?.next_payment
                    ? Moments(
                        customer.client_data?.next_payment
                      )
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Base Price</td>
                <td> : </td>
                <td>
                  {" "}
                  {customer.client_data?.base_price
                    ? customer.client_data?.base_price
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Discount</td>
                <td> : </td>
                <td>
                  {customer.client_data?.discount
                    ? customer.client_data?.discount
                    : "No Data"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
    <Card
      className="trail-card"
      style={{ backgroundColor: "#F1F5F7" }}
    >
      <CardBody style={{ padding: "10px" }}>
        <p style={{ fontWeight: "900" }}>Plan Details</p>

        <div style={{ width: "500px" }}>
          <table
            cellSpacing="0"
            cellPadding="6"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr style={{ verticalAlign: "top" }}>
                <td>On Trial</td>
                <td style={{ width: "5%" }}> : </td>
                <td>
                  {" "}
                  {customer.client_data?.on_trial
                    ? "Yes"
                    : "No"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Number Of Trial Days</td>
                <td> : </td>
                <td style={{ width: "60%" }}>
                  {customer.client_data?.number_of_trial_days
                    ? customer.client_data?.number_of_trial_days
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Number Of Trial Licenses</td>
                <td> : </td>
                <td>
                  {" "}
                  {customer.client_data
                    ?.number_of_trial_licenses
                    ? customer.client_data
                        ?.number_of_trial_licenses
                    : "No Data"}
                </td>
              </tr>
              <tr>
                <td>Acceptance To TC</td>
                <td> : </td>
                <td>
                  {" "}
                  {customer.client_data?.acceptance_to_updates
                    ? "Yes"
                    : "No"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Acceptance To Updates</td>
                <td> : </td>
                <td>
                  {" "}
                  {customer.client_data?.acceptance_to_updates
                    ? "Yes"
                    : "No"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>App Type</td>
                <td> : </td>
                <td>
                  {customer.client_data?.app_type
                    ? customer.client_data?.app_type
                    : "No Data"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  </div>
  )
}

export default ClientPaymentAndPlanComponent