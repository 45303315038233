import React from "react";
// import {Link} from "gatsby";

const PriceCard = ({
  count,
  amount,
  name,
  extraClassName,
  options,
  button
}) => {
  return (
    <div className="pricing-one__single">
      <div className={`circle circle-${extraClassName}`}style={{bottom:'-10px',right:'0px'}}>
        <div className={`count count-${extraClassName}`}>
          <h4>{count}</h4>
        </div>
      </div>
      <div className="price">
        <h2 style={{fontSize:'35px'}}>{amount}</h2>
        <p>{name}</p>
      </div>
      <ul className="list-unstyled pricing-one__list" style={{minHeight:'358px'}}>
        {options.map(({ label }, index) => (
          <li key={`options-key-${index}`}style={{fontSize:'14px',fontFamily:'Lato',    lineHeight: "36px",color:'#8D8D8D',fontWeight:'500'}}>
            <i className="fa fa-check icon-color-2"></i> {label}
          </li>
        ))}
      </ul>
      {/* <Link to={button.link}> */}
        <button  className="abaci-button " style={{width:'220px',height:'60px',marginBottom:'15px'}}>
        <span>{button.label}</span>
        </button>
          
      {/* </Link> */}
    </div>
  );
};

export default PriceCard;
