import React, { useState } from "react";
import { Card } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Swal from "sweetalert2";
import { IconButton, Tooltip } from "@material-ui/core";
// import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import StarringStar from "../../../../components/StarringStar";
import EditIcon from "@mui/icons-material/Edit";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { authAxios } from "../../../../axiosInstance";
import youtubePic from "../icons/youtube.svg";
import { editYoutube } from "../../../../store/youTubeSlice";
import { useNavigate } from "react-router-dom";
import { updateApps } from "../../../../store/appSlice";
import urlMaker from "../../../../helpers/urlMaker";
function Index({ item, deleteHandler, app }) {
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  const [mouseOnTitle, setMouseOnTitle] = useState(false);
  const [mouseOnEdit, setMouseOnEdit] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const editHandler = () => {
    setEditEnabled(true);
  };

  const editNameHandler = (data, event) => {
    if (name !== "") {
      const linkData = {
        name: name,
        object: { ...data.object, title: name },
      };
      const url = `/api/signage/objectrud/${data.id}`;
      authAxios
        .patch(url, linkData)
        .then((response) => {
          // Toastr("success", "Link Updated Successfully");
          if (app) {
            dispatch(updateApps(response.data));
          } else {
            dispatch(editYoutube(response.data));
          }
          setEditEnabled(false);
          setName("");
        })
        .catch((error) => {
          const err_msg = Error(error);
          setEditEnabled(false);
          setName("");
          Toastr("error", err_msg);
        });
    } else {
      setEditEnabled(false);
    }
  };
  const makeFavourite = (data) => {
    const fev = data.is_favourite;
    const id = data.id;
    const url = `/api/signage/objectrud/${id}`;
    authAxios
      .patch(url, { is_favourite: fev === true ? false : true })
      .then((response) => {
        // Toastr("success", "Link Updated Successfully");

        if (app) {
          dispatch(updateApps(response.data));
        } else {
          dispatch(editYoutube(response.data));
        }
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <div>
      <Card
        className="abaci-app-instance-card"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="abaci-app-instance-card-content1">
          <div className="abaci-app-instance-image">
            <img
              className="abaci-app-instance-images"
              alt="media"
              src={item?.thumbnail ? urlMaker(item?.thumbnail,'youtube') : youtubePic}
            />
          </div>
          <div style={{ marginTop: "6px" }}>
            <div
              onMouseEnter={() => setMouseOnTitle(true)}
              onMouseLeave={() => setMouseOnTitle(false)}
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "300px",
                display: "flex",
                flexDirection: "row",
                height: "24px",
                textTransform: "capitalize",
              }}
            >
              {editEnabled ? (
                <input
                  autoFocus
                  placeholder={item?.name}
                  style={{
                    borderWidth: "0px",
                    borderBottomWidth: "2px",
                    borderBottomColor: "#E5E4E2",
                    textTransform: "capitalize",
                  }}
                  onChange={(event) => setName(event.target.value)}
                  onBlurCapture={() => editNameHandler(item)}
                ></input>
              ) : (
                <h4
                  style={{
                    fontSize: "14px",
                    color: " #8D9091",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={editHandler}
                >
                  {item?.name}
                </h4>
              )}
              <div
                style={{ marginLeft: "5px", marginTop: "-3px" }}
                onMouseEnter={() => setMouseOnEdit(true)}
                onMouseLeave={() => setMouseOnEdit(false)}
              >
                {mouseOnTitle && !editEnabled ? (
                  <EditOutlined
                    fontSize={"6px"}
                    color={mouseOnEdit ? "action" : "disabled"}
                    onClick={editHandler}
                    style={{ zIndex: "9999" }}
                  />
                ) : null}
              </div>
            </div>

            <div
              style={{
                fontWeight: "normal",
                fontSize: "13px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "300px",
                color: "#adadad",
              }}
            >
              {item?.object?.link}
            </div>
          </div>
        </div>

        <div
          className="abaci-app-instance-card-content2"
          style={{ marginTop: "6px" }}
        >
          <div
            style={{
              fontWeight: "normal",
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              color: "#adadad",
            }}
          >
            {"Added by: " + item?.created_by}
          </div>
          <div
            style={{
              fontWeight: "normal",
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              color: "#adadad",
            }}
          >
            {"Added on: " + moment(item?.created_date).format("MMM Do YY")}
            {/* {"Added on: " + moment(item?.Created_on).format("MMM Do YY")} */}
          </div>
        </div>

        <div
          className="abaci-app-instance-card-action"
          style={{ width: "145px" }}
        >
          <span onClick={() => makeFavourite(item)}>
            {item.is_favourite || isHovered ? (
              <StarringStar selected={item?.is_favourite} />
            ) : null}
          </span>
          {isHovered && (
            <>
              <Tooltip title="Edit" arrow>
                <IconButton
                  onClick={() => {
                    navigate("/createyoutube", {
                      state: { id: item.id, isApps: app ? app : false },
                    });
                  }}
                >
                  {/* className="far fa-trash-alt"
                                    style={{ cursor: "pointer" }} */}

                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" arrow>
                <IconButton
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        // deleteClientData(rowData?.id)
                        deleteHandler(item.id);
                      }
                    });
                  }}
                >
                  {/* className="far fa-trash-alt"
                                    style={{ cursor: "pointer" }} */}

                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
        {/* </Col> */}

        {/* </Col> */}
        {/* </Row> */}
      </Card>
    </div>
  );
}

export default Index;
