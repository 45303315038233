import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import logo from "../../../assets/images/signage logo dark.png";
import NavLinks from "./nav-links";
import { useNavigate } from "react-router-dom";
import SupportNavLinks from "./SupportNavLinks";
const SupportHeader = () => {
  const navigate = useNavigate();
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    mobileMenu();
    return () => {
      mobileMenu();
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const mobileMenu = () => {
    document.querySelectorAll(".side-menu__toggler").forEach((btn) => {
      btn.addEventListener("click", function (e) {
        document.querySelector(".side-menu__block").classList.toggle("active");
        e.preventDefault();
      });
    });

    //Close Mobile Menu
    let sideMenuCloser = document.querySelectorAll(
      ".side-menu__close-btn, .side-menu__block-overlay"
    );

    sideMenuCloser.forEach((sideMenuCloserBtn) => {
      sideMenuCloserBtn.addEventListener("click", function (e) {
        document.querySelector(".side-menu__block").classList.remove("active");
        e.preventDefault();
      });
    });
  };
  return (
    <>
      <header className={`site-header-one stricky site-header-one__fixed-top `}>
        <Container
          fluid
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="site-header-one__logo">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={logo} width="140" alt="" />
              {/* 
              <span
                style={{
                  fontFamily: "aquatico",
                  fontSize: "11px",
                  marginTop: "-20px",
                  // lineHeight: "10px",
                }}>
                ABACI SIGNAGE
              </span> */}
            </div>

            <span className="side-menu__toggler">
              <i className="fa fa-bars"></i>
            </span>
          </div>
          <div
            className="main-nav__main-navigation"
            style={{ marginRight: "20px" }}
          >
            <SupportNavLinks />
          </div>
        </Container>
      </header>

      {/* stricky menu cloned */}
      <header
        className={`site-header-one stricked-menu  site-header-one__fixed-top ${
          sticky === true ? "stricky-fixed" : null
        }  `}
      >
        <Container
          fluid
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="site-header-one__logo">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={logo} width="140" alt="" />

              {/* <span
                style={{
                  fontFamily: "aquatico",
                  fontSize: "10px",
                  lineHeight: "10px",
                  fontWeight:'900'
                }}>
                ABACISIGNAGE
              </span> */}
            </div>
            <span className="side-menu__toggler">
              <i className="fa fa-bars"></i>
            </span>
          </div>
          <div
            className="main-nav__main-navigation"
            style={{ marginRight: "20px" }}
          >
            <SupportNavLinks />
          </div>
        </Container>
      </header>
    </>
  );
};

export default SupportHeader;
