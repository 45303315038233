import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { addAssetDetails } from "../../../store/assetDetails";
// import Medias from "./Medias";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";
import ScreenCard from '../ScreenCards/ScreenCard'
import { CardBody, Spinner } from "reactstrap";

import {Row} from "reactstrap";
import {
  deleteImage,
  deleteVideo,
} from "../../../../store/mediaSlice";
import InfiniteScroll from "react-infinite-scroller";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { authAxios } from "../../../../axiosInstance";

function ScreenTab({loadFunc,hasNextpage,windowHeight}) {

    const dispatch=useDispatch();
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);
  const screens=useSelector((state) => state.screenSlice.screens)
  // Function to Delete the Media File ie Images,Videos passed as
  // an prop to media card and called upon clicking the icon button
  const deleteHandler = (id, type) => {
    let url;
    if (tenantDetails?.app_type === "Admin") {
      url = `/stock/api/signage/filesrudview/${id}`;
    } else {
      url = `/api/signage/filesrudview/${id}`;
    }
    // const url = `/api/signage/filesrudview/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        if (type === "image") {
          dispatch(deleteImage(id));
          Toastr("success", "Image Deleted Successfully");
        } else {
          dispatch(deleteVideo(id));
          Toastr("success", "Video Deleted Successfully");
        }
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

 



  return (
    <>
      <React.Fragment>
   
        {screens === null || screens === undefined ? (
        <>
            <div
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
            }}
            >
            <Spinner animation="grow" />
            </div>
        </>
        ) : screens?.length === 0 ? (
        <>
            <div className=" text-center">
            <img
                src={EmptyList}
                alt=""
                style={{
                width: "450px",
                height: "450px",
                }}
            />
            <h6 style={{}}>No Screens Found !</h6>
            </div>
        </>
        ) : (
        <>
            <InfiniteScroll
            pageStart={0}
            // dataLength={totalCount}
            // next={loadFunc}
            loadMore={loadFunc}
            hasMore={hasNextpage}
            loader={
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
                className="loader"
                key={0}
                >
                <Spinner animation="grow" />
                </div>
            }
            >
            <Row
                style={{
                display: "flex",
                justifyContent: "flex-start",
                maxHeight: Math.round(
                    windowHeight - 313
                ),
                overflowY: "scroll",
                overflowX: "hidden",
                }}
            >
                {screens?.length !==0 && screens?.map(
                (items, index) => (
                    <ScreenCard
                    key={index}
                    item={items}
                    isScreenGroup={false}

                    />
                )
                )}
            </Row>
            </InfiniteScroll>
        </>
        )}
                      
       
      </React.Fragment>
    </>
  );
}

export default ScreenTab;
