
import moment from 'moment'

function Moments($date, $type = "") {
    if($type === "datetime"){
        return moment($date).format('MM/DD/YYYY hh:mm A')
    }else{
        return moment($date).format('MM/DD/YYYY')
    }
}

export default Moments