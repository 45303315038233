import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  user_images: null,
  user_videos: null,
  stock_images: null,
  stock_videos: null,
};
// const initialState = [];
const mediaSlice = createSlice({
  name: "mediaSlice",
  initialState,
  reducers: {
    setMedias(state, action) {
      state.user_images = action.payload.user_images;
      state.user_videos = action.payload.user_videos;
      state.stock_images = action.payload.stock_images;
      state.stock_videos = action.payload.stock_videos;
    },
    setImages(state,action) {
      state.user_images= action.payload
    },
    setVideos(state,action) {
      state.user_videos= action.payload
    },
    deleteImage(state,action){
      state.user_images=state.user_images.filter((data) => data.id !== action.payload)
    },
    deleteVideo(state,action){
      state.user_videos=state.user_videos.filter((data) => data.id !== action.payload)
    },
    addNewImage(state,action){
      state.user_images=[...state.user_images,action.payload]
    },
    addNewVideo(state,action){
      state.user_videos=[...state.user_videos,action.payload]

    },

    addMediaTag(state,action){
      if(action.payload.type==='image'){
       state.user_images=state.user_images.map((data)=>
       
       data.id===action.payload.file? {...data,tag_file:[...data.tag_file,action.payload]}:data
       
       )

      }
      else{
      
        state.user_videos=state.user_videos.map((data)=>
       
       data.id===action.payload.file? {...data,tag_file:[...data.tag_file,action.payload]}:data
       
       )
       console.log(state.user_videos)
      }
    

    },
  

    editImage(state,action){
      state.user_images=state.user_images.map((data) => 
        data.id === action.payload.id ? action.payload : data
      )
    },
    editVideo(state,action){
      state.user_videos=state.user_videos.map((data) => 
        data.id === action.payload.id ? action.payload : data
      )
    },
    addMedia: (state, action) => {
      if (action.payload.file_type === "image") {
        state.user_images.push(action.payload.file);
      }
      state.user_videos.push(action.payload.file);
    },
    updateMedia: (state, action) => {
      const temp =state.user_videos.map((item) =>
      item.id === action.payload.file.id ? action.payload.file : item
    );
    state.user_videos=temp;

      
      // state.user_videos = [...state.user_videos];
      // const indexToBeUpdate = action.payload.index;
      // const tempState = [...state.user_videos];
      // tempState.splice(indexToBeUpdate, 1,action.payload.file);
      // state.user_videos = tempState;
    },

 
    favouriteMedia(state,action){

      console.log(action.payload)
      if(action.payload.type==="image"){
        state.user_images=state.user_images.map((data)=>
        data.id===action.payload.id? {...data,is_favourite:action.payload.is_favourite}:data
        )
      }
      else{
        state.user_videos=state.user_videos.map((data)=>
        data.id===action.payload.id? {...data,is_favourite:action.payload.is_favourite}:data
        )
      }

    },

    deleteMedia(state, action) {
      console.log(action.payload);
      if (action.payload.docType === "1") {
        state.user_images = [...state.user_images];
        const indexToBeDeleted = action.payload.index;
        const tempState = [...state.user_images];
        tempState.splice(indexToBeDeleted, 1);
        state.user_images = tempState;
      }
      state.user_videos = [...state.user_videos];
      const indexToBeDeleted = action.payload.index;
      const tempState = [...state.user_videos];
      tempState.splice(indexToBeDeleted, 1);
      state.user_videos = tempState;
    },
  },
});

export const { setMedias, updateMedia, deleteMedia, addMedia,setImages,setVideos,
  deleteImage,deleteVideo,addNewImage,addNewVideo,editVideo,editImage,addMediaTag,favouriteMedia } =
  mediaSlice.actions;
export default mediaSlice.reducer;
