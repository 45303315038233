import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//Import images
// import errorImg from "../../assets/images/error-img.png";

export default function Unauthorized() {
  const profile = useSelector((state) => state.authSlice.tenantDetails);
  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center my-5">
                <h1 className="fw-bold text-error">Unauthorized</h1>
                <h3 className="text-uppercase">Sorry, page not found</h3>
                <div className="mt-5 text-center">
                  {profile.app_type !== "Admin" ? (
                    <Link
                      to="/screens"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Back to Dashboard
                    </Link>
                  ) : (
                    <Link
                      to="/dashboard"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Back to Dashboard
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
