import { createSlice } from "@reduxjs/toolkit";


function fetchOnlineOfflineCount(item){
  const temp=item?.map((data) => ({...data,
    online_count:data.screen_screengroup.filter((data) => data.online_status).length,
    offline_count:data.screen_screengroup.filter((data) => !data.online_status).length
    }))

    return temp
}

const initialState = {
  screens :null,
  selectedItem : null,
  screenGroup:null,
  screenGroupScreens:null,

};

const screenSlice = createSlice({
  name: "screenSlice",
  initialState,
  reducers: {
    setScreens(state, action) {
      state.screens = action.payload;
    },
    addScreen(state, action) {
      state.screens = [...state.screens, action.payload];
    },
    deleteScreen(state, action) {
     
      state.screens=state.screens.filter((data) => data.id !== action.payload)

    },
    setScreenGroupScreens(state,action) {
      state.screenGroupScreens = action.payload;

    },
    setSelectedContentForScreen(state, action) {
      state.selectedItem = action.payload;
    },


    setScreenGroup(state, action) {

      

      state.screenGroup = fetchOnlineOfflineCount(action.payload);
    },
    addScreenGroup(state, action) {
      state.screenGroup = [...state.screenGroup,action.payload];
    },
    deleteScreenGroup(state, action) {
      // state.screens = [...state.screens];
     state.screenGroup=state.screenGroup.filter((data) => data.id !== action.payload)
    },
    updateScreenGroup(state, action) {

      console.log(action)
      // state.screens = [...state.screens];
     state.screenGroup=state.screenGroup.map((data) => data.id === action.payload.id ?action.payload : data)
    },
    screenListUpdater(state, action) {
      // state.screens = [...state.screens];
      let idsToRemove=action.payload

      const updatedItems = state.screens.filter(item => !idsToRemove.includes(item.id));
   
      state.screens=updatedItems
    },

    screenStatusUpdater(state,action){

      state.screens=state.screens.map((data) => data.screen_id === action.payload.screen_id ? {...data,online_status:action.payload.online_status} : data)
    },
    
    screenGroupScreenStatusUpdater(state,action){

      state.screenGroupScreens=state.screenGroupScreens.map((data) => data.screen_id === action.payload.screen_id ? {...data,online_status:action.payload.online_status} : data)
    }
  },
});

export const { screenListUpdater,setScreens, addScreen,deleteScreen ,
                updateScreenGroup,deleteScreenGroup,addScreenGroup,
                setScreenGroup,setScreenGroupScreens, screenStatusUpdater,
                screenGroupScreenStatusUpdater
              } = screenSlice.actions;
export default screenSlice.reducer;
