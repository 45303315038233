import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { setDroppableBagItems } from "../../../store/bagItems";
import { useSelector, useDispatch } from "react-redux";
import "./Duration.css";

export default function Duration(props) {

  const tt=props?.initialDuration %(60 * 60)

  const [seconds, setSeconds] = useState(
    Number(props.initialDuration % 60).toFixed(0)
  );
  const [minutes, setMinutes] = useState(props?.initialDuration >= 60  ?
    Math.floor(tt/60) : 0
  );
  const [hours, setHours] = useState(
    Math.floor(props?.initialDuration / (60 * 60))
  );
  const [duration, setDuration] = useState(
    Number(props.initialDuration).toFixed(0)
  );
  const droppableBagItems = useSelector(
    (state) => state.bagItems.droppableBagItems
  );
  const dispatch = useDispatch();

  // useEffect(() => {
    
  //   setDuration(Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds));

  //   // console.log(duration)
  // }, [seconds, minutes, hours]);

  useEffect(() => {
    // console.log('prps',props)
  }, [props])
  

  useEffect(() => {

   

    if(props.file_type !== "video"){
    const durationChangedItem = { ...props.item, scheduled_duration: duration };
    // Now we need to change the item in array of the props.items
    const durationChangedItemsArray = [...props.items[props.items.id]];
    durationChangedItemsArray.splice(props.index, 1, durationChangedItem);
    // Now we need to make a copy of props.items with the new values
    const durationChangedItems = {
      ...props.items,
      [props.items.id]: durationChangedItemsArray,
    };
    // Now we need to recreate the droppable bag item with new values
    const tempDroppableBagItems = [...droppableBagItems];
    tempDroppableBagItems.splice(props.divIndex, 1, durationChangedItems);
    dispatch(
      setDroppableBagItems({ droppableBagItems: tempDroppableBagItems })
    );
    }
  }, [duration]);

  const onChange = async (value, type) => {
    // console.log('wrked')

    if(type === "sec"){
      setSeconds(value);
      setDuration(Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(value));
    }
    else if(type=== 'min'){
      setMinutes(value);
      setDuration(Number(hours) * 60 * 60 + Number(value) * 60 + Number(seconds));


    }
    else{
      setHours(value);
      setDuration(Number(value) * 60 * 60 + Number(minutes) * 60 + Number(seconds));


    }
    //   case "sec":
    //     setSeconds(value);
    //     break;
    //   case "min":
    //     setMinutes(value);
    //     break;
    //   case "hour":
    //     setHours(value);
    //     break;
    // }
  };
  return (
    <div className='flex-duration'>


      <Input className='flex-duration:nth-child(2)' type="number" step="1"
        min={0}
        max={23}
        value={hours}
        onChange={(e) => onChange(e.target.value, "hour")}
        // bordered={false}
        style={{ hieght: "20px", width: "50px", fontSize: "12px", fontFamily: "monospace" }}
        // size="small"
        disabled={props.file_type === "video" || props.file_type === "youtube"} />
      <div style={{ paddingTop: "3px" }}>:</div>

      {/* <InputNumber
        className='flex-duration:nth-child(2)'
      
      /> */}
      <div style={{ paddingTop: "3px" }}>:</div>

      <Input className='flex-duration:nth-child(2)' type="number" step="1"

        min={0}
        max={59}
        value={minutes}
        onChange={(e) => onChange(e.target.value, "min")}
        // bordered={false}
        style={{ hieght: "20px", width: "50px", fontSize: "12px", fontFamily: "monospace" }}
        // size="small"
        disabled={props.file_type === "video"  || props.file_type === "youtube"} />


<div style={{ paddingTop: "3px" }}>:</div>

<Input className='flex-duration:nth-child(2)' type="number" step="1"

  min={0}
  max={59}
  value={seconds}
  onChange={(e) => onChange(e.target.value, "sec")}
  // bordered={false}
  style={{ hieght: "20px", width: "50px", fontSize: "12px", fontFamily: "monospace" }}
  // size="small"
  disabled={props.file_type === "video"  || props.file_type === "youtube"} />

    </div>
  );
}
