// import "./TemplateCard.css";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
// import Cast from "@mui/icons-material/Cast";
import Swal from "sweetalert2";
import { IconButton, Tooltip } from "@mui/material";
import screenGroup from "../../../../assets/images/groupscreenicon.png";
import CastIcon from "@mui/icons-material/Cast";
// import moment from "moment";
// import VirtualTemplate from "../../../../components/VirtualTemplate/VirtualTemplate";
import { Card, Col, CardImg, CardBody } from "reactstrap";

import { Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";

export default function ScreenGroupCard({
  item,
  deleteHandler,
  editHandler,
  castScreenModalToggle,
  cast,
  setSelectedItem,
  selectedItem,
}) {
  const navigate = useNavigate();

  return (
    <Col md={4} xl={2} style={{ minWidth: "260px" }}>
      <Card
        className={cast ? "cast-screengroup-card" : "abaci-item-card"}
        style={{
          marginTop: "10px",
          border: `${
            selectedItem !== null && cast && item.id === selectedItem.id
              ? "2px solid 	#BEBEBE"
              : "1px solid #DCDADA"
          }`,
        }}
        onClick={() => cast && setSelectedItem(item)}
      >
        <CardBody>
          <CardImg
            className="img-fluid mt-4"
            src={screenGroup}
            // item?.online_count !== 0 && item?.offline_count !== 0
            //   ? OfflineOnlineGroup
            //   : item?.offline_count !== 0
            //   ? OfflineGroup
            //   : OnlineGroup

            alt="Template"
            onClick={() =>
              cast
                ? setSelectedItem(item)
                : navigate(`/screengroupdetail/${item.id}`)
            }
          />

          <div
            className=" d-flex justify-content-start mt-4 "
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",

              textTransform: "capitalize",
            }}
          >
            {item?.name}
          </div>

          <div
            className=" d-flex justify-content-start mt-1 mb-2"
            style={{ color: "#67818f" }}
          >
            {item?.screen_screengroup?.length + " Screens"}
          </div>

          {cast ? (
            <div style={{ width: "100%", height: "50px" }}></div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <Tooltip title="Cast" arrow>
                <IconButton
                  onClick={() => {
                    castScreenModalToggle(item);
                  }}
                >
                  <CastIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Edit Screen Group" arrow>
                <IconButton
                  onClick={() => {
                    editHandler(item);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              {/* <Tooltip title="View Screen Group" arrow>
                    <IconButton >
                        <VisibilityIcon />
                    </IconButton>
                    </Tooltip> */}

              <Tooltip title="Delete Screen Group" arrow>
                <IconButton
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        // deleteClientData(rowData?.id)
                        deleteHandler(item?.id);
                        //   props.deleteHandler(props.card.id);
                      }
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
}
