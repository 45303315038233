import Cookies from "js-cookie";
import { baseURL } from "../baseURL";

export default function urlMaker(url, type, is_stock) {
  if (process.env.NODE_ENV === "development") {
    return urlMakerDevelopment(url, type, is_stock)
  } else {
    return urlMakerProduction(url, type, is_stock)
  }
  
}  
export function urlMakerDevelopment(url, type, is_stock) {
  //here we are making file urls for both tenant and admin for that we are passing an flag to check whether
  //the file type is stock or not all files uploaded by ADMIN are stock file which user can make an copy to be used

  let url_name = "";
  const tenant = Cookies.get("tenant");

  if (
    type === "image" ||
    type === "templates" ||
    type === "canvas" ||
    type === "youtube" ||
    type === "help_center_content" ||
    type === "training_hub_content"
  ) {
    //here we split the coming url with respect to its file type or getting the name of the file
    //here changing or hardcoding image type to thumbnail for fetching file correctly
    const file_name =
      type === "image"
        ? url?.split("/thumbnails/")[1]
        : url?.split(`/${type}/`)[1];
    if (is_stock) {
      url_name = `${baseURL}/media/media/public/${
        type === "image" ? "thumbnails" : type
      }/${file_name}`;
    } else {
      url_name = `${baseURL}/clients/${tenant}/media/media/${tenant}/${
        type === "image" ? "thumbnails" : type
      }/${file_name}`;
    }
  } else {
    const file_name = url?.split("/files/")[1];
    if (is_stock) {
      url_name = `${baseURL}/media/media/public/files/${file_name}`;
    } else {
      url_name = `${baseURL}/clients/${tenant}/media/media/${tenant}/files/${file_name}`;
    }
  }
   return url_name;
}

export function urlMakerProduction(url, type, is_stock) {
  //here we are making file urls for both tenant and admin for that we are passing an flag to check whether
  //the file type is stock or not all files uploaded by ADMIN are stock file which user can make an copy to be used

  let url_name = "";
  const tenant = Cookies.get("tenant");

  if (
    type === "image" ||
    type === "templates" ||
    type === "canvas" ||
    type === "youtube" ||
    type === "help_center_content" ||
    type === "training_hub_content"
  ) {
    //here we split the coming url with respect to its file type or getting the name of the file
    //here changing or hardcoding image type to thumbnail for fetching file correctly
    const file_name =
      type === "image"
        ? url?.split("/thumbnails/")[1]
        : url?.split(`/${type}/`)[1];
    if (is_stock) {
      url_name = `${baseURL}/media/public/${
        type === "image" ? "thumbnails" : type
      }/${file_name}`;
    } else {
      url_name = `${baseURL}/media/${tenant}/${
        type === "image" ? "thumbnails" : type
      }/${file_name}`;
    }
  } else {
    const file_name = url?.split("/files/")[1];
    if (is_stock) {
      url_name = `${baseURL}/media/public/files/${file_name}`;
    } else {
      url_name = `${baseURL}/media/${tenant}/files/${file_name}`;
    }
  }
   return url_name;
}