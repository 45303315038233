import React, { useEffect } from "react";

// import { useHistory } from "react-router-dom";
// import Logo from "../../../assets/images/abaci-logo.png";
import { Container } from "react-bootstrap";
import logo from "../../../assets/images/logo-1-1.png";
import bannerShape1 from "../../../assets/images/shapes/banner-1-1.png";

export default function TermsConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
        <header className={`site-header-one stricky site-header-one__fixed-top `} style={{padding:'15px'}}>
        <Container fluid>
          <div className='site-header-one__logo'>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <img src={logo} width='60' alt='' />

              <span
                style={{
                  fontFamily: "aquatico",
                  fontSize: "10px",
                  lineHeight: "10px",
                }}>
                ABACI SIGNAGE
              </span>
            </div>

            <span className='side-menu__toggler'>
              <i className='fa fa-bars'></i>
            </span>
          </div>
          <img src={bannerShape1} style={{position:'absolute',top:'0px',right:'0px',zIndex:'4'}} alt="" />
          <div className='main-nav__main-navigation'>
          <h1 style={{ fontFamily: "lato",margin:'10px',marginLeft:'60px',marginTop:'30px' }}>Privacy Policy</h1>
          </div>
          
        </Container>
      </header>
      <div className='hero'>
        <div style={{ margin: "5rem" ,marginRight:'51px'}}>
          <h1 style={{ fontFamily: "lato" }}>Terms and Conditions</h1>
          <p className='textalignPrivacy'>‍Last updated: October 2, 2021</p>
          <p className='textalignPrivacy'>
            These Terms and Conditions ("Terms", "Terms and Conditions") govern
            your relationship with
          </p>
          <a
            className='textalignPrivacy'
            style={{ marginTop: "1rem",color:'#0798FF',fontSize:'14px'  }}
            href=' http://www.abacisignage.com'>
            http://www.abacisignage.com
          </a>
          <br />
          <a
            className='textalignPrivacy'
            style={{ marginTop: "1rem",color:'#0798FF',fontSize:'14px'   }}
            href='http://www.abacitechs.com'>
            http://www.abacitechs.com
          </a>
          <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
            websites (the "Service") operated by ABACI DS LLC. ("us", "we", or
            "our").
          </p>
          <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
            Please read these Terms and Conditions carefully before using the
            Service.
          </p>
          <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
            Your access to and use of the Service is conditioned on your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users and others who access or use the Service.
          </p>
          <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
            By accessing or using the Service you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
            access the Service.
          </p>
          <h4 className='textalignPrivacy'>Subscriptions</h4>
          <p className='textalignPrivacy'>
            ‍Some parts of the Service are billed on a subscription basis
            ("Subscription(s)"). You will be billed in advance on a recurring
            and periodic basis ("Billing Cycle"). Billing cycles are set on a
            monthly basis. At the end of each Billing Cycle, your Subscription
            will automatically renew under the exact same conditions unless you
            cancel it or ABACI DS LLC. cancels it. You may cancel your
            Subscription renewal either through your online account management
            page or by contacting ABACI DS LLC. customer support team. A valid
            payment method, including credit card, is required to process the
            payment for your Subscription. You shall provide ABACI DS LLC. with
            accurate and complete billing information including full name,
            address, state, zip code, telephone number, and a valid payment
            method information. By submitting such payment information, you
            automatically authorize ABACI DS LLC. to charge all Subscription
            fees incurred through your account to any such payment instruments.
            Should automatic billing fail to occur for any reason, ABACI DS LLC.
            will issue an electronic invoice indicating that you must proceed
            manually, within a certain deadline date, with the full payment
            corresponding to the billing period as indicated on the invoice.
          </p>
          <h4 className='textalignPrivacy'>‍Free Trial</h4>
          <p className='textalignPrivacy'>
            ABACI DS LLC. may, at its sole discretion, offer a Subscription with
            a free trial for a limited period of time ("Free Trial").You may be
            required to enter your billing information in order to sign up for
            the Free Trial. If you do enter your billing information when
            signing up for the Free Trial, you will not be charged by ABACI DS
            LLC. until the Free Trial has expired. On the last day of the Free
            Trial period, unless you cancelled your Subscription, you will be
            automatically charged the applicable Subscription fees for the type
            of Subscription you have selected. At any time and without notice,
            ABACI DS LLC. reserves the right to (i) modify the terms and
            conditions of the Free Trial offer, or (ii) cancel such Free Trial
            offer.
          </p>
          <h4 className='textalignPrivacy'>‍Fee Changes</h4>
          <p className='textalignPrivacy'>
            ‍ABACI DS LLC., in its sole discretion and at any time, may modify
            the Subscription fees for the Subscriptions. Any Subscription fee
            change will become effective at the end of the then-current Billing
            Cycle.
            <br />
            ABACI DS LLC. will provide you with a reasonable prior notice of any
            change in Subscription fees to give you an opportunity to terminate
            your Subscription before such change becomes effective.Your
            continued use of the Service after the Subscription fee change comes
            into effect constitutes your agreement to pay the modified
            Subscription fee amount.
          </p>
          <h4 className='textalignPrivacy'>‍Refunds</h4>
          <p className='textalignPrivacy'>
            ‍By the reason, that we provide an opportunity to test our service
            in a 14 days FREE Trial period for every customer, all paid
            subscription fees are non-refundable, except when required by law.
          </p>
          <h4 className='textalignPrivacy'> ‍Content</h4>
          <p className='textalignPrivacy'>
            Our Service allows you to post, link, store, share and otherwise
            make available certain information, text, graphics, videos, or other
            material ("Content"). You are responsible for the Content that you
            post to the Service, including its legality, reliability, and
            appropriateness.By posting Content to the Service, you grant us the
            right and license to use, modify, perform, display, reproduce, and
            distribute such Content on and through the Service. You retain any
            and all of your rights to any Content you submit, post or display on
            or through the Service and you are responsible for protecting those
            rights.You represent and warrant that: (i) the Content is yours (you
            own it) or you have the right to use it and grant us the rights and
            license as provided in these Terms, and (ii) the posting of your
            Content on or through the Service does not violate the privacy
            rights, publicity rights, copyrights, contract rights or any other
            rights of any person.
          </p>
          <h4 className='textalignPrivacy'>‍Accounts</h4>
          <p className='textalignPrivacy'>
            When you create an account with us, you must provide us information
            that is accurate, complete, and current at all times. Failure to do
            so constitutes a breach of the Terms, which may result in immediate
            termination of your account on our Service.You are responsible for
            safeguarding the password that you use to access the Service and for
            any activities or actions under your password, whether your password
            is with our Service or a third-party service.You agree not to
            disclose your password to any third party. You must notify us
            immediately upon becoming aware of any breach of security or
            unauthorized use of your account. You may not use as a username the
            name of another person or entity or that is not lawfully available
            for use, a name or trade mark that is subject to any rights of
            another person or entity other than you without appropriate
            authorization, or a name that is otherwise offensive, vulgar or
            obscene.
          </p>
          <h4 className='textalignPrivacy'>‍‍Links To Other Web Sites</h4>
          <p className='textalignPrivacy'>
            ‍Our Service may contain links to third-party web sites or services
            that are not owned or controlled by ABACI DS LLC.. ABACI DS LLC. has
            no control over, and assumes no responsibility for, the content,
            privacy policies, or practices of any third party web sites or
            services. You further acknowledge and agree that ABACI DS LLC. shall
            not be responsible or liable, directly or indirectly, for any damage
            or loss caused or alleged to be caused by or in connection with use
            of or reliance on any such content, goods or services available on
            or through any such web sites or services.We strongly advise you to
            read the terms and conditions and privacy policies of any
            third-party web sites or services that you visit.
          </p>
          <h4 className='textalignPrivacy'>‍‍Termination</h4>
          <p className='textalignPrivacy'>
            ‍We may terminate or suspend your account immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you breach the Terms.Upon termination, your right to
            use the Service will immediately cease. If you wish to terminate
            your account, you may simply discontinue using the Service.
          </p>
          <h4 className='textalignPrivacy'>Governing Law</h4>
          <p className='textalignPrivacy'>
            These Terms shall be governed and construed in accordance with the
            laws of Hong Kong, without regard to its conflict of law
            provisions.Our failure to enforce any right or provision of these
            Terms will not be considered a waiver of those rights. If any
            provision of these Terms is held to be invalid or unenforceable by a
            court, the remaining provisions of these Terms will remain in
            effect. These Terms constitute the entire agreement between us
            regarding our Service, and supersede and replace any prior
            agreements we might have between us regarding the Service.
          </p>
          <h4 className='textalignPrivacy'> ‍Changes</h4>
          <p className='textalignPrivacy'>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will try to
            provide at least 30 days’ notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.By continuing to access or use our Service after
            those revisions become effective, you agree to be bound by the
            revised terms. If you do not agree to the new terms, please stop
            using the Service.
          </p>
        </div>
      </div>
     
    </>
  );
}
