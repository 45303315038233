import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useForm } from "react-hook-form";
// import swal from "sweetalert";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
// import { baseURL } from "../../baseURL";

export default function ImageCropper(props) {
  const [loader, setLoader] = useState(false);

  // const [cropData, setCropData] = useState(null);
  const [cropper, setCropper] = useState(null);
  const { reset } = useForm();
  const handleOk = () => {
    reset({ imageInput: null });
    setLoader(true);
    getCropData();
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const fileUploaded = cropper.getCroppedCanvas().toDataURL();
      // props.setImageData(b64toBlob(fileUploaded));
      props.setImageDataB64(fileUploaded);
      props.toggle();
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <Modal
          size={props.size}
          centered
          isOpen={props.isOpen}
          toggle={props.toggle}
        >
          <ModalHeader toggle={props.toggle}>{"Crop the Picture"}</ModalHeader>
          <ModalBody>
            <Cropper
              style={{ height: 380, width: "100%" }}
              zoomTo={1}
              aspectRatio={1.33}
              preview=".img-preview"
              src={props.image}
              viewMode={1}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
            <br />
            <p>Scroll for zooming in and out...</p>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              className="w-xs waves-effect waves-light me-1"
              onClick={handleOk}
            >
              {loader ? <Spinner animation="grow" size="sm" /> : "Save"}
            </Button>
            <Button
              type="button"
              color="danger"
              className="w-xs waves-effect waves-light me-1"
              onClick={props.toggle}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}
