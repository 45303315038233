import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  user_data: null,
  stock_data: null,
};
// const initialState = [];
const canvasSlice = createSlice({
  name: "canvasSlice",
  initialState,
  reducers: {
    setCanvases(state, action) {
      
      state.user_data = action.payload;
      // state.stock_data = action.payload.stock_data;
      // state.user_data = action.payload;
    },
    addCanvas: (state, action) => {
      state.user_data.push(action.payload);
    },
    addNewCanvas: (state, action) => {
      state.user_data=[...state.user_data,...action.payload]
    },
    updateCanvas: (state, action) => {
      const temp = state.user_data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.user_data = temp;
    },
    favouriteCanvas(state,action) {
      state.user_data=state.user_data.map((data) => data.id === action.payload.id ? {...data,is_favourite:action.payload.is_favourite} : data)
    },
    deleteCanvas(state, action) {

      state.user_data=state.user_data.filter((data) => data.id !== action.payload)
    },
    addCanvasTag(state,action){
      state.user_data=state.user_data.map((data) => data.id === action.payload.object ? {...data,tag_jsonobject:[...data?.tag_jsonobject,action.payload]} : data)
    }
  },
});

export const { setCanvases, addCanvas, updateCanvas, deleteCanvas ,favouriteCanvas,addCanvasTag,addNewCanvas} =
canvasSlice.actions;
export default canvasSlice.reducer;
