import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  droppableBagItems: [],
  collections: [],
};

const bagSlicer = createSlice({
  name: "bagSlicer",
  initialState,
  reducers: {
    setDroppableBagItems(state, action) {
      // console.log("dispatched", action.payload);
      state.droppableBagItems = action.payload.droppableBagItems;
      state.collections = state.collections;
    },

    updateDroppableBagItems(state,action){
      state.droppableBagItems = action.payload;

    },
    editDroppableBagItems(state,action) {
      state.droppableBagItems=state.droppableBagItems.map((data) => data.div_id === action.payload.div_id ? action.payload : data)
      // state.droppableBagItems = action.payload.droppableBagItems;

    },
    setCollections(state, action) {
      state.droppableBagItems = state.droppableBagItems;
      state.collections = action.payload.collections;
    },
    
    addToCollections(state, action) {
      state.droppableBagItems = state.droppableBagItems;
      const tempCollecion = [...state.collections, ...action.payload.collections]
      state.collections = tempCollecion;
    },
  },
});

export const { setDroppableBagItems, setCollections,addToCollections,editDroppableBagItems ,updateDroppableBagItems} =
  bagSlicer.actions;
export default bagSlicer.reducer;
