import React, { useEffect, useState } from "react";
import { baseURL } from "../../baseURL";
import urlMaker from "../../helpers/urlMaker";

export default function ProgressingBar(props) {

  const [progress,setProgress] = useState('')

  useEffect(() => {
    let timeoutIds = [];

    const updateProgress = (x) => {
      setProgress(((x + 1) / props.scheduled_content.scheduled_duration) * 100);
    };

    const calculateRemainingDuration = () => {
      const playedDuration = Math.round(props.scheduled_content.isStartTime / 1000);
      const remainingDuration = props.scheduled_content.scheduled_duration - playedDuration;
      return remainingDuration;
    };

    const startTimeouts = () => {
      const remainingDuration = calculateRemainingDuration();

      for (let i = 0; i < remainingDuration; i++) {
        const timeoutId = setTimeout(() => {
          updateProgress(i + Math.round(props.scheduled_content.isStartTime / 1000));
        }, 1000 * i);

        timeoutIds.push(timeoutId);
      }
    };

    if (props.scheduled_content.isPlaying) {
      if (props.scheduled_content.isStartTime !== null) {
        updateProgress(Math.round(props.scheduled_content.isStartTime / 1000));
        startTimeouts();
      } else {
        for (let x = 0; x < props.scheduled_content.scheduled_duration; x++) {
          const timeoutId = setTimeout(() => {
            updateProgress(x);
          }, 1000 * x);

          timeoutIds.push(timeoutId);
        }
      }
    }

    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
      setProgress(0);
    };
  }, [props.scheduled_content]);
  



  return (
    <>
    {/* {progress !=='0' && */}
      <div
        className='progressing-bar-schedule'
        style={{
          width: String(progress)+"%",
          // width: typeof progressingKeys.tempKey === "undefined" ? "0%":"100%",
          // transition: "linear all 1s",
          transition:`${progress === 0 ? 'none' : "linear all 1s"}`
        }}></div> 
      <table className='table-of-schedule-wrapper'>
        <tbody>
          <tr>
        <td className='td-of-schedule-wrapper'>
          <img
            className='content-of-schedule-thumbnail'
            src={urlMaker(props.scheduled_content.thumbnail,props.scheduled_content.file_type === 'video' ? 'image' : props.scheduled_content.file_type)}
            alt=''
          />
        </td>
        <td>
          <div className='text-content-of-schedule'>
            {props.scheduled_content.uploaded_file_name}
          </div>
        </td>
        <td>
          <div className='text-content-of-schedule'>
            {props.scheduled_content.scheduled_duration} sec
          </div>
        </td>
        </tr>
        </tbody>
      </table>
    </>
  );
}
