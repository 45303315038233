import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Cast from "@mui/icons-material/Cast";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Swal from "sweetalert2";
// import PermMediaIcon from '@mui/icons-material/PermMedia';
import { IconButton, Tooltip } from "@material-ui/core";
// import StarringStar from "../../../components/StarringStar/index";
import { authAxios } from "../../../axiosInstance";
// import VirtualTemplate from "../../../components/VirtualTemplate/VirtualTemplate";
import { useDispatch } from "react-redux";
import { Card } from "reactstrap";
// import moment from "moment";
import { Toastr } from "../../../helpers/Alert";
import { deleteSchedule, updateSchedule } from "../../../store/scheduleSlice";
import { useNavigate } from "react-router-dom";
import Error from "../../../helpers/Error";
import { SchedulerContainerModal } from "./SchedulerContainerModal";
// import VirtualTemplateNew from "../../../components/VirtualTemplateNew/VirtualTemplateNew";
import urlMaker from "../../../helpers/urlMaker";
export const SchedulerCard = (props) => {
  const [mouseOnTitle, setMouseOnTitle] = useState(false);
  const [mouseOnEdit, setMouseOnEdit] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [contentModalData, setContentModalData] = useState(null);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editHandler = () => {
    setEditEnabled(true);
  };

  const editNameHandler = (data, event) => {
    if (name !== "") {
      const url = `/api/signage/schedulesrudview/${data.id}`;
      authAxios
        .patch(url, { schedule_name: name })
        .then((response) => {
          // Toastr("success", "Scheduler Updated Successfully");
          dispatch(updateSchedule(response.data));
          setEditEnabled(false);
          setName("");
        })
        .catch((error) => {
          const err_msg = Error(error);
          setEditEnabled(false);
          setName("");
          Toastr("error", err_msg);
        });
    } else {
      setEditEnabled(false);
    }
  };

  const deleteHandler = (id) => {
    const url = `/api/signage/schedulesrudview/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        dispatch(deleteSchedule(id));
        Toastr("success", "Scheduler Deleted Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const showMediaModalToggle = () => {
    setShowMediaModal(!showMediaModal);
  };

  return (
    <div>
      {showMediaModal && (
        <SchedulerContainerModal
          toggle={showMediaModalToggle}
          isOpen={showMediaModal}
          title={"Conatiner Viewer"}
          data={contentModalData}
        />
      )}
      <Card
        className="abaci-scheduler-card "
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div>
          <div className="scheduler-card-template">
            <div className="scheduler-card-template-child-1">
              <img
                src={urlMaker(
                  props?.item.template_clone?.thumbnail,
                  "templates"
                )}
                alt="scheduler"
                className="scheduler-image"
              />
            </div>

            <div className="scheduler-card-template-child-2">
              <div
                onMouseEnter={() => setMouseOnTitle(true)}
                onMouseLeave={() => setMouseOnTitle(false)}
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "300px",
                  display: "flex",
                  flexDirection: "row",
                  height: "24px",
                  textTransform: "capitalize",
                  color: "#8D9091",
                }}
              >
                {editEnabled ? (
                  <input
                    autoFocus
                    placeholder={props?.item.schedule_name}
                    style={{
                      borderWidth: "0px",
                      borderBottomWidth: "2px",
                      borderBottomColor: "#E5E4E2",
                      textTransform: "capitalize",
                    }}
                    onChange={(event) => setName(event.target.value)}
                    onBlurCapture={() => editNameHandler(props.item)}
                  ></input>
                ) : (
                  <h4
                    style={{
                      fontSize: "14px",
                      color: " #8D9091",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={editHandler}
                  >
                    {props?.item.schedule_name}
                  </h4>
                )}
                <div
                  style={{ marginLeft: "5px", marginTop: "-3px" }}
                  onMouseEnter={() => setMouseOnEdit(true)}
                  onMouseLeave={() => setMouseOnEdit(false)}
                >
                  {mouseOnTitle && !editEnabled ? (
                    <EditOutlined
                      fontSize={"6px"}
                      color={mouseOnEdit ? "action" : "disabled"}
                      onClick={editHandler}
                      style={{ zIndex: "9999" }}
                    />
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  color: "#adadad",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {"Screen orientation: " +
                  props?.item.template_clone?.orientation}
              </div>

              <div
                style={{
                  color: "#adadad",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {"Resolution :  " +
                  props?.item.template_clone?.resolution_width +
                  " x " +
                  props?.item.template_clone?.resolution_height +
                  " px"}
              </div>

              <div style={{ fontSize: "10px", color: "#F59300" }}>
                {/* {"Draft saved, publish required"} */}
                {"Draft Saved"}
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{marginTop:'50px'}} className='abaci-scheduler-card-content2' >
                      <div 
                        style={{
                        fontWeight: "normal",
                        fontSize: "13px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "300px",
                        color: "#adadad",
                        
                        }}
                        >
                        {"Added by: " + props?.item.created_by_full_name}
                        </div>
                       <div 
                        style={{
                            marginTop:"10px",
                        fontWeight: "normal",
                        fontSize: "13px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "300px",
                        color: "#adadad",
                        }}>
                        {"Added on: " + moment(props?.item.created_date).format("MMM Do YY")}
                        </div>
                    </div> */}
        {isHovered && (
          <div className="scheduler-card-action">
            {/* <div onClick={() => makeFavourite(item.id, item.is_favourite)}> */}
            {/* <span 
                    onClick={() => makeFavourite(item)}>
                    <StarringStar selected={item.is_favourite} />

                    </span> */}
            {/* </div> */}
            {/* <Tooltip title="show media" arrow>
                    <IconButton onClick={() => {
                      showMediaModalToggle();
                      setContentModalData(props.item)}}> */}
            {/* className="far fa-trash-alt"
                                            style={{ cursor: "pointer" }} */}
            {/* 
                    <PermMediaIcon />
                    </IconButton>
                    </Tooltip>
                   */}
            <Tooltip title="Set to Screen" arrow>
              <IconButton
                onClick={() =>
                  props.castScreenModalToggle({
                    ...props.item,
                    file_type: "scheduler",
                  })
                }
              >
                <Cast />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit" arrow>
              <IconButton
                onClick={() => {
                  navigate(`/scheduler/${props.item.id}`);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete" arrow>
              <IconButton
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // deleteClientData(rowData?.id)
                      deleteHandler(props?.item.id);
                    }
                  });
                }}
              >
                {/* className="far fa-trash-alt"
                                            style={{ cursor: "pointer" }} */}

                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Card>
    </div>
  );
};
