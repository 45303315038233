import React,{useEffect} from "react";

// import { useHistory } from "react-router-dom";
// import Logo from "../../../assets/images/abaci-logo.png";
import "./PublicHome.css";
// import Header from "../../../component/PublicComponents/header/header";
import Footer from "../../../components/PublicComponents/footer";
import { Container } from "react-bootstrap";
import logo from "../../../assets/images/logo-1-1.png";
import bannerShape1 from "../../../assets/images/shapes/banner-1-1.png";
export default function PrivacyPolicy() {
  useEffect(() => { window.scrollTo(0, 0);})
  return (
      <>
          <header className={`site-header-one stricky site-header-one__fixed-top `} style={{padding:'15px'}}>
        <Container fluid>
          <div className='site-header-one__logo'>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <img src={logo} width='60' alt='' />

              <span
                style={{
                  fontFamily: "aquatico",
                  fontSize: "10px",
                  lineHeight: "10px",
                }}>
                ABACI SIGNAGE
              </span>
            </div>

            <span className='side-menu__toggler'>
              <i className='fa fa-bars'></i>
            </span>
          </div>
          <img src={bannerShape1} style={{position:'absolute',top:'0px',right:'0px',zIndex:'4'}} alt="" />
          <div className='main-nav__main-navigation'>
          <h1 style={{ fontFamily: "lato",margin:'10px',marginLeft:'60px',marginTop:'30px' }}>Privacy Policy</h1>
          </div>
         
        </Container>
      </header>

    <div className='hero'>
    <div style={{ margin: "5rem" ,marginRight:'51px'}}>
      
        <p className='textalignPrivacy'>
          ‍This privacy policy has been compiled to better serve those who are
          concerned with how their 'Personally Identifiable Information' (PII)
          is being used online. PII, as described in US privacy law and
          information security, is information that can be used on its own or
          with other information to identify, contact, or locate a single
          person, or to identify an individual in context. Please read our
          privacy policy carefully to get a clear understanding of how we
          collect, use, protect or otherwise handle your Personally Identifiable
          Information in accordance with our website.
        </p>
        <h4 className='textalignPrivacy'>
          ‍What personal information do we collect from the people that visit
          our blog, website or app?
        </h4>
        <p className='textalignPrivacy'>
          ‍When ordering or registering on our site, as appropriate, you may be
          asked to enter your name, email address, phone number or other details
          to help you with your experience.{" "}
        </p>
        <h4 className='textalignPrivacy'> When do we collect information?</h4>
        <p className='textalignPrivacy'>
          ‍We collect information from you when you register on our site or
          enter information on our site.{" "}
        </p>
        <h4 className='textalignPrivacy'> How do we use your information?</h4>
        <p className='textalignPrivacy'>
          ‍We may use the information we collect from you when you register,
          make a purchase, sign up for our newsletter, respond to a survey or
          marketing communication, surf the website, or use certain other site
          features in the following ways:{" "}
        </p>
        <li className='textalignPrivacy'>
          To send periodic emails regarding your order or other products and
          services.{" "}
        </li>
        <h4 className='textalignPrivacy'>
          ‍How do we protect your information?{" "}
        </h4>
        <p>
          We do not use vulnerability scanning and/or scanning to PCI standards.
          We only provide articles and information. We never ask for credit card
          numbers. We do not use Malware Scanning. Your personal information is
          contained behind secured networks and is only accessible by a limited
          number of persons who have special access rights to such systems, and
          are required to keep the information confidential. In addition, all
          sensitive/credit information you supply is encrypted via Secure Socket
          Layer (SSL) technology. We implement a variety of security measures
          when a user enters, submits, or accesses their information to maintain
          the safety of your personal information. All transactions are
          processed through a gateway provider and are not stored or processed
          on our servers.{" "}
        </p>
        <h4 className='textalignPrivacy'>‍Do we use 'cookies'? </h4>
        <p className='textalignPrivacy'>
          ‍Yes. Cookies are small files that a site or its service provider
          transfers to your computer's hard drive through your Web browser (if
          you allow) that enables the site's or service provider's systems to
          recognise your browser and capture and remember certain information.
          For instance, we use cookies to help us remember and process the items
          in your shopping cart. They are also used to help us understand your
          preferences based on previous or current site activity, which enables
          us to provide you with improved services. We also use cookies to help
          us compile aggregate data about site traffic and site interaction so
          that we can offer better site experiences and tools in the future.
        </p>
        <h4 className='textalignPrivacy'>‍We use cookies to:</h4>
        <li className='textalignPrivacy'>
          Understand and save user's preferences for future visits.{" "}
        </li>
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          You can choose to have your computer warn you each time a cookie is
          being sent, or you can choose to turn off all cookies. You do this
          through your browser settings. Since browser is a little different,
          look at your browser's Help Menu to learn the correct way to modify
          your cookies.
        </p>
        <h4 className='textalignPrivacy'>
          ‍If users disable cookies in their browser:
        </h4>
        <p className='textalignPrivacy'>
          {" "}
          ‍If you turn cookies off, some features will be disabled. Some of the
          features that make your site experience more efficient and may not
          function properly. However, you will still be able to place orders All
          the features.{" "}
        </p>
        <h4 className='textalignPrivacy'> ‍Third-party disclosure</h4>
        <p className='textalignPrivacy'>
          ‍We do not sell, trade, or otherwise transfer to outside parties your
          Personally Identifiable Information.
        </p>
        <h4 className='textalignPrivacy'>‍Third-party links</h4>
        <p className='textalignPrivacy'>
          {" "}
          We do not include or offer third-party products or services on our
          website.{" "}
        </p>
        <h4 className='textalignPrivacy'> ‍Google</h4>
        <p className='textalignPrivacy'>
          {" "}
          ‍Google's advertising requirements can be summed up by Google's
          Advertising Principles. They are put in place to provide a positive
          experience for users.
        </p>
        <a href='‍https://support.google.com/adspolicy/answer/6008942?hl=en&visit_id=637249634902668594 -1377494276&rd=1 'style={{color:'#0798FF',fontSize:'14px' }}>
          ‍https://support.google.com/adspolicy/answer/6008942?hl=en&visit_id=637249634902668594
          -1377494276&rd=1{" "}
        </a>
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          We have not enabled Google AdSense on our site but we may do so in the
          future.{" "}
        </p>
        <h4 className='textalignPrivacy'>
          {" "}
          ‍California Online Privacy Protection Act{" "}
        </h4>
        <p className='textalignPrivacy'>
          CalOPPA is the first state law in the nation to require commercial
          websites and online services to post a privacy policy. The law's reach
          stretches well beyond California to require any person or company in
          the United States (and conceivably the world) that operates websites
          collecting Personally Identifiable Information from California
          consumers to post a conspicuous privacy policy on its website stating
          exactly the information being collected and those individuals or
          companies with whom it is being shared. - See more at:
        </p>
        <a href='http://consumercal.org/california- online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf 'style={{color:'#0798FF',fontSize:'14px'  }}>
          {" "}
          http://consumercal.org/california-
          online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf{" "}
        </a>
        <h4 className='textalignPrivacy'>
          ‍According to CalOPPA, we agree to the following:{" "}
        </h4>
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          Users can visit our site anonymously.
        </p>
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          Once this privacy policy is created, we will add a link to it on our
          home page or as a minimum, on the first significant page after
          entering our website.
        </p>
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          Our Privacy Policy link includes the word ‘Privacy’ and can easily be
          found on the page specified above.
        </p>
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          {" "}
          You will be notified of any Privacy Policy changes:
        </p>{" "}
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          • On our Privacy Policy Page ‍Can change your personal information:
        </p>
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          • By logging in to your account ‍
        </p>{" "}
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          How does our site handle Do Not Track signals?
        </p>
        <p className='textalignPrivacy' style={{ marginTop: "1rem" }}>
          We don’t honor Do Not Track signals and Do Not Track, plant cookies,
          or use advertising when a Do Not Track (DNT) browser mechanism is in
          place. We don’t honor them because we use cookies only for
          authorization.
        </p>
        <h4 className='textalignPrivacy'>
          ‍Does our site allow third-party behavioral tracking?
        </h4>
        <p className='textalignPrivacy'>
          ‍It's also important to note that we do not allow third-party
          behavioral tracking.{" "}
        </p>
        <h4 className='textalignPrivacy'>
          ‍COPPA (Children Online Privacy Protection Act)
        </h4>
        <p className='textalignPrivacy'>
          {" "}
          ‍When it comes to the collection of personal information from children
          under the age of 13 years old, the Children's Online Privacy
          Protection Act (COPPA) puts parents in control. The Federal Trade
          Commission, United States' consumer protection agency, enforces the
          COPPA Rule, which spells out what operators of websites and online
          services must do to protect children's privacy and safety online. We
          do not specifically market to children under the age of 13 years old.
        </p>
        <h4 className='textalignPrivacy'>‍Fair Information Practices</h4>
        <p className='textalignPrivacy'>
          ‍The Fair Information Practices Principles form the backbone of
          privacy law in the United States and the concepts they include have
          played a significant role in the development of data protection laws
          around the globe. Understanding the Fair Information Practice
          Principles and how they should be implemented is critical to comply
          with the various privacy laws that protect personal information. In
          order to be in line with Fair Information Practices we will take the
          following responsive action, should a data breach occur:
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          We will notify you via email{" "}
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          • Within 7 business days{" "}
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          We also agree to the Individual Redress Principle which requires that
          individuals have the right to legally pursue enforceable rights
          against data collectors and processors who fail to adhere to the law.
          This principle requires not only that individuals have enforceable
          rights against data users, but also that individuals have recourse to
          courts or government agencies to investigate and/or prosecute
          non-compliance by data processors.
        </p>
        <h4 className='textalignPrivacy'>‍CAN SPAM Act</h4>
        <p className='textalignPrivacy'>
          ‍The CAN-SPAM Act is a law that sets the rules for commercial email,
          establishes requirements for commercial messages, gives recipients the
          right to have emails stopped from being sent to them, and spells out
          tough penalties for violations.{" "}
        </p>
        <h5 className='textalignPrivacy'>
          ‍We collect your email address in order to:
        </h5>
        <p className='textalignPrivacy'>
          ‍• Send information, respond to inquiries, and/or other requests or
          questions
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          • Process orders and to send information and updates pertaining to
          orders.
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          • Send you additional information related to your product and/or
          service{" "}
        </p>
        <h5 className='textalignPrivacy'>
          ‍To be in accordance with CANSPAM, we agree to the following:
        </h5>
        <p className='textalignPrivacy'>
          {" "}
          • Not use false or misleading subjects or email addresses.
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          • Identify the message as an advertisement in some reasonable way.
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          • Include the physical address of our business or site headquarters.
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          • Monitor third-party email marketing services for compliance, if one
          is used.
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          {" "}
          • Honor opt-out/unsubscribe requests quickly.
        </p>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          {" "}
          • Allow users to unsubscribe by using the link at the bottom of each
          email.{" "}
        </p>
        <h4 className='textalignPrivacy'>How can you remove your account:</h4>
        <p style={{ fontFamily: "lato", marginTop: "1rem" }}>
          Upon your request, we will remove your subscription and contact
          information from our active databases. Such information will be
          deleted as soon as reasonably possible in accordance with our deleted
          policy and applicable law. To delete your Look personal account,
          please contact our Live Chat. Note that you must be logged in to
          perform this action.
        </p>
        <p className='textalignPrivacy'>
          If at any time you would like to unsubscribe from receiving future
          emails, you can contact us and we will promptly remove you from ALL
          correspondence. Contacting Us If there are any questions regarding
          this privacy policy, you may contact us using the information below.{" "}
        </p>
      </div>
     
    </div>
    <Footer />
    </>
  );
}
