import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import Button from "@mui/material/Button";
//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
// import axios from "axios";
// import GraphLoader from "../../components/GraphLoader/GraphLoader";
// import Switch from '@mui/material/Switch';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';

// import moment from "moment";

// import axios from "axios";
// import Error from "../../../helpers/Error";
import { IconButton, Tooltip } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import Error from "../../../helpers/Error";
// import GraphLoader from "../../../components/GraphLoader/GraphLoader";
import SyncIcon from "@mui/icons-material/Sync";
import { Toastr } from "../../../helpers/Alert";

export default function LineGraph(props) {
  // const [loader, setLoader] = useState(true)
  // const profile = useSelector((state) => state.authSlice.profile);
  // const [date, setDate] = useState(null);
  const [error, setError] = useState(null);
  const [state, setState] = useState({ series: [], options: {} });
  const [totalCount, setTotalCount] = useState({});
  const [loader, setLoader] = useState(true);
  const [allData, setAllData] = useState({});
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeButton, setActiveButton] = useState(0);

  const [dateAndTime, setDateAndTime] = useState({
    start_date: "",

    start_time: "",
    end_time: "",
  });
  const siteOptions = ["site_in", "site_out", "site_occ"];
  const options = ["count_in", "count_out", "count_occ"];
  //graph-total-count-week-month

  const totalCountMonthWeekToday = (id) => {
    const url = "/abaci_count/monthly_weekly_daily_data";
    axios
      .get(url)
      .then((res) => {
        setAllData(res.data);
        setTotalCount({
          ...res.data[siteOptions[id]],
          monthly_count: res.data[siteOptions[id]][`monthly_${options[id]}`],
          today_count: res.data[siteOptions[id]][`daily_${options[id]}`],
          week_count: res.data[siteOptions[id]][`weekly_${options[id]}`],
        });
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
        setTotalCount({ week_count: 0, today_count: 0, monthly_count: 0 });
      });
  };

  //graph
  const graphDataHandler = (dataToBeSend) => {};

  // useEffect(() => {
  //   const start_date = moment().format("YYYY-MM-DD");
  //   const end_date = moment().add(1, "days").format("YYYY-MM-DD");
  //   // const start_time = "9:00";
  //   // const end_time = moment().format("HH:mm");
  //   // setDateAndTime({
  //   //   start_date: start_date,
  //   //   start_time: "",
  //   //   end_time: "",
  //   // });
  //   // graphDataHandler({
  //   //   start_date: start_date,
  //   //   end_date: end_date,
  //   //   start_time: "",
  //   //   end_time: "",
  //   //   site_type_filter: siteOptions[activeButton],
  //   // });

  //   // totalCountMonthWeekToday(0);

  //   // totalCountMonthWeekToday(0);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Update window width on resiz

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const buttonHandler = (id) => {
    setActiveButton(id);
    // totalCountMonthWeekToday(id);

    // setTotalCount({
    //   ...allData[siteOptions[id]],
    //   monthly_count: allData[siteOptions[id]][`monthly_${options[id]}`],
    //   today_count: allData[siteOptions[id]][`daily_${options[id]}`],
    //   week_count: allData[siteOptions[id]][`weekly_${options[id]}`],
    // });

    // setLoader(true);
    // graphDataHandler({
    //   start_date: dateAndTime.start_date,
    //   end_date: moment(dateAndTime.start_date)
    //     .add(1, "days")
    //     .format("YYYY-MM-DD"),

    //   start_time: dateAndTime.start_time,
    //   end_time: dateAndTime.end_time,
    //   site_type_filter: siteOptions[id],
    // });
  };

  const dateAndTimeHandler = (value, type) => {
    setDateAndTime({
      ...dateAndTime,
      [type]: value,
    });
  };

  const filterWithDateAndTime = () => {
    setLoader(true);
    // console.log(dateAndTime);
    graphDataHandler({
      start_date: dateAndTime.start_date,
      end_date: moment(dateAndTime.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD"),
      start_time: dateAndTime.start_time,
      end_time: dateAndTime.end_time,
      site_type_filter: siteOptions[activeButton],
    });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Card
          style={{
            minHeight: `${
              // windowHeight > 1000
              //   ? windowHeight - 390
              //   : windowWidth < 600
              //   ? 800
              //   : 650
              windowHeight > 1000 ? windowHeight - 410 : 500
            }px`,
            marginLeft: "7px",
            marginRight: "7px",
          }}
        >
          <CardBody>
            <div className="graph-heading-container">
              <h4 className="card-title mb-4">{props.title}</h4>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  minWidth: "20%",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  style={{
                    borderRadius: "7px",
                    textTransform: "capitalize",
                    color: `${activeButton !== 0 ? "#1976D2" : "white"}`,
                    backgroundColor: `${activeButton !== 0 ? "" : "#1976D2"}`,
                  }}
                  onClick={() => buttonHandler(0)}
                  className="dashboard-button-group-style"
                >
                  Year
                </Button>
                <Button
                  style={{
                    borderRadius: "7px",
                    textTransform: "capitalize",
                    color: `${activeButton !== 1 ? "#1976D2" : "white"}`,
                    backgroundColor: `${activeButton !== 1 ? "" : "#1976D2"}`,
                  }}
                  className="dashboard-button-group-style"
                  onClick={() => buttonHandler(1)}
                >
                  Month
                </Button>
              </div> */}
            </div>

            <div id="donut-chart" className="apex-charts">
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="bar"
                height={`${windowHeight > 1000 ? windowHeight - 550 : 370}px`}
              />
              {error ? (
                <>
                  <p className="text-center">{error}</p>
                </>
              ) : null}
            </div>

            {loader ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              >
                {/* <GraphLoader /> */}
              </div>
            ) : null}
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
}
