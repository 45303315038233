import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  data: null,
};
// const initialState = [];
const scheduleSlice = createSlice({
  name: "scheduleSlice",
  initialState,
  reducers: {
    setSchedules(state, action) {
      state.data = action.payload;
    },
    
    addSchedule: (state, action) => {
      state.data=[...state.data,action.payload]
    },
    addNewSchedule: (state, action) => {
      state.data=[...state.data,...action.payload]
    },
    updateSchedule: (state, action) => {
      //   const index = state.data.findIndex(post => post.id === action.payload.id)
      // console.log('from store',index)
      //   state.data.splice(index,0,action.payload)
      state.data=state.data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    // deleteSchedule: (state, action) => {
    //   const indexToBeDeleted = action.payload
    //   const tempState = [...state.data];
    //   tempState.splice(indexToBeDeleted, 1)
    //   state.data = tempState
    // },
    deleteSchedule(state,action){
      state.data=state.data.filter((item) => item.id !== action.payload)
    }
  },
});

export const { setSchedules, updateSchedule, deleteSchedule, addSchedule ,addNewSchedule} =
  scheduleSlice.actions;
export default scheduleSlice.reducer;
