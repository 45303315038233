import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { addAssetDetails } from "../../../store/assetDetails";
// import Medias from "./Medias";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";
import {  Spinner } from "reactstrap";

import {Row} from "reactstrap";
import {
  deleteImage,
  deleteVideo,
} from "../../../../store/mediaSlice";
import InfiniteScroll from "react-infinite-scroller";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { authAxios } from "../../../../axiosInstance";
import ScreenGroupCard from "../ScreenCards/ScreenGroupCard";

function OrchestartionTab({loadFunc,hasNextpage}) {

  const dispatch=useDispatch();
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);
  const screenGroup=useSelector((state) => state.screenSlice.screenGroup)
  // Function to Delete the Media File ie Images,Videos passed as
  // an prop to media card and called upon clicking the icon button
  const deleteHandler = (id, type) => {
    let url;
    if (tenantDetails?.app_type === "Admin") {
      url = `/stock/api/signage/filesrudview/${id}`;
    } else {
      url = `/api/signage/filesrudview/${id}`;
    }
    // const url = `/api/signage/filesrudview/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        if (type === "image") {
          dispatch(deleteImage(id));
          Toastr("success", "Image Deleted Successfully");
        } else {
          dispatch(deleteVideo(id));
          Toastr("success", "Video Deleted Successfully");
        }
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

 



  return (
    <>
      <React.Fragment>
   
        {/* {screenGroup === null ? (
            <>
                <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                }}
                >
                <Spinner animation="grow" />
                </div>
            </>
            ) : screenGroup?.length === 0 ? (
            <> */}
                <div className=" text-center">
                <img
                    src={EmptyList}
                    alt=""
                    style={{
                    width: "450px",
                    height: "450px",
                    }}
                />
                <h6 style={{}}>No Orchestartion screens Found !</h6>
                </div>
         
                      
       
      </React.Fragment>
    </>
  );
}

export default OrchestartionTab;
