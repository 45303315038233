import "./TemplateCard.css";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
// import Cast from "@mui/icons-material/Cast";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Swal from "sweetalert2";
import { IconButton, Tooltip } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
// import moment from "moment";
import StarringStar from "../../../../components/StarringStar/index";
// import VirtualTemplate from "../../../../components/VirtualTemplate/VirtualTemplate";
import {
  // TabContent,
  // TabPane,
  // Nav,
  // NavItem,
  // NavLink,
  Card,
  // Button,
  // CardTitle,
  // CardText,
  // Row,
  Col,
  // Container,
  // CardSubtitle,
  CardImg,
  CardBody,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";

// import urlMaker from "../../../helpers/urlMaker";
// import { height } from "@mui/system";
import { Toastr } from "../../../../helpers/Alert";
import { authAxios } from "../../../../axiosInstance";
import {
  favouriteTemplate,
  updateTemplate,
} from "../../../../store/templateSlice";
import Error from "../../../../helpers/Error";
import urlMaker from "../../../../helpers/urlMaker";

export default function TemplateCard(props) {
  const dispatch = useDispatch();
  const [mouseOnTitle, setMouseOnTitle] = useState(false);
  const [mouseOnEdit, setMouseOnEdit] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  const [editEnabled, setEditEnabled] = useState(false);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const editHandler = () => {
    setEditEnabled(true);
  };

  // useEffect(() => {
  //   console.log(item);
  // }, []);
  const editNameHandler = (data) => {
    if (name !== "") {
      const id = data.id;
      let url;
      if (tenantDetails?.app_type === "Admin") {
        url = `/stock/api/signage/templatesrud/${id}`;
      } else {
        url = `/api/signage/templatesrud/${id}`;
      }

      // const url = `/api/signage/templatesrud/${id}`;
      authAxios
        .patch(url, { template_name: name })
        .then((response) => {
          // Toastr("success", "File Updated Successfully");

          dispatch(updateTemplate(response.data));
          setEditEnabled(false);
          setName("");
        })
        .catch((error) => {
          setEditEnabled(false);
          setName("");
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    } else {
      setEditEnabled(false);
    }
  };

  const favouriteHandler = (id, fvrt) => {
    dispatch(
      favouriteTemplate({
        is_favourite: fvrt === true ? false : true,
        id,
      })
    );
    const url = `/api/signage/templatesrud/${id}`;
    authAxios
      .patch(url, { is_favourite: fvrt === true ? false : true })
      .then((response) => {
        // if (type === "image") {
        //   dispatch(editImage(response.data));
        // } else {
        //   dispatch(editVideo(response.data));
        // }
        // Toastr("success", "File Updated Successfully");
      })
      .catch((error) => {
        dispatch(favouriteTemplate({ is_favourite: fvrt, id }));
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };
  // const [screenWidth, setScreenWidth] = React.useState(1920);

  // class Css {
  //   constructor(cssObj, virtualizationFactor) {
  //     this.cssObj = cssObj;
  //     this.virtualizationFactor = virtualizationFactor;
  //   }
  //   convertToOriginal = () => ({
  //     position: this.cssObj.position,
  //     marginTop: this.cssObj.marginTop / this.virtualizationFactor,
  //     marginLeft:
  //       screenWidth / 2 + this.cssObj.marginLeft / this.virtualizationFactor,
  //     height: this.cssObj.height / this.virtualizationFactor,
  //     width: this.cssObj.width / this.virtualizationFactor,
  //     backgroundColor: this.cssObj.backgroundColor,
  //     zIndex: this.cssObj.zIndex,
  //   });
  //   convertToVirtual = () => ({
  //     position: this.cssObj.position,
  //     marginTop: this.cssObj.marginTop * this.virtualizationFactor,
  //     marginLeft: this.cssObj.marginLeft * this.virtualizationFactor,
  //     height: this.cssObj.height * this.virtualizationFactor,
  //     width: this.cssObj.width * this.virtualizationFactor,
  //     backgroundColor: this.cssObj.backgroundColor,
  //     border:
  //       this.cssObj.border === 0
  //         ? "1px solid"
  //         : String(this.cssObj.border) + "px solid",
  //     borderRadius: this.cssObj.borderRadius,
  //     borderColor:
  //       this.cssObj.border === 0 ? "light grey" : this.cssObj.borderColor,
  //     zIndex: this.cssObj.zIndex,
  //   });
  // }
  // console.log(card.id)
  // const virtualizationFactor =
  //   props.card?.resolution_width > props.card?.resolution_height
  //     ? 180 / props.card?.resolution_width
  //     : (180 / props.card?.resolution_height) * 0.7;
  // let divs = props.card?.divs_templates;
  // if (typeof divs === "undefined") {
  //   divs = props.card?.divclone_templateclone;
  // }
  // const tempDivs = divs?.map((divMapped, index) => {
  //   const divObj = new Css(divMapped, virtualizationFactor);
  //   // const divStyle = divObj.convertToVirtual();
  //   const divComponent = (
  //     <div key={index} style={divObj.convertToVirtual()}></div>
  //   );
  //   return () => divComponent;
  // });
  // const cardId = props.card?.id;
  //   if we pass withoutcard = true in props, this component will return only the templates without card

  const clickHandler = (templateId) => {
    navigate(`/templatescreator/${templateId}`);
  };

  return (
    <Col md={4} xl={2} style={{ minWidth: "200px" }}>
      <Card
        className="abaci-item-card"
        style={{ marginTop: "10px" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardBody>
          <div>
            <div
              onMouseEnter={() => setMouseOnTitle(true)}
              onMouseLeave={() => setMouseOnTitle(false)}
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "300px",
                display: "flex",
                flexDirection: "row",
                height: "24px",
                textTransform: "capitalize",
              }}
            >
              {editEnabled ? (
                <input
                  autoFocus
                  placeholder={props.card?.template_name}
                  style={{
                    borderWidth: "0px",
                    borderBottomWidth: "2px",
                    borderBottomColor: "#E5E4E2",
                    textTransform: "capitalize",
                  }}
                  onBlurCapture={() => editNameHandler(props.card)}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                ></input>
              ) : (
                <h4
                  style={{
                    fontSize: "14px",
                    color: " #8D9091",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={editHandler}
                >
                  {props.card?.template_name}
                </h4>
              )}
              <div
                style={{ marginLeft: "5px", marginTop: "-3px" }}
                onMouseEnter={() => setMouseOnEdit(true)}
                onMouseLeave={() => setMouseOnEdit(false)}
              >
                {mouseOnTitle && !editEnabled ? (
                  <EditOutlined
                    style={{ width: "15px", height: "15px" }}
                    color={mouseOnEdit ? "action" : "disabled"}
                    onClick={editHandler}
                  />
                ) : null}
              </div>
            </div>

            <div
              style={{
                fontWeight: "normal",
                fontSize: "13px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "300px",
                color: "#adadad",
              }}
            >
              {/* {"item?.object?.link"} */}
            </div>
          </div>
        </CardBody>
        <CardImg
          className="img-fluid"
          src={
            tenantDetails?.app_type === "Admin"
              ? urlMaker(props?.card?.thumbnail, "templates", true)
              : urlMaker(props?.card?.thumbnail, "templates")
          }
          alt="Template"
          onClick={() => clickHandler(props.card.id)}
        />
        <CardBody style={{ marginTop: "-20px" }}>
          <div className=" d-flex justify-content-center  template-card-orientation">
            {"Screen orientation: " + props.card?.orientation}
          </div>

          <div
            className=" d-flex justify-content-center mt-1"
            style={{ color: "#67818f", fontSize: ".5rem" }}
          >
            {"Resolution :  " +
              props.card?.resolution_width +
              " x " +
              props.card?.resolution_height +
              " px"}
          </div>
          <div
            style={{
              width: "100%",
              height: "47px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {tenantDetails?.app_type !== "Admin" && (
              <div
                onClick={() =>
                  favouriteHandler(props.card.id, props.card.is_favourite)
                }
              >
                {/* <StarringStar selected={props.card.is_favourite} /> */}
                {(props.card.is_favourite || isHovered) && (
                  <StarringStar selected={props.card.is_favourite} />
                )}
              </div>
            )}
            {isHovered && (
              <>
                <Tooltip title={"Edit Template"} arrow>
                  <IconButton onClick={() => clickHandler(props.card.id)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <IconButton
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // deleteClientData(rowData?.id)
                          props.deleteHandler(props.card.id);
                        }
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>{" "}
              </>
            )}
          </div>
        </CardBody>

        {/* <div className="d-flex justify-content-center" style={{height:'125px'}}> */}
        {/* <div
            className="  "
            style={{
              width: props.card?.resolution_width * virtualizationFactor,
              height: props.card?.resolution_height * virtualizationFactor,
              borderRadius: 20 * virtualizationFactor,
             
            }}
            onClick={() => clickHandler(props.card.id)}
          > */}

        {/* <img src={urlMaker(props?.card?.thumbnail,'templates')} alt=''  
               onClick={() => clickHandler(props.card.id)}
            /> */}

        {/* {tempDivs?.map((tempDiv) => tempDiv())} */}
        {/* /> */}
        {/* </CardImg> */}
        {/* </div> */}
        {/* </div> */}
      </Card>
    </Col>
  );
}
