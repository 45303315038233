import React from 'react';
import { Container } from "react-bootstrap";
import logo from "../../../assets/images/logo-1-1.png";
import bannerShape1 from "../../../assets/images/shapes/banner-1-1.png";
// import image from "../../../assets/images/banner/banner-img-1.gif";
import './Demo.css';
export default function Demo() {
    return (
        <>
           <header
        className={`site-header-one stricky site-header-one__fixed-top `}
        style={{ padding: "15px" }}>
        <Container fluid>
          <div className='site-header-one__logo'>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <img src={logo} width='60' alt='' />

              <span
                style={{
                  fontFamily: "aquatico",
                  fontSize: "10px",
                  lineHeight: "10px",
                }}>
                ABACI SIGNAGE
              </span>
            </div>

            <span className='side-menu__toggler'>
              <i className='fa fa-bars'></i>
            </span>
          </div>
          <img
            src={bannerShape1}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              zIndex: "4",
            }}
            alt=''
          />
          <div className='main-nav__main-navigation'>
            <h1
              style={{
                fontFamily: "lato",
                margin: "10px",
                marginLeft: "60px",
                marginTop: "30px",
              }}>
        Get a Free Demo of Abaci Signage
            </h1>
          </div>
        </Container>
      </header>
        <div className='holderdemo'>
           <section class="contact_wrapper">
           {/* <img
                src={image}
                alt="Banner-img"
                style={{ position: "absolute", top: "-30px", right: "-460px",zIndex:'4' }}
              /> */}
  {/* <div class="contact_info">
    <h3 class="title">
      Contact Info
    </h3> */}

    {/* <ul class="icons_wrapp">
      <li>
        <div class="icon">
          <span class="material-icons-outlined"> place </span>
        </div>
        <div class="text">
          123 ABCDedf Road, 123 ABC, ABCD - 123456123 ABCDedf Road, 123 ABC, ABCD - 123456
        </div>
      </li>
      <li>
        <div class="icon">
          <span class="material-icons-outlined"> mail_outline </span>
        </div>
        <div class="text">
          ABCDedfRoad@ABCD.com
        </div>
      </li>
      <li>
        <div class="icon">
          <span class="material-icons-outlined"> phone_in_talk </span>
        </div>
        <div class="text">
          123-456-789
        </div>
      </li>
    </ul> */}

    {/* <ul class="soci_wrap">
      <li>
        <a href="#">
          <span class="material-icons-outlined"> facebook </span>
        </a>
      </li>
      <li>
        <a href="#">
          <span class="material-icons-outlined"> face </span>
        </a>
      </li>
      <li>
        <a href="#">
          <span class="material-icons-outlined"> facebook </span>
        </a>
      </li>
      <li>
        <a href="#">
          <span class="material-icons-outlined"> face </span>
        </a>
      </li>
    </ul>
  </div> */}

  <div class="contact_msg">
    <h3 class="title">
 Abaci Signage
    </h3>

    <div class="form_fild">
      <div class="input_group w_50">
        <input type="text" className="inputdata" required placeholder='First Name'/>
      
      </div>

      <div class="input_group w_50">
        <input type="text" className="inputdata" required placeholder='Last Name'/>
       
      </div>

      <div class="input_group w_50">
        <input type="text" className="inputdata" required placeholder='Email Address'/>
      
      </div>

      <div class="input_group w_50">
        <input type="tel" className="inputdata" required placeholder='Mobile Number'/>
      
      </div>

      <div class="input_group w_100">
      <input type="text" className="inputdata" required placeholder='How many displays would you like to use?'/>
       
      </div>

      <div class="input_group">
      <button className='abaci-button' style={{ color: "white" }}>
              <span
                style={{ color: "white" }}
                onClick={() => {
                //   history.push("/signin");
                }}>
                Get Free Demo
              </span>
            </button>
      </div>

    </div>

  </div>

</section> 
        </div>
        </>
    )
}
