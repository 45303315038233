import React, { useEffect } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux';
import ScreenCard from '../../ScreenCards/ScreenCard';
import EmptyList from "../../../../../assets/SVG/Search Not Found 2.svg";
import { Row } from 'reactstrap';
import Error from '../../../../../helpers/Error';
import { setScreenGroupScreens, setScreens } from '../../../../../store/screenSlice';
import { Toastr } from '../../../../../helpers/Alert';
import { authAxios } from '../../../../../axiosInstance';

function ScreenDroppableComponent({windowHeight,screenGroupData}) {

    const dispatch = useDispatch();
    const screenGroupScreens = useSelector((state) => state.screenSlice.screenGroupScreens);
    const screenList = useSelector((state) => state.screenSlice.screens);

    const deleteHandler = (data) => {

         // taking an backup of current screengroupscreen list and screen data inorder to revert to current state if
        // any error occurs during the submission of data to backend
        let oldScreenGroupScreenBackUp = screenGroupScreens;
        let oldScreenListBackUp = screenList;

        //  then we take an copy of the screenList , then remove the screen which was dropped on to an screenGroup
        //  then update the redux store with the newly made set of data

        let newScreenListArray = [...screenList,data];

        let newScreenGroupArray = screenGroupScreens.filter((item) => item.id !== data.id)

        dispatch(setScreens(newScreenListArray));

        dispatch(setScreenGroupScreens(newScreenGroupArray));

        const dataForSubmission = {
          name: screenGroupData.name,
          screen_ids: newScreenGroupArray.map((data) => data.id),
        };

      let url = `/api/signage/screengrouprudview/${screenGroupData.id}`;

      authAxios
        .patch(url, dataForSubmission)
        .then((res) => {})
        .catch((err) => {
          dispatch(setScreenGroupScreens(oldScreenGroupScreenBackUp));
          dispatch(setScreens(oldScreenListBackUp));
          const err_msg = Error(err);
          Toastr("error", err_msg);
        });
    }
      
  return (
    <Droppable droppableId={'droppable'}>
    {(provided, snapshot) => (
      // <ul ref={provided.innerRef} >
      <div ref={provided.innerRef} >
        {screenGroupScreens?.length === 0 || screenGroupScreens === null? (
            <>
                <div className=" text-center">
                <img
                    src={EmptyList}
                    alt=""
                    style={{
                    width: "450px",
                    height: "450px",
                    }}
                />
                <h6 style={{}}>No Screens Found !</h6>
                </div>
            </>
            ) : (
            <Row
                style={{
                display: "flex",
                justifyContent: "flex-start",
                height: Math.round( windowHeight - 360 ) ,
                overflowY:'scroll',overflowX:'hidden'
                }}
            >
            {screenGroupScreens.map((data,index) => (
            <ScreenCard 
            key={index}
            item={data}
            isScreenGroup={true}
            deleteHandler={deleteHandler}
            />
            ))}
            </Row>
            )}
        <p style={{ display: "none" }}>{provided.placeholder}</p>
        </div>
    )}
    </Droppable>
  )
}

export default ScreenDroppableComponent