import { Container, Spinner } from "reactstrap";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import SupportCard from "../../../../components/SupportCard";
import { CustomButton } from "../../../../components/CustomButton";
// import AddHelpCenterModal from "./AddHelpCenterModal";
import {
  AddHelpCenter,
  AddTrainingHub,
  DeleteTrainingHub,
} from "../../../../store/supportSlice";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { authAxios } from "../../../../axiosInstance";
import AddTrainingHubContents from "./AddTrainingHubContents";
import EditHeplCenterModal from "../HelpCenter/EditHeplCenterModal";
import EditTrainingHubModal from "./EditTrainingHubModal";
function TrainingHub() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [value, setValue] = useState(0);
  const [addModalShow, setAddModalShow] = useState(false);
  const [pageSize] = useState(20);
  const [hasNextpage, setHasNextPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const trainingHub = useSelector((state) => state.supportSlice.trainingHub);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "/dashboard" },
      { title: "Training Hub", link: "#" },
    ],
  });

  function editModalToggle() {
    setEditModalShow(!editModalShow);
  }

  const editModalHandler = (data) => {
    // navigate(`/traininghub/${data.id}`);
    setImage(data);
    setIsModalVisible(!isModalVisible);
  };
  const deleteHandler = (id) => {
    const url = `/abaciadmin_api/traininghub/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        dispatch(DeleteTrainingHub(id));
        Toastr("success", "Content deleted succesfully");
      })
      .catch((error) => {
        let error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };

  useEffect(() => {
    const url = `/abaciadmin_api/traininghub?limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }`;

    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);
        if (trainingHub === null) {
          dispatch(AddTrainingHub(response.data.results));
        } else {
          dispatch(AddTrainingHub([...trainingHub, ...response.data.results]));
        }

        setTotalCount(response.data.count);

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  }, [page]);

  useEffect(() => {
    return () => {
      dispatch(AddTrainingHub(null));
    };
  }, []);

  function addModalToggle() {
    setAddModalShow(!addModalShow);
  }

  const enableDisableHandler = (data) => {
    const status = data.status === "Active" ? "Disabled" : "Active";
    const url = `${data.id}`;
    axios
      .patch(url, { status: status })
      .then((response) => {
        dispatch();
        // updatetrainingHub({
        //   ...response.data,
        //   image: urlMaker(response.data.landing_image),
        //   thumbnail: urlMaker(response.data.thumbnail),
        // })
        Toastr("success", "Content updated succesfully");
      })
      .catch((error) => {
        let error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };

  const ModalToggle = (card) => {
    setImage(card);
    setIsModalVisible(!isModalVisible);
  };

  const loadFunc = () => {
    if (hasNextpage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
  };

  return (
    <React.Fragment>
      {addModalShow && (
        <AddTrainingHubContents
          isOpen={addModalShow}
          toggle={addModalToggle}
          title={"Add Contents"}
        />
      )}
      {isModalVisible && (
        <EditTrainingHubModal
          isOpen={isModalVisible}
          toggle={editModalHandler}
          title={"Edit Contents"}
          item={image}
        />
      )}
      <div className="page-content">
        <CustomButton title="+" onClick={addModalToggle} />
        <Container fluid>
          <Breadcrumbs
            title="Training Hub"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>

          {trainingHub?.length === 0 ? (
            <div className=" text-center">
              <img
                src={EmptyList}
                alt=""
                style={{ width: "450px", height: "450px" }}
              />

              <h6 style={{}}>No Contents Found !</h6>
            </div>
          ) : (
            <>
              {trainingHub === null || loader ? (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                  }}
                >
                  <Spinner animation="grow" />
                </div>
              ) : (
                <div className="mt-2">
                  <InfiniteScroll
                    pageStart={0}
                    // dataLength={totalCount}
                    // next={loadFunc}
                    loadMore={loadFunc}
                    hasMore={hasNextpage}
                    loader={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="loader"
                        key={0}
                      >
                        <Spinner animation="grow" />
                      </div>
                    }
                  >
                    {trainingHub?.map((data, index) => (
                      <SupportCard
                        data={data}
                        key={index}
                        editModalHandler={editModalHandler}
                        deleteHandler={deleteHandler}
                        enableDisableHandler={enableDisableHandler}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default TrainingHub;
