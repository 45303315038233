import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
// import { setScreens } from "../../../store/screenSlice";
import {
  screenGroupScreenStatusUpdater,
  setScreenGroup,
  setScreenGroupScreens,
  setScreens,
} from "../../../../../store/screenSlice";
import { DragDropContext } from "react-beautiful-dnd";
import "./screen.css";
import RightComponent from "./RightSideComponent";
import Error from "../../../../../helpers/Error";
import { Toastr, topRightToastr } from "../../../../../helpers/Alert";
import classnames from "classnames";
import { authAxios } from "../../../../../axiosInstance";
import TopComponent from "./TopComponent";
import { useParams } from "react-router-dom";
import AbaciLoader from "../../../../../components/AbaciLoader/AbaciLoader";
import ScreenDroppableComponent from "./ScreenDroppableComponent";
import ScreenOnlineStatus from "../ScreenDetailView/ScreenOnlineStatus";
import RecentActivityFeed from "../ScreenDetailView/RecentActivityFeed";
import { WebsocketContext } from "../../../../../context/WebsocketContext";
import {
  setCurrentPage,
  setSearchDisabled,
  setSearchkeyWord,
} from "../../../../../store/searchSlice";
import CastScreenModal from "../../CastScreenModal";
export default function ScreenGroupDetail() {
  const screenDetails = useContext(WebsocketContext);

  const [activeTabJustify, setActiveTabJustify] = useState("0");
  const { id } = useParams();
  const [screenGroupData, setScreenGroupData] = useState(null);
  const screenList = useSelector((state) => state.screenSlice.screens);
  const screenGroupScreens = useSelector(
    (state) => state.screenSlice.screenGroupScreens
  );
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [item, setItem] = useState(null);
  const [isCastScreenModalVisible, setIsCastScreenModalVisible] =
    useState(false);

  const dispatch = useDispatch();
  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    // console.log(screenDetails)
    // dispatch(updateScreenStatus(screenDetails.screens))
    if (screenGroupScreens?.length !== 0) {
      if (screenDetails?.messageType === "single_screen_status") {
        let screen_id = screenDetails?.screens[0].screen_id;
        if (screenGroupScreens?.some((item) => item.screen_id === screen_id)) {
          dispatch(screenGroupScreenStatusUpdater(screenDetails?.screens[0]));

          if (screenDetails?.screens[0]?.online_status) {
            topRightToastr(
              "success",
              `${screenDetails?.screens[0].screen_name} is Online !!`
            );
          } else {
            topRightToastr(
              "error",
              `${screenDetails?.screens[0].screen_name} is Offline !!`
            );
          }
        }
      }
      // dataFetcher()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenDetails]);

  useEffect(() => {
    //Done inorder to activate or deactivate search box input
    dispatch(setCurrentPage("screen_group_details"));
    dispatch(setSearchDisabled(true));
    dispatch(setSearchkeyWord(null));

    window.addEventListener("resize", handleResize);

    dataFetcher();

    return () => {
      dispatch(setSearchDisabled(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataFetcher = () => {
    const url = `/api/signage/screengrouprudview/${id}`;

    authAxios
      .get(url)
      .then((res) => {
        // const temp=res?.data?.results?.map((data)=>({...data,uniqueID:'files'+nanoid()}))

        setScreenGroupData(res.data);
        dispatch(setScreenGroupScreens(res?.data?.screen_screengroup));
      })
      .catch((error) => {
        let err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      //   localStorage.setItem("screenGroupTab", tab);
      setActiveTabJustify(tab);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setScreenGroup(null));
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      // If the component is dragged to non droppable area, this condition will run and nothing will happen
      return;
    } else if (source.droppableId === destination.droppableId) {
      // If the components are dragging and dropping inside the same droppable area, this will execute
      if (source.droppableId === "NonDroppable") {
        // if the components are dragged inside the collections area, no actions are required
        return;
      } else {
      }
    } else {
      // If the drag is between two droppable area, this will be executed
      if (destination.droppableId === "NonDroppable") {
        // if the drag is from the dropppable bag to collections, no actions are required
        return;
      } else {
        // console.log(source,destination,res)
        //Drag and drop logic

        // taking an backup of current screengroupscreen list and screen data inorder to revert to current state if
        // any error occurs during the submission of data to backend
        let oldScreenGroupScreenBackUp = screenGroupScreens;
        let oldScreenListBackUp = screenList;

        //  then we take an copy of the screenList , then remove the screen which was dropped on to an screenGroup
        //  then update the redux store with the newly made set of data

        let newScreenArray = [...screenList];

        newScreenArray.splice(Number(result.draggableId), 1);

        dispatch(setScreens(newScreenArray));

        // then we create a variable for updating the screen list tagged with the respective group
        // by adding  dragged screen to the variable and spread old data to it and update the redux state

        const newScreenGroupScreenArray = [
          screenList[Number(result.draggableId)],
          ...screenGroupScreens,
        ];

        dispatch(setScreenGroupScreens(newScreenGroupScreenArray));

        const dataForSubmission = {
          name: screenGroupData.name,
          screen_ids: newScreenGroupScreenArray.map((data) => data.id),
        };

        let url = `/api/signage/screengrouprudview/${screenGroupData.id}`;

        authAxios
          .patch(url, dataForSubmission)
          .then((res) => {})
          .catch((err) => {
            dispatch(setScreenGroupScreens(oldScreenGroupScreenBackUp));
            dispatch(setScreens(oldScreenListBackUp));
            const err_msg = Error(err);
            Toastr("error", err_msg);
          });
      }
    }
  };

  const castScreenModalToggle = (item) => {
    setItem(item);
    setIsCastScreenModalVisible(!isCastScreenModalVisible);
  };

  return (
    <React.Fragment>
      {isCastScreenModalVisible && (
        <CastScreenModal
          isModalVisible={isCastScreenModalVisible}
          setIsModalVisible={setIsCastScreenModalVisible}
          item={item}
          isScreen={true}
        />
      )}

      {screenGroupData === null ? (
        <AbaciLoader />
      ) : (
        <>
          <Card
            style={{ height: "105px", marginTop: "71px", marginBottom: "1px" }}
          >
            <CardBody>
              <TopComponent data={screenGroupData} />
            </CardBody>
          </Card>

          <div className="p-2">
            <Col xl={24}>
              <DragDropContext onDragEnd={onDragEnd}>
                <div
                  style={{
                    width: "100%",
                    height: "calc(100vh - 200px)",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div className="screenGroup_droppable-components-wrapper">
                    <Card
                      style={{
                        minHeight: Math.round(windowHeight - 260),
                        maxHeight: Math.round(windowHeight - 260),
                      }}
                    >
                      <CardBody>
                        <Nav
                          tabs
                          className="nav-tabs-custom nav-justified"
                          fill
                        >
                          <NavItem style={{ color: "#1F2027" }}>
                            <NavLink
                              className={classnames({
                                active: activeTabJustify === "0",
                              })}
                              onClick={() => {
                                toggleCustomJustified("0");
                              }}
                            >
                              <span className=" d-sm-block">Screens</span>
                            </NavLink>
                          </NavItem>

                          {/* <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={classnames({
                          active: activeTabJustify === "1",
                        })}
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className="d-sm-block">Screen Online Status</span>
                      </NavLink>
                    </NavItem> */}

                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTabJustify === "1",
                              })}
                              onClick={() => {
                                toggleCustomJustified("1");
                              }}
                            >
                              <span className="d-sm-block">
                                Recent Activity Status
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTabJustify}>
                          <TabPane tabId="0" className="p-3">
                            {activeTabJustify === "0" && (
                              <>
                                <ScreenDroppableComponent
                                  windowHeight={windowHeight}
                                  screenGroupData={screenGroupData}
                                />
                              </>
                            )}
                          </TabPane>
                          {/* <TabPane tabId="1" className="p-3">
                      {activeTabJustify === "1" && (
                        <>
                        <div >
                            <ScreenOnlineStatus data={[]} isScreenGroup={true}/>
                        </div>
                        </>
                      )}
                    </TabPane> */}
                          <TabPane tabId="1" className="p-3">
                            {activeTabJustify === "1" && (
                              <>
                                <RecentActivityFeed
                                  data={[]}
                                  isScreenGroup={true}
                                  windowHeight={windowHeight}
                                />
                              </>
                            )}
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </div>

                  {/* RightSide Drag & Drop Component and Search Bars */}

                  <RightComponent
                    castScreenModalToggle={castScreenModalToggle}
                  />
                </div>
              </DragDropContext>
            </Col>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
