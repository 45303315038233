import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from "../containers/Pages/Dashboard/index";
import LandingPage from "../containers/PublicPage/PublicHome/PublicHome";
import MediaFiles from "../containers/Pages/MediaFiles/MediaFiles";
import Templates from "../containers/Pages/Template/Templates";
import TemplatesCreator from "../containers/Pages/Template/TemplateCreator";
import Apps from "../containers/Pages/Apps/Apps";
import Canvas from "../containers/Pages/Canvas/Canvas";
import UserSettings from "../containers/Pages/UserSettings/UserSettings";
import Schedulers from "../containers/Pages/Scheduler/Schedulers";
import Scheduler from "../containers/Pages/Scheduler/Scheduler";
import Screens from "../containers/Pages/Screens/Screens";
// import Template from "../containers/Pages/Template/Template.js";
import Contact from "../containers/PublicPage/Contact/Contact";
import Demo from "../containers/PublicPage/Demo/Demo";
import AppInstance from "../containers/Pages/Apps/clock/Clock.js";
import ClockInstance from "../containers/Pages/Apps/clock/CreateClock.js";
import CreateWeather from "../containers/Pages/Apps/weather/createWeather.js";
import Weather from "../containers/Pages/Apps/weather/Weather.js";
import Youtube from "../containers/Pages/Apps/youtube/Youtube";
import Texteditor from "../containers/Pages/Apps/Titles/Titles.js";
import CreateClock from "../containers/Pages/Apps/clock/CreateClock";
import CreateWeathers from "../containers/Pages/Apps/weather/createWeather";
import CreateYoutube from "../containers/Pages/Apps/youtube/CreateYouTubeInstance";
// import SignUp from '../containers/PublicPage/Authentication/SignUp';
// import OtpVerification from '../containers/PublicPage/Authentication/Otp_verification';
// import NewRegistration from "../containers/PublicPage/Registration/NewRegistration";
// import TryNowLogin from "../containers/PublicPage/Registrations/TryNowLogin";
import Registration from "../containers/PublicPage/Registrations/Registration";
import RegistrationGoogle from "../containers/PublicPage/Registrations/RegistrationGoogle";
import PrivacyPolicy from "../containers/PublicPage/PublicHome/PrivacyPolicy";
import Login from "../containers/PublicPage/Authentification/Login";
import SocialLogin from "../containers/PublicPage/Authentification/LoginSocial";
import Customer from "../containers/Pages/UserManagement/Customer/Customers";
import { CustomerAssetsDetails } from "../containers/Pages/UserManagement/Customer/CustomerAssetsDetails";
import WebLinks from "../containers/Pages/WebLinks/WebLink";
import { Test } from "../containers/Pages/Test";
import FabricEditor from "../containers/FabricEditor/index";
import TermsConditions from "../containers/PublicPage/PublicHome/Terms_Conditions";
import CustomerDetails from "../containers/Pages/UserManagement/Customer/CustomerDetails";
import CustomerInvoices from "../containers/Pages/UserManagement/Customer/CustomerInvoices";
import UsersInvoiceList from "../containers/Pages/UserManagement/UsersInvoiceList";
import Home from "../containers/Pages/Home";
import ForgotPassword from "../containers/PublicPage/Authentification/ForgotPassword";
import ScreenManagment from "../containers/Pages/Screens/ScreenManagment/Screenmanagment";
import ScreenDetailView from "../containers/Pages/Screens/ScreenManagment/ScreenDetailView/ScreenDetailView";
import ScreenGroupDetail from "../containers/Pages/Screens/ScreenManagment/ScreenGroupDetailView/ScreenGroupDetailView";
import ScreenManagementTest from "../containers/Pages/Screens/ScreenManagment/ScreenManagementTest";
import { SupportPage } from "../containers/PublicPage/Support/SupportPage";
import HelpCenter from "../containers/Pages/Support/HelpCenter/HelpCenter";
import EditHelpCenter from "../containers/Pages/Support/HelpCenter/EditHelpCenterPage";
import TrainingHub from "../containers/Pages/Support/TrainingHub/TrainingHub";
import EditTrainingHubPage from "../containers/Pages/Support/TrainingHub/EditTrainingHubPage";
import ContactUs from "../containers/PublicPage/Support/ContactUs";
import WebHelpCenter from "../containers/PublicPage/Support/WebHelpCenter";
import TrainingHubHeading from "../containers/Pages/Support/TrainingHub/TrainingHubHeading";
// import AccountCreation from "../containers/PublicPage/Authentification/AccountCreation";
const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    appType: ["Admin"],
    sidebar_presence: true,
    // icon_class: null,
    icon_class: "ri-dashboard-line",
    name: "Dashboard",
    layout_wrapper_required: true,
  },
  // {
  //   path: "/home",
  //   component: <Home />,
  //   appType: ["Admin"],
  //   sidebar_presence: true,
  //   // icon_class: null,
  //   icon_class: "ri-dashboard-line",
  //   name: "",
  //   layout_wrapper_required: true,
  // },
  // {
  //   path: "/screensTest",
  //   component: <ScreenManagementTest />,
  //   appType: ["Simple start", "Essentials", "Professional"],
  //   sidebar_presence: true,
  //   icon_class: "ri-tv-line",
  //   name: "ScreensSSSS",
  //   layout_wrapper_required: true,
  // },
  {
    path: "/screendetail/:id",
    component: <ScreenDetailView />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: "ri-tv-line",
    name: "Screens",
    layout_wrapper_required: true,
  },
  {
    path: "/screengroupdetail/:id",
    component: <ScreenGroupDetail />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: "ri-tv-line",
    name: "Screens",
    layout_wrapper_required: true,
  },
  {
    path: "/screens",
    component: <ScreenManagment />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: true,
    icon_class: "ri-tv-line",
    name: "Screens",
    layout_wrapper_required: true,
  },
  {
    path: "/schedulers",
    component: <Schedulers />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: true,
    icon_class: "ri-calendar-line",
    name: "Scheduler",
    layout_wrapper_required: true,
  },
  {
    path: "/scheduler/:id",
    component: <Scheduler />,
    appType: ["Simple start", "Essentials", "Professional"],
    // sidebar_presence: true,
    icon_class: "ri-calendar-line",
    name: "Scheduler",
    layout_wrapper_required: true,
  },
  {
    path: "/mediafiles",
    component: <MediaFiles />,
    appType: ["Admin", "Simple start", "Essentials", "Professional"],
    sidebar_presence: true,
    icon_class: "ri-film-fill",
    name: "Media files",
    layout_wrapper_required: true,
  },
  {
    path: "/canvaspage",
    component: <Canvas />,
    appType: ["Simple start", "Admin", "Essentials", "Professional"],
    sidebar_presence: true,
    icon_class: "ri-tv-2-line",
    name: "Canvas",
    layout_wrapper_required: true,
  },
  {
    path: "/canvascreator/:id",
    component: <FabricEditor />,
    appType: ["Admin", "Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: "ri-tv-2-line",
    name: "Canvas Creator",
    layout_wrapper_required: false,
  },
  {
    path: "/templates",
    component: <Templates />,
    appType: ["Admin", "Simple start", "Essentials", "Professional"],
    sidebar_presence: true,
    icon_class: "ri-stack-fill",
    name: "Templates",
    layout_wrapper_required: true,
  },
  {
    path: "/templatescreator/:id",
    component: <TemplatesCreator />,
    appType: ["Admin", "Simple start", "Essentials", "Professional"],
    // sidebar_presence: true,
    icon_class: "ri-stack-fill",
    name: "Templates",
    layout_wrapper_required: false,
  },
  {
    path: "/weblinks",
    component: <WebLinks />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: true,
    icon_class: "ri-link-m",
    name: "Weblinks",
    layout_wrapper_required: true,
  },
  // {
  //   path: "/usersettings",
  //   component: <UserSettings />,
  //   appType: ["Admin"],
  //   sidebar_presence: true,
  //   icon_class: "ri-user-settings-fill",
  //   name: "User Settings",
  //   layout_wrapper_required: true,
  // },
  {
    path: "/apps",
    component: <Apps />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: true,
    icon_class: "ri-dashboard-line",
    name: "Apps",
    layout_wrapper_required: true,
  },
  {
    path: "/customer",
    component: <Customer />,
    appType: ["Admin"],
    sidebar_presence: true,
    icon_class: "ri-user-settings-line",
    name: "Customers",
    layout_wrapper_required: true,
  },
  // {
  //   path: "/apps/:id",
  //   component: <AppInstance />,
  //   appType: ["Admin", "Simple start", "Essentials", "Professional"],
  //   sidebar_presence: false,
  //   icon_class: null,
  //   name: "Home",
  //   layout_wrapper_required:true,
  // },
  {
    path: "/clock",
    component: <AppInstance />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: true,
  },
  {
    path: "/apps/instanceCreator/:id",
    component: <ClockInstance />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: false,
  },
  {
    path: "/weather/weatherCreator/:id",
    component: <CreateWeather />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: true,
  },
  {
    path: "/weather",
    component: <Weather />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: true,
  },
  {
    path: "/youtube",
    component: <Youtube />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: true,
  },
  {
    path: "/texts",
    component: <Texteditor />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: true,
  },
  {
    path: "/createClock",
    component: <CreateClock />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: false,
  },
  {
    path: "/createweather",
    component: <CreateWeathers />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: false,
  },
  {
    path: "/createyoutube",
    component: <CreateYoutube />,
    appType: ["Simple start", "Essentials", "Professional"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: false,
  },
  {
    path: "/customerdetails/:id",
    component: <CustomerDetails />,
    appType: ["Admin"],
    sidebar_presence: false,
    icon_class: null,
    name: "Home",
    layout_wrapper_required: true,
  },
  {
    path: "/customerassets/:id",
    component: <CustomerAssetsDetails />,
    appType: ["Admin"],
    sidebar_presence: false,
    icon_class: null,
    name: "",
    layout_wrapper_required: true,
  },
  {
    path: "/customerinvoices",
    component: <CustomerInvoices />,
    appType: ["Admin"],
    sidebar_presence: false,
    icon_class: null,
    name: "",
    layout_wrapper_required: true,
  },

  {
    path: "/invoices",
    component: <UsersInvoiceList />,
    appType: ["Admin"],
    sidebar_presence: true,
    // icon_class: null,
    icon_class: "ri-file-text-fill",
    name: "Invoices",
    layout_wrapper_required: true,
  },
  {
    path: "/helpcenter",
    component: <HelpCenter />,
    appType: ["Admin"],
    sidebar_presence: false,
    // icon_class: null,
    icon_class: "ri-file-text-fill",
    name: "Invoices",
    layout_wrapper_required: true,
  },
  {
    path: "/traininghub",
    component: <TrainingHub />,
    appType: ["Admin"],
    sidebar_presence: false,
    // icon_class: null,
    icon_class: "ri-file-text-fill",
    name: "Invoices",
    layout_wrapper_required: true,
  },
  {
    path: "/traininghubheading",
    component: <TrainingHubHeading />,
    appType: ["Admin"],
    sidebar_presence: false,
    // icon_class: null,
    icon_class: "ri-file-text-fill",
    name: "Invoices",
    layout_wrapper_required: true,
  },
  {
    path: "/traininghub/:id",
    component: <EditTrainingHubPage />,
    appType: ["Admin"],
    sidebar_presence: false,
    // icon_class: null,
    icon_class: "ri-file-text-fill",
    name: "Invoices",
    layout_wrapper_required: true,
  },
  {
    path: "/helpcenter/:id",
    component: <EditHelpCenter />,
    appType: ["Admin"],
    sidebar_presence: false,
    // icon_class: null,
    icon_class: "ri-file-text-fill",
    name: "Invoices",
    layout_wrapper_required: true,
  },

  {
    path: "/#",
    icon_class: "ri-customer-service-fill",
    name: "Support",
    sidebar_presence: true,
    layout_wrapper_required: true,
    appType: ["Admin"],
    submenu: true,
    submenu_components: [
      {
        name: "Training Hub Title",
        // icon_class: "ri-customer-service-fill",
        path: "/traininghubheading",
      },
      {
        name: "Training Hub",
        // icon_class: "ri-customer-service-fill",
        path: "/traininghub",
      },
      {
        name: "Help Center",
        // icon_class: "ri-customer-service-fill",
        path: "/helpcenter",
      },
    ],
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/social_login", component: <SocialLogin /> },
  { path: "/", component: <LandingPage /> },
  { path: "/register", component: <Registration /> },
  { path: "/register_google", component: <RegistrationGoogle /> },
  { path: "/contact", component: <Contact /> },
  { path: "/demo", component: <Demo /> },
  { path: "/termsandconditions", component: <TermsConditions /> },
  { path: "/privacy", component: <PrivacyPolicy /> },
  { path: "/test", component: <Test /> },
  { path: "/forgotpassword", component: <ForgotPassword /> },
  { path: "/webtraininghub", component: <SupportPage /> },
  { path: "/webhelpcenter", component: <WebHelpCenter /> },
  { path: "/contactus", component: <ContactUs /> },
];

export { authProtectedRoutes, publicRoutes };
