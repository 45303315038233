import React from 'react'
import { Col, Row } from 'reactstrap'

import LeftScreenDetails from './LeftScreenDetails';
import RecentActivityFeed from './RecentActivityFeed';
import ScreenOnlineStatus from './ScreenOnlineStatus';
function DetailComponent({data}) {
    
  return (
    <div className='p-2'>
        <Row>
            <Col xl={4}>
                <LeftScreenDetails data={data} />
            </Col>

            <Col xl={3}>
                <ScreenOnlineStatus data={data?.screen_online_offline} isScreenGroup={false}/>
            </Col>
            <Col xl={5}>
              <RecentActivityFeed />
            </Col>
        </Row>
        </div>
  )
}

export default DetailComponent