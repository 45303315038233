import React from "react";
// import { Link } from "gatsby";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { Link as ScrollLink } from "react-router-dom";

const SupportNavLinks = (props) => {
  const handleDropdownStatus = (e) => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
  };
  return (
    <ul className="main-nav__navigation-box one-page-scroll-menu">
      <li>
        <ScrollLink
          to='/webtraininghub'
        >
          Training Hub
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          style={{ textDecoration: "none" }}
      
          to="/webhelpcenter"
       
        >
          Help Center
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          style={{ textDecoration: "none" }}
    
          to="/contactus"
     
        >
          Contact Us
        </ScrollLink>
      </li>

      {/* <button className="dropdown-btn" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-right"></i>
        </button> */}
      <ul className="dropdown-list">
        <li>
          {/* <Link to="/blog">
              News
            </Link> */}
        </li>
        <li>
          {/* <Link to="/blog-details">
              News Details
            </Link> */}
        </li>
      </ul>
      {/* </li> */}
    </ul>
  );
};

export default SupportNavLinks;
