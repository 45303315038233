import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  ButtonGroup,
  Container,
  Form,
  Input,
  Spinner,
} from "reactstrap";

import { WebsocketContext } from "../../../../../context/WebsocketContext";
import { deleteScreen, setScreens } from "../../../../../store/screenSlice";
import "./screen.css";
import NonDroppableScreenList from "./NonDroppableScreenList";
import { nanoid } from "@reduxjs/toolkit";
import { authAxios } from "../../../../../axiosInstance";

export default function RightComponent({ castScreenModalToggle }) {
  const dispatch = useDispatch();
  const [status] = useState(["", true, false]);
  const screenDetails = useContext(WebsocketContext);
  const screenList = useSelector((state) => state.screenSlice.screens);

  const [filterText, setFilterText] = useState("");
  const [activeTab, setActiveTab] = useState("0");
  const [limit, setLimit] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [hasNextpage, setHasNextPage] = useState(false);

  useEffect(() => {
    // console.log(screenDetails)
    // dispatch(updateScreenStatus(screenDetails.screens))
    if (screenDetails?.messageType === "single_screen_status") {
      dataFetcher();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenDetails]);

  function deleteHandler(cardId) {
    const deleteUrl = `/api/signage/screensrud/${cardId}`;
    authAxios.delete(deleteUrl).then((res) => dispatch(deleteScreen(cardId)));
  }

  useEffect(() => {
    dataFetcher();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, activeTab, searchText]);

  const dataFetcher = () => {
    const url = `api/signage/screenslistcreateview?limit=${limit}&offset=0&search=${searchText}&online_status=${status[activeTab]}&screen_group__isnull=true`;

    authAxios
      .get(url)
      .then((res) => {
        if (res.data.next !== null) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }

        const temp = res?.data?.results?.map((data) => ({
          ...data,
          uniqueID: "files" + nanoid(),
        }));
        setTotalCount(res?.data?.count);

        dispatch(setScreens(temp));
      })
      .catch((error) => {
        dispatch(setScreens([]));
      });
  };

  useEffect(() => {
    const search = setTimeout(() => {
      setSearchText(filterText);
    }, 500);
    return () => clearTimeout(search);
  }, [filterText]);

  const loadFunc = () => {
    // if (fileCollections?.length !== totalCount) {
    if (hasNextpage) {
      setTimeout(() => {
        setLimit(limit + 5);
      }, 1500);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setScreens(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (tab) => {
    setActiveTab(tab);
    setHasNextPage(false);
    // setOptionScreens([])
    dispatch(setScreens(null));
    setLimit(20);
  };

  return (
    <div className="screenGroup_rightComponent">
      <Form className="app-search d-none d-lg-block" style={{}}>
        <div className="position-relative" style={{ zIndex: "0" }}>
          <div
            style={{
              // width: "300px",
              backgroundColor: "#F1F5F7",
              //   paddingLeft: "10px",
              borderRadius: "30px",
              zIndex: "0",
            }}
          >
            <Input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
              style={{
                borderWidth: "0px",
                backgroundColor: "#fff",
                borderRadius: "30px",
              }}
            />
          </div>
        </div>
      </Form>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonGroup style={{ width: "90%", borderRadius: "10px" }}>
          <Button
            className={
              "button_group_class" + (activeTab === "0" ? " class_active " : "")
            }
            color="secondary"
            outline
            onClick={() => toggle("0")}
            style={{ marginRight: "5px", borderRadius: "10px" }}
            // active={props.currentActiveTab === '1'}
          >
            All
          </Button>
          <Button
            className={
              "button_group_class" + (activeTab === "1" ? " class_active " : "")
            }
            color="secondary"
            outline
            onClick={() => toggle("1")}
            style={{ marginRight: "5px", borderRadius: "10px" }}
            // active={props.currentActiveTab === '1'}
          >
            Online
          </Button>
          <Button
            color="secondary"
            outline
            // id='button_group_class'
            className={
              "button_group_class" + (activeTab === "2" ? " class_active " : "")
            }
            onClick={() => toggle("2")}
            // active={props.currentActiveTab === '2'}
            style={{ borderRadius: "10px" }}
          >
            Offline
          </Button>
        </ButtonGroup>
      </div>
      {screenList === null ? (
        <>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "88%",
            }}
          >
            <Spinner animation="grow" />
          </div>
        </>
      ) : (
        <div className="non-droppable-component-wrapper">
          <NonDroppableScreenList
            items={screenList}
            loadFunc={loadFunc}
            totalCount={totalCount}
            deleteHandler={deleteHandler}
            hasNextpage={hasNextpage}
            castScreenModalToggle={castScreenModalToggle}
          />
        </div>
      )}
    </div>
  );
}
