import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  // Input,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Modals from "../../../../components/Modal";
import { useForm } from "react-hook-form";
import { authAxios } from "../../../../axiosInstance";

export const EditCustomer = (props) => {
  const [waitingForAxios] = useState(false);

  // const dispatch = useDispatch();
  // const customer = useSelector((state) => state.customerSlice.customer);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
    let formData = new FormData(); //formdata object
    formData.append("name", data.name);
    formData.append("discount", data.discount);

    const url = `/abaciadmin_api/edit_tenant/${props.item.id}`;
    authAxios
      .patch(url, formData)
      .then((response) => {
        props.tableRef.current.onQueryChange({ page: 0 });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    if (props.item !== null) {
      reset(props.item);
    }
  }, [props]);

  return (
    <div>
      <Modals isOpen={props.isOpen} toggle={props.toggle} title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Customer Name" sm={3}>
              Customer Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={9}>
              <input
                placeholder="Customer Name"
                type="text"
                className="form-control"
                {...register("name", {
                  required: true,
                })}
              />
              {errors.name?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
          {/* <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Address" sm={3}>
              Address <span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={9}>
              <input
                placeholder="Address"
                type="text"
                className="form-control"
                {...register("address", {
                  required: true,
                })}
              />
              {errors.address?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup> */}
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Contact Number" sm={3}>
              Contact Number <span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={9}>
              <input
                placeholder="Contact Number"
                type="text"
                className="form-control"
                {...register("contact_number", {
                  required: true,
                })}
              />
              {errors.contact_number?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Discount" sm={3}>
              Discount
            </Label>
            <Col sm={9}>
              <input
                placeholder="Discount"
                type="number"
                className="form-control"
                {...register("discount", {
                  required: true,
                })}
              />
              {errors.discount?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Email Id" sm={3}>
              Email Id <span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={9}>
              <input
                placeholder="Email Id"
                type="text"
                className="form-control"
                {...register("email", {
                  required: true,
                })}
              />
              {errors.email?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <ModalFooter>
            <Button
              type="submit"
              className="w-xs waves-effect waves-light me-1"
              onClick={() => {
                onSubmit();
              }}
            >
              {waitingForAxios ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              type="button"
              color="danger"
              className="w-xs waves-effect waves-light me-1"
              onClick={props.toggle}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modals>
    </div>
  );
};
