import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import { authAxios } from "../../../axiosInstance";
// import { addAssetDetails } from "../../../store/assetDetails";
// import Medias from "./Medias";
import EmptyList from "../../../assets/SVG/Search Not Found 2.svg";

import { CardBody, Spinner } from "reactstrap";
import "./MediaFiles.css";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  // Button,
  // CardTitle,
  CardText,
  Row,
  Col,
  Container,
  // CardSubtitle,
  // CardImg,
} from "reactstrap";
import classnames from "classnames";
// import AddIcon from "@material-ui/icons/Add";
// import Fab from "@material-ui/core/Fab";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import MediaLists from "./MediaLists";
import {
  deleteImage,
  deleteVideo,
  setImages,
  // setMedias,
  setVideos,
  // addNewImage,
  // addNewVideo
} from "../../../store/mediaSlice";
import { CustomButton } from "../../../components/CustomButton";
import MediaCard from "./MediaCard";
import StockMediaCard from "./StockMediaCard";
import InfiniteScroll from "react-infinite-scroller";
import DropZone from "./DropZone";
// import { uniqueID } from "../../../helpers/uniqueIDGenerator";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
// import ImageViewer from "./MediaViewer";
import MediaViewer from "./MediaViewer";
import CastContentModal from "../../../components/CastContentModal";

function MediaFiles() {
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);

  const [unmounted, setUnMounted] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [image, setImage] = useState(null);
  const [searchKeyCount,setSearchKeyCount] = useState(0)
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);

  const [isCastScreenModalVisible, setIsCastScreenModalVisible] =
    useState(false);
  const [item, setItem] = useState(null);
  const ModalToggle = (card) => {
    // console.log(card);
    setImage(card);
    setIsModalVisible(!isModalVisible);
  };
  const dispatch = useDispatch();
  const [uploadActive, setUploadActive] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState(
    localStorage.getItem("mediaFilesTabKey") != null
      ? localStorage.getItem("mediaFilesTabKey")
      : "5"
  );
  const [totalCount, setTotalCount] = useState(0);
  const [hasNextpage, setHasNextPage] = useState(false);

  const [limit] = useState(20);
  const [page, setPage] = useState(1);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "Media Files", link: "#" },
    ],
  });

  const uploadToggle = () => {
    setUploadActive(!uploadActive);
  };

  const MediaFilesUserImages = useSelector(
    (state) => state.mediaSlice.user_images
  );
  const MediaFilesUserVideos = useSelector(
    (state) => state.mediaSlice.user_videos
  );

  useEffect(() => {
   
    // if(searchKey !==null){
    setPage(1);
    setTotalCount(0);
    setHasNextPage(false)

    if (activeTabJustify === "5" || activeTabJustify === "7") {
      //inorder to clear the user image and stock images
      dispatch(setImages(null));
    } else if (activeTabJustify === "6" || activeTabJustify === "8") {
      //inorder to clear the user video and stock video
      dispatch(setVideos(null));
    }

    if(searchKey !== prevSearchKey){
      setPrevSearchKey(searchKey)
    }
  // }
  setSearchKeyCount(searchKey !==null ? searchKey.length : 0)

},[searchKey])


  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    let url;

    // Condition for changing the url inorder to make it work on both tenant side and admin side
    // As this page is used both on the admin side and tenant side,Admin uploaded files are stock images and videos
    // which user can make an copy to their images or videos and can be used.
    if (tenantDetails?.app_type === "Admin") {
      url = `/stock/api/signage/fileslistcreateview?limit=${limit}&offset=${
        limit * (page - 1)
      }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}&file_type=`;
    } else {
      url = `/api/signage/fileslistcreateview?limit=${limit}&offset=${
        limit * (page - 1)
      }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}&file_type=`;
    }

    if (activeTabJustify === "5") {
      authAxios
        .get(url + "image")
        .then((response) => {
          // if(page === 1){
          if (!unmounted) {
            // flag for making call to backend if there is more data to be loaded,
            // if not state is made to false
            if (response.data.next !== null && response.data.length !== 0) {
              setHasNextPage(true);
            } else {
              setHasNextPage(false);
            }

            // condition written for loading data on first load ,after first load if there is more data
            // the offset is changed and next set of data are loaded then the else condition is used
            // inorder to append data the already existing datas
            if (MediaFilesUserImages === null) {
              dispatch(setImages(response.data.results));
            } else {
              dispatch(
                setImages([...MediaFilesUserImages, ...response.data.results])
              );
            }

            setTotalCount(response.data.count);
          }
        })
        .catch((error) => {
          Toastr("error", "Oops!,Something Went Wrong");
        });
    } else if (activeTabJustify === "6") {
      authAxios
        .get(url + "video")
        .then((response) => {
          if (!unmounted) {
            // flag for making call to backend if there is more data to be loaded,
            // if not state is made to false
            if (response.data.next && response.data.length !== 0) {
              setHasNextPage(true);
            } else {
              setHasNextPage(false);
            }

            // condition written for loading data on first load ,after first load if there is more data
            // the offset is changed and next set of data are loaded then the else condition is used
            // inorder to append data the already existing datas
            if (MediaFilesUserVideos === null) {
              dispatch(setVideos(response.data.results));
            } else {
              dispatch(
                setVideos([...MediaFilesUserVideos, ...response.data.results])
              );
            }

            setTotalCount(response.data.count);
          }
        })
        .catch((error) => {
          Toastr("error", "Oops!,Something Went Wrong");
        });
    } else if (activeTabJustify === "7" || activeTabJustify === "8") {
      // code for loading stock image or video based on tab navigation

      let type = activeTabJustify === "7" ? "image" : "video";

      let stockUrl = `/api/signage/stockfileslistcreateview/public?limit=${limit}&offset=${
        limit * (page - 1)
      }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}&file_type=${type}`;

      authAxios
        .get(stockUrl)
        .then((response) => {
          if (!unmounted) {
            // flag for making call to backend if there is more data to be loaded,
            // if not state is made to false

            if (response.data.next && response.data.length !== 0) {
              setHasNextPage(true);
            } else {
              setHasNextPage(false);
            }

            if (type === "image") {
              // condition written for loading data on first load ,after first load if there is more data
              // the offset is changed and next set of data are loaded then the else condition is used
              // inorder to append data the already existing datas
              if (MediaFilesUserImages === null) {
                dispatch(setImages(response.data.results));
              } else {
                dispatch(
                  setImages([...MediaFilesUserImages, ...response.data.results])
                );
              }
            } else {
              // condition written for loading data on first load ,after first load if there is more data
              // the offset is changed and next set of data are loaded then the else condition is used
              // inorder to append data the already existing datas
              if (MediaFilesUserVideos === null) {
                dispatch(setVideos(response.data.results));
              } else {
                dispatch(
                  setVideos([...MediaFilesUserVideos, ...response.data.results])
                );
              }
            }

            setTotalCount(response.data.count);
          }
        })
        .catch((error) => {
          Toastr("error", "Oops!,Something Went Wrong");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabJustify, page,prevSearchKey]);

  useEffect(() => {
    // console.log(hasNextpage)
  }, [hasNextpage]);

  useEffect(() => {
    return () => {
      // done inorder to clear the redux stores and to remove event listener on when component unmounts
      dispatch(setVideos(null));
      dispatch(setImages(null));
      setUnMounted(true);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      localStorage.setItem("mediaFilesTabKey", tab);
      setActiveTabJustify(tab);
      setPage(1);
      setTotalCount(0);
      setHasNextPage(false);

      if (activeTabJustify === "5" || activeTabJustify === "7") {
        //inorder to clear the user image and stock images
        dispatch(setImages(null));
      } else if (activeTabJustify === "6" || activeTabJustify === "8") {
        //inorder to clear the user video and stock video
        dispatch(setVideos(null));
      }
    }
  };

  // function used inorder to change page and load more data upon user scrolling if there is more data
  const loadFunc = () => {
    if (hasNextpage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
  };

  // Function to Delete the Media File ie Images,Videos passed as
  // an prop to media card and called upon clicking the icon button
  const deleteHandler = (id, type) => {
    let url;
    if (tenantDetails?.app_type === "Admin") {
      url = `/stock/api/signage/filesrudview/${id}`;
    } else {
      url = `/api/signage/filesrudview/${id}`;
    }
    // const url = `/api/signage/filesrudview/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        if (type === "image") {
          dispatch(deleteImage(id));
          Toastr("success", "Image Deleted Successfully");
        } else {
          dispatch(deleteVideo(id));
          Toastr("success", "Video Deleted Successfully");
        }
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };


  //Template Copy Handler
  const copyTemplateHandler = (file_id) => {
    const url = `/api/signage/copystockfileslistcreateview?tenant=public&stockfile_id=${file_id}`;
    authAxios
      .post(url)
      .then((response) => {
        Toastr("success", "File Copied Successfully !!");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };
  const castScreenModalToggle = (item) => {
    setItem(item);
    setIsCastScreenModalVisible(!isCastScreenModalVisible);
  };

  return (
    <>
      <React.Fragment>
        {isModalVisible && (
          <MediaViewer
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            data={image}
          />
        )}
        {isCastScreenModalVisible && (
          <CastContentModal
            isModalVisible={isCastScreenModalVisible}
            setIsModalVisible={setIsCastScreenModalVisible}
            item={item}
          />
        )}

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Media Management"
              breadcrumbItems={dashboardState.breadcrumbItems}
            ></Breadcrumbs>
            <Col xl={24}>
              <Card style={{ minHeight: "70vh" }}>
                <CardBody>
                  {uploadActive ? (
                    <DropZone />
                  ) : (
                    <>
                      <Nav tabs className="nav-tabs-custom nav-justified" fill>
                        <NavItem style={{ color: "#1F2027" }}>
                          <NavLink
                            className={classnames({
                              active: activeTabJustify === "5",
                            })}
                            onClick={() => {
                              toggleCustomJustified("5");
                            }}
                          >
                            {/* <span className="d-none d-sm-block">My Images</span> */}
                            <span className=" d-sm-block">My Images</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{
                              cursor: "pointer",
                            }}
                            className={classnames({
                              active: activeTabJustify === "6",
                            })}
                            onClick={() => {
                              toggleCustomJustified("6");
                            }}
                          >
                            <span className="d-sm-block">My Videos</span>
                          </NavLink>
                        </NavItem>
                        {tenantDetails?.app_type !== "Admin" && (
                          <>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTabJustify === "7",
                                })}
                                onClick={() => {
                                  toggleCustomJustified("7");
                                }}
                              >
                                <span className="d-sm-block">Stock Images</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                // style={
                                //   activeTabJustify === "8"
                                //     ? {
                                //         cursor: "pointer",
                                //         borderBottom: " 2px solid #1F2027 ",
                                //         transition: "border-bottom ",
                                //         color: "#1F2027",
                                //       }
                                //     : {}
                                // }

                                className={classnames({
                                  active: activeTabJustify === "8",
                                })}
                                onClick={() => {
                                  toggleCustomJustified("8");
                                }}
                              >
                                <span className="d-sm-block">Stock Videos</span>
                              </NavLink>
                            </NavItem>
                          </>
                        )}
                      </Nav>

                      <TabContent activeTab={activeTabJustify}>
                        <TabPane tabId="5" className="p-3">
                          {activeTabJustify === "5" && (
                            <>
                              {MediaFilesUserImages === null ? (
                                <>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                    }}
                                  >
                                    <Spinner animation="grow" />
                                  </div>
                                </>
                              ) : MediaFilesUserImages?.length === 0 ? (
                                <>
                                  <div className=" text-center">
                                    <img
                                      src={EmptyList}
                                      alt=""
                                      style={{
                                        width: "450px",
                                        height: "450px",
                                      }}
                                    />
                                    <h6 style={{}}>No Medias Found !</h6>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <InfiniteScroll
                                    pageStart={0}
                                    // dataLength={totalCount}
                                    // next={loadFunc}
                                    loadMore={loadFunc}
                                    hasMore={hasNextpage}
                                    loader={
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        className="loader"
                                        key={0}
                                      >
                                        <Spinner animation="grow" />
                                      </div>
                                    }
                                  >
                                    <Row
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        maxHeight: Math.round(
                                          windowHeight - 313
                                        ),
                                        overflowY: "scroll",
                                        overflowX: "hidden",
                                      }}
                                    >
                                      {MediaFilesUserImages?.map(
                                        (items, index) => (
                                          <MediaCard
                                            key={index}
                                            item={items}
                                            deleteHandler={deleteHandler}
                                            ModalToggle={ModalToggle}
                                            is_admin={
                                              tenantDetails?.app_type ===
                                              "Admin"
                                                ? true
                                                : false
                                            }
                                            castScreenModalToggle={
                                              castScreenModalToggle
                                            }
                                          />
                                        )
                                      )}
                                    </Row>
                                  </InfiniteScroll>
                                </>
                              )}
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId="6" className="p-3">
                          {activeTabJustify === "6" && (
                            <>
                              {MediaFilesUserVideos === null ? (
                                <>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                    }}
                                  >
                                    <Spinner animation="grow" />
                                  </div>
                                </>
                              ) : MediaFilesUserVideos?.length === 0 ? (
                                <>
                                  <div className=" text-center">
                                    <img
                                      src={EmptyList}
                                      alt=""
                                      style={{
                                        width: "450px",
                                        height: "450px",
                                      }}
                                    />
                                    <h6 style={{}}>No Medias Found !</h6>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <InfiniteScroll
                                    pageStart={0}
                                    // dataLength={totalCount}
                                    // next={loadFunc}
                                    loadMore={loadFunc}
                                    hasMore={hasNextpage}
                                    loader={
                                      <div
                                        className="loader text-center mb-3"
                                        key={0}
                                      >
                                        <Spinner animation="grow" />
                                      </div>
                                    }
                                  >
                                    <Row
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        maxHeight: Math.round(
                                          windowHeight - 313
                                        ),
                                      }}
                                    >
                                      {MediaFilesUserVideos?.map(
                                        (items, index) => (
                                          // <Col key={index}>
                                          <MediaCard
                                            key={index}
                                            item={items}
                                            deleteHandler={deleteHandler}
                                            ModalToggle={ModalToggle}
                                            is_admin={
                                              tenantDetails?.app_type ===
                                              "Admin"
                                                ? true
                                                : false
                                            }
                                            castScreenModalToggle={
                                              castScreenModalToggle
                                            }
                                          />
                                          // </Col>
                                        )
                                      )}
                                    </Row>
                                  </InfiniteScroll>
                                </>
                              )}
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId="7" className="p-3">
                          {activeTabJustify === "7" && (
                            <>
                              {MediaFilesUserImages === null ? (
                                <>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                    }}
                                  >
                                    <Spinner animation="grow" />
                                  </div>
                                </>
                              ) : MediaFilesUserImages?.length === 0 ? (
                                <>
                                  <div className=" text-center">
                                    <img
                                      src={EmptyList}
                                      alt=""
                                      style={{
                                        width: "450px",
                                        height: "450px",
                                      }}
                                    />
                                    <h6 style={{}}>No Stock Medias Found !</h6>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <InfiniteScroll
                                    pageStart={0}
                                    // dataLength={totalCount}
                                    // next={loadFunc}
                                    loadMore={loadFunc}
                                    hasMore={hasNextpage}
                                    loader={
                                      <div
                                        className="loader text-center mb-3"
                                        key={0}
                                      >
                                        <Spinner animation="grow" />
                                      </div>
                                    }
                                  >
                                    <Row
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        maxHeight: Math.round(
                                          windowHeight - 313
                                        ),
                                      }}
                                    >
                                      {MediaFilesUserImages?.map(
                                        (items, index) => (
                                          // <Col key={index}>
                                          <StockMediaCard
                                            key={index}
                                            item={items}
                                            ModalToggle={ModalToggle}
                                            copyTemplateHandler={
                                              copyTemplateHandler
                                            }
                                          />
                                          // </Col>
                                        )
                                      )}
                                    </Row>
                                  </InfiniteScroll>
                                </>
                              )}
                            </>
                          )}
                        </TabPane>

                        <TabPane tabId="8" className="p-3">
                          {activeTabJustify === "8" && (
                            <>
                              {MediaFilesUserVideos === null ? (
                                <>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                    }}
                                  >
                                    <Spinner animation="grow" />
                                  </div>
                                </>
                              ) : MediaFilesUserVideos?.length === 0 ? (
                                <>
                                  <div className=" text-center">
                                    <img
                                      src={EmptyList}
                                      alt=""
                                      style={{
                                        width: "450px",
                                        height: "450px",
                                      }}
                                    />
                                    <h6 style={{}}>No Medias Found !</h6>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <InfiniteScroll
                                    pageStart={0}
                                    // dataLength={totalCount}
                                    // next={loadFunc}
                                    loadMore={loadFunc}
                                    hasMore={hasNextpage}
                                    loader={
                                      <div
                                        className="loader text-center mb-3"
                                        key={0}
                                      >
                                        <Spinner animation="grow" />
                                      </div>
                                    }
                                  >
                                    <Row
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        maxHeight: Math.round(
                                          windowHeight - 313
                                        ),
                                      }}
                                    >
                                      {MediaFilesUserVideos?.map(
                                        (items, index) => (
                                          // <Col key={index}>
                                          <StockMediaCard
                                            key={index}
                                            item={items}
                                            ModalToggle={ModalToggle}
                                            copyTemplateHandler={
                                              copyTemplateHandler
                                            }
                                          />
                                          // </Col>
                                        )
                                      )}
                                    </Row>
                                  </InfiniteScroll>
                                </>
                              )}
                            </>
                          )}
                        </TabPane>
                      </TabContent>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>

            <div>
              <CustomButton
                onClick={uploadToggle}
                title={uploadActive ? "-" : "+"}
              />
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}

export default MediaFiles;
