import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { addAssetDetails } from "../../../store/assetDetails";
// import Medias from "./Medias";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";
import {  Spinner } from "reactstrap";

import {Row} from "reactstrap";
import {
  deleteImage,
  deleteVideo,
} from "../../../../store/mediaSlice";
import InfiniteScroll from "react-infinite-scroller";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { authAxios } from "../../../../axiosInstance";
import ScreenGroupCard from "../ScreenCards/ScreenGroupCard";
import CastScreenModal from "../CastScreenModal";
import ScreenEditGroupModal from "../EditScreenGroupModal";
import { deleteScreenGroup } from "../../../../store/screenSlice";

function ScreenGroupTab({loadFunc,hasNextpage,windowHeight}) {
  const [item, setItem] = useState(null);
  const [isCastScreenModalVisible, setIsCastScreenModalVisible] =useState(false)
  const [itemToBeEdited, setItemToBeEdited] = useState(null);
  const [isGroupEditModalVisible, setIsGroupEditModalVisible] = useState(false);

    const dispatch=useDispatch();
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);
  const screenGroup=useSelector((state) => state.screenSlice.screenGroup)
  // Function to Delete the Media File ie Images,Videos passed as
  
  const deleteHandler = (grpId) => {
    const deleteUrl = `/api/signage/screengrouprudview/${grpId}`;

    authAxios
      .delete(deleteUrl)
      .then((res) => {
        dispatch(deleteScreenGroup(grpId));
        Toastr("success", "Screen Group Deleted Successfully");
      })
      .catch((err) => {
        const err_msg = Error(err);
        Toastr("error", err_msg);
      });
  };

  const castScreenModalToggle = (item) => {
    setItem(item);
    setIsCastScreenModalVisible(!isCastScreenModalVisible);
  };


  const editHandler = (item) => {
    setItemToBeEdited(item);
    setIsGroupEditModalVisible(!isGroupEditModalVisible);
  };

  return (
    <>
    {isGroupEditModalVisible && (
        <ScreenEditGroupModal
          isModalVisible={isGroupEditModalVisible}
          setIsModalVisible={setIsGroupEditModalVisible}
          item={itemToBeEdited}
        />
      )}
       {isCastScreenModalVisible && (
        <CastScreenModal
          isModalVisible={isCastScreenModalVisible}
          setIsModalVisible={setIsCastScreenModalVisible}
          item={item}
          isScreen={false}
        />
      )}
      <React.Fragment>
   
        {screenGroup === null || screenGroup === undefined ? (
            <>
                <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                }}
                >
                <Spinner animation="grow" />
                </div>
            </>
            ) : screenGroup?.length === 0 ? (
            <>
                <div className=" text-center">
                <img
                    src={EmptyList}
                    alt=""
                    style={{
                    width: "450px",
                    height: "450px",
                    }}
                />
                <h6 style={{}}>No Screen Groups Found !</h6>
                </div>
            </>
            ) : (
            <>
                <InfiniteScroll
                pageStart={0}
                // dataLength={totalCount}
                // next={loadFunc}
                loadMore={loadFunc}
                hasMore={hasNextpage}
                loader={
                    <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                    className="loader"
                    key={0}
                    >
                    <Spinner animation="grow" />
                    </div>
                }
                >
                <Row
                    style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    maxHeight: Math.round(
                        windowHeight - 313
                    ),
                    overflowY: "scroll",
                    overflowX: "hidden",
                    }}
                >
                    {screenGroup?.map(
                    (items, index) => (
                        <ScreenGroupCard
                        key={index}
                        item={items}
                        deleteHandler={deleteHandler}
                        editHandler={editHandler}
                        castScreenModalToggle={castScreenModalToggle}
                        />
                    )
                    )}
                </Row>
                </InfiniteScroll>
            </>
            )}
                      
       
      </React.Fragment>
    </>
  );
}

export default ScreenGroupTab;
