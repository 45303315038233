import React,{ useEffect, useState } from 'react'
import YouTube from "react-youtube";
var getYouTubeID = require("get-youtube-id");


function YoutubeComponent({currentPlayingItem,width,height,durationRemaining}) {

    const [id, setId] = useState("");
    const [options,setOptions] = useState({
      height: height,
      width: width,
      playerVars: {
        mute: `${currentPlayingItem?.object?.isMuted ? 1 : 0}`,
        autoplay: 1,
        start:currentPlayingItem.durationRemaining
       }})
  // console.log(durationRemaining)

  

    useEffect(() => {

        const temp = getYouTubeID(currentPlayingItem?.object?.link);
        // console.log(temp)
        setId(temp);

        console.log(currentPlayingItem.durationRemaining)

        setOptions((state) => ({...state,start:currentPlayingItem.durationRemaining}))

    }, [])



    
    


  return (
    <div className="mt-4">
    <YouTube videoId={id} opts={options} />;
  </div>
  )
}

export default YoutubeComponent