import { Container, Spinner } from "reactstrap";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import SupportCard from "../../../../components/SupportCard";
import { CustomButton } from "../../../../components/CustomButton";
// import AddHelpCenterModal from "./AddHelpCenterModal";
import {
  AddTrainingHubHeading,
  DeleteTrainingHub,
  DeleteTrainingHubHeading,
  EditTrainingHubHeading,
} from "../../../../store/supportSlice";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { authAxios } from "../../../../axiosInstance";
import AddEditTrainingHubHeading from "./AddEditTrainingHubHeading";
function TrainingHubHeading() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [itemToBeEdited, setItemToBeEdited] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const trainingHubHeading = useSelector(
    (state) => state.supportSlice.trainingHubHeading
  );
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "/dashboard" },
      { title: "Training Hub Title", link: "#" },
    ],
  });

  const editModalHandler = (data) => {
    setIsEdit(true);
    setItemToBeEdited(data);
    setModalShow(!modalShow);
  };

  const deleteHandler = (id) => {
    const url = `/abaciadmin_api/traininghubmainheading/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        dispatch(DeleteTrainingHubHeading(id));
        Toastr("success", "Content deleted succesfully");
      })
      .catch((error) => {
        let error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };

  useEffect(() => {
    const url = `/abaciadmin_api/traininghubmainheading`;

    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);

        dispatch(AddTrainingHubHeading(response.data));
      })
      .catch((error) => {
        setLoader(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });

    return () => {
      dispatch(AddTrainingHubHeading(null));
    };
  }, []);

  function ModalToggle() {
    if (isEdit) {
      setIsEdit(false);
      setModalShow(!modalShow);
    } else {
      setModalShow(!modalShow);
    }
  }

  const enableDisableHandler = (data) => {
    const status = data.status === "Active" ? "Disabled" : "Active";
    const url = `/abaciadmin_api/traininghubmainheading/${data.id}`;
    axios
      .patch(url, { status: status })
      .then((response) => {
        dispatch(EditTrainingHubHeading(response.data));

        Toastr("success", "Content updated succesfully");
      })
      .catch((error) => {
        let error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };

  return (
    <React.Fragment>
      {modalShow && (
        <AddEditTrainingHubHeading
          isOpen={modalShow}
          toggle={ModalToggle}
          title={isEdit ? "Edit Title" : "Add Title"}
          item={itemToBeEdited}
          isEdit={isEdit}
        />
      )}
      <div className="page-content">
        <CustomButton title="+" onClick={ModalToggle} />
        <Container fluid>
          <Breadcrumbs
            title="Training Hub Title"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>

          {trainingHubHeading?.length === 0 ? (
            <div className=" text-center">
              <img
                src={EmptyList}
                alt=""
                style={{ width: "450px", height: "450px" }}
              />

              <h6 style={{}}>No Contents Found !</h6>
            </div>
          ) : (
            <>
              {trainingHubHeading === null || loader ? (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                  }}
                >
                  <Spinner animation="grow" />
                </div>
              ) : (
                <div className="mt-2">
                  {trainingHubHeading?.map((data, index) => (
                    <SupportCard
                      data={data}
                      key={index}
                      editModalHandler={editModalHandler}
                      deleteHandler={deleteHandler}
                      enableDisableHandler={enableDisableHandler}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default TrainingHubHeading;
