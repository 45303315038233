import React, { useState, useEffect } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { authProtectedRoutes } from "../../routes";

//i18n
// import { withNamespaces } from 'react-i18next';

// import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/layout";

export default function SidebarContent(props) {
  const location = useLocation();
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  const initMenu = () => {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    // console.log("parent", parent);
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    initMenu();
  }, []);
  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{"Menu"}</li>
          {authProtectedRoutes.map((item, index) => {
            if (
              item.sidebar_presence &&
              item.appType?.includes(tenantDetails?.app_type)
            ) {
              return (
                // <li key={index} className="waves-effect">
                //   <Link to={item.path} className="waves-effect ">
                //     <i
                //       className={item.icon_class}
                //       style={{
                //         color: location.pathname == item.path ? "#fff" : "",
                //       }}
                //     ></i>

                //     <span
                //       className="ms-1 "
                //       style={{
                //         color: location.pathname == item.path ? "#fff" : "",
                //       }}
                //     >
                //       {item.name}
                //     </span>
                //   </Link>
                // </li>

                <li key={index}>
                  {item.submenu ? (
                    <>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i
                          className={item.icon_class}
                         
                        ></i>

                        <span className="ms-1">{item.name}</span>
                      </Link>

                      <ul className="sub-menu">
                        {item.submenu_components?.map((data, index) => (
                          <li key={index}>
                            <Link to={data.path} className="waves-effect">
                              <i
                                className={data.icon_class}
                                style={{
                                  color: location.pathname == data.path ? "#fff" : "",
                                }}
                              ></i>

                              <span
                                className="ms-1"
                                style={{
                                  color: location.pathname == data.path ? "#fff" : "",
                                }}
                              >
                                {data.name}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                 
                    </>
                  ) : (
                    <>
                      <Link to={item.path} className="waves-effect">
                        <i
                          className={item.icon_class}
                          style={{
                            color: location.pathname == item.path ? "#fff" : "",
                          }}
                        ></i>

                        <span
                          className="ms-1"
                          style={{
                            color: location.pathname == item.path ? "#fff" : "",
                          }}
                        >
                          {item.name}
                        </span>
                      </Link>
                    </>
                  )}
                </li>
              );
            }
          })}
        </ul>
      </div>
    </React.Fragment>
  );
}
