import { useEffect, useState } from "react";
import { publicAxios, authAxios } from "./axiosInstance";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import AbaciLoader from "./components/AbaciLoader/AbaciLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { setLogin, setLogOut, setTenant } from "./store/auth";
import Unauthorized from "./containers/Pages/Error/Unauthorized"
import Error from "./containers/Pages/Error"
import axios from "axios";

export default function PrivateRoute(props) {
  const [error, setError] = useState(null);
  const auth = useSelector((state) => state.authSlice);
  const [pathName,setPathName]=useState('')
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
   
    if (auth.loggedIn === null) {
      const token = Cookies.get("token");
      if (typeof token === "undefined") {
        dispatch(setLogOut())
        navigate("/login");
      }
      else{
        const url = "/users_api/profile";
        authAxios
          .get(url)
          .then((response) => {
            if (response.data?.user.is_active) {
              dispatch(setLogin({ user: response.data?.user }))
              dispatch(setTenant(response.data?.tenant_details))
            }
            else {
              dispatch(setLogOut())
              navigate("/login");
            }
          })
          .catch((error) => {
            if (!error.response) {
              setError(502);
            } else {
              const errorStatusTemp = error.response.status;
              if (errorStatusTemp === 401 || errorStatusTemp === 403) {
                Cookies.remove("token");
                dispatch(setLogOut())
                navigate("/login");
              } else {
                setError(errorStatusTemp)
              }
            }
          })
      }
    }
    //else condition for calling to the backend when location changes done inorder to 
    //check the user is online or not
      else {
        if(pathName !== location.pathname){
          setPathName(location.pathname)
        const url = "/users_api/profile";
        authAxios
          .get(url)
          .then((response) => {
            if (response.data?.user.is_active) {
              dispatch(setLogin({ user: response.data?.user }))
              dispatch(setTenant(response.data?.tenant_details))
            }
            else {
              dispatch(setLogOut())
              navigate("/login");
            }
          })
          .catch((error) => {
            if (!error.response) {
              setError(502);
            } else {
              const errorStatusTemp = error.response.status;
              if (errorStatusTemp === 401 || errorStatusTemp === 403) {
                Cookies.remove("token");
                dispatch(setLogOut())
                navigate("/login");
              } else {
                setError(errorStatusTemp)
              }
            }
          })
          
      }
  }
  }, [location]);

  if (error !== null) {
    return (<Error error={error} />)
  }
  else if (auth.loggedIn) {
    // if (true) {
      if (props.appType?.includes(auth.tenantDetails?.app_type)) {
      return <>{props.children}</>
    }
    else {
      return <Unauthorized />
      // return (<Error error={404} />)
    }
  }
  else {
    return <AbaciLoader />;
  }
}
