import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Progress, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { APP_NAME } from "../../../../helpers/Constants";
import Noimage from "../../../../assets/images/profile.png";
import "./profile.css";
import onlineScreen from "../../../../assets/images/tv green.png";
import offlinescreen from "../../../../assets/images/tv red.png";
import { IconButton, Tooltip } from "@material-ui/core";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import DescriptionIcon from "@mui/icons-material/Description";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  AddCustomerDetails,
  UpdateCustomerDetails,
} from "../../../../store/customersStore";
import Error from "../../../../helpers/Error";
import { Toastr } from "../../../../helpers/Alert";
import { authAxios } from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Moments from "../../../../helpers/Moment";
import AbaciLoader from "../../../../components/AbaciLoader/AbaciLoader";
import {
  PercentageCalculator,
  bytToGb,
  byteToGb,
} from "../../../../helpers/byteToGb";
import Swal from "sweetalert2";
import LeftDetailComponent from "./CustomerDetailComponents/LeftDetailComponent";
import ClientDetailTopComponent from "./CustomerDetailComponents/ClientDetailTopComponent";
import ClientPaymentAndPlanComponent from "./CustomerDetailComponents/ClientPaymentAndPlanComponent";
import { setCurrentPage, setSearchDisabled, setSearchkeyWord } from "../../../../store/searchSlice";
function CustomerDetails() {
  const customer = useSelector((state) => state.customerSlice.customer);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [loader, setLoader] = useState(true);
  //   const profileData = useLocation();
  //   const customer = profileData.state;

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    // console.log(customer);
    dispatch(setCurrentPage('customer_details'))
    dispatch(setSearchDisabled(true))
    dispatch(setSearchkeyWord(null))

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      dispatch(setSearchDisabled(false))

    };
  }, []);

  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Customer", link: "/customer" },
      { title: "Customer Details", link: "#" },
    ],
  });

  useEffect(() => {
    const url = `/abaciadmin_api/client/${id}`;
    authAxios
      .get(url)
      .then((response) => {
        dispatch(AddCustomerDetails(response.data));
        setLoader(false);
      })
      .catch((error) => {
        let err_msg = Error(error);
        Toastr("error", err_msg);
        setLoader(false);
      });

    return () => {
      dispatch(AddCustomerDetails({}));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerStatusHandler = (status) => {
    const url = `/abaciadmin_api/update_client_status/${customer.client_data?.id}`;
    authAxios
      .put(url, { status: status })
      .then((response) => {
        dispatch(UpdateCustomerDetails(response.data));
        if (status === "Active") {
          Toastr("success", `Customer Activeted Sucessfully`);
        } else if (status === "Deleted") {
          Toastr("success", `Customer Deleted Sucessfully`);
          navigate("/customer");
        } else {
          Toastr("success", `Customer Blocked Sucessfully`);
        }
      })
      .catch((error) => {
        let err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title="Customer Details"
          breadcrumbItems={dashboardState.breadcrumbItems}
        ></Breadcrumbs>

        {customer === null || loader ? (
          <AbaciLoader />
          ) : (
          <Card>
            <Row>
              <Col xl={4}>
               <LeftDetailComponent />
              </Col>
              <Col xl={8}>
                <div style={{ padding: "20px" }}>
                  <ClientDetailTopComponent />
                  <ClientPaymentAndPlanComponent />
                </div>
              </Col>
            </Row>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
}

export default CustomerDetails;
