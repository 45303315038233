import React, { useState } from "react";
// import { ReactComponent as LinkIcon } from "../../../assets/icons/link.svg";

// import { Link } from "react-router-dom";
import moment from "moment";
// import img4 from "../../../assets/images/small/img-4.jpg";
import {
  // TabContent,
  // TabPane,
  // Nav,
  // NavItem,
  // NavLink,
  Card,
  // Button,
  // CardTitle,
  // CardText,
  // Row,
  Col,
  // Container,
  // CardSubtitle,
  CardImg,
  CardBody,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { Toastr } from "../../../helpers/Alert";
import urlMaker from "../../../helpers/urlMaker";
// import { height } from "@mui/system";
import { removeExtension } from "../../../helpers/Filename";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function Index({ item, ModalToggle, copyTemplateHandler }) {
  const dispatch = useDispatch();
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Col md={4} xl={2} style={{ minWidth: "200px" }}>
      <Card
        className="abaci-item-card"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        // style={{transition:'height 0.3s'}}
      >
        <CardBody>
          {/* <CardTitle className="h4">Card title</CardTitle> */}
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "300px",
                display: "flex",
                flexDirection: "row",
                height: "24px",
                textTransform: "capitalize",
              }}
            >
              <h4
                style={{
                  fontSize: "14px",
                  color: " #8D9091",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                //   onClick={editHandler}
              >
                {removeExtension(item?.name)}
              </h4>
            </div>
            {item?.file_type === "video" ? (
              <div
                style={{
                  fontSize: "10px",
                  color: " #8D9091",
                  textAlign: "right",
                  marginTop: "3px",
                }}
              >
                {moment
                  .utc(
                    moment.duration(item?.duration, "seconds").asMilliseconds()
                  )
                  .format("HH:mm:ss")}
              </div>
            ) : null}
          </div>
        </CardBody>

        <CardImg
          style={{ marginTop: "-15px" }}
          className="img-fluid "
          src={urlMaker(item.thumbnail, "image", true)}
          alt="media File"
          onClick={() => ModalToggle({ is_stock: true, ...item })}
        />
        <CardBody>
          <div
            style={{
              marginTop: "-20px",
              fontSize: "13px",
              color: " #8D9091",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {"Uploded Name : " + item?.uploaded_file_name}
          </div>
          <div className=" d-flex justify-content-between mb-3">
            <span style={{ fontSize: "13px", color: " #8D9091" }}>
              {"File Size : " + item?.file_size}
            </span>
          </div>
          <div
            style={{
              marginTop: "-20px",
              width: "100%",
              display: "flex",
              height: "47px",
              justifyContent: "center",
            }}
          >
            {isHovered && (
              <Tooltip arrow title="Copy">
                <IconButton
                 onClick={() => copyTemplateHandler(item.id)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default Index;
