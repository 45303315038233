import { Container, Spinner } from "reactstrap";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import SupportCard from "../../../../components/SupportCard";
import { CustomButton } from "../../../../components/CustomButton";
import AddHelpCenterModal from "./AddHelpCenterModal";
import {
  AddHelpCenter,
  DeleteHelpCenter,
  EditHelpCenter,
} from "../../../../store/supportSlice";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { authAxios } from "../../../../axiosInstance";
import EditHeplCenterModal from "./EditHeplCenterModal";
function HelpCenter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);

  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [value, setValue] = useState(0);
  const [addModalShow, setAddModalShow] = useState(false);
  const [pageSize] = useState(20);
  const [hasNextpage, setHasNextPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);
  const [searchKeyCount, setSearchKeyCount] = useState(0);
  const helpCenter = useSelector((state) => state.supportSlice.helpCenter);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "/dashboard" },
      { title: "Help Center", link: "#" },
    ],
  });

  useEffect(() => {
    setPage(1);
    setHasNextPage(false);
    dispatch(AddHelpCenter(null));

    if (searchKey !== prevSearchKey) {
      setPrevSearchKey(searchKey);
    }

    setSearchKeyCount(searchKey !== null ? searchKey.length : 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  function editModalToggle() {
    setEditModalShow(!editModalShow);
  }

  const editModalHandler = (data) => {
    // navigate(`/helpcenter/${data.id}`);
    setImage(data);
    setIsModalVisible(!isModalVisible);
  };
  const deleteHandler = (id) => {
    const url = `/abaciadmin_api/helpcenter/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        dispatch(DeleteHelpCenter(id));
        Toastr("success", "Content Deleted Succesfully");
      })
      .catch((error) => {
        let error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };

  useEffect(() => {
    const url = `/abaciadmin_api/helpcenter?limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }&search=${searchKey !== null && searchKey !== "" ? searchKey : ""}`;

    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);
        if (helpCenter === null) {
          dispatch(AddHelpCenter(response.data.results));
        } else {
          dispatch(AddHelpCenter([...helpCenter, ...response.data.results]));
        }

        setTotalCount(response.data.count);

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  }, [page, prevSearchKey]);

  useEffect(() => {
    return () => {
      dispatch(AddHelpCenter(null));
    };
  }, []);

  function addModalToggle() {
    setAddModalShow(!addModalShow);
  }

  const enableDisableHandler = (data) => {
    const status = data.status === "Active" ? "Disabled" : "Active";
    const url = `/abaciadmin_api/helpcenter/${data.id}`;
    authAxios
      .patch(url, { status: status })
      .then((response) => {
        dispatch(
          // updatehelpCenter({
          //   ...response.data,
          //   landing_image: urlMaker(response.data.landing_image),
          //   thumbnail: urlMaker(response.data.thumbnail),
          // })
          EditHelpCenter(response.data)
        );
        Toastr("success", "Content updated succesfully");
      })
      .catch((error) => {
        let error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };

  const loadFunc = () => {
    if (hasNextpage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
  };

  return (
    <React.Fragment>
      {addModalShow && (
        <AddHelpCenterModal
          isOpen={addModalShow}
          toggle={addModalToggle}
          title={"Add Contents"}
        />
      )}
      {isModalVisible && (
        <EditHeplCenterModal
          isOpen={isModalVisible}
          toggle={editModalHandler}
          title={"Edit Contents"}
          data={image}
        />
      )}
      <div className="page-content">
        <CustomButton title="+" onClick={addModalToggle} />
        <Container fluid>
          <Breadcrumbs
            title="Help Center"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>

          {helpCenter?.length === 0 ? (
            <div className=" text-center">
              <img
                src={EmptyList}
                alt=""
                style={{ width: "450px", height: "450px" }}
              />

              <h6 style={{}}>No Contents Found !</h6>
            </div>
          ) : (
            <>
              {helpCenter === null || loader ? (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                  }}
                >
                  <Spinner animation="grow" />
                </div>
              ) : (
                <div className="mt-2">
                  <InfiniteScroll
                    pageStart={0}
                    // dataLength={totalCount}
                    // next={loadFunc}
                    loadMore={loadFunc}
                    hasMore={hasNextpage}
                    loader={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="loader"
                        key={0}
                      >
                        <Spinner animation="grow" />
                      </div>
                    }
                  >
                    {helpCenter?.map((data, index) => (
                      <SupportCard
                        data={data}
                        // key={index}
                        editModalHandler={editModalHandler}
                        deleteHandler={deleteHandler}
                        // editModalToggle={editModalToggle}
                        enableDisableHandler={enableDisableHandler}
                        // lockHide={true}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default HelpCenter;
