import calltoAction1 from "../assets/images/callToAction/Retail.png";
import calltoAction2 from "../assets/images/callToAction/Restaurant.png";
import calltoAction3 from "../assets/images/callToAction/Banking.png";
import calltoAction4 from "../assets/images/callToAction/Corporate.png";
import calltoAction5 from "../assets/images/callToAction/HSE.png";
import videoBG from "../assets/images/resources/video-bg-1-1.jpg";
import appFeature1 from "../assets/images/app-feauture/bullhorn.png";
import appFeature2 from "../assets/images/app-feauture/cloud-data.png";
import appFeature3 from "../assets/images/app-feauture/web-design.png";
import appFeature4 from "../assets/images/app-feauture/calendar.png";
import appFeature5 from "../assets/images/app-feauture/spyware.png";
import appFeature6 from "../assets/images/app-feauture/save.png";
import appFeature7 from "../assets/images/app-feauture/man.png";
import appFeature8 from "../assets/images/app-feauture/cyber-security.png";
import appFeature9 from "../assets/images/app-feauture/customer-service.png";
import testimonial1 from "../assets/images/testimonials/testimonials-1-img-1.png";
import testimonial2 from "../assets/images/testimonials/testimonials-1-img-2.png";
import testimonialShape from "../assets/images/testimonials/testimonials-1-shape-1.png";
import testimonialShape2 from "../assets/images/testimonials/testimonials-1-shape-2.png";
import appshot1 from "../assets/images/app-shots/app-shot-n-1-1.png";
import appshot2 from "../assets/images/app-shots/app-shot-n-1-2.png";
import appshot3 from "../assets/images/app-shots/app-shot-n-1-3.png";
import appshot4 from "../assets/images/app-shots/app-shot-n-1-4.png";
import appshot5 from "../assets/images/app-shots/app-shot-n-1-5.png";
import blog1 from "../assets/images/blog/blog.png";
import blog2 from "../assets/images/blog/blog.png";
import blog3 from "../assets/images/blog/blog.png";
import blog4 from "../assets/images/blog/blog.png";
import blog5 from "../assets/images/blog/blog.png";
import blog6 from "../assets/images/blog/blog.png";

export const SERVICE_DATA = {
  sectionTitle: {
    title:
      "Your system will be ready to go in minutes with the following 3 steps...",
    text: "3 Simple steps for start",
  },
  posts: [
    {
      extraClassName: "",
      icon: "1",
      title: "On your marks...",
      text: "Sign Up at abacisignage.com",
    },
    {
      extraClassName: "bg-2",
      icon: "2",
      title: "Get set...",
      text: "Install the app on the device of your choice.",
    },
    {
      extraClassName: "bg-3",
      icon: "3",
      title: "Go !",
      text: "Pair the device with your digital signage account.",
    },
  ],
};

export const CALL_TO_ACTION_DATA = {
  sectionTitle: {
    text: "Solutions",
    title: "Powerful App for \n Your Mobile",
  },

  posts: [
    {
      image: calltoAction1,
      title: "Retail",
      content:
        " Increase your footfall with the eye-catching videos or images about your products on your screens.  You can remotely monitor and schedule your contents with abacisignage",
    },
    {
      image: calltoAction2,
      title: "Restaurants",
      content:
        "Replace your printed stickers on your wall with digital screens. Show your delicious food on your screen, and let the customer’s ghrelin get released.. that will really make them hungry ",
    },
    {
      image: calltoAction3,
      title: "Banking",
      content:
        "Improve your customer’s experience by broadcasting news or stock exchange details on your screen. Educate your customers with the new features and offers your bank provides to your customers ",
    },
    {
      image: calltoAction4,
      title: "Corporate",
      content:
        "Engage your employees with motivational videos, corporate news, achievements and announcements. Build a strong communication with your employees and management. ",
    },
    {
      image: calltoAction5,
      title: "HSE",
      content:
        "Educate your employees with the health, safety and environmental tips and convey the safety and security messages that your employees will always remember. The screens are remind them always. ",
    },
  ],
  button: {
    link: "#",
    label: "Discover More",
  },
};

export const VIDEO_DATA = {
  title: "Watch Our Video",
  image: videoBG,
  videoID: "Kl5B6MBAntI",
};

export const APP_FEATURE_DATA = {
  sectionTitle: {
    title: "Discover Tools for\nYour Customers",
    text: "ABACISIGNAGE's Feature Lists",
  },

  options: [
    {
      label: "Flexibility to support multiple contents.",
      content:
        "Use images, videos, webpages, webapps,clocks, calendars, whether updates,  stock updates and much more",
      image: appFeature1,
      color: "#AC2174",
    },
    {
      label: "Cloud based solution.",
      content:
        "Access your digital signage account from anywhere via secure cloud based interface.",
      image: appFeature2,
      color: "#C23635",
    },
    {
      label: "Advanced template designer.",
      content:
        "A highly flexible template creator which will help you to divide the screen into multiple areas and create the template as you want to organize the contents",
      image: appFeature3,
      color: "#CD2121",
    },

    {
      label: "Powerful scheduler.",
      content:
        "Drag and drop your contents and schedule your media playlists. Schedule hourly,daily, weekly, monthly and as you like.",
      image: appFeature4,
      color: "#F1DB0C",
    },
    {
      label: "Realtime Monitoring.",
      content:
        "Real-time monitoring of your device status on various parameters such as network status, content playing details etc.",
      image: appFeature5,
      color: "#A0CD2D",
    },
    {
      label: "Content cachingr.",
      content:
        "The media contents will be cached to the device and will be played in offline mode. This helps to reduce the bandwidth usage.",
      image: appFeature6,
      color: "#5F9F55",
    },
    {
      label: "Multi-User Access.",
      content:
        "Role-based access control helps to keep different level of users with different permissions",
      image: appFeature7,
      color: "#107BC9",
    },
    {
      label: "Extreme Security & Reliability.",
      content:
        "Enterprise-grade security includes SSL, firewall & password policies. Align your digital signage   with your corporate security protocols.",
      image: appFeature8,
      color: "#0855A7",
    },
    {
      label: "24x7 Customer support.",
      content:
        "Our customers are our number one priority. We help our customers in solving their problems through our 24x7 online chat ",
      image: appFeature9,
      color: "#891175",
    },
  ],
  button: { link: "#", label: "Discover More" },
};

export const PRICING_DATA = {
  sectionTitle: {
    title: "Our Pricing Plans",
    text: "Choose Our Pricing ",
  },
  monthly: [
    {
      count: "01",
      amount: "50 AED",

      // amount: "$12.00",
      name: "Simple start",
      extraClassName: "color-1",
      options: [
        { label: "Media library management" },
        { label: "Unlimited Template Creation" },
        { label: "Canvas Option – Custom Made Designs" },
        { label: "Active Web Socket Connectivity" },

        { label: "Real Time Monitoring" },
        { label: "Access to free media contents" },
        { label: "Content scheduling" },
        { label: "Reports and proof of play" },

        { label: "2 GB storage per screen" },
        { label: "24X7 email support" },
      ],
      button: {
        label: "Choose Plan",
        link: "#",
      },
    },
    // {
    //   count: "02",
    //   amount: "$18.00",
    //   name: "Essentials",
    //   extraClassName: "color-2",
    //   options: [
    //     { label: "Everything in Simple start" },
    //     { label: "Unlimited app access" },
    //     { label: "Reports and proof of play" },
    //     { label: "User and role management" },

    //     { label: "Audit logs" },
    //     { label: "5 GB storage per screen" },
    //   ],
    //   button: {
    //     label: "Choose Plan",
    //     link: "#",
    //   },
    // },
    {
      count: "02",
      amount: "Talk to us",
      name: "Professional",
      extraClassName: "color-3",
      options: [
        { label: "Everything in Simple Start" },
        { label: "Custom app development" },
        { label: "Custom Dashboards" },
        { label: "Integration with internal business application" },

        { label: "Dedicated Hosting" },
        { label: "Report Customisations" },
        { label: "Dedicated Support Manager" },
        { label: "Access to rest API’s" },
        { label: "Custom parameters" },
      ],
      button: {
        label: "Choose Plan",
        link: "#",
      },
    },
  ],
  yearly: [
    {
      count: "01",
      // amount: "$10.00",
      amount: "600 AED",

      name: "Simple start",
      extraClassName: "color-1",
      options: [
        { label: "Media library management" },
        { label: "Unlimited Template Creation" },
        { label: "Canvas Option – Custom Made Designs" },
        { label: "Active Web Socket Connectivity" },

        { label: "Real Time Monitoring" },
        { label: "Access to free media contents" },
        { label: "Content scheduling" },
        { label: "Reports and proof of play" },

        { label: "2 GB storage per screen" },
        { label: "24X7 email support" },
      ],
      button: {
        label: "Choose Plan",
        link: "#",
      },
    },
    // {
    //   count: "02",
    //   amount: "$15.00",
    //   name: "Essentials",
    //   extraClassName: "color-2",
    //   options: [
    //     { label: "Everything in Simple start" },
    //     { label: "Unlimited app access" },
    //     { label: "Reports and proof of play" },
    //     { label: "User and role management" },

    //     { label: "Audit logs" },
    //     { label: "5 GB storage per screen" },
    //   ],
    //   button: {
    //     label: "Ask to sales",
    //     link: "#",
    //   },
    // },
    {
      count: "02",
      amount: "Talk to us",
      name: "Professional",
      extraClassName: "color-3",
      options: [
        { label: "Everything in Simple Start" },
        { label: "Custom app development" },
        { label: "Custom Dashboards" },
        { label: "Integration with internal business application" },

        { label: "Dedicated Hosting" },
        { label: "Report Customisations" },
        { label: "Dedicated Support Manager" },
        { label: "Access to rest API’s" },
        { label: "Custom parameters" },
      ],
      button: {
        label: "Ask to sales",
        link: "#",
      },
    },
  ],
};

export const TESTIMONIALS_DATA = {
  sectionTitle: {
    text: "Our Testimonials",
    title: "What They Says",
  },
  posts: [
    {
      extraClassName: " ",
      shape: testimonialShape,
      image: testimonial1,
      title: "Isabel Griffith",
      designation: "Customer",
      text: "We provide turnkey digital signage solutions that include hardware,free digital signage software,content, and implementation services.",
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text: "We provide turnkey digital signage solutions that include hardware,free digital signage software,content, and implementation services.",
    },
    {
      extraClassName: " ",
      image: testimonial1,
      shape: testimonialShape,
      title: "Isabel Griffith",
      designation: "Customer",
      text: "We provide turnkey digital signage solutions that include hardware,free digital signage software,content, and implementation services.",
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text: "We provide turnkey digital signage solutions that include hardware,free digital signage software,content, and implementation services..",
    },
  ],
};

export const APP_SHOT_DATA = {
  sectionTitle: {
    title: " Screenshots",
    text: "Check Before Choosing",
  },
  posts: [
    {
      image: appshot1,
    },
    {
      image: appshot2,
    },
    {
      image: appshot3,
    },
    {
      image: appshot4,
    },
    {
      image: appshot5,
    },
    {
      image: appshot1,
    },
    {
      image: appshot2,
    },
    {
      image: appshot3,
    },
    {
      image: appshot4,
    },
    {
      image: appshot5,
    },
    {
      image: appshot1,
    },
    {
      image: appshot2,
    },
    {
      image: appshot3,
    },
    {
      image: appshot4,
    },
    {
      image: appshot5,
    },
  ],
};

export const FAQ_DATA = {
  sectionTitle: {
    text: "Frequently Asked Questions",
    title: "Have Any Question?",
  },
  posts: [
    {
      status: true,
      title: "What kind of hardware, smart televisions, and displays can I use?",
      text: "You can use any kind of Android devices which are later than Android 7.0. You can simply download the abacisignage application from the playstore, pair it with your signage web application account and you will be ready to go.",
    },
    {
      status: false,
      title: "Is it mandatory to have a TV screen to test AbaciSignage?",
      text: "No. You can install the android application on any mobile or tablet and you can test the application. For implementation purpose we recommend to have bigger screens to get maximum impact for your media content.",
    },
    // {
    //   status: false,
    //   title: "How to update application new features",
    //   text: "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam.",
    // },
    // {
    //   status: false,
    //   title: "How to connect with the support to improve app experience",
    //   text: "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam.",
    // },
  ],
};

export const BLOG_DATA = {
  sectionTitle: {
    text: "From the Blog Posts",
    title: "News & Articles",
  },
  posts: [
    {
      image: blog1,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Leverage agile frame works to",
      text: "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More",
      },
    },
    {
      image: blog2,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Launch New Mobile Marketing Pitfalls",
      text: "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More",
      },
    },
    {
      image: blog3,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Bring to the table win-win survival",
      text: "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More",
      },
    },
    {
      image: blog4,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Capitalize on low hanging fruit to",
      text: "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More",
      },
    },
    {
      image: blog5,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Collaboratively admin istrate via ",
      text: "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More",
      },
    },
    {
      image: blog6,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Efficiently unleash cross-media ",
      text: "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More",
      },
    },
  ],
};
