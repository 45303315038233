import React from "react";
import closeIcon from "../../assets/images/shapes/close-1-1.png";
import NavLinks from "./header/nav-links";

const MobileNav = () => {
  return (
    <div className='side-menu__block'>
      <div className='side-menu__block-overlay custom-cursor__overlay'>
        <div className='cursor'></div>
        <div className='cursor-follower'></div>
      </div>
      <div className='side-menu__block-inner '>
        <div className='side-menu__top justify-content-end'>
          <a href='#' className='side-menu__toggler side-menu__close-btn'>
            <img src={closeIcon} alt='' />
          </a>
        </div>

        <nav className='mobile-nav__container'>
          <NavLinks />
        </nav>
        <div className='side-menu__sep'></div>
        <div className='side-menu__content'>
          <p>
            We help you to motivate collaboration and innovation, 
            and increase the efficiency of your employees and visitors within
            your environment.
            <br />
            Simple and powerful way to manage and publish content on your
            displays.
            <br />
          </p>
          <p>
            <a href=''>mail@abacitechs.com</a> <br />{" "}
            <a href=''>099883 34422</a>
          </p>
          <div className='side-menu__social'>
            <a  href="https://www.facebook.com/Abaci-Technologies-Pvt-Ltd-106399074333308/">
              <i className='fab fa-facebook-square'></i>
            </a>
            <a href='href="https://www.linkedin.com/company/abaci-technologies-pvt-ltd/mycompany/"'>
            <i className="fab  fa-linkedin-in"></i>
            </a>
            {/* <a href='#'>
              <i className='fab fa-instagram'></i>
            </a>
            <a href='#'>
              <i className='fab fa-pinterest-p'></i>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
