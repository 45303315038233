import React, { useEffect, useState } from 'react';
// import { schedule } from './temp_schedule';
import useInterval from "../../hooks/use-interval";
import ContentForScdedule from "./ContentForSchedule"

import ScheduleContentWrapper from "./ScheduleContentWrapper"
import { useDispatch, useSelector } from 'react-redux';
import { editDroppableBagItems } from '../../store/bagItems';
import loading from '../../assets/lottie/mini-loader.json'
import { Player } from '@lottiefiles/react-lottie-player';

function Index({divId,scheduleDetail,width,height,previewType}) {
  const droppableBagItems = useSelector(state => state.bagItems.droppableBagItems)
  const dispatch=useDispatch();
  const [currentPlayingItem, setCurrentPlayingItem] = useState(null)
  const [nextFileIndex, setNextFileIndex] = useState(null)
  const [todaysDate, setTodaysDate] = useState(new Date().getDay())
  const [todaysPlaylist, setTodaysPlaylist] = useState(null)
  const [startPositionOfFirstFile, setStartPositionOfFirstFile] = useState(null)

  const timeStringToEpochShift = (timeString) => {
    const [hr, min, sec] = timeString.split(':')
    const epochTimeShift = Number(hr) * 60 * 60 * 1000 + Number(min) * 60 * 1000 + Number(sec) * 1000
    return epochTimeShift
  }

  const todaysPlaylistMaker = async (playlist, todayStartTime, todayEndTime) => {
    let counter = todayStartTime
    const new_playlist = []
    while (counter < todayEndTime) {
      for (let i = 0; i < playlist.length; i++) {
        const file = playlist[i]
        if (file?.start_time <= counter && file?.end_time > counter) {
          const new_file = { [counter]: file }
          await new_playlist.push(new_file)
          counter += file.scheduled_duration * 1000
        }
        else{
          counter += 1000
        }
      }
    }
    return await new_playlist
  }

  const nowPlayingItemFinder = async (new_playlist) => {
    let current_playing_file
    let startPositionOfCurrentFile
    let index = 0
    for (index = 0; index < new_playlist.length; index++) {
      const item = new_playlist[index]
      const time_stamp = Object.keys(item)[0]
      const current_time = Date.now()
      if (time_stamp > current_time) {
        current_playing_file = new_playlist[index - 1]
        const current_playing_file_starting_time = Object.keys(current_playing_file)[0]
        startPositionOfCurrentFile = current_time - current_playing_file_starting_time
        break
      }
    }
    const tempNextFileIndex = index
    return await ([current_playing_file, startPositionOfCurrentFile, tempNextFileIndex])
  }
  const updatedFileChangerOnInterval = () => {
    // If a day has changed, we need to run the main useEffect to get the new schedules.
    setStartPositionOfFirstFile(null)
    const today = new Date().getDay()
    if (todaysDate !== today) {
      setTodaysDate(today)
    }
    else {
      const current_time = Date.now()
      if(nextFileIndex){
      const nextFile = todaysPlaylist[nextFileIndex]
      const nextFileChangeTime = Object.keys(nextFile)[0]
      if (current_time >= nextFileChangeTime) {
       
        setCurrentPlayingItem( nextFile[Object.keys(nextFile)[0]])
        setNextFileIndex(state => state + 1)
      }
    }
    }
  }
  useInterval(updatedFileChangerOnInterval, 100, null);
  useEffect(() => {
    // 12 AM of today
    async function fetchData() {
      // You can await here
     

    const todayStartTime = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`).getTime()
    // 12 PM of today - 1 micro second
    const todayEndTime = todayStartTime + 86399999
    const todaysPlaylistFinder = async (divId, todayStartTime) => {
      let scheduleprops_saved_divclone
      // Everyday we will be creating the playlist with fresh priority with respect to the total priorities in the schedules. 
      // In schdules, the priority will be based on the total tenure of the schedule, but in this we will create todays priority with respect to the original schedule priority
      // The input playlist should be an array with priority order from 1 - n
      let priority = 1
      for (let i = 0; i < scheduleDetail?.template_clone.divclone_templateclone?.length; i++) {
        const item = scheduleDetail?.template_clone.divclone_templateclone[i]
        if (item.id === divId) {
          scheduleprops_saved_divclone = item.scheduleprops_saved_divclone
          break
        }
      }
      let scheduleprops_saved_divclone_for_today = []
      for (let i = 0; i < scheduleprops_saved_divclone?.length; i++) {
        const item = scheduleprops_saved_divclone[i]
        const StartDate = new Date(item.start_date).setHours(0, 0, 0, 0);
        const EndDate = new Date(item.end_date).setHours(0, 0, 0, 0);
        if (StartDate <= todayStartTime && EndDate >= todayStartTime) {
          const startTimeEpoch = todayStartTime + timeStringToEpochShift(item.start_time)
          const endTimeEpoch = todayStartTime + timeStringToEpochShift(item.end_time)
          scheduleprops_saved_divclone_for_today.push({ ...item, priority: priority, start_time: startTimeEpoch, end_time: endTimeEpoch })
          priority++
        }
      }
      return await scheduleprops_saved_divclone_for_today
    }
    const tempSchedulepropsSavedDivcloneForToday = await todaysPlaylistFinder(divId, todayStartTime)
    if (tempSchedulepropsSavedDivcloneForToday.length > 0) {
      const new_playlist = await todaysPlaylistMaker(tempSchedulepropsSavedDivcloneForToday, todayStartTime, todayEndTime)
      const [current_playing_file, startPositionOfCurrentFile, tempNextFileIndex] = await nowPlayingItemFinder(new_playlist)
      setTodaysPlaylist(new_playlist)
      setCurrentPlayingItem(current_playing_file[Object.keys(current_playing_file)[0]])
      setNextFileIndex(tempNextFileIndex)
      setStartPositionOfFirstFile(startPositionOfCurrentFile)

    }
    else{
      setTodaysPlaylist({})
    }
  }

    fetchData();
  }, [todaysDate, divId])



  useEffect(() => {
    // console.log(currentPlayingItem)
    if(currentPlayingItem !== null){


      if(previewType === "schedule"){

      // console.log(currentPlayingItem)

      const temp=droppableBagItems.find((data) => data.div_id === divId )
      
      // console.log(startPositionOfFirstFile/1000)

      const dt={...temp,[divId]:temp[divId].map((data) => data.id === currentPlayingItem.id ? {...data,isPlaying:true,isStartTime:startPositionOfFirstFile} : {...data,isPlaying:false,isStartTime:startPositionOfFirstFile})}


      dispatch(editDroppableBagItems(dt))

      }

      // console.log(dt)

      

    //   const temp=droppableBagItems?.map((item, index) => (item.div_id === divId ?
    //     {...item,[divId]:
    //     item[divId]?.map((data) => data.id === currentPlayingItem.id ? {...data,isPlaying:true} : {...data,isPlaying:false})} : item))

    // setNowPlaying(temp)
    }

  }, [currentPlayingItem])
  

  return (
    <>{currentPlayingItem === null ? 
    <Player
      autoplay
      loop
      src={loading}
      style={{ height:`${height}`,
              width: `${width}`,
              position:'absolute',
              top: '50%',
              left: '50%',
              transform: "translate(-50%, -50%)"
            }}
    /> 
    : 
    <ContentForScdedule currentPlayingItem={currentPlayingItem} templateDetail={scheduleDetail} width={width} height={height} startPositionOfFirstFile={startPositionOfFirstFile}/>
  }
    </>
  )
}

export default Index;