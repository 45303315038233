import React, { useEffect, useState } from "react";
import SupportHeader from "../../../components/PublicComponents/header/SupportHeader";
import intro from "../../../assets/images/intro1.png";
import { Card } from "reactstrap";
import { authAxios, publicAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import { IntroductionContent } from "../../../components/SupportComponets/IntroductionContent";
import { Player } from "@lottiefiles/react-lottie-player";
import loading from "../../../assets/lottie/skeleton.json";
import { GetStartContent } from "../../../components/SupportComponets/GetStartContent";
export const SupportPage = () => {
  const [content, setContent] = useState([]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const url = `/abaciadmin_api/traininghubweb`;
    publicAxios
      .get(url)
      .then((response) => {
        setContent(response.data);
        setLoader(false);
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
        setLoader(false);
      });
  }, []);

  return (
    <div className="page-wrapper" id="wrapper">
      <SupportHeader />

      {content.length === 0 || loader ? (
        <Player
          autoplay
          loop
          src={loading}
          style={{ marginTop: "20px" }}
        ></Player>
      ) : (
        content.map((data) => {
          if (data.order === 1) {
            return (
              <div className="text-center" style={{ width: "100%" }}>
                <p style={{ fontWeight: "bold" }} className="mt-5">
                  {data.main_heading}
                </p>

                <IntroductionContent content={data.training_hub_main_content} />
              </div>
            );
          } else if (data.order === 2) {
            return (
              <div
                className="mt-4 text-center"
                style={{ width: "100%", backgroundColor: "#F3F7FC" }}
              >
                <p style={{ fontWeight: "bold" }} className="mt-4 pt-3">
                  {data.main_heading}
                </p>
                <GetStartContent content={data.training_hub_main_content} />
              </div>
            );
          }
        })
      )}
    </div>
  );
};
