import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Clockimage from "../icons/clock.svg";
// import AbaciButton from "../../../../components/AbaciButton/AbaciButton";
// import CreateModal from "./CreateClock";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";

import ClockCard from "./ClockCard";
import {
  // addClock,
  deleteClock,
  setClockes,
  // toStoreEditValue,
} from "../../../.././store/clockSlice";
// import Swal from "sweetalert2";
import "../Apps.css";
import { useNavigate } from "react-router-dom";
import {  Container, Spinner } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { CustomButton } from "../../../../components/CustomButton";
import InfiniteScroll from "react-infinite-scroller";
import { Toastr } from "../../../../helpers/Alert";
import { authAxios } from "../../../../axiosInstance";
import Error from "../../../../helpers/Error";
export default function Clock() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);
  const [searchKeyCount,setSearchKeyCount] = useState(0)
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);
  const [totalCount, setTotalCount] = useState(0);

  const [loader, setLoader] = useState(true);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "Clock Instance", link: "#" },
    ],
  });

  const [pageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const clockList = useSelector((state) => state.clockSlice.user_data);

  const deleteHandler = (id) => {
    const url = `api/signage/objectrud/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        dispatch(deleteClock(id));
        Toastr("success", "Clock Instance Deleted Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };
  
  useEffect(() => {
  
    setPage(1);
    setHasNextPage(false);
    dispatch(setClockes(null));

    if(searchKey !== prevSearchKey){
      setPrevSearchKey(searchKey)
    }

    setSearchKeyCount(searchKey !==null ? searchKey.length : 0)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[searchKey])

  useEffect(() => {
    const url = `/api/signage/objectlistcreate?object_type=clock&limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}`;

    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);
        if (clockList === null) {
          dispatch(setClockes(response.data.results));
        } else {
          dispatch(setClockes([...clockList, ...response.data.results]));
        }

        setTotalCount(response.data.count);

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        Toastr("error", "Oops!,Something Went Wrong");
      });

    return () => {
      dispatch(setClockes(null));
    };
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,prevSearchKey]);

  const loadFunc = () => {
    if (hasNextPage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
    // setTemp(state => [...state, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Clock Instance"
          breadcrumbItems={dashboardState.breadcrumbItems}
        ></Breadcrumbs>
        {/* <Col xl={24}> */}

        <div style={{ minHeight: "calc(100vh - 300px" }}>
          {clockList?.length === 0 ? (
            <div className="weblinkEmptyImage text-center">
              <img
                src={EmptyList}
                alt=""
                style={{ width: "450px", height: "450px" }}
              />
              <h6 style={{}}>No Instance Found !</h6>
            </div>
          ) : (
            <>
              {clockList === null || loader ? (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                  }}
                >
                  <Spinner animation="grow" />
                </div>
              ) : (
                <InfiniteScroll
                  pageStart={0}
                  // dataLength={totalCount}
                  // next={loadFunc}
                  loadMore={loadFunc}
                  hasMore={hasNextPage}
                  loader={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      className="loader"
                      key={0}
                    >
                      <Spinner animation="grow" size="sm" />
                    </div>
                  }
                >
                  {clockList?.map((item, index) => (
                    <ClockCard
                      key={index}
                      item={item}
                      deleteHandler={deleteHandler}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </>
          )}
        </div>
        <div>
          <CustomButton
            title={"+"}
            onClick={() => {
              navigate("/createClock");
            }}
          />
        </div>
      </Container>
    </div>
  );
}
