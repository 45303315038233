import React, { useEffect, useState } from "react";
import urlMaker from "../../helpers/urlMaker";

export const IntroductionContent = (props) => {
  return (
    <div style={{ width: "100%" }}>
      {props.content.map((item, index) => (
        <div key={index}>
          <div className="d-flex justify-content-center ">
            <h1 style={{ maxWidth: "950px" }}>
              {item.heading && item.heading}
            </h1>
          </div>
          <div
            className="d-flex justify-content-center "
            style={{ width: "100%" }}
          >
            <p style={{ maxWidth: "850px" }}>
              {item.description && item.description}
            </p>
          </div>

          <div className="d-flex justify-content-center mb-2">
            <div style={{ width: "700px", height: "350px" }} className="mt-2">
              {item.file_type === "image" ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={urlMaker(item.file, "training_hub_content", true)}
                />
              ) : (
                <video
                  // autoPlay
                  controls
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                >
                  <source
                    src={urlMaker(item.file, "training_hub_content", true)}
                    type="video/mp4"
                  />
                </video>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
