import React, { useEffect, useState } from "react";

// import Lottie from './Lottie';
import {
  ModalBody,
  Button,
  ModalFooter,
  Spinner,
  Card,
  Col,
  Row,
} from "reactstrap";
// import { ReactComponent as LinkIcon } from "../../../assets/icons/link.svg";
import Modals from "./Modal";
import { authAxios } from "../axiosInstance";
import Error from "../helpers/Error";
import { Toastr } from "../helpers/Alert";
import { useDispatch } from "react-redux";
// import { addScreenGroup, screenListUpdater } from "../../../store/screenSlice";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MovieIcon from "@mui/icons-material/Movie";
import LayersIcon from "@mui/icons-material/Layers";
import TvIcon from "@mui/icons-material/Tv";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import GridViewIcon from "@mui/icons-material/GridView";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import InfiniteScroll from "react-infinite-scroller";
import urlMaker from "../helpers/urlMaker";
import { removeExtension } from "../helpers/Filename";
import screengroup from "./../assets/images/groupscreenicon.png";
import screen from "./../assets/images/screen123.png";
import ScreenCard from "../containers/Pages/Screens/ScreenCards/ScreenCard";
import EmptyList from "./../assets/SVG/Search Not Found 2.svg";
import ScreenGroupCard from "../containers/Pages/Screens/ScreenCards/ScreenGroupCard";
export default function CastContentModal(props) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [pageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [files, setFiles] = useState([]);
  const [limit, setLimit] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loader, setLoader] = useState(false);
  const [waitingForAxios, setWaitingForAxios] = useState(false);

  useEffect(() => {
    console.log(props);
  }, [props]);

  const urls = [
    `api/signage/screenslistcreateview?limit=${pageSize}&screen_group__isnull=true&online_status=true&&offset=${
      pageSize * (page - 1)
    }`,
    `/api/signage/screengrouplistcreateview?limit=${pageSize}&offset=${
      pageSize * (page - 1)
    } &file_type=image`,
  ];

  useEffect(() => {
    setLoader(true);
    const url = urls[selectedOption];
    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);

        if (files.length === 0) {
          setFiles(response.data.results);
        } else {
          setFiles([...files, ...response.data.results]);
        }

        setTotalCount(response.data.count);

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  }, [page, selectedOption]);

  useEffect(() => {
    console.log(selectedItem);
  }, [selectedItem]);
  // function used inorder to change page and load more data upon user scrolling if there is more data
  const loadFunc = () => {
    if (hasNextPage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
  };

  const optionHandler = (id) => {
    setPage(1);
    setTotalCount(0);
    setHasNextPage(false);
    setSelectedOption(id);
    setFiles([]);
    setSelectedItem(null);
  };

  const handleCancel = () => {
    props.setIsModalVisible(false);
  };

  const submitHandler = () => {
    setWaitingForAxios(true);

    const dataToBeSend = {
      id: selectedItem.id,
      content_id: props.item.id,
      is_screen_group: selectedOption === 0 ? false : true,
      content_type: props.item.file_type,
    };

    const url = "/api/signage/casttoscreen";
    authAxios
      .post(url, dataToBeSend)
      .then((response) => {
        Toastr(
          "success",
          `Cast to ${
            selectedOption === 0 ? "screen" : "screengroup"
          } successfully!`
        );
        handleCancel();
        setWaitingForAxios(false);
      })
      .catch((error) => {
        setWaitingForAxios(false);

        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const options = [
    {
      id: 0,
      icon: screen,
      name: "Screens",
    },
    {
      id: 1,
      icon: screengroup,
      name: "Screen Groups",
    },
  ];

  return (
    <div>
      <Modals
        title="Cast"
        isOpen={props.isModalVisible}
        toggle={() => props.setIsModalVisible(false)}
        size="xl"
      >
        <ModalBody>
          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                width: "30%",
                paddingLeft: "20px",
                paddingRight: "30px",
              }}
            >
              <p style={{}}>Please select an option </p>

              <div style={{ height: "400px" }}>
                {options.map((data, index) => (
                  <Card
                    style={{
                      width: "100%",
                      height: "50px",
                      border: `${
                        data.id === selectedOption
                          ? "1.5px solid #505D69"
                          : "1px solid #DCDADA"
                      }`,
                      display: "flex",
                      marginBottom: "8px",
                      cursor: "pointer",
                      // backgroundColor: `${
                      //   data.id === selectedOption ? "red" : "white"
                      // }`,
                    }}
                    className="cast-screen-option-card"
                    onClick={() => optionHandler(data.id)}
                  >
                    <img
                      style={{
                        width: "44px",
                        marginTop: "10px",
                        marginLeft: "3px",
                      }}
                      src={data.icon}
                      alt=""
                    ></img>

                    <p
                      style={{
                        position: "absolute",
                        top: "13px",
                        left: "60px",
                      }}
                    >
                      {data.name}
                    </p>
                  </Card>
                ))}
              </div>
            </div>

            <div
              style={{
                width: "70%",
                backgroundColor: "#F5F5F5",
                height: "450px",
              }}
            >
              <InfiniteScroll
                pageStart={0}
                loadMore={loadFunc}
                hasMore={hasNextPage}
                loader={
                  <div
                    style={{
                      position: "absolute",
                      left: "64%",
                      bottom: "15%",
                    }}
                    className="loader"
                    key={0}
                  >
                    <Spinner
                      style={{
                        fontSize: "8px",
                        width: "1rem",
                        height: "1rem",
                      }}
                      animation="grow"
                    />
                  </div>
                }
              >
                <div className="cast-screen-content-container">
                  {loader ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "63%",
                        top: "40%",
                      }}
                    >
                      <Spinner animation="grow" />
                    </div>
                  ) : files.length === 0 ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "70%",
                        top: "45%",
                        transform: "translate(-70%,-60%)",
                      }}
                    >
                      <img
                        src={EmptyList}
                        style={{ width: "350px", height: "250px" }}
                      ></img>
                    </div>
                  ) : (
                    <>
                      {files?.map((data, index) => (
                        <Card
                          key={index}
                          style={{
                            height: "100px",
                            width: "340px",
                            cursor: "pointer",
                            border: `${
                              selectedItem !== null &&
                              data.id === selectedItem.id
                                ? "2px solid 	#BEBEBE"
                                : "1px solid #DCDADA"
                            }`,
                          }}
                          className="cast-screen-item-card"
                          onClick={() => setSelectedItem(data)}
                        >
                          {selectedOption === 0 && (
                            <i
                              className="ri-checkbox-blank-circle-fill"
                              style={{
                                position: "absolute",
                                left: "112px",
                                top: "12px",
                                color: "#60D394",
                                fontSize: "10px",
                              }}
                            ></i>
                          )}

                          <div
                            style={{
                              width: "115px",
                              height: "80px",
                              objectFit: "contain",
                              marginTop: "10px",
                              marginLeft: "10px",
                              border: "1px solid #DCDADA",
                              borderRadius: "5px",
                              padding: "8px",
                            }}
                          >
                            <img
                              src={selectedOption === 0 ? screen : screengroup}
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "5px",
                              }}
                            />

                            <div
                              style={{
                                position: "absolute",
                                left: "40%",
                                top: "15%",
                                fontWeight: "bold",
                                fontSize: "14px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: "180px",

                                color: "#8D9091",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: "-5px",
                                  textTransform: "capitalize",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {data?.name}
                              </p>

                              <p
                                style={{
                                  fontSize: "13px",
                                  marginBottom: "-8px",
                                }}
                              >
                                {selectedOption === 1 && data.screen_screengroup
                                  ? data.screen_screengroup.length + "  Screens"
                                  : data.device_name}
                              </p>
                              <p style={{ fontSize: "13px" }}>
                                {data.model_name && data.model_name}
                              </p>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>

          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              className="w-xs waves-effect waves-light me-1"
              onClick={submitHandler}
              disabled={selectedItem === null || waitingForAxios}
              style={{ width: "120px" }}
            >
              {waitingForAxios ? (
                <Spinner
                  style={{ marginTop: "2px" }}
                  animation="grow"
                  size="sm"
                />
              ) : (
                "Set to Screen"
              )}
            </Button>
            <Button
              type="button"
              color="danger"
              className="w-xs waves-effect waves-light me-1"
              onClick={handleCancel}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modals>
    </div>
  );
}
