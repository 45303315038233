import React, { useEffect, useState } from "react";
import { Row, Col, Button, Container, Label, Spinner } from "reactstrap";
import IconButton from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import logodark from "../../../assets/images/signage logo dark.png";
import { publicAxios, updateToken } from "../../../axiosInstance";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import AbaciLoader from "../../../components/AbaciLoader/AbaciLoader";
import { setLogin, setLogOut, setTenant, setTrail } from "../../../store/auth";
import { useNavigate } from "react-router-dom";
import { APP_NAME } from "../../../helpers/Constants";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";

import google from "../../../assets/images/google.png";
import CustomizedCheckbox from "../../../components/CustomCheckBox";

function SocialLogin() {
  // TODO This page does not require any signup or submit as it is only user for routing.
  const [waitingForGoogleAxis, setWaitingForGoodleAxis] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [waitingForAxis, setWaitingForAxis] = useState(false);
  const [multiTenantFound, setMultiTenantFound] = useState(false);
  const [multiTenantList, setMultiTenantList] = useState([]);
  const [error, setError] = useState(null);
  const [authData, setAuthData] = useState(null);
  const auth = useSelector((state) => state.authSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const setTenantFunc = (tenant) => {
    document.cookie = `tenant=${JSON.stringify(tenant)}`;
    dispatch(setLogin({ user: authData.user }));
    for (let i = 0; i < authData.tenants_details.length; i++) {
      if (authData.tenants_details[i]?.schema_name === tenant) {
        dispatch(setTenant(authData.tenants_details[i]));
      }
    }
    dispatch(setLogin({ user: authData.user }));
    navigate("/screens");
  };

  useEffect(() => {
    console.log(searchParams);
    Cookies.remove("token");
    Cookies.remove("tenant");
    const error = searchParams.get("error");
    if (error === "true") {
      navigate("/screens");
    } else {
      const url = "users_api/social_login";
      const email = searchParams.get("email");
      const password = searchParams.get("key");
      const social_type = searchParams.get("social_type");
      const data = {
        email: email,
        password,
        social_type,
      };
      console.log(error, "Error");
      console.log(password, "Pass");

      publicAxios
        .post(url, data)
        .then((response) => {
          setWaitingForAxis(false);
          const cookie = Cookies.get("token");
          const tenant = response.data?.tenants[0];
          // console.log(response.data);
          if (response.data?.tenants?.length > 1) {
            // Save the data for tenant selection
            setAuthData(response.data);
            setMultiTenantList(response.data.tenants);
            setMultiTenantFound(true);
          } else {
            updateToken(cookie, tenant);

            // console.log(response.data?.tenants[0]);
            document.cookie = `tenant=${JSON.stringify(
              response.data?.tenants[0]
            )}`;
            dispatch(setLogin({ user: response.data?.user }));
            dispatch(setTenant(response.data?.tenants_details[0]));
            dispatch(setTrail(response.data.trial_expired))

            if (response.data?.tenants_details[0].app_type === "Admin") {
              navigate("/dashboard");
            } else {
              navigate("/screens");
            }
          }
        })
        .catch((error) => {
          dispatch(setLogOut());
          let errorMessage = null;
          if (error.response?.status === 403) {
            errorMessage = error?.response?.data?.detail;
          } else {
            errorMessage =
              "Error occured, please check your connection and try again!";
          }
          setWaitingForAxis(false);
          Toastr("error", errorMessage);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUpHandler = () => {
    setWaitingForGoodleAxis(true);
    const url = "signup_api/google";
    publicAxios.get(url).then((response) => {
      window.location.href = response.data.url;
      setWaitingForGoodleAxis(false);
    });
  };

  const onSubmit = (data) => {
    setWaitingForAxis(true);
    const url = "users_api/login";
    publicAxios
      .post(url, data)
      .then((response) => {
        // console.log(response.data);
        setWaitingForAxis(false);
        if (response.data?.tenants?.length > 1) {
          // Save the data for tenant selection
          setAuthData(response.data);
          setMultiTenantList(response.data.tenants);
          setMultiTenantFound(true);
        } else {
          document.cookie = `tenant=${JSON.stringify(
            response.data?.tenants[0]
          )}`;
          dispatch(setLogin({ user: response.data?.user }));
          dispatch(setTenant(response.data?.tenants_details[0]));

          dispatch(setTrail(response.data.trial_expired))

          if (response.data?.tenants_details[0].app_type === "Admin") {
            navigate("/dashboard");
          } else {
            navigate("/screens");
          }
          // navigate("/screens");
        }
      })
      .catch((error) => {
        dispatch(setLogOut());
        let errorMessage = null;
        if (error.response?.status === 403) {
          errorMessage = error?.response?.data?.detail;
        } else {
          errorMessage =
            "Error occured, please check your connection and try again!";
        }
        setWaitingForAxis(false);
        Toastr("error", errorMessage);
      });
  };
  if (error !== null) {
    return <Error error={error} />;
  }
  if (auth.loggedIn === null || auth.loggedIn) {
    return <AbaciLoader />;
  } else {
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/">
                              <img
                                  src={logodark}
                                  alt=""
                                  height="50"
                                  className="auth-logo logo-dark mx-auto mt-4 mb-4"
                                />
                                <img
                                  src={logodark}
                                  alt=""
                                  height="50"
                                  className="auth-logo logo-light mx-auto mt-4 mb-4"
                                />
                              </Link>
                            </div>
                            <h4 className="font-size-18 mt-1">Welcome !</h4>
                            <p className="text-muted">
                              Sign in to continue to ABACISIGNAGE !
                            </p>
                          </div>

                          {multiTenantFound ? (
                            <>
                              <p className="text-muted text-center">
                                Multiple account found
                              </p>
                              <p
                                className="text-muted text-center"
                                style={{ marginBottom: "-10px" }}
                              >
                                Please click on the desired account to proceed
                              </p>
                              {waitingForAxis ? (
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <div
                                  className="p-2 mt-5  tenant-list"
                                  style={{
                                    maxHeight: "300px",
                                    overflow: "auto",
                                    cursor: "pointer",
                                    marginTop: "",
                                  }}
                                >
                                  {multiTenantList.map((item, index) => {
                                    return (
                                      <Button
                                        key={index}
                                        style={{
                                          width: "200px",
                                          marginBottom: "10px",
                                          marginLeft: "60px",
                                        }}
                                        onClick={() => setTenantFunc(item)}
                                      >
                                        {item}
                                      </Button>
                                    );
                                  })}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center mb-3">
                                <div
                                  className="d-flex flex-column "
                                  style={{ width: "325px" }}
                                >
                                  <IconButton
                                    onClick={() => signUpHandler("google")}
                                    className="btn"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "black",
                                      marginTop: "10px",
                                      borderColor: "#CED4DA",
                                      textTransform: "none",
                                      // borderColor: "#CED4DA",
                                      boxShadow: "30px",
                                      // fontFamily: "Lato",
                                    }}
                                    variant="outlined"
                                    startIcon={
                                      waitingForGoogleAxis ? (
                                        <div
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                        >
                                          <Spinner
                                            animation="grow"
                                            size="sm"
                                            style={{
                                              marginBottom: "2px",
                                              color: "green",
                                              fontSize: "8px",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          src={google}
                                          alt="Stroke"
                                        />
                                      )
                                    }
                                  >
                                    Login with Google
                                  </IconButton>
                                </div>
                              </div>
                              <div
                                className="d-flex justify-content-space-between"
                                style={{ marginLeft: "15px" }}
                              >
                                <hr style={{ width: "45%" }} />
                                or <hr style={{ width: "45%" }} />
                              </div>

                              <div className="p-2 mt-3">
                                <form
                                  className="form-horizontal"
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  {/* register your input into the hook by invoking the "register" function */}
                                  <div className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="email">Username</Label>
                                    <input
                                      className="form-control"
                                      placeholder="Enter Username"
                                      {...register("email", {
                                        required: true,
                                        // pattern:
                                        //   /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      })}
                                    />

                                    {errors.username?.type === "required" && (
                                      <span
                                        style={{
                                          color: "#bf3d48",
                                        }}
                                      >
                                        This field is required
                                      </span>
                                    )}
                                    {/* {errors.username?.type === "pattern" && (
                                    <span
                                      style={{
                                        color: "#bf3d48",
                                      }}
                                    >
                                      Please provide a valid email address
                                    </span>
                                  )} */}
                                  </div>
                                  <div className="auth-form-group-custom mb-1">
                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="email">Password</Label>
                                    <input
                                      className="form-control"
                                      placeholder="Enter password"
                                      type="password"
                                      {...register("password", {
                                        required: true,
                                      })}
                                    />
                                    {errors.password && (
                                      <span
                                        style={{
                                          color: "#bf3d48",
                                        }}
                                      >
                                        Please provide your password
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                    }}
                                  >
                                    <CustomizedCheckbox
                                      // checked={checked2}
                                      // onChange={checkBoxHandleChange2}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                    <p
                                      style={{
                                        marginTop: "7px",
                                      }}
                                    >
                                      Remember me
                                    </p>
                                  </div>

                                  <div className="mt-3 text-center">
                                    <Button
                                      className="w-md waves-effect waves-light"
                                      type="submit"
                                      style={{
                                        backgroundColor: "#5664D2",
                                        borderColor: "#5664D2",
                                      }}
                                    >
                                      {waitingForAxis ? (
                                        <Spinner animation="grow" size="sm" />
                                      ) : (
                                        "Log In"
                                      )}
                                    </Button>
                                    {/* <p style={{ color: "red" }}>{responseError}</p> */}
                                  </div>
                                  <div className="mt-3 text-center">
                                    <Link
                                      to="/forgotpassword"
                                      className="text-muted"
                                    >
                                      <i className="mdi mdi-lock me-1"></i>{" "}
                                      Forgot your password?
                                    </Link>
                                  </div>
                                </form>
                                <div className="mt-2 text-center text-mute">
                                  <p>
                                    Don't have an account?{" "}
                                    <Link
                                      to="/register"
                                      style={{ color: "#74788D" }}
                                    >
                                      <u>Register</u>
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                          <div className=" text-center">
                            <p>
                              © {new Date().getFullYear()} {APP_NAME}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SocialLogin;
