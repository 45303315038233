import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import axios from "axios";
import { baseURL } from "./baseURL";
import { WebsocketProvider } from './context/WebsocketContext'
import { CanvasProvider } from './context/CanvasContext'

axios.interceptors.request.use((request) => {
  // const url = 
  request.baseURL = baseURL;
  // request.baseURL = 'http://' + localStorage.getItem('tenant')+'.abacisignage.com';
  request.headers.Authorization =
    "Bearer " + localStorage.getItem("access_token")
  return request;
});
// ReactDOM.render(
//   <Provider store={store}>
//     {/* <React.StrictMode> */}
//       <App />
//     {/* </React.StrictMode> */}
//   </Provider>,
//   document.getElementById("root")
// );
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* // <React.StrictMode> */}
    <WebsocketProvider>
    <CanvasProvider>
      <App />
    </CanvasProvider>
    </WebsocketProvider>
    {/* // </React.StrictMode> */}
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
