import {
  Button,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useRef, useState } from "react";

import { Card } from "react-bootstrap";
// import AddCertification from "./AddCertificate";
// import EditCertificate from "./EditCertificate";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
// import ImageCropper from "./ImageCropper";
import ImageCropper from "../../../../components/ImageCropper";
import { authAxios, authAxiosFileUpload } from "../../../../axiosInstance";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import urlMaker from "../../../../helpers/urlMaker";

function EditHelpCenter() {
  const videoRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const inputFile = useRef(null);
  const inputFile1 = useRef(null);

  const [imageORVideo, setImageORVideo] = useState("");
  const [addModalShow, setAddModalShow] = useState(false);
  const [waitingForAxios, setwaitingForAxios] = useState(false);

  const [videoData, setVideoData] = useState("");
  const [imageData, setImageData] = useState([]);
  const [thumbImageDataB64, setThumbImageDataB64] = useState("");
  const [imageDataB64, setImageDataB64] = useState("");
  const [VideoDataB64, setVideoDataB64] = useState("");
  const [count, setCount] = useState("");
  const editData = useLocation();
  const [loader, setLoader] = useState(true);
  const [thumbImageData, setThumbImageData] = useState([]);

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm();

  useEffect(() => {
    const url = `/abaciadmin_api/helpcenter/${id}`;
    authAxios
      .get(url)
      .then((response) => {
        reset(response.data);

        if (response.data.file_type === "image") {
          setImageDataB64(
            urlMaker(response.data.file, "help_center_content", true)
          );
        } else {
          setVideoDataB64(
            urlMaker(response.data.file, "help_center_content", true)
          );
        }

        setImageORVideo(response.data.file_type);
        setLoader(false);
      })
      .catch((error) => {
        let error_msg = Error(error);
        Toastr("error", error_msg);
        setLoader(false);
      });
  }, []);
  const onSubmit = (data) => {
    console.log(thumbImageData.length);
    setwaitingForAxios(true);

    let formData = new FormData(); //formdata object

    formData.append("sub_heading", data.sub_heading);
    formData.append("heading", data.heading);
    formData.append("order", data.order);

    // if (
    //   imageORVideo === "video" &&
    //   thumbImageDataB64 !== "" &&
    //   thumbImageData.length !== 0
    // ) {
    //   formData.append("thumbnail", thumbImageDataB64);
    // }
    if (
      imageORVideo === "image" &&
      imageDataB64 !== "" &&
      imageData.length !== 0
    ) {
      formData.append("file", imageDataB64);
    }

    if (
      imageORVideo === "video" &&
      videoData !== "" &&
      imageData.length !== 0
    ) {
      formData.append("file", videoData);
    }
    formData.append("file_type", imageORVideo);

    const url = `/abaciadmin_api/supportpagecontents/${id}`;

    authAxiosFileUpload
      .patch(url, formData)
      .then((response) => {
        setwaitingForAxios(false);
        // dispatch(updateHome(response.data));
        Toastr("success", "Content updated succesfully");
        navigate("/helpcenter");
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };

  const handleAcceptedFiles = (files) => {
    setImageData(files);
    const reader = new FileReader();
    const uploadedFile = files[0];

    setImageORVideo(uploadedFile.type.split("/")[0]);

    if (uploadedFile.name.match(/\.(jpg|jpeg|JPG|JPEG|png|PNG)$/)) {
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(uploadedFile);
      addModalToggle();
    } else if (uploadedFile.name.match(/\.(mp4|MP4)$/)) {
      setThumbImageDataB64("");

      reader.onload = () => {
        setVideoData(uploadedFile);

        const temp = URL.createObjectURL(uploadedFile);

        videoRef.current.src = temp;
        // setVideoDataB64(reader.result);
      };

      reader.readAsDataURL(uploadedFile);
    } else {
      Swal.fire({
        title: "Oops...",
        text: "Invalid File Format",
        icon: "error",
      });
    }
  };

  const handleThumbnailFiles = (files) => {
    setThumbImageData(files);
    const reader = new FileReader();
    const uploadedFile = files[0];

    if (uploadedFile.name.match(/\.(jpg|jpeg|JPG|JPEG)$/)) {
      addModalToggle();
    } else {
      Swal.fire({
        title: "Oops...",
        text: "Invalid File Format",
        icon: "error",
      });
    }
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(uploadedFile);
  };

  const addModalToggle = () => {
    setAddModalShow(!addModalShow);
  };

  //   const imageEditHandler = (id) => {
  //     setCount(id);
  //   };

  return (
    <React.Fragment>
      {addModalShow && (
        <ImageCropper
          isOpen={addModalShow}
          toggle={addModalToggle}
          image={image}
          setImageDataB64={setImageDataB64}
        />
      )}

      <div className="page-content">
        {loader ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <Spinner animation="grow" />
          </div>
        ) : (
          <Container fluid>
            <Card style={{}}>
              <CardBody className="helpcenter-details-container">
                <div
                  className="sub-container-edit1"
                  // style={{ marginLeft: "20px", marginTop: "10px", width: "50%" }}
                >
                  <Form>
                    <Label for="Heading" sm={3}>
                      Heading
                    </Label>
                    <FormGroup row style={{ marginBottom: "10px" }}>
                      <Col sm={9}>
                        <input
                          placeholder="Heading"
                          type="text"
                          id="Heading"
                          className="form-control"
                          {...register("heading", {
                            required: true,
                          })}
                        />
                        {errors.heading?.type === "required" && (
                          <span style={{ color: "red" }}>
                            *This field is required
                          </span>
                        )}
                      </Col>
                    </FormGroup>

                    <Label for="Sub Heading" sm={3}>
                      Sub Heading
                    </Label>
                    <FormGroup row style={{ marginBottom: "10px" }}>
                      <Col sm={9}>
                        <textarea
                          className="form-control"
                          id="Sub Heading"
                          rows="5"
                          cols="33"
                          placeholder="Sub Heading"
                          {...register("sub_heading", {
                            required: true,
                          })}
                        />
                        {errors.body?.type === "required" && (
                          <span style={{ color: "red" }}>
                            *This field is required
                          </span>
                        )}
                      </Col>
                    </FormGroup>
                    <Label for="Description" sm={3}>
                      Description
                    </Label>
                    <FormGroup row style={{ marginBottom: "10px" }}>
                      <Col sm={9}>
                        <textarea
                          className="form-control"
                          rows="5"
                          cols="33"
                          placeholder="Description"
                          {...register("description", {
                            required: true,
                          })}
                        />
                        {errors.description?.type === "required" && (
                          <span style={{ color: "red" }}>
                            *This field is required
                          </span>
                        )}
                      </Col>
                    </FormGroup>

                    <Label for="Order" sm={3}>
                      Order
                    </Label>
                    <FormGroup row style={{ marginBottom: "10px" }}>
                      <Col sm={9}>
                        <input
                          placeholder="Order No"
                          id="Order"
                          type="number"
                          min="0"
                          onKeyDown={(evt) =>
                            evt.key === "-" ||
                            evt.key === "e" ||
                            evt.key === "."
                              ? evt.preventDefault()
                              : null
                          }
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          {...register("order", {
                            required: true,
                          })}
                        />
                        {errors.order?.type === "required" && (
                          <span style={{ color: "red" }}>
                            *This field is required
                          </span>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </div>

                <div className="sub-container-edit2">
                  {imageORVideo === "image" || imageORVideo === "Image" ? (
                    <>
                      <div
                        className="details-video-container"
                        style={{ minHeight: "400px" }}
                      >
                        <Label
                          style={{ color: "black" }}
                          for="Media File"
                          sm={3}
                        >
                          Media File
                        </Label>
                        <img
                          className="fade-in-image"
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            borderRadius: "10px",
                          }}
                          src={imageDataB64}
                          alt=""
                        />
                        <div
                          className="upload-btn"
                          onClick={() => {
                            inputFile.current.click();
                            //   imageEditHandler(1);
                          }}
                        >
                          <EditIcon
                            style={{ color: "white" }}
                            className="mt-2"
                          />
                        </div>
                        <input
                          required
                          type="file"
                          id="Media File"
                          // accept="image/jpeg,video/mp4"
                          accept="image/*,video/*"
                          onChange={(event) =>
                            handleAcceptedFiles(event.target.files)
                          }
                          ref={inputFile}
                          style={{ display: "none" }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <Label style={{ color: "black" }} for="Video File" sm={3}>
                        Media File
                      </Label>
                      <div className="details-video-container">
                        <video
                          style={{
                            height: "300px",
                            width: "100%",
                            borderRadius: "5px",
                            marginBottom: "20px",
                            objectFit: "cover",
                          }}
                          autoPlay
                          controls
                          ref={videoRef}
                        >
                          <source src={VideoDataB64} type="video/mp4" />
                        </video>

                        <div
                          className="upload-btn2"
                          onClick={() => {
                            inputFile.current.click();
                            //   imageEditHandler(1);
                          }}
                        >
                          <EditIcon
                            style={{ color: "white" }}
                            className="mt-2"
                          />
                        </div>
                        <input
                          type="file"
                          required
                          accept="image/jpeg,video/mp4"
                          id="Video File"
                          onChange={(event) =>
                            handleAcceptedFiles(event.target.files)
                          }
                          ref={inputFile}
                          style={{ display: "none" }}
                        />
                      </div>
                      {/* <Label
                      style={{ marginLeft: "30px" }}
                      for="Thumbnail"
                      sm={3}
                    >
                      Thumbnail
                    </Label>
                    <div className="details-video-container">
                      <img
                        className="fade-in-image"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          borderRadius: "10px",
                          minHeight: "300px",
                        }}
                        src={thumbImageDataB64}
                        alt=""
                      />
                      <div
                        className="details-upload-btn"
                        onClick={() => {
                          inputFile1.current.click();
                          imageEditHandler(2);
                        }}
                      >
                        <EditIcon style={{ color: "white" }} className="mt-2" />
                      </div>
                      <input
                        required
                        id="Thumbnail"
                        type="file"
                        accept="image/*"
                        onChange={(event) =>
                          handleThumbnailFiles(event.target.files)
                        }
                        ref={inputFile1}
                        style={{ display: "none" }}
                      />
                    </div> */}
                    </>
                  )}

                  <div className="d-flex justify-content-end mt-5">
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      color="primary"
                      disabled={waitingForAxios}
                      style={{ marginRight: "10px", width: "100px" }}
                    >
                      {waitingForAxios ? (
                        <Spinner animation="grow" size="sm" />
                      ) : (
                        "Save"
                      )}
                      {/* Save */}
                    </Button>
                    <Button
                      onClick={() => navigate("/helpcenter")}
                      color="danger"
                      style={{ width: "100px", marginRight: "10px" }}
                    >
                      Go Back
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}

export default EditHelpCenter;
