import React, {  useState } from "react";
// import { useNavigate } from "react-router-dom";
import Weather from "./icons/weather2.svg";
import Clock from "./icons/clock2.svg";
import Youtube from "./icons/youtube2.svg";

import TextBoard from "./icons/textBoard2.svg";
import { Card, CardImg, CardBody, CardTitle } from "reactstrap";
import {  useSelector } from "react-redux";
import AppViewModal from "./AppViewModal";

function AppGallery({ item, deleteHandler, editModalHandler, modalToggle }) {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const clockList = useSelector((state) => state.clockSlice.user_data);
  const youTubeList = useSelector((state) => state.youTubeSlice.user_data);
  // const textList = useSelector((state) => state.textSlice.user_data);
  // const weatherList = useSelector((state) => state.weatherSlice.user_data);
  const [appType,setAppType]=useState()
  const [isModalOpen,setModalOpen]=useState(false)
  
  function modalToggle(){
    // console.log('wrked')
    setModalOpen(!isModalOpen)
  }

  return (
    <React.Fragment>

      {isModalOpen &&
        <AppViewModal
        isOpen={isModalOpen}
        toggle={modalToggle}
        app={appType}
        />
      }

      <div className="abaci-app-cards">
        <Card className="abaci-item-card">
          <CardBody>
            <CardTitle className="h4">Clock</CardTitle>
          </CardBody>

          <CardImg
            className="img-fluid mt-1 "
            src={Clock}
            alt="App image"
            onClick={() => {
              modalToggle();
              // navigate("/clock");
              setAppType(0)
            }}
          />
          <CardBody>
            <p
              className=" text-center "
              style={{ color: "#67818f", marginTop: "35px" }}
            >
              {/* {clockList.length + " Instance Created"} */}0 Instance Created
            </p>
          </CardBody>
        </Card>

        <Card className="abaci-item-card">
          <CardBody>
            <CardTitle className="h4">Weather</CardTitle>
          </CardBody>

          <CardImg
            className="img-fluid  mt-1"
            src={Weather}
            alt="Weather image"
            onClick={() => {
              modalToggle();

              setAppType(1)

              // navigate("/weather");
            }}
          />
          <CardBody>
            <p
              className=" text-center "
              style={{ color: "#67818f", marginTop: "35px" }}
            >
              {/* {weatherList.length + " Instance Created"} */}0 Instance
              Created
            </p>
          </CardBody>
        </Card>

        <Card className="abaci-item-card ">
          <CardBody>
            <CardTitle className="h4">Youtube</CardTitle>
          </CardBody>

          <CardImg
            className="img-fluid mt-1"
            src={Youtube}
            alt="Youtube image"
            onClick={() => {
              modalToggle();

              setAppType(2)
              // navigate("/youtube");
            }}
          />
          <CardBody>
            <p
              className=" text-center "
              style={{ color: "#67818f", marginTop: "35px" }}
            >
              {youTubeList === null
                ? " 0 Instance Created"
                : youTubeList?.length + " Instance Created"}
            </p>
          </CardBody>
        </Card>

        <Card className="abaci-item-card  ">
          <CardBody>
            <CardTitle className="h4">Text</CardTitle>
          </CardBody>

          <CardImg
            className="img-fluid mt-1 "
            src={TextBoard}
            alt="Text image"
            onClick={() => {
              modalToggle();

              setAppType(3)
              // navigate("/texteditor");
            }}
          />
          <CardBody>
            <p
              className=" text-center "
              style={{ color: "#67818f", marginTop: "35px" }}
            >
              0 Instance Created
            </p>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default AppGallery;
