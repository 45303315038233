import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  loggedIn: null,
  profile: null,
  tenantDetails: null,
  trialDetails:null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin(state, action) {
      state.loggedIn = true;
      state.profile = action.payload.user
    },
    setLogOut(state) {
      state.loggedIn = false;
      state.profile = null;
      state.tenant_detail = null;
      state.trialDetails = null;
      Cookies.remove("token");
      Cookies.remove("tenant");
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
    setTenant(state, action) {
      state.tenantDetails = action.payload;
    },
    setTrail(state,action) {
      state.trialDetails = action.payload
    }
  },
});

export const { setLogin, setLogOut , setProfile, setTenant ,setTrail} =
  authSlice.actions;
export default authSlice.reducer;