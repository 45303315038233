import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Faq from "../../assets/images/faqquestion.svg";
// import Presentation from "../../assets/images/presentation.svg";
import shape2 from "../../assets/images/shapes/cta-1-shape-2.png";
export default function faq2() {
  return (
    <section className='faq2-one'>
            <img src={shape2} className="cta-one__bg-shape-2" alt="" />
      {/* <img src={faqShape} className="faq-one-shape-1" alt="" /> */}
      <Container>
        <div className='faq2sub'>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "40px",
                padding: "20px",
              }}>
              <img src={Faq} alt='' width='60' />
              <div>
                <h1 style={{ margin: "15px" }}>Can we help you?</h1>
                <h4>Contact us</h4>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                padding: "20px",
              }}>
              <p style={{ maxWidth: "350px",fontSize:'16px' }}>
                Get in touch by <Link style={{color:'#4576BD',textDecoration:'none'}} to='/contact'>submitting a question </Link>and we'll reach out
                as soon as we can.
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "65px",
                padding: "20px",
              }}>
              {/* <img src={Presentation} alt='' width='60' /> */}
              <div>
                <h1 style={{ margin: "15px" }}>Longer question?</h1>
                <h4>Request a demo.</h4>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                padding: "20px",
              }}>
              <p style={{ maxWidth: "350px",fontSize:'16px'}}>
                For in-depth questions, request a <Link style={{color:'#4576BD',textDecoration:'none'}} to='/demo'>free web demo </Link>and our team
                will get in touch to schedule a consultation.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
