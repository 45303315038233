import React, { useContext, useEffect, useState } from 'react'
import TopComponent from './TopComponent'
import { useParams } from 'react-router-dom'
import { authAxios } from '../../../../../axiosInstance';
import { Toastr, topRightToastr } from '../../../../../helpers/Alert';
import Error from '../../../../../helpers/Error';
import { Card, CardBody } from 'reactstrap';
import AbaciLoader from '../../../../../components/AbaciLoader/AbaciLoader';
import DetailComponent from './DetailComponent';
import { WebsocketContext } from '../../../../../context/WebsocketContext';
import { setCurrentPage, setSearchDisabled, setSearchkeyWord } from '../../../../../store/searchSlice';
import { useDispatch } from 'react-redux';

function ScreenDetailView() {

  const dispatch=useDispatch();
  const screenDetails = useContext(WebsocketContext);
  const [data,setData] = useState(null)
    const {id} =useParams();

  useEffect(() => {
    // console.log(screenDetails)
    // dispatch(updateScreenStatus(screenDetails.screens))
    if(data !== null){

    if(screenDetails?.messageType === 'single_screen_status'){

      let screen_id=screenDetails?.screens[0].screen_id
      if(data?.screen_id === screen_id){


        if(screenDetails?.screens[0]?.online_status){
          setData((data) => ({...data,online_status:true}))
          topRightToastr('success',`${screenDetails?.screens[0].screen_name} is Online !!`)
        }
        else{
          setData((data) => ({...data,online_status:false}))
          topRightToastr('error',`${screenDetails?.screens[0].screen_name} is Offline !!`)  
        }
      }
    }
      // dataFetcher()
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenDetails])

  

    useEffect(() => {

        //Done inorder to activate or deactivate search box input
        dispatch(setCurrentPage('screen_details'))
        dispatch(setSearchDisabled(true))
        dispatch(setSearchkeyWord(null))

        const url=`/api/signage/screensrud/${id}`
        authAxios.get(url)
        .then((res) => {
            setData(res.data)
        })
        .catch(err => {
            const err_msg=Error(err)
            Toastr('error',err_msg)
        })

        return () => {
          dispatch(setSearchDisabled(false))
        }
    }, [])
    
  return (
    <React.Fragment>
    {data === null ? <AbaciLoader /> :
      <div >

        <Card style={{ height: '105px', marginTop: '71px', marginBottom: '1px' }}>
          <CardBody>
            <TopComponent data={data} />
          </CardBody>
        </Card>

        <DetailComponent data={data} />
 

      </div>
}
</React.Fragment>
  )
}

export default ScreenDetailView