import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Container, Row } from "reactstrap";

// import Tooltip from "@mui/material/Tooltip";

import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { APP_NAME } from "../../../../helpers/Constants";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
// import { fontFamily } from "@mui/system";
import { authAxios } from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { AddCustomerDetails } from "../../../../store/customersStore";
// import fileDownload from "js-file-download";
// import DownloadIcon from "@mui/icons-material/Download";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import AbaciLoader from "../../../../components/AbaciLoader/AbaciLoader";
import Error from "../../../../helpers/Error";
import { Toastr } from "../../../../helpers/Alert";
// import pic from "../../../../assets/images/thubnail.png";
// import MediaViewerAdmin from "../MediaViewerAdmin";
import TableComponent from "./TableComponent";
export const CustomerAssetsDetails = (props) => {
  const { id } = useParams();
  // const {state}=useLocation();
  const tableRef = useRef();
  const navigate = useNavigate();

  // const [urlBackup, setUrlBackup] = useState("");
  // const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  // const [customer, setCustomer] = useState([]);
  // const customer = useSelector((state) => state.customerSlice.customer);
  const dispatch = useDispatch();
  // const [loader, setLoader] = useState(true);
  const [tenant_name] = useState(useLocation()?.state);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Customer", link: "/customer" },
      { title: "Customer Assets", link: "#" },
    ],
  });
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    tableRef.current.onQueryChange({ page: 0, filters: [], search: "" });
  };

  let styles = {
    fontSize: "0.85rem",
    textTransform: "none",
  };

  function deleteUpdateHandler(type) {
    if (type === "delete") {
      const url = `/public_api/client/${id}`;

      authAxios
        .delete(url)
        .then((res) => {
          navigate("/customer");
          Toastr("success", "Customer Deleted Successfully");
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    } else {
      const dataForSubmission = { status: type };

      const url = `/public_api/update_client_status/${id}`;

      authAxios
        .put(url, dataForSubmission)
        .then((res) => {
          // navigate('/customer')
          dispatch(AddCustomerDetails(res.data));
          Toastr("success", `Customer Updated Successfully`);
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
  }

  return (
    <React.Fragment>
      {/* {loader ? (
        <AbaciLoader />
      ) : ( */}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Customer Assets"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>

          <div>
            <Card style={{}}>
              <CardBody>
                <Box sx={{ width: "100%", marginBottom: "5px" }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="primary tabs example"
                  >
                    <Tab label="Media" style={styles} />
                    <Tab label="Apps" style={styles} />
                    <Tab label="Canvas" style={styles} />
                    <Tab label="Templates " style={styles} />
                    <Tab label="Weblinks" style={styles} />
                    <Tab label="Schedulers" style={styles} />
                  </Tabs>
                </Box>
                <TableComponent
                  activeTab={tabValue}
                  tenant={tenant_name}
                  tableRef={tableRef}
                />
                {/* </Row> */}
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
      {/* )} */}
    </React.Fragment>
  );
};
