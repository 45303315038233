import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import shape1 from "../../assets/images/shapes/cta-1-shape-1.png";
import shape2 from "../../assets/images/shapes/cta-1-shape-2.png";
import shape3 from "../../assets/images/shapes/cta-1-shape-3.png";
import moc from "../../assets/images/resources/cat-1-moc-1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { CALL_TO_ACTION_DATA } from "../../data/index";

// import Ellipse from "../../assets/images/callToAction/Ellipse.png";
SwiperCore.use([Pagination, Autoplay]);
const CallToAction = () => {
  const CarouselOptions = {
    loop : true,
    speed: 1000,
    spaceBetween: 0,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    // pagination: {
    //   // el: "#app-shot-swiper-pagination",
    //   type: "bullets",
    //   clickable: "true",
    // },
    // slidesPerView: 5,
    // slidesPerGroup: 5,
    // // Responsive breakpoints
    // breakpoints: {
    //   0: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 30 },
    //   480: { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 30 },
    //   600: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 30 },
    //   991: { slidesPerView: 4, slidesPerGroup: 4, spaceBetween: 30 },
    //   1000: { slidesPerView: 5, slidesPerGroup: 4, spaceBetween: 30 },
    //   1200: { slidesPerView: 5, slidesPerGroup: 5, spaceBetween: 30 },
    // },
  };
  const CarouselOptions2 = {
    loop : true,
    preloadImages: true,
    lazy: 'true',
    speed: 1000,
    spaceBetween: 0,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    // pagination: {
    //   // el: "#app-shot-swiper-pagination",
    //   type: "bullets",
    //   clickable: "true",
    // },
    // slidesPerView: 5,
    // slidesPerGroup: 5,
    // // Responsive breakpoints
    // breakpoints: {
    //   0: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 30 },
    //   480: { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 30 },
    //   600: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 30 },
    //   991: { slidesPerView: 4, slidesPerGroup: 4, spaceBetween: 30 },
    //   1000: { slidesPerView: 5, slidesPerGroup: 4, spaceBetween: 30 },
    //   1200: { slidesPerView: 5, slidesPerGroup: 5, spaceBetween: 30 },
    // },
  };
  const { sectionTitle, paragraph, posts, button } = CALL_TO_ACTION_DATA;
  // const { sectionTitle, posts } = APP_SHOT_DATA;
  return (
    <section className='cta-one' style={{ paddingTop: "60px" }} id='solutions'>
      <img src={shape1} className='cta-one__bg-shape-1' alt='' />
      <img src={shape2} className='cta-one__bg-shape-2' alt='' />
      {/* <img
        src={Ellipse}
        alt=""
        style={{ position: "absolute", top: "0px", left: "130px" }}
      /> */}

      <Container>
        <div className='block-title text-center'>
          <h3 style={{ fontFamily: "lato", color: "#fa496e" }}>
            {sectionTitle.text}
          </h3>
        </div>
        
        <div style={{ display:'flex',justifyContent:'space-between',flexWrap:'wrap' }}>
          <div className='soloutionRound'>
            <div className='container_tv'>
              <div className='monitor_tv'>
                {/* <div ></div> */}

                <Swiper className='monitorscreen_tv' {...CarouselOptions2}>
                  {posts.map(({ image, title, content }, index) => (
                    <SwiperSlide
                      className='item'
                      key={`app-shot-key-${index}`}
                      style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <img
                          src={image}
                          alt='Awesome Image'
                          style={{

                            width:"310px",
                            height:"540px",
                          }}
                        />
                      </div>

                      {/* <div style={{marginLeft:'135px'}}>
              <h3 style={{color:'black',fontWeight:'bold',fontFamily:'Lato'}}>{title}</h3>
              <p style={{color:'black',fontWeight:'300',fontFamily:'Lato'}}>{content}</p>
              </div> */}

                      {/* <p>rty</p> */}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        
        
          <Swiper
            style={{ width: "500px", marginRight: "0px" }}
            {...CarouselOptions}>
            {posts.map(({ image, title, content }, index) => (
              <SwiperSlide
                className='item'
                key={`app-shot-key-${index}`}
                style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginLeft: "10px" }}>
                  <h3
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontFamily: "Lato",
                    }}>
                    {title}
                  </h3>
                  <p
                    style={{
                      color: "black",
                      fontWeight: "100",
                      fontFamily: "Lato",
                      lineHeight: '36px',
                      fontSize:'18px'
                    }}>
                    {content}
                  </p>
                </div>

                {/* <p>rty</p> */}
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className='swiper-pagination'
            id='app-shot-swiper-pagination'></div>
          {/* <div className="soloutionRound"></div> */}
      </div>
      </Container>
    </section>
  );
};

export default CallToAction;
