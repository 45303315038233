
import React, { useState, useEffect } from "react";
import "./ScheduleCreator.css";

import ScheduleAddContentModalContents from "./ScheduleAddContentModalContents";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import WeatherImage from "../../Pages/Apps/icons/weather.svg"
import ClockImage from "../../Pages/Apps/icons/clock.svg"
import YoutubeImage from "../../Pages/Apps/icons/youtube.svg"
import TextImage from "../../Pages/Apps/icons/textBoard.svg"

import MeidaIcons from "../../../components/MediaIcons/MediaIcons";
import linkThumb from "../../../assets/images/weblinkThumb.png";
import urlMaker from "../../../helpers/urlMaker";
import { Card, Spinner } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";


export default function NonDroppableComponent(props) {

  useEffect(() => {
  }, [props])
  

  return (
    <InfiniteScroll
    pageStart={0}
    // dataLength={totalCount}
   //  next={props.loadFunc}
    loadMore={props.loadFunc}
    hasMore={props.totalCount === props?.items?.length ? false : true}
    useWindow={false}
    loader={
      <div className="text-center" key={0}>
        <Spinner animation="grow"  />
      </div>
    }
  >
    <Copyable
      droppableId='NonDroppable'
      className='non-droppable'
      items={props.items}
      loadFunc={props.loadFunc}
    
    />
    </InfiniteScroll>
  );
}
// This method is needed for rendering clones of draggables
const getRenderItem = (items, className) => (provided, snapshot, rubric) => {
  const item = items[rubric.source.index];
  return (
    <React.Fragment>
      <li
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={provided.draggableProps.style}
        className={
          snapshot.isDragging ? "media_card_small dragging" : "media_card_small"
        }>
        <div className='media_card_thumbnail-wrapper'>
          <img
            src={pickThumb(item)}
            alt='thumbnail'
            className='media_card_thumbnail small'
          />
        </div>

        <div className='media_card_file_name-small'>
          {item.uploaded_file_name}
        </div>
        {/* <div className='media_card_file_type'>
          <MeidaIcons type={item.file_type} />
        </div> */}
      </li>
    </React.Fragment>
  );
};

const pickThumb = (item) => {

  if (item.file_type === "link") {
    return linkThumb;
  } else if (
    item.file_type === "image" ||
    item.file_type === "video"
  ) {
    return urlMaker(item.thumbnail,'image');
  }
  else if(item.file_type === "canvas" ){
    return urlMaker(item.thumbnail,'canvas');
  }
  else if(item.file_type === 'weather'){
    return WeatherImage
  }
  else if(item.file_type === 'clock'){
    return ClockImage
  }
  else if(item.file_type === 'youtube'){
    if (item.thumbnail !== null)
    return urlMaker(item.thumbnail,'youtube');
    else return YoutubeImage
    }
  else if(item.file_type === 'text'){
    return TextImage
  }
};

function Copyable(props) {
  return (
    <>
        
      <Droppable
        renderClone={getRenderItem(props.items, props.className)}
        droppableId={props.droppableId}
        isDropDisabled={true}>
        {(provided, snapshot) => (
          
          <ul ref={provided.innerRef} className={props.className}>
      
            {props.items.map((item, index) => {
              const shouldRenderClone =
                item.id === snapshot.draggingFromThisWith;
              return (
                <React.Fragment key={item.id}>
                  
                  {shouldRenderClone ? (
                    <li className='react-beatiful-dnd-copy'>
                      <div className='media_card_thumbnail-wrapper' key={index}>
                        <img
                          src={pickThumb(item)}
                          alt='thumbnail'
                          className='media_card_thumbnail small'
                        />
                      </div>
                      <div className='media_card_file_name-small'>
                        {item.uploaded_file_name}
                      </div>
                      {/* <div className='media_card_file_type'>
                        <MeidaIcons type={item.file_type} />
                      </div> */}
                    </li>
                  ) : (
                    <Draggable draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <React.Fragment>
                          
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={
                              snapshot.isDragging
                                ? "media_card_small dragging"
                                : "media_card_small"
                            }>
                             
                            <div className='media_card_thumbnail-wrapper' key={index}>
                              <img
                                src={pickThumb(item)}
                                alt='thumbnail'
                                className='media_card_thumbnail small'
                              />
                            </div>
                            <div className='media_card_file_name-small'>
                              {item.uploaded_file_name}
                            </div>
                     
                            {/* <div className='media_card_file_type'>
                              <MeidaIcons type={item.file_type} />
                            </div> */}
                          </li>
                        </React.Fragment>
                      )}
                    </Draggable>
                  )}
                </React.Fragment>
              );
            })}
            {provided.placeholder}
            {/* </InfiniteScroll> */}
          </ul>
         
        )}
      </Droppable>
    </>
  );
}
