import React from 'react'
import { useSelector } from 'react-redux';
import Moments from '../../../../../helpers/Moment';
import { Card, CardBody } from 'reactstrap';
import onlineScreen from "../../../../../assets/images/tv green.png";
import offlinescreen from "../../../../../assets/images/tv red.png";
function ClientDetailTopComponent() {
    const customer = useSelector((state) => state.customerSlice.customer);

  return (
 
    <Card style={{ backgroundColor: "#F1F5F7" }}>
      <CardBody style={{ padding: "10px" }}>
        <p style={{ fontWeight: "900" }}>Client Details</p>
        <div
          className="d-flex justify-content-between"
          style={{ padding: "10px" }}
        >
          <div style={{ width: "500px" }}>
            <table
              cellSpacing="0"
              cellPadding="6"
              style={{ width: "100%" }}
            >
              <tbody>
                <tr style={{ verticalAlign: "top" }}>
                  <td>Created On</td>
                  <td style={{ width: "5%" }}> : </td>
                  <td>
                    {Moments(customer.client_data?.created_date)}
                  </td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td>Address</td>
                  <td> : </td>

                  <td style={{ width: "60%" }}>
                    {customer.client_data?.address
                      ? customer.client_data?.address
                      : "No Data"}
                  </td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td>Country</td>
                  <td> : </td>
                  <td>
                    {customer.client_data?.country
                      ? customer.client_data?.country
                      : "No Data"}
                  </td>
                </tr>
                <tr>
                  <td>Client Timezone</td>
                  <td> : </td>
                  <td>
                    {customer.client_data?.client_timezone
                      ? customer.client_data?.client_timezone
                      : "No Data"}
                  </td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td>Client Vat Number</td>
                  <td> : </td>
                  <td>
                    {customer.client_data?.client_vat_number
                      ? customer.client_data?.client_vat_number
                      : "No Data"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <p style={{ fontWeight: "950" }}>
              {customer.screens?.total_screen + "  Screens"}
            </p>
            <div
              style={{
                width: "100px",
                height: "60px",
                marginTop: "-10px",
              }}
              className="container-screen"
            >
              <div
                className="centered "
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {customer.screens?.online_screen
                  ? customer.screens.online_screen
                  : "0"}
              </div>
              <img
                src={onlineScreen}
                alt="onlinescreen"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{ width: "100px", height: "60px" }}
              className="container-screen"
            >
              <div
                className="centered"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {customer.screens?.offline_screen
                  ? customer.screens.offline_screen
                  : "0"}
              </div>
              <img
                src={offlinescreen}
                alt="onlinescreen"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
 
  )
}

export default ClientDetailTopComponent