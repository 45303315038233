import MaterialTable from "material-table";
import React, { useState } from "react";
import { tableIcons } from "../../../../helpers/MaterialIcons";
import MediaViewerAdmin from "../MediaViewerAdmin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/link.svg";

// import pic from "../../../../assets/images/thubnail.png";
import Error from "../../../../helpers/Error";
import { authAxios } from "../../../../axiosInstance";
import { Toastr } from "../../../../helpers/Alert";
import Moments from "../../../../helpers/Moment";
import { appThumbnailMaker } from "../../../../helpers/appThumbnailMaker";
import { Link } from "react-router-dom";
import adminUrlMaker from "../../../../helpers/adminUrlMaker";
import { useSelector } from "react-redux";

export default function TableComponent({ activeTab, tenant, tableRef }) {
  const [pageSize, setPageSize] = useState(5);
  const customer = useSelector((state) => state.customerSlice.customer);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [content, setContent] = useState({});
  // const table = [{ tumbnail: pic, file_type: "image", file: pic }];

  const [urlBackup, setUrlBackup] = useState("");
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const tableData = [
    {
      url: "client_files",
      column: [
        {
          // title: "Media File",

          render: (rowData) => (
            <img
              src={adminUrlMaker(rowData.thumbnail, "image", tenant)}
              alt="img"
              style={{ height: "50px", cursor: "pointer" }}
              onClick={() => ModalToggle(rowData)}
            />
          ),
          sorting: false,
        },
        {
          title: "Media  Name",
          field: "name",
        },
        {
          title: "Media size",
          field: "file_size",
        },
        {
          title: "Media Type",
          field: "file_type",
        },
        {
          title: "Is Favourite",
          field: "is_favourite",
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => (
            <>
              {rowData.created_date ? Moments(rowData.created_date) : "No Data"}
            </>
          ),
        },
        {
          title: "Created By",
          field: "created_by",
        },
      ],
    },
    {
      url: "client_object_list/app",
      column: [
        {
          // title: "Media File",

          render: (rowData) => (
            <div style={{ width: "60px", height: "60px" }}>
              <img
                src={appThumbnailMaker(rowData.file_type)}
                alt="img"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                // onClick={() => ModalToggle(rowData)}
              />
            </div>
          ),
        },
        {
          title: "App  Name",
          field: "name",
        },
        // {
        //   title: "Media size",
        //   field: "",
        // },
        {
          title: "App Type",
          field: "file_type",
        },
        {
          title: "Is Favourite",
          field: "is_favourite",
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => (
            <>
              {rowData.created_date ? Moments(rowData.created_date) : "No Data"}
            </>
          ),
        },
        {
          title: "Created By",
          field: "created_by",
        },
      ],
    },
    {
      url: "client_object_list/canvas",
      column: [
        {
          title: "Canvas",

          render: (rowData) => (
            <img
              src={adminUrlMaker(rowData.thumbnail, rowData.file_type, tenant)}
              alt="img"
              style={{ height: "50px", cursor: "pointer" }}
              onClick={() => ModalToggle(rowData)}
            />
          ),
        },
        {
          title: "Canvas Name",
          field: "name",
        },
        {
          title: "Is Favourite",
          field: "is_favourite",
        },

        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => (
            <>
              {rowData.created_date ? Moments(rowData.created_date) : "No Data"}
            </>
          ),
        },
        {
          title: "Created By",
          field: "created_by",
        },
      ],
    },
    {
      url: "client_templates",
      column: [
        {
          //   title: "Canvas",
          render: (rowData) => (
            <img
              src={adminUrlMaker(rowData.thumbnail, "template", tenant)}
              alt="img"
              style={{ height: "50px" }}
              // onClick={() => ModalToggle(rowData)}
            />
          ),
        },
        {
          title: "Template Name",
          field: "template_name",
        },
        {
          title: "Template Orientation",
          field: "orientation",
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => (
            <>
              {rowData.created_date ? Moments(rowData.created_date) : "No Data"}
            </>
          ),
        },
        {
          title: "Created By",
          field: "created_by",
        },
      ],
    },
    {
      url: "client_object_list/link",
      column: [
        {
          render: (rowData) => (
            <>
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  backgroundColor: rowData?.object?.color_code,
                  borderRadius: "5px",
                  marginRight: "20px",
                }}
              >
                <LinkIcon
                  style={{
                    margin: "20px",
                    fill: rowData?.object?.light_or_dark,
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            </>
          ),
        },
        {
          title: "Weblink Name",
          field: "name",
        },
        {
          title: "Link",
          field: "link",
          render: (rowData) => (
            <>
              {rowData?.object?.link ? (
                <Link to={rowData.object.link} className="link_tag">
                  {rowData.object.link}
                </Link>
              ) : (
                "No Data"
              )}
            </>
          ),
        },
        {
          title: "Is Favourite",
          field: "is_favourite",
        },

        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => (
            <>
              {rowData.created_date ? Moments(rowData.created_date) : "No Data"}
            </>
          ),
        },
        {
          title: "Created By",
          field: "created_by",
        },
      ],
    },
    {
      url: "client_schedules",
      column: [
        {
          title: "Sheduler Name",
          field: "schedule_name",
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => (
            <>
              {rowData.created_date ? Moments(rowData.created_date) : "No Data"}
            </>
          ),
        },

        {
          title: "Thread Status",
          field: "thread_status",
        },
        {
          title: "Created By",
          field: "created_by",
        },
      ],
    },
  ];

  const ModalToggle = (content) => {
    setContent(content);
    setIsModalVisible(!isModalVisible);
  };

  return (
    <React.Fragment>
      {isModalVisible && (
        <MediaViewerAdmin
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          data={content}
        />
      )}

      <div
        style={{
          overflowY: "auto",
        }}
      >
        <MaterialTable
          title=""
          tableRef={tableRef}
          columns={tableData[activeTab].column}
          onChangeRowsPerPage={setPageSize}
          options={{
            exportFileName: "Assets",
            exportAllData: true,
            actionsColumnIndex: -1,
            exportButton: true,
            //   filtering: filterEnabled,
            pageSize: pageSize,
          }}
          icons={tableIcons}
          // actions={[
          //   (rowData) => ({
          //     icon: VisibilityIcon,
          //     tooltip: "View Content",
          //     onClick: (event, rowData) => {
          //       ModalToggle(rowData);
          //     },
          //   }),
          // ]}

          data={(query) => {
            // console.log(query)
            return new Promise((resolve, reject) => {
              let orderBy = "";
              if (query.orderBy) {
                orderBy =
                  query.orderDirection === "asc"
                    ? `&ordering=-${query.orderBy?.field}`
                    : `&ordering=${query.orderBy?.field}`;
              }

              let url = `/abaciadmin_api/${
                tableData[activeTab].url
              }/${tenant}?limit=${query.pageSize}&offset=${
                query.pageSize * query.page
              }&search=${query.search}${orderBy}`;

              // if (Object.keys(date).length !== 0) {
              //   url += `&start_date=${date.start_date}&end_date=${date.end_date}`;
              // }
              setUrlBackup(url);
              authAxios
                .get(url)
                .then((response) => {
                  setTotalRecordsCount(response.data?.count);

                  // dispatch(addGtcc(response.data.results))
                  const TempData = response.data?.results.map((data) => ({
                    ...data,
                  }));

                  resolve({
                    data: TempData,
                    page: response.data?.results.length === 0 ? 0 : query.page,
                    totalCount: response.data?.count,
                  });
                })
                .catch((error) => {
                  let err_msg = Error(error);
                  Toastr("error", err_msg);
                  reject({
                    data: [],
                    page: query.page,
                    totalCount: 0,
                  });
                });
            });
          }}
        />
      </div>
    </React.Fragment>
  );
}
