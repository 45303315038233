import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";
import { authAxios } from "../../../axiosInstance";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CustomButton } from "../../../components/CustomButton";
import { AddWebLink, DeleteWebLink } from "../../../store/webLinks";
import WebLinkAddModal from "./WebLinkAddModal";
import EmptyList from "../../../assets/SVG/Search Not Found 2.svg";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import WebLinkCard from "./WebLinkCard";
import WebLinkEditModal from "./EditWebLinkModal";
import InfiniteScroll from "react-infinite-scroller";
import CastContentModal from "../../../components/CastContentModal";

function WebLinks() {
  const webLinks = useSelector((state) => state.webLinkSlice.webLinks);
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);

  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const [linkModalShow, setLinkModalShow] = useState(false);
  // const [deleteModal, setDeleteModal] = useState({ id: null, toggle: false, index: null });
  const [searchKeyCount,setSearchKeyCount] = useState(0)
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);

  const [pageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [editData, setEditData] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [hasNextpage, setHasNextPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "WebLinks", link: "#" },
    ],
  });

  
  useEffect(() => {
  
    setPage(1);
    setHasNextPage(false);
    dispatch(AddWebLink(null));

    if(searchKey !== prevSearchKey){
      setPrevSearchKey(searchKey)
    }

    setSearchKeyCount(searchKey !==null ? searchKey.length : 0)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[searchKey])


  const [isCastScreenModalVisible, setIsCastScreenModalVisible] =
    useState(false);
  const [item, setItem] = useState(null);

  useEffect(() => {
    const url = `/api/signage/objectlistcreate?object_type=link&limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}`;

    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);
        if (webLinks === null) {
          dispatch(AddWebLink(response.data.results));
        } else {
          dispatch(AddWebLink([...webLinks, ...response.data.results]));
        }

        setTotalCount(response.data.count);

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        Toastr("error", "Oops!,Something Went Wrong");
      });
  }, [page,prevSearchKey]);

  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };

  useEffect(() => {
    return () => {
      dispatch(AddWebLink(null));
    };
  }, []);


  const linkModalToggle = () => {
    setLinkModalShow(!linkModalShow);
  };

  const deleteHandler = (id) => {
    const url = `api/signage/objectrud/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        dispatch(DeleteWebLink(id));
        Toastr("success", "Weblink Deleted Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const editModalHandler = (data) => {
    setEditData(data);

    editModalToggle();
  };
  const loadFunc = () => {
    if (hasNextpage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
    // setTemp(state => [...state, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
  };


  const castScreenModalToggle = (item) => {
    setItem(item);
    setIsCastScreenModalVisible(!isCastScreenModalVisible);
  };
  return (
    <React.Fragment>
      {linkModalShow && (
        <WebLinkAddModal
          isOpen={linkModalShow}
          toggle={linkModalToggle}
          title={"Add Web Link"}
        />
      )}

      {editModalShow && (
        <WebLinkEditModal
          isOpen={editModalShow}
          toggle={editModalToggle}
          title={"Edit Web Link"}
          itemToBeEdited={editData}
        />
      )}

      {isCastScreenModalVisible && (
        <CastContentModal
          isModalVisible={isCastScreenModalVisible}
          setIsModalVisible={setIsCastScreenModalVisible}
          item={item}
        />
      )}

      <div className="page-content">
        <CustomButton onClick={linkModalToggle} title="+" />
        <Container fluid>
          <Breadcrumbs
            title="WebLinks"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>
          <Row>
            <Col lg={12}>
              <div style={{ minHeight: "calc(100vh - 300px" }}>
                {webLinks?.length === 0 ? (
                  <div className="weblinkEmptyImage text-center">
                    <img
                      src={EmptyList}
                      alt=""
                      style={{ width: "450px", height: "450px" }}
                    />
                    <h6 style={{}}>No Links Found !</h6>
                  </div>
                ) : (
                  <>
                    {webLinks === null || loader ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                      >
                        <Spinner animation="grow" />
                      </div>
                    ) : (
                      <InfiniteScroll
                        pageStart={0}
                        // dataLength={totalCount}
                        // next={loadFunc}
                        loadMore={loadFunc}
                        hasMore={hasNextpage}
                        loader={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="loader"
                            key={0}
                          >
                            <Spinner animation="grow" />
                          </div>
                        }
                      >
                        {webLinks?.map((items, index) => (
                          <WebLinkCard
                            key={index}
                            item={items}
                            deleteHandler={deleteHandler}
                            editModalHandler={editModalHandler}
                            castScreenModalToggle={castScreenModalToggle}
                          />
                        ))}
                      </InfiniteScroll>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
          {/* {
            paginationActive  ?
            // <div style={{position: 'absolute', bottom:'60px'}}>
              <Stack spacing={2}>
                <Pagination count={pageCount} shape="rounded"  onChange={handlePageChange} page = {currentPage}/>
              </Stack>
              // </div>
              :
              null
          } */}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default WebLinks;
