import React, { createContext, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

const CanvasContext = createContext(null);
const { Provider } = CanvasContext;

const CanvasProvider = ({ children }) => {
    const [activePanel, setActivePanel] = useState(null);
    const [canvas, setCanvas] = useState(null);
    const [background, setBackground] = useState(null);
    const [objectStates, setObjectsState] = useState(null);
    const [canvasObjFromBackend, setCanvasObjFromBackend] = useState(null)

    return (
        <Provider
            value={{
                activePanel,
                setActivePanel,
                canvas,
                setCanvas,
                background,
                setBackground,
                objectStates,
                setObjectsState,
                canvasObjFromBackend, 
                setCanvasObjFromBackend
            }}>
            {children}
        </Provider>
    );
};

export { CanvasContext, CanvasProvider };