import {
  // Spinner,
  // Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  // FormGroup,
  // Label,
  // UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import "./ScheduleAnimationModal.css";
import { useSelector, useDispatch } from "react-redux";
import { setDroppableBagItems } from "../../../store/bagItems";
// import AbaciHorizontalLine from "../../../components/AbaciHorizontalLine/AbaciHorizontalLine";
// import AbaciButton from "../../../components/AbaciButton/AbaciButton";
import AFrame from "../../../assets/images/A.png";
import { animationTypeArray } from "./animationDictionary";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from "react-router-dom";
export default function ScheduleAnimationModal(props) {
  const [selectedAnimation, setSelectedAnimation] = useState(
    animationTypeArray[0]
  );

  const [animationDropShow,setAnimationDropShow] = useState(false)
  const [durationDropShow,setDurationDropShow] = useState(false)

  const [animatedComponent, setAnimatedComponent] = useState(null);
  const [animationDuration, setAnimationDuration] = useState(1);
  const durationArray = [1, 2, 3, 4, 5];
  const droppableBagItems = useSelector(
    (state) => state.bagItems.droppableBagItems
  );


    
  useEffect(() => {
    // console.log(props)
    // const div_name = droppableBagItems[props.divIndex].id;
    // const tp=droppableBagItems[props.divIndex][div_name].find((data) => data.id === props.selectedItem.id)

    const animation=animationTypeArray.find((data) => data.type === props.selectedItem.animation)
    setSelectedAnimation(animation)
    
    const dura=durationArray.find((data) => Number(data) === Number(props.selectedItem.animation_duration))
    setAnimationDuration(dura)
  }, [])


  useEffect(() => {
    // console.log(droppableBagItems)
  }, [droppableBagItems])
  

  const dispatch = useDispatch();
  const saveHandler = () => {
    // Getting the div name
    const div_name = droppableBagItems[props.divIndex].id;
    // Getting the items in the div of droppableBag
    const currentBagItems = [...droppableBagItems[props.divIndex][div_name]];

    // Now we need to change the date of the selected item with the date input
    const animationChangedItem = {
      ...props.selectedItem,
      animation: selectedAnimation.type,
      animation_duration: animationDuration,
    };
    // Now we need to replace the above item with the same item inside the current droppable bag
    currentBagItems.splice(props.selectedItem.index, 1, animationChangedItem);
    // Now we need to make a copy of droppable bag items for current div with the new values
    const animationChangedItems = {
      ...droppableBagItems[props.divIndex],
      [div_name]: currentBagItems,
    };
    // Now we need to dispatch the above to the store
    const tempDroppableBagItems = [...droppableBagItems];
    tempDroppableBagItems.splice(props.divIndex, 1, animationChangedItems);
    dispatch(
      setDroppableBagItems({ droppableBagItems: tempDroppableBagItems })
    );
    handleCancel();
  };
  const handleCancel = () => {
    props.setVisible(false);
  };

  const onClickAnimation = (key) => {
    setSelectedAnimation(animationTypeArray[key]);
  };

  const onClickDuration = (key) => {
    setAnimationDuration(Number(key) + 1);
  };
  // const menu = (
  //   <Menu onClick={onClickAnimation}>
  //     {animationTypeArray.map((item, index) => (
  //       <Menu.Item key={index}>{item.description}</Menu.Item>
  //     ))}
  //   </Menu>
  // );

  // const durationMenu = (
  //   <Menu onClick={onClickDuration}>
  //     {durationArray.map((item, index) => (
  //       <Menu.Item key={index}>{item + " s"}</Menu.Item>
  //     ))}
  //   </Menu>
  // );

  React.useEffect(() => {
    setAnimatedComponent(null);
    setAnimatedComponent(
      <img
        className={`content-img animate__animated animate__${selectedAnimation.type}`}
        style={{
          marginTop: "100px",
          marginLeft: "140px",
          animationDuration: animationDuration + 's',
          animationFillMode: "both",
          width: "300px",
          height: "300px",
          // marginTop: "0px",
          objectFit: "contain",
        }}
        src={AFrame}
        alt=''></img>
    );
  }, [selectedAnimation, animationDuration]);

 
  // useEffect(() => {
  //   console.log(animationDuration)
  // }, [animationDuration])
  
  

  return (

    <Modal
      size={"xl"}
      centered
      isOpen={props.visible}
    
      
      toggle={handleCancel}
    >
      <ModalHeader toggle={handleCancel}>{"Animation Modal"}</ModalHeader>
      <ModalBody>
        <div className='parent'>
          <div className='div1'>
            {/* <select className="form-control" placeholder=" Please select the transition animation" multiple="true" onChange={onClickAnimation}>
              {animationTypeArray.map((obj,index) =>
                <option value={obj.id} key={index}>{obj.description}</option>
              )}
            </select> */}

                        <Dropdown
                          isOpen={animationDropShow}
                          toggle={() =>
                            setAnimationDropShow(!animationDropShow)
                          }
                          onMouseOver={() =>  setAnimationDropShow(true)
                          }
                          onMouseLeave={() => setAnimationDropShow(false)}
                          className="mt-4 mt-sm-0"
                          style={{marginBottom:'15px'}}
                          onClick={(event) =>event.target.value && onClickAnimation(event.target.value)}
                        >
                          <DropdownToggle style={{border:'0',color:'black',backgroundColor:'#fff',boxShadow:'none'}}>
                            <div style={{color:'black'}}><Link to="#">Please Select The Transition Animation <KeyboardArrowDownIcon/></Link></div>
                          </DropdownToggle>
                          <DropdownMenu>
                          {animationTypeArray.map((obj,index) =>
                                    <DropdownItem active={selectedAnimation?.description === obj?.description} value={index} key={index}>{obj.description}</DropdownItem>
                                    )}
                            </DropdownMenu>
                        </Dropdown>
     
                        <p style={{marginLeft:'10px'}}> Selected animation is : {selectedAnimation?.description}</p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <Dropdown
                          isOpen={durationDropShow}
                          toggle={() =>
                            setDurationDropShow(!durationDropShow)
                          }
                          onMouseOver={() =>  setDurationDropShow(true)
                          }
                          onMouseLeave={() => setDurationDropShow(false)}
                          className="mt-4 mt-sm-0"
                          style={{marginBottom:'15px'}}
                          onClick={(event) =>event.target.value && onClickDuration(event.target.value)}
                        >
                          <DropdownToggle style={{border:'0',color:'black',backgroundColor:'#fff',boxShadow:'none'}}>
                            <div style={{color:'black'}}><Link to="#">Please Provide the Animation Duration <KeyboardArrowDownIcon/></Link></div>
                          </DropdownToggle>
                          <DropdownMenu>
                          {durationArray.map((obj,index) =>
                                    <DropdownItem active={animationDuration === index+1} value={index}  key={index}>{obj + " s"}</DropdownItem>
                                    )}
                            </DropdownMenu>
                        </Dropdown>
            
         
            <p style={{marginLeft:'10px'}}>Animation Duration is : {animationDuration + 's'}</p>
            <div className='flex-container'></div>
          </div>
          <div className='div2'>{animatedComponent}</div>
        </div>

        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            className="w-xs waves-effect waves-light me-1"
            onClick={saveHandler}

          >
            Save
          </Button>
          <Button
            type="button"
            color="primary"
            className="w-xs waves-effect waves-light me-1"
            onClick={handleCancel}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>


  );
}
