import React, { useEffect, useState } from "react";

import Styles from "./ScreenGroup.module.css";
// import Lottie from './Lottie';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {  ModalBody, Button, ModalFooter, Spinner } from "reactstrap";
import Modals from "../../../components/Modal";
import OnlineIcon from '../../../assets/images/Group 1507.png'
import OfflineIcon from '../../../assets/images/Group 1567.png'
// import { IconButton, Tooltip } from "@material-ui";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton, Tooltip } from "@mui/material";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import { useDispatch } from "react-redux";
import { addScreenGroup, screenListUpdater } from "../../../store/screenSlice";

export default function ScreenAddGroupModal(props) {

  const dispatch=useDispatch();
    const [name,setName]=useState('')
    const [isLoading,setIsLoading] = useState(false)

    useEffect(() => {
      const url='api/signage/screenslistcreateview?limit=150&offset=0&screen_group__isnull=true'

    authAxios.get(url)
    .then((res) => setScreenDataList(res?.data?.results))
    }, [])
    

//   const screenList = useSelector((state) => state.screenSlice.screens);

  const [screenDataList, setScreenDataList] = useState([]);
  const [newScreenGrouplist, setNewSceenGroupList] = useState([]);

//   useEffect(() => {
//     setScreenDataList(screenList && screenList);
//   }, [screenList]); // set the relation between redux screenList and local state

  // const handleOk = () => {
  //   props.setIsModalVisible(false);
  // };

  const handleCancel = () => {
    props.setIsModalVisible(false);
  };
  const AddScreenToGroup = (arr, item) => {
    setScreenDataList(arr.filter((el) => el !== item));
    setNewSceenGroupList([...newScreenGrouplist, item])
  };
  const removeScreenFromGroup = (arr, item) => {
    setNewSceenGroupList(arr.filter((el) => el !== item));
    setScreenDataList([...screenDataList, item])
  };
  const submitHandler = () => {

    if(name !=='' && newScreenGrouplist.length !==0){

    setIsLoading(true)

    const screenListArray = newScreenGrouplist?.map((data) => data.id);

    const dataToBeSubmitted={
        name:name,
        screen_ids:screenListArray
    }

    // console.log(dataToBeSubmitted)
    
    const url='/api/signage/screengrouplistcreateview'

    authAxios.post(url,dataToBeSubmitted)
    .then((res) => {

      // console.log(res['data']['created_by'])

       
        // const temp={...res.data,
        //   online_count:res.data.screen_screengroup.filter((data) => data.online_status).length,
        //   offline_count:res.data.screen_screengroup.filter((data) => !data.online_status).length
        // }
        // dispatch(screenListUpdater(screenListArray))
        dispatch(addScreenGroup(res.data))
        setIsLoading(false)
        Toastr('success','New Screen Group Successfully Created')
        handleCancel();
        
        
    })
    .catch((error) => {

    let err_msg=Error(error)
    Toastr('error',err_msg)
    setIsLoading(false)

    })
    }
    else{
        Toastr('info','Please Enter the Name of the Screen Group & Select 1 Screen To Continue')
    }
  };
  return (
    <div>
             <Modals
        title="Add New Group"
        isOpen={props.isModalVisible}
        toggle={() => props.setIsModalVisible(false)}
        size="xl"
      >
        <ModalBody>
        <div className={Styles.parent_media_review}>
          <div className={Styles.div1_media_review}>
            <p style = {{}}>Please provide a screen Group Name</p>
          
              <input
                className={"form-control mb-4" }
                type='text'
                onChange={(event) => setName(event.target.value)}
              
                placeholder='Group Name'
              /> 
              <div style={{height:'300px',overflowY:'scroll'}}>
        <p>Screen List</p>
            {screenDataList !== null ? (
              screenDataList.map((item, index) => (
                // <>
                
                  <div
                    className={Styles.filelist}
                    style={{ backgroundColor: "#FAFAFA", cursor: 'pointer' }}
                    key={index}
                  >
                    <div className={Styles.media_icon_wrapper_list}     key={index}>
                      <img
                        alt='media'
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "5px",

                          objectFit: "contain",
                        }}
                        src={item?.online_status ? OnlineIcon : OfflineIcon}
                      />
                    </div>

                    <p className={Styles.filelist_name}>{item.name}</p>
                    <Tooltip title="Add" arrow>
              <IconButton
                    style={{ 
                    color: "grey",
                    width: "10px",
                    height: "10px",
                   }}

                    onClick={() => {
                        AddScreenToGroup(screenDataList, item);
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
                      {/* onClick={() => {
                        AddScreenToGroup(screenDataList, item);
                      }}></i> */}



                  </div>
                
                // </>
              ))
              
            ) : ( null
            //   <Lottie />
            )}
              </div>
          </div>


          <div className={Styles.div2_media_review}>
            {newScreenGrouplist.length > 0 ? (
              newScreenGrouplist.map((item, index) => (
                // <>
                  <div
                    className={Styles.filelist}
                    style={{ backgroundColor: "#FAFAFA" }}
                    key={index}

                  >
                    <div className={Styles.media_icon_wrapper_list}     key={index}>
                      <img
                        alt='media'
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "5px",

                          objectFit: "contain",
                        }}
                        src={item?.online_status ? OnlineIcon : OfflineIcon}
                      />
                    </div>

                    <p className={Styles.filelist_name}>{item.name}</p>

                    <Tooltip title="Remove" arrow>
              <IconButton
                    style={{ 
                    color: "grey",
                    width: "10px",
                    height: "10px",
                    marginLeft: "45px",
                   }}

                    
                onClick={() => { removeScreenFromGroup(newScreenGrouplist, item) }}>
                <RemoveCircleIcon />
              </IconButton>
            </Tooltip>
               


                  </div>
                // </>
              ))
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {/* <Lottie /> */}
                <p style={{ marginTop: "10px" }}>
                  List of screens in the group will display here
                </p>
              </div>
            )}</div>
        </div>

          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              className="w-xs waves-effect waves-light me-1"
              onClick={submitHandler}
              disabled={isLoading}
            >
            {isLoading ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )} 
              
            </Button>
            <Button
              type="button"
              color="danger"
              className="w-xs waves-effect waves-light me-1"
              onClick={handleCancel}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modals>





    
    </div>
  );
}
