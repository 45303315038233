import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import { APP_NAME } from "../../../helpers/Constants";
import LineGraph from "./LineGraph";
// import "aos/dist/aos.css";
import axios from "axios";
// import "bootstrap-daterangepicker/daterangepicker.css";
import MiniWidgets from "./MiniWidgets";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import { authAxios } from "../../../axiosInstance";
import { setCurrentPage, setSearchDisabled, setSearchkeyWord } from "../../../store/searchSlice";
import { useDispatch } from "react-redux";
// import { Toastr } from "../../../helpers/Alert";
// import Error from "../../../helpers/Error";
// import NotificationModal from "../Envirol/Notifications/NotificationModal";
export default function Index() {

  const dispatch=useDispatch();
  const [counts, setCounts] = useState([
    {
      icon: "ri-team-fill",

      title: " New Customers",
      // value: 40,
      desc: "Total New Customers / Year",
    },
    {
      icon: "ri-tv-2-fill",
      title: "New Screens",
      // value: 14,
      desc: "Total New Screens / Year",
    },
    {
      icon: "ri-article-fill",
      title: "Invoice Value",
      // value: 100,
      desc: " Total Invoice Value / Year",
    },
  ]);

  const [dashboardState, setDashboardState] = useState({
    breadcrumbItems: [
      // { title: APP_NAME, link: "/dashboard" },
      { title: "Dashboard", link: "#" },
    ],
  });

  useEffect(() => {
    setDashboardState({
      breadcrumbItems: [
        // { title: APP_NAME, link: "/dashboard" },
        { title: "Dashboard", link: "#" },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const url = "/abaciadmin_api/dashboard_tiles";
    authAxios
      .get(url)
      .then((res) => {
        // console.log(res.data);
        setCounts([
          {
            icon: "ri-team-fill",

            title: " New Customers",
            value: res.data.new_customers_this_year,
            desc: "Total New Customers / Year",
          },
          {
            icon: "ri-tv-2-fill",
            title: "New Screens",
            value: res.data.new_screens_this_year,
            desc: "Total New Screens / Year",
          },
          {
            icon: "ri-article-fill",
            title: "Invoice Value",
            value: res.data.new_invoices_this_year,
            desc: " Total Invoice Value / Year",
          },
        ]);
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  }, []);

  useEffect(() => {
    tempTheme();

    dispatch(setCurrentPage('dashboard'))
    dispatch(setSearchDisabled(true))
    dispatch(setSearchkeyWord(null))


    return () => {
      dispatch(setSearchDisabled(false))
    }
  }, []);

  const tempTheme = () => {
    if (document.body) {
      document.body.setAttribute("data-sidebar", "dark");
    }
  };

  return (
    <div className="page-content">
      {/* {notificationModalShow &&
        <NotificationModal
        // title="Cookies Policy"
        isOpen={notificationModalShow}
        toggle={notificationToggle}
        data={notificationData}
        />
        } */}
      {/* Container can be fluid or boxed */}
      <Container fluid>
        <Breadcrumbs
          title="Dashboard"
          breadcrumbItems={dashboardState.breadcrumbItems}
        />
        <Row>
          <Col xl={12}>
            <Row xl={3}>
              <MiniWidgets
                count={counts}
                // loader={loader.loader5}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <LineGraph title={"Monthly Invoice Value"} />
        </Row>
      </Container>
    </div>
  );
}
