import React, { useState, useEffect } from "react";
import {
 
  Card,
  CardBody,
} from "reactstrap";
import Modals from "../../Modal";
import Moments from "../../../helpers/Moment";
import { useSelector } from "react-redux";

export default function PlanDetails(props) {

  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails )
  const customer=''
  console.log(tenantDetails)
 
  return (
  <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={props.size}>

<div
    className="payment-trail-container"
    style={{ width: "100%", gap: "20px",overflowX:'hidden' }}
  >
    <Card
      className="payment-card"
      style={{ backgroundColor: "#F1F5F7" }}
    >
      <CardBody style={{ padding: "10px" }}>
        <p style={{ fontWeight: "900" }}>Payment Details</p>

        <div style={{ width: "400px" }}>
          <table
            cellSpacing="0"
            cellPadding="6"
            style={{ width: "100%" }}
          >
            <tbody>
              {/* <tr style={{ verticalAlign: "top" }}>
                <td>Payment Status</td>
                <td style={{ width: "5%" }}> : </td>
                <td>
                  {" "}
                  {tenantDetails?.payment_status
                    ? tenantDetails?.payment_status
                    : "No Data"}
                </td>
              </tr> */}
              <tr style={{ verticalAlign: "top" }}>
                <td>Last Payment</td>
                <td> : </td>

                <td style={{ width: "60%" }}>
                  {" "}
                  {tenantDetails?.last_payment
                    ? Moments(
                        tenantDetails?.last_payment
                      )
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Paid Upto</td>
                <td> : </td>
                <td>
                  {" "}
                  {tenantDetails?.paid_upto
                    ? Moments(tenantDetails?.paid_upto)
                    : "No Data"}
                </td>
              </tr>
              <tr>
                <td>Next Payment</td>
                <td> : </td>
                <td>
                  {" "}
                  {tenantDetails?.next_payment
                    ? Moments(
                        tenantDetails?.next_payment
                      )
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Base Price</td>
                <td> : </td>
                <td>
                  {" "}
                  {tenantDetails?.base_price
                    ? tenantDetails?.base_price+" "+tenantDetails?.currency
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Discount</td>
                <td> : </td>
                <td>
                  {tenantDetails?.discount
                    ? tenantDetails?.discount
                    : "No Data"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
    <Card
      className="trail-card"
      style={{ backgroundColor: "#F1F5F7" }}
    >
      <CardBody style={{ padding: "10px" }}>
        <p style={{ fontWeight: "900" }}>Plan Details</p>

        <div style={{ width: "500px" }}>
          <table
            cellSpacing="0"
            cellPadding="6"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr style={{ verticalAlign: "top" }}>
                <td>On Trial</td>
                <td style={{ width: "5%" }}> : </td>
                <td>
                  {" "}
                  {tenantDetails?.on_trial
                    ? "Yes"
                    : "No"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Number Of Trial Days</td>
                <td> : </td>
                <td style={{ width: "60%" }}>
                  {tenantDetails?.number_of_trial_days
                    ? tenantDetails?.number_of_trial_days
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Number Of Trial Licenses</td>
                <td> : </td>
                <td>
                  {" "}
                  {tenantDetails?.number_of_trial_licenses
                    ? tenantDetails?.number_of_trial_licenses
                    : "No Data"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Trial Days Remaining</td>
                <td> : </td>
                <td>
                  {" "}
                  {tenantDetails?.remaining_trial_days
                    ? tenantDetails?.remaining_trial_days
                    : "0"}
                </td>
              </tr>
              {/* <tr>
                <td>Acceptance To TC</td>
                <td> : </td>
                <td>
                  {" "}
                  {tenantDetails?.acceptance_to_updates
                    ? "Yes"
                    : "No"}
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td>Acceptance To Updates</td>
                <td> : </td>
                <td>
                  {" "}
                  {tenantDetails?.acceptance_to_updates
                    ? "Yes"
                    : "No"}
                </td>
              </tr> */}
              <tr style={{ verticalAlign: "top" }}>
                <td>App Type</td>
                <td> : </td>
                <td>
                  {tenantDetails?.app_type
                    ? tenantDetails?.app_type
                    : "No Data"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  </div>

    </Modals>
  );
}
