import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import shape from "../../assets/images/shapes/cta-2-shape-1.png";
import { APP_FEATURE_DATA } from "../../data/index";
import shape1 from "../../assets/images/shapes/cta-1-shape-1.png";
import shape2 from "../../assets/images/shapes/cta-1-shape-2.png";
const AppFeature = () => {
  const { sectionTitle, image, paragraph, options, button } = APP_FEATURE_DATA;
  return (
    <section className="cta-one" style={{paddingBottom:'90px',marginTop:'90px'}} id='AppFeature'>
      {/* <img src={shape1} className="cta-one__bg-shape-1" alt="" /> */}
      <img src={shape2} className="cta-one__bg-shape-2" alt="" />

      <Container>
        <Row>
          <div className="cta-two__content">
            <div className="block-title cus-pb">
              <h3 style={{fontFamily:'lato', color: "#fa496e"}}
                >
                {sectionTitle.text}
              </h3>
            </div>
          </div>
        </Row>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {options.map((item,index )=> (
            <div style={{ flex: " 0 0 33.333333%",marginBottom:'50px'}} key={index}>
              <div style={{ display: "flex" }}>
                <div>
                <div className="appFeatureRound" style={{backgroundColor:item.color}}>
                  <img
                    src={item.image}
                    style={{ maxWidth: "76px", maxHeight: "76px" }}
                  />
                </div>
                </div>
                
                <div style={{ display: "flex", flexDirection: "column",marginLeft:'5px',marginRight:'5px',minWidth:'200px' }}>
                  <p style={{fontSize:'14px',fontWeight:'bold',fontFamily:'Lato',color:'black'}}> {item.label}</p>
                  <p style={{fontSize:'14px',fontWeight:'400',fontFamily:'Lato',color:'black',lineHeight:'20px'}}>{item.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default AppFeature;
