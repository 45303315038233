import { configureStore } from "@reduxjs/toolkit";
import layoutSlice from "./layout";
import isAuthenticated from "./isAuthenticated";
import _authToken from "./token";
import profileDetails from "./profileDetails";
import assetDetails from "./assetDetails";
import mediaSlice from "./mediaSlice";
import fabricCanvasSlice from "./fabricCanvasSlice";
import counter from "./counter";
import templateSlice from "./templateSlice";
import clockSlice from "./clockSlice";
import weatherSlice from "./weatherSlice";
import youTubeSlice from "./youTubeSlice";
import scheduleSlice from "./scheduleSlice";
import bagItems from "./bagItems";
import authSlice from "./auth";
import customerSlice from "./customersStore";
import webLinkSlice from "./webLinks";
import screenSlice from "./screenSlice";
import canvasSlice from "./canvasSlice";
import screenContentModalSelectedItemSlice from "./screenContentModalSelectedItemSlice";
import appSlices from "./appSlice";
import textSlice from "./TextSlice";
import searchSlice from "./searchSlice";
import supportSlice from "./supportSlice";

const store = configureStore({
  reducer: {
    authSlice: authSlice,
    layoutSlice: layoutSlice,
    isAuth: isAuthenticated,
    authToken: _authToken,
    profileDetails: profileDetails,
    assetDetails: assetDetails,
    mediaSlice: mediaSlice,
    fabricCanvasSlice: fabricCanvasSlice,
    counter: counter,
    templateSlice: templateSlice,
    clockSlice: clockSlice,
    weatherSlice: weatherSlice,
    youTubeSlice: youTubeSlice,
    scheduleSlice: scheduleSlice,
    bagItems: bagItems,
    customerSlice: customerSlice,
    webLinkSlice: webLinkSlice,
    screenSlice: screenSlice,
    canvasSlice: canvasSlice,
    screenContentModalSelectedItemSlice: screenContentModalSelectedItemSlice,
    appSlices: appSlices,
    textSlice: textSlice,
    searchSlice: searchSlice,
    supportSlice: supportSlice,
  },
});

export default store;
