import moment from "moment";
import { nanoid } from "nanoid";
import { setCollections } from "../../../store/bagItems";

export const reorder = (list, startIndex, endIndex) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
  return list;
};

export const copy = (source, destination, droppableSource, droppableDestination) => {
  let item = source[droppableSource.index];
  // When taking from setCollections, the file will not have a start date and end date
  // so, here we are setting the start date as today and the end date as tomorrow
  // item = {...item, start_date: moment().format("YYYY-MM-DD"), end_date: moment().add(1, 'days').format("YYYY-MM-DD")}
  destination.splice(droppableDestination.index, 0, { ...item, id: nanoid() });


  return destination;
};

export const move = (
  droppableBagItems,
  movedItem,
  sourceIndex,
  sourceDroppableId,
  destinationIndex,
  destinationDroppableId
) => {
  const tempState = droppableBagItems.map((element, index) => {
    if (element.id === sourceDroppableId) {
      return element[sourceDroppableId].splice(sourceIndex, 1);
    }
    if (element.id === destinationDroppableId) {
      return element[destinationDroppableId].splice(
        destinationIndex,
        0,
        movedItem
      );
    } else {
      return element;
    }
  });
  return tempState;
};
