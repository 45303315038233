
import React from "react";
import Layout from "../../../components/PublicComponents/layout";
import Header from "../../../components/PublicComponents/header/header";
import Banner from "../../../components/PublicComponents/banner";

import AppFeature from "../../../components/PublicComponents/app-feature";
import CallToAction from "../../../components/PublicComponents/call-to-action";
import PriceHome from "../../../components/PublicComponents/price/price-home";

import AppShot from "../../../components/PublicComponents/app-shot";

import ServicesHome from "../../../components/PublicComponents/services/services-home";
import FAQ from "../../../components/PublicComponents/faq";
import Faqsub from '../../../components/PublicComponents/faq2'
// import Brands from "@components/brands";
// import Subscribe from "../../../components/PublicComponents/subscribe";
import Footer from "../../../components/PublicComponents/footer";
import MobileNav from "../../../components/PublicComponents/mobile-nav";
import Video from "../../../components/PublicComponents/video";

const Home = () => {
  return (
    <Layout pageTitle="ABACI SIGNAGE">
      <Header />
      <Banner />
      <ServicesHome />
      <CallToAction />
      <Video />

      <AppShot />
      <AppFeature />
      <PriceHome />
      {/* <TestimonialsHome /> */}

      <FAQ />
      <Faqsub/>
      {/* <BlogHome /> */}
      {/* <Brands /> */}
      {/* <Subscribe /> */}
      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default Home;
