import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  webLinks: null,
};
const webLinkSlice = createSlice({
  name: "Weblink",
  initialState,
  reducers: {
    AddWebLink(state, action) {
      state.webLinks = action.payload;
    },
    AddNewWebLink(state, action) {
      state.webLinks = [...state.webLinks, action.payload];
    },
    DeleteWebLink(state, action) {
      state.webLinks = state.webLinks.filter(
        (data) => data.id !== action.payload
      );
    },
    EditLink(state, action) {
      state.webLinks = state.webLinks.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },
    favouriteWeblink(state, action) {
      state.webLinks = state.webLinks.map((data) =>
        data.id === action.payload.id
          ? { ...data, is_favourite: action.payload.is_favourite }
          : data
      );
    },

    addWeblinkTag(state, action) {
      console.log("state");
      console.log(state.webLinks);
      console.log(action.payload);
      state.webLinks = state.webLinks.map((data) =>
        data.id === action.payload.object
          ? {
              ...data,
              tag_jsonobject: [...data?.tag_jsonobject, action.payload],
            }
          : data
      );
    },
  },
});

export const {
  favouriteWeblink,
  AddWebLink,
  AddNewWebLink,
  DeleteWebLink,
  EditLink,
  addWeblinkTag,
} = webLinkSlice.actions;
export default webLinkSlice.reducer;
