import React, { useEffect, useState } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "../../assets/images/banner/banner-shape-1-1.png";
import bgShape2 from "../../assets/images/banner/banner-shape-1-2.png";
import bannerBG from "../../assets/images/banner/banner-bg-1.png";
import bannerShape1 from "../../assets/images/shapes/banner-1-1.png";

import image from "../../assets/images/banner/banner-img-1.gif";
// import { baseURL } from "../../baseURL";
import {

  Events,

} from "react-scroll";
import axios from "../../axiosInstance";
import "bootstrap/dist/css/bootstrap.min.css";
const Banner = () => {

  const navigate=useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [emailError, setEmailError] = useState(true);
  const [emailResponse, setEmailResponse] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  useEffect(() => {
    Events.scrollEvent.register("end", function () {
   
    });
    return () => {
      Events.scrollEvent.remove("end");
    };
  }, []);
  useEffect(() => {}, [buttonDisable]);
  // const emailSentHandler = () => {
  //   setButtonDisable(true);
  //   // email Validation
   
  //   const regex =
  //     /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //   if (regex.test(userEmail)) {
  //     setEmailError(false);
  //     navigator.geolocation.getCurrentPosition(function(position) {
  //       console.log("Latitude is :", position.coords.latitude);
  //       console.log("Longitude is :", position.coords.longitude);
  //    if(position){

  //    const latitude=position.coords.latitude;
  //    const longitude=position.coords.longitude;
  //     const url = baseURL + "/public/emailcheck";
  //     axios
  //       .post(url, { email: userEmail,location:`latitude-${latitude},longitude-${longitude}` })
  //       .then((response) => {
  
          
  //         setEmailResponse(
  //           "The registation link has been sent to your email.!"
  //         );
  //         // history.push("/login");
  //         setButtonDisable(false);
  //       })
  //       .catch((error) => {
      
          
  //         setEmailError(true);
  //         setEmailResponse(
  //           "The given email ID is already registered!"
  //         );
  //         setButtonDisable(false);
  //       });
  //     }

  //     });
  //   } else {
  //     setEmailError(true);
  //     setEmailResponse("Please provide a valid email address !");
  //     setButtonDisable(false);
  //   }
  // };
  return (
    <section className='banner-one' id='home'>
      {/* <div className="main-nav__right" onClick={() => {
                  history.push("/signin");
                
                }}>
            <a href="/signin" className="thm-btn" onClick={()=>{history.push('/signin')}}>
              <span>Login</span>
            </a>
          </div> */}
      <img src={bgShape1} alt='Banner-Shape-1' className='banner-shape-1' />
      <img src={bgShape2} alt='Banner-Shape-2' className='banner-shape-2' />
      <div
        className='banner-one__bg'
        style={{ backgroundImage: `url(${bannerBG})` }}></div>
      <Container>
        <Row>
          <Col xl={6} lg={6}>
            <div className='banner-one__content'>
              <h3 style={{ fontSize: "80px", fontFamily: "lato" }}>
                Simpler than the
                <br />
                simple digital signage
                <br />
              </h3>
              <p
                style={{
                  fontSize: "19px",
                  fontFamily: "lato",
                  lineHeight: "25px",
                  // marginLeft: "35px",
                }}>
                We help you to motivate collaboration and innovation, <br />
                and increase the efficiency of your employees and visitors
                within your environment.
                <br />
                Simple and powerful way to manage and publish content on your
                displays.
                <br />
                {/* REACH YOUR GOALS WITH THE RIGHT MIX OF VISUAL IDEAS AND COMMUNICATIONS SOLUTIONS */}
              </p>
              {/* <a href="#" className="thm-btn">
                <span>Start free trial</span>
              </a> */}
                 <div
                style={{
                  display: "flex",
                  marginTop: "90px",
                  // justifyContent: "center",
                }}>
                {/* <div className='try_now'>
                  <input
                    type='text'
                    className='tryNowTextField'
                    placeholder='Enter your work or business email here...'
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                  /> */}
                {/* </div> */}
                <button
                 
                  className='try_now_box'
                  onClick={() => {
                    navigate('/register')
                  }}>
                  Try Now
                </button>
              </div>
              <p
                style={{
                  color: emailError ? "#f7361d" : "#07c042",
                  marginTop: "10px",
                  marginLeft: "50px",
                  width: "450px",
                  wordWrap: "break-word",
                  lineHeight: "1.5",
                  fontFamily: "Lato",
                  fontSize: "13px",
                }}>
                {emailResponse ? "*" + emailResponse : null}
              </p>
            </div>
          </Col>
          <Col xl={6} lg={6}>
            <div className='banner-img wow fadeInUp' data-wow-duration='1500ms'>
              <img src={bannerShape1} className='banner-image__curvs' alt='' />
              <div
                className='banner-bg'
                // style={{ backgroundImage: `url(${bannerShape2})` }}
              ></div>
              <img
                src={image}
                alt='Banner-img'
                style={{
                  position: "absolute",
                  top: "-30px",
                  right: "-460px",
                  zIndex: "-1",
                }}
              />
              {/* <div className="banner-icon-1">
                <i className="piloz-lamp"></i>
              </div> */}
              <div className='banner-icon-2'>
                <i className='piloz-linked'></i>
              </div>
              <div className='banner-icon-3'>
                <i className='piloz-human-resources'></i>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
