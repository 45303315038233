// URL Set for Danish
// export const productionURL = 'https://subdomain.abacisignage.com'
// export const productionURLPublic = 'https://abacisignage.com'
// export const developmentURL = 'http://subdomain.localhost:8000'
// export const developmentURLPublic = 'http://localhost:8000'

// // URL Set for Meethu
export const productionURL = "https://signage.abacitechs.com";
export const productionURLPublic = "https://signage.abacitechs.com";

export const developmentURL = 'http://signage.abacitechs.com:8000';
export const developmentURLPublic = 'http://signage.abacitechs.com:8000';

const getBaseURL = () => {
  let url = null;
  const tenant = localStorage.getItem("tenant");
  if (process.env.NODE_ENV === "development") {
    url = developmentURL.replace("subdomain", tenant);
  } else {
    url = productionURL.replace("subdomain", tenant);
  }
  return url;
};
export const baseURL = getBaseURL();

const getBaseURLPublic = () => {
  let url = null;
  if (process.env.NODE_ENV === "development") {
    url = developmentURLPublic;
    // url='http://65.108.148.78:8000/';
  } else {
    url = productionURLPublic;
  }
  return url;
};
export const baseURLPublic = getBaseURLPublic();
export const storageBucket='s3';
// export const storageBucket='local';