import React, { useState, useEffect } from "react";
import { ReactComponent as Close } from "../../../../assets/icons/close.svg";
import AbaciButton from "../../../../components/AbaciButton/AbaciButton";
import AbaciHorizontalLine from "../../../../components/AbaciHorizontalLine/AbaciHorizontalLine";
// import WeatherModal from "./WeatherModal";
import Styles from "./weatherCreater.module.css";
import {
  GeoapifyGeocoderAutocomplete,
  GeoapifyContext,
} from "@geoapify/react-geocoder-autocomplete";
import "@geoapify/geocoder-autocomplete/styles/round-borders.css";
// import {
//   setNewWeather,
//   toStoreEditValue,
// } from "../../../.././store/weatherSlice";
// import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, SelectPicker } from "rsuite";
import Error from "../../../../helpers/Error";
import { Toastr } from "../../../../helpers/Alert";
import { authAxios } from "../../../../axiosInstance";
import { Spinner } from "reactstrap";
import { setSearchkeyWord } from "../../../../store/searchSlice";
import { useDispatch } from "react-redux";
export default function CreateWeather(props) {
  const navigate = useNavigate();
  const dispatch=useDispatch();

  // const weatherEditData = useSelector((state) => state.weatherSlice.editValue);
  // const dispatch = useDispatch();
  // const [weatherCreateModal, setWeatherCreateModal] = useState(false);
  const [weatherList, setWeatherList] = useState([{}, {}, {}]);
  // const [coordinates, setCoordinates] = useState([{}, {}, {}]);
  const [locations, setLocations] = useState({
    location0: "",
    location1: "",
    location2: "",
  });
  const weatherId = useLocation();
  const [tempType, setTempType] = useState("Celsius");
  const [name, setName] = useState("");

  let tempArray = [{},{},{}];

  useEffect(() => {

    dispatch(setSearchkeyWord(null))


    if (weatherId?.state?.id) {
      const url = `/api/signage/objectrud/${weatherId.state?.id}`;

      authAxios.get(url).then((response) => {
        setName(response.data.name);
        setTempType(response.data.object.unit_type);
        // setCoordinates(response.data.object.weatherlist)

        response.data.object.weatherlist.forEach((data, index) => {
          if (Object.keys(data).length !== 0) {
            setLocations((state) => ({
              ...state,
              [`location${index}`]: data.name,
            }));

            const apiKey = "73da0bb73bd930dba81d9b24b7e974b2";

            const url = `https://api.openweathermap.org/data/2.5/weather?lat=${data.latitude}&lon=${data.longitude}&appid=${apiKey}`;

            fetch(url)
              .then((res) => res.json())
              .then((ndata) => {
                tempArray[index] = ndata;

                setWeatherList((state) => [...tempArray]);
              });
          }
        });
      });
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tempOptions = [
    { label: "Celsius", value: "Celsius" },
    { label: "Fahrenheit", value: "Fahrenheit" },
  ];

  function onPlaceSelect(value, id) {
    if (value) {
      // for setting the the location names on location change
      setLocations((state) => ({
        ...state,
        [`location${id}`]: value.properties.formatted,
      }));

      // for setting the data passed to the backend
      // const temp=[...coordinates]
      // temp[id]={longitude:value.properties.lon,latitude:value.properties.lat,name:value.properties.formatted}

      // setCoordinates(temp)

      // function called for fetching the current weather using weather api
      weatherFetch(value.properties.lat, value.properties.lon, id);
    } else {
      // for removing the location from the array when user clicks the close icon

      const dataToBeEdited = [...weatherList];

      dataToBeEdited[id] = {};

      setWeatherList(dataToBeEdited);
    }
  }

  function onSuggectionChange(value) {
    // console.log(value);
  }

  const kelvinToFarenheit = (k) => {
    return (k - 273.15).toFixed(2);
  };

  const weatherFetch = (lat, long, id) => {
    const apiKey = "73da0bb73bd930dba81d9b24b7e974b2";

    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${long}&appid=${apiKey}`;

    fetch(url)
      .then((res) => res.json())
      .then((ndata) => {
        const temp = [...weatherList];
        temp[id] = ndata;

        setWeatherList(temp);
      });
  };

  const [loadingOnButton, setLoadingOnButton] = useState(false);

  // useEffect(() => {
  //   console.log(weatherId);
  // }, []);

  // useEffect(() => {

  //     // if (weatherEditData !== null && weatherEditData.data !== null) {
  //     //   weatherEditData.data.map((item) => {
  //         const apiKey = "73da0bb73bd930dba81d9b24b7e974b2";

  //         // if (item.coord.lat.indexOf(" ") >= 0) {

  //         //   const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${item.coord.lat.trim()}&lon=${item.coord.lat.trim()}&appid=${apiKey}&units=metric`;

  //         //   setUrl(apiUrl);
  //         //   console.log("indexOf 1", apiUrl);
  //         // } else {
  //         //   const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${item.coord.lat}&lon=${item.coord.lat}&appid=${apiKey}&units=metric`;
  //         //   setUrl(apiUrl);
  //         //   console.log("indexOf 2", apiUrl);
  //         // }
  //         const url=`https://api.openweathermap.org/data/2.5/weather?q=London&appid=${apiKey}`

  //         // const url = `https://api.openweathermap.org/data/2.5/weather?lat=11.2588&lon=75.7804&appid=${apiKey}&units=metric`;

  //         fetch(url)
  //           .then((res) => res.json())
  //           .then((ndata) => {
  //             // const list=[...list,ndata]
  //             setWeatherList((weatherList) => [...weatherList, ndata]);
  //           });
  //       // });
  //     // } else {
  //     //   setWeatherList([]);
  //     // }

  //   // useEffect unmount
  //   return () => {
  //     setWeatherList([]);
  //   };
  // }, []);

  const handleClose = () => {
    // console.log(weatherId?.state?.apps);
    if (weatherId?.state?.isApps) {
      navigate("/apps");
    } else {
      navigate("/weather/");
    }
  };

  useEffect(() => {}, [weatherList]);

  const handleSave = () => {

    // console.log(weatherList)

    const co_ordinates = weatherList.map((data) =>
      Object.keys(data).length !== 0
        ? {
            longitude: data?.coord?.lon,
            latitude: data?.coord?.lat,
            name: data?.name,
          }
        : {}
    );

    const data = {
      name: name,
      file_type: "weather",
      object: {
        title: name,
        weatherlist: co_ordinates,
        unit_type: tempType,
      },
    };

    
      if (name !== "" && Object.keys(weatherList[0]).length !== 0) {

        setLoadingOnButton(true);
        
        if (weatherId?.state?.id) {
        // {This is for Updating the Already Created Weather Instance if it is already present}
        
        const url = `/api/signage/objectrud/${weatherId.state?.id}`;

        authAxios
          .patch(url, data)
          .then((response) => {
            // dispatch(addYouTube(response.data))
            setLoadingOnButton(false);
            Toastr("success", "Weather Instance Updated Succesfully");
          })
          .catch((error) => {
            setLoadingOnButton(false);
            const err_msg = Error(error);
            Toastr("error", err_msg);
          });
      }
     else {
      // {This is for Creating an new weather instance}
        const url = "api/signage/objectlistcreate";

        authAxios
          .post(url, data)
          .then((response) => {
            // dispatch(addYouTube(response.data))
            setLoadingOnButton(false);
            Toastr("success", "Weather Instance Created Succesfully");
          })
          .catch((error) => {
            setLoadingOnButton(false);
            const err_msg = Error(error);
            Toastr("error", err_msg);
          });
      } 
    }
    else {
        Toastr("info", "Please Fill Out the Name & 1st Location Field");
      }
    
  };

  return (
    <div className={Styles.preview_backdrop}>
      <div className={Styles.close_icon_container}>
        <Close
          className={Styles.close_icon}
          onClick={() => {
            handleClose();
          }}
        />
      </div>
      <div>
        <div
          className={Styles.monitor}
          style={{
            height: 400 + 20 + "px",
            width: 700 + 10 + "px",
          }}
        >
          {/* <canvas
            id='canvas'
            style={{ margin: "5px" }}
            ref={canvasRef}></canvas> */}
          <div
            className={Styles.clock_container}
            style={{
              height: "400px",
              width: "700px",
              backgroundColor: "white",
              marginTop: "1%",
            }}
          >
            {weatherList.map((item, index) => (
              <>
                {Object.keys(item).length !== 0 && (
                  <div className="weather_cardsList" key={item.id}>
                    <div key={item.id}>
                      <img
                        src={`http://openweathermap.org/img/w/${item.weather[0]?.icon}.png`}
                        alt="weather status icon"
                        className="weather-icon"
                      />
                      {tempType === "Celsius" ? (
                        <p className="h2">
                          {kelvinToFarenheit(item.main?.temp_min)}&deg; C
                        </p>
                      ) : (
                        <p className="h2">{item.main?.temp_min}&deg; F</p>
                      )}
                      <p>{item.name}</p>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>

          <div className={Styles.tvscreen_logo}>
            ABACI
            <div className={Styles.icon_led_green}></div>
          </div>
        </div>
      </div>
      <div className={Styles.template_settings_holder_div}>
        <div className={Styles.clock_settings}>
          <div className={Styles.template_settings_header}>
            <p style={{ fontFamily: "lato", fontSize: "20px" }}>
              Instance Properties
            </p>
          </div>
          <AbaciHorizontalLine />

          <table
            style={{
              margin: "0px",
              marginTop: "10px",
              color: "white",
              width: "200px",
            }}
          >
            <tbody>
              <tr>
                <th>
                  <div className={Styles.table_head}>Name</div>
                </th>
                <td>
                  <div>
                    <Input
                      placeholder="Name"
                      style={{ width: "210px" }}
                      onChange={setName}
                      value={name}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div className={Styles.table_head}>Location 1</div>
                </th>
                <td style={{ width: "300px" }}>
                  <div style={{ width: "250px", color: "black" }}>
                    <GeoapifyContext apiKey="de8f98b565ea48449e6e94602714062f">
                      <GeoapifyGeocoderAutocomplete
                        placeSelect={(event) => {
                          onPlaceSelect(event, 0);
                        }}
                        suggestionsChange={onSuggectionChange}
                        placeholder="Enter Address"
                        onClose={(event) => {
                          // console.log(event);
                        }}
                        value={locations.location0}
                      />
                    </GeoapifyContext>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div className={Styles.table_head}>Location 2 (Optional)</div>
                </th>
                <td style={{ width: "300px" }}>
                  <div style={{ width: "250px", color: "black" }}>
                    <GeoapifyContext apiKey="de8f98b565ea48449e6e94602714062f">
                      <GeoapifyGeocoderAutocomplete
                        placeSelect={(event) => {
                          onPlaceSelect(event, 1);
                        }}
                        suggestionsChange={onSuggectionChange}
                        placeholder="Enter Address"
                        value={locations.location1}
                      />
                    </GeoapifyContext>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div className={Styles.table_head}>Location 3 (Optional)</div>
                </th>
                <td style={{ width: "300px" }}>
                  <div style={{ width: "250px", color: "black" }}>
                    <GeoapifyContext apiKey="de8f98b565ea48449e6e94602714062f">
                      <GeoapifyGeocoderAutocomplete
                        placeSelect={(event) => {
                          onPlaceSelect(event, 2);
                        }}
                        suggestionsChange={onSuggectionChange}
                        placeholder="Enter Address"
                        value={locations.location2}
                      />
                    </GeoapifyContext>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div className={Styles.table_head}>Unit type</div>
                </th>
                <td>
                  <div>
                    <SelectPicker
                      data={tempOptions}
                      value={tempType}
                      onChange={setTempType}
                      appearance="default"
                      cleanable={false}
                      placeholder="Select Unit"
                      style={{ width: 210 }}
                      searchable={false}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          {/* end */}

          {/* <AbaciHorizontalLine /> */}
          {/* container list data mapping */}
          <div></div>

          {/* container list data mapping */}
        </div>
        <div className={Styles.container_add_button}>
          <AbaciButton
            onClick={() => {
              handleSave();
            }}
          >
            {loadingOnButton ? <Spinner animation="grow" size="sm" /> : "Save"}
          </AbaciButton>
        </div>
      </div>

      {/* <div className={Styles.button_holder}>
          <div className="flex-footer-container-modal">
            <div className="button-container-modal">
              <AbaciButton
                width="100px"
                height="40px"
                textcolor="white"
                color="#FF8585"
                waveColor="#F5B9B9"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </AbaciButton>
            </div>
            <div className="button-container-modal">
              <AbaciButton
                width="100px"
                height="40px"
                textcolor="white"
                color="#3CA1B1"
                waveColor="#3BCBE2"
                //   loadingOnButton={loadingOnButton}
                onClick={() => {
                  handleSave();
                }}
              >
                Save
              </AbaciButton>
            </div>
          </div>
        </div> */}
      {/* {weatherCreateModal ? (
            <WeatherModal
              weatherCreateModal={weatherCreateModal}
              setWeatherCreateModal={setWeatherCreateModal}
              setWeatherList={setWeatherList}
              weatherList={weatherList}
            />
          ) : null} */}
    </div>
  );
}
