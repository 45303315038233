import React, { useEffect, useState } from "react";
import Modals from "../../../components/Modal";
import { Col } from "reactstrap";
import urlMaker from "../../../helpers/urlMaker";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import { useDispatch } from "react-redux";
import temp from "./templateThumbnail.jpg";

import { addCanvasTag, updateCanvas } from "../../../store/canvasSlice";
function CanvasModal(props) {
  const [value, setValue] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const tmp = props?.data?.tag_jsonobject?.map((data) => ({
      ...data,
      label: data.tag,
      value: data.tag,
    }));

    setValue(tmp);

    // console.log(props.data)
  }, [props.data]);

  const handleCreate = (inputValue) => {
    const url = "/api/signage/tagslistcreateview";
    const data = {
      tag: inputValue,
      type: "canvas",
      object: props.data.id,
    };

    authAxios
      .post(url, data)
      .then((res) => {
        const temp = { ...res.data, label: res.data.tag, value: res.data.tag };
        // console.log(temp)
        setValue((state) => [...state, temp]);
        // console.log(res.data)
        dispatch(addCanvasTag(res.data));

        // Toastr('success','Tag Added Successfully')
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const clearHandler = (newValue, oldValue) => {
    const id = oldValue.removedValue.id;
    const url = `/api/signage/tagsrudview/${id}`;
    authAxios
      .delete(url)
      .then((res) => {
        const data = { ...props.data, tag_jsonobject: newValue };
        dispatch(updateCanvas(data));
        setValue(newValue);
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };
  return (
    <>
      <Modals
        title={"Media Viewer"}
        isOpen={props.isOpen}
        toggle={() => props.toggle(false)}
        padding={0}
        size="lg"
      >
        <div className="canvasInfo__Main_container">
          <div style={{}}>
            <img
              src={
                props.data.thumbnail
                  ? props.data.is_stock
                    ? urlMaker(props.data.file, "canvas", true)
                    : urlMaker(props.data.file, "canvas")
                  : temp
              }
              className="fade-in-image"
              alt="Stroke"
              style={{
                maxWidth: "535px",
                height: "535px",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
        <div className="canvasInfo_Detail_container">
          <div>
            <div className="image-viewer-details">
              <p className="image-viewer-details-head">Details</p>

              <table className="image-viewer-details-table">
                <tbody>
                  <tr>
                    <td className="image-viewer-details-table-td">Name</td>
                    <td
                      className="image-viewer-details-table-td1"
                      style={{
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                        overflowWrap: " break-word",
                      }}
                    >
                      {props.data.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="image-viewer-details-table-td">Format</td>
                    <td className="image-viewer-details-table-td1">
                      {props?.data.file_type}
                    </td>
                  </tr>

                  <tr>
                    <td className="image-viewer-details-table-td">
                      Uploaded by
                    </td>
                    <td
                      className="image-viewer-details-table-td1"
                      style={{ textTransform: "capitalize" }}
                    >
                      {props?.data.created_by}
                    </td>
                  </tr>
                  <tr>
                    <td className="image-viewer-details-table-td">
                      Uploaded date
                    </td>
                    <td className="image-viewer-details-table-td1">
                      {moment(props?.data.created_date).format("MMM DD,YYYY")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Col className="ml-3 mt-5" style={{ padding: "10px" }}>
              <span style={{ paddingLeft: "10px" }}>Tags </span>
              <CreatableSelect
                isMulti
                onCreateOption={handleCreate}
                placeholder="No Tags Added"
                value={value}
                isClearable={false}
                onChange={(newValue, oldValue) =>
                  clearHandler(newValue, oldValue)
                }
              />
            </Col>
          </div>
        </div>

        {/* <div className='d-flex justify-content-between'style={{height:"600px"  , backgroundColor:'#F8F8F8'}}> 

           <div style={{borderRadius:"10px" ,
              marginTop:'50px',
              display:"flex",
              justifyContent:"center",
              width:'600px',
              height:'300px',
           
              }}>
             <img
             src={props.data.thumbnail ? urlMaker(props.data.file,'canvas') : temp}
             className="fade-in-image"
             style={{ maxWidth:'550px', height:'300px',objectFit:'contain' ,marginTop:'90px'}}>
           
             </img>
            </div>
            </div>
               
       <div style={{borderLeft:'1px solid lightgrey'}}>
       <div className='image-viewer-details' > 
       
       <p className='image-viewer-details-head'>Details</p>
       
       <table className='image-viewer-details-table'>

       <tbody>
       <tr>
            <td className='image-viewer-details-table-td'>Name</td>
            <td className='image-viewer-details-table-td1' style={{textTransform:"capitalize"}}>{props.data.name }</td>
        </tr>
       <tr>
            <td className='image-viewer-details-table-td'>Format</td>
            <td className='image-viewer-details-table-td1'>{props?.data.file_type}</td>
        </tr>
    
        <tr>
            <td className='image-viewer-details-table-td'>Uploaded by</td>
            <td className='image-viewer-details-table-td1' style={{textTransform:"capitalize"}}>{props?.data.created_by}</td>
        </tr>
        <tr>
            <td className='image-viewer-details-table-td'>Uploaded date</td>
            <td className='image-viewer-details-table-td1'>{moment(props?.created_date).format('MMM DD,YYYY')}</td>
        </tr>
       </tbody>
       </table>
       
    
       </div>
       
       <Col  className="ml-3 mt-5">
      <span style={{paddingLeft:"10px"}}>Tags </span>
       <CreatableSelect isMulti  
         onCreateOption={handleCreate}
         placeholder='No Tags Added'
         value={value} 
         isClearable={false}
         onChange={(newValue,oldValue)=>clearHandler(newValue,oldValue)}/>
       </Col>
       </div> */}
      </Modals>
    </>
  );
}

export default CanvasModal;
