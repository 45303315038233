import React, { useEffect, useState } from 'react';
import VerticalLayout from "../components/VerticalLayout";
// import MaterialTable from 'material-table';
import { Container,  Col, Row } from 'reactstrap'
import { ReactComponent as ScreenGroup } from "../assets/SVG/testing.svg";
import { ReactComponent as Screen } from "../assets/SVG/screen.svg";

// import Breadcrumbs from '../components/Common/Breadcrumb'
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
// import ScreenGroup from '../assets/SVG/ScreenGroup.svg'
function Index() {
  const [load] = useState(false)
  // var video = document.querySelector("#videoElement");

  // const [playList, setPlayList] = useState({})
  // const obj = [
  //   {
  //     name: 'f1',
  //     duration: 10,
  //     start_time: 1678478400,
  //     end_time: 1678478400,
  //     priority: 1,
  //   },
  //   {
  //     name: 'f2',
  //     duration: 60,
  //     start_time: 1678478400,
  //     end_time: 1678478400,
  //     priority: 1,
  //   },
  //   {
  //     name: 'f3',
  //     duration: 100,
  //     start_time: 1678478400,
  //     end_time: 1678478400,
  //     priority: 1,
  //   },
  //   {
  //     name: 'f4',
  //     duration: 1000,
  //     start_time: 1678478400,
  //     end_time: 1678478400,
  //     priority: 1,
  //   },

    
  // ]
//   useEffect(() => {
// //     // const day_start = 1678478400
// //     // const day_end = 1678478400 + 24 * 60 * 60

// //     // let tempPlaylist = {}
// //     // let i = day_start
// //     // let priority = 0
// //     // while (i < day_end){
// //     //   while (priority < obj.length){
// //     //     const tempObj = obj[priority]
// //     //     if (tempObj.end_time > i){
// //     //       // pass
// //     //     }
// //     //     else{
// //     //       if (tempObj.start_time <= i){
// //     //         tempPlaylist[i] = tempObj
// //     //         i += tempObj.duration
// //     //       }
// //     //     }
// //     //     priority++
// //     //   }
// //     //   console.log(i)
// //     //   // for (let x = 0; x <  obj.length ; x++ ){
        
// //     //   // }
// //     //   // i++
// //     // }
// //     // console.log(tempPlaylist)
// //     // setLoad(false)
// // //     const permissions = navigator.mediaDevices.getUserMedia({
// // //       audio: true,
// // //       video: true,
// // //     })

// // //     console.log(permissions)

// // // navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;

// // // if (navigator.getUserMedia) {       
// // //     navigator.getUserMedia({video: true}, handleVideo);
// // // }

// //     // console.log(navigator.mediaDevices.getUserMedia())

// //     getMedia({
// //       audio: true,
// //       video: true,
// //     })

// const permissions = navigator?.mediaDevices?.getUserMedia
// console.log(permissions)
//   }, [])


//   async function getMedia(constraints) {
//     console.log('wrked')
//     let stream = null;
  
//     try {
//       stream = await navigator.mediaDevices.getUserMedia(constraints);
//       console.log(stream)
//     } catch (err) {
//       console.log(err)
//     }
//   }

//   function handleVideo(stream) {
//     video.src = window.URL.createObjectURL(stream);
// }


const actions = [
  { icon: <ScreenGroup />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
];

  
  return (
    <VerticalLayout>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>


    <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{  right: -10,color:'primary' }}
        icon={<SpeedDialIcon/>}
      >
         <SpeedDialAction
            icon={<Screen />}
            tooltipTitle={'Screens'}
            sx={{width:90,height:90}}
          />
          <SpeedDialAction
            icon={<ScreenGroup />}
            tooltipTitle={'Screen Groups'}
            
            sx={{width:90,height:90}}
          />
          
      </SpeedDial>
     
    </Box>
        </Col>
          </Row>
        </Container>
      </div>
    </VerticalLayout>
  )
}

export default Index;