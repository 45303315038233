import React, { useEffect, useState } from "react";
import Styles from "./ScreenAddModalTv.module.css";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import useWindowSize from "../../../hooks/useWindowSize";
// import { useEffect, useState} from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import AbaciButton from "../../../components/AbaciButton/AbaciButton";
import axios from "axios";
// import { message } from "antd";
import { addScreen } from "../../../store/screenSlice";
import { authAxios } from "../../../axiosInstance";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";

export default function ScreenAddModalTv(props) {
  const screenList = useSelector((state) => state.screenSlice.screens);
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);
  const [showTv, setShowTv] = useState(false);
  const [pairingkey, setPairingkey] = useState("");
  const [submitButtonDisable, setSubmitButtunDisable] = useState(true);
  const [submissionloading, setSubmissionloading] = useState(false);

  useEffect(() => {
    if (props.isModalVisible) {
      setShowModal(true);
      setTimeout(() => setShowTv(true), 10);
    } else {
      setShowTv(false);
      setShowModal(false);
    }
  }, [props.isModalVisible]);
  const window = useWindowSize();

  // const handleClose = () => {
  //   setShowModal(false);
  //   setShowTv(false);
  //   setPairingkey("");
  //   setSubmitButtunDisable(true);
  //   setSubmissionloading(false);
  //   props.setIsModalVisible(false);
  // };
  const pairingkeyInputHandler = (value) => {
    if (value.length <= 6) {
      setPairingkey(value);
    }
  };
  useEffect(() => {
    if (pairingkey.length === 6) {
      setSubmitButtunDisable(false);
    } else {
      setSubmitButtunDisable(true);
    }
  }, [pairingkey]);

  const submitHandler = (e) => {
    e.preventDefault()
    setSubmissionloading(true);
    const data = {
      screen_id: pairingkey,
    };
    const url = "/api/signage/screenslistcreateview";
    authAxios
      .post(url, data)
      .then((response) => {
 
        // message.success(response.data.message);
        dispatch(addScreen(response.data))
        Toastr('success','New Screen Added Succesfully')
        props.setIsModalVisible(false);
      })
      .catch((error) => {
        // setSubmitButtunDisable(trufalsee)
        setSubmissionloading(false);
       let err_msg=Error(error)
       Toastr('error',err_msg)
      });
  };

  if (!showModal) {
    return null;
  } else {
    return (
      <div style={{zIndex:'9999'}}>
        <div className={Styles.preview_backdrop} style={{zIndex:'9999'}}>
          <div className={Styles.close_icon_container} >
            <Close className={Styles.close_icon} onClick={() => props.setIsModalVisible(false)} />
          </div>
          <div
            className={showTv ? Styles.monitor : Styles.open}
            style={{
              width: 960,
              height: 540,
            }}>
            <div className={Styles.screen_inside_bezel}>
              <div className={Styles.heading_inside_tv}>
                Please enter the Pairing code
              </div>
              <div className={Styles.key_input}>
                <form
                    onSubmit={submitHandler}
                
                >
                  <TextField
                    id='standard-basic'
                    variant='standard'
                    color='warning'
                    autoComplete='off'
                    inputProps={{
                      min: 0,
                      style: {
                        textAlign: "center",
                        color: "white",
                        fontSize: "48px",
                      },
                    }}
                    value={pairingkey}
                    disabled={submissionloading}
                    onChange={(e) => pairingkeyInputHandler(e.target.value)}
                  />
                  <br />
                  <br />
                  <AbaciButton
                    width='150px'
                    height='50px'
                    textcolor='white'
                    // color='red'
                    // waveColor='purple'
                    disabled={submitButtonDisable}
                    loading={submissionloading}
                    >
                    Submit
                  </AbaciButton>
                </form>
              </div>
            </div>
            <div className={Styles.tvscreen_logo}>
              ABACI
              <div className={Styles.icon_led_green}></div>
            </div>
            <div
              style={{
                width: window.width / props.scalingFactorToTheScreen,
                height:
                  (window.width / props.scalingFactorToTheScreen) *
                  props.virtualHieghtFactor,
                backgroundColor: "white",
                margin: "3px",
                transition: "all ease-in-out 0.5s",
              }}></div>
          </div>
          
        </div>
      </div>
    );
  }
}
