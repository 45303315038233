import React, { useState } from 'react'
import SupportHeader from "../../../components/PublicComponents/header/SupportHeader";
import './contactUs.css'
function ContactUs() {

    const [dataToBeSend, setDataToBeSend] = useState({name:"",email:"",message:""});
    const inputHandler=(value,type)=>{
        setDataToBeSend({...dataToBeSend,[type]:value})
      
      }
  return (
    <div className="page-wrapper" id="wrapper">
      <SupportHeader />

      <div style={{ width: "100%" }} className='p-3'>

      <h1 className="mb-3 mt-3" style={{fontWeight:900}}>{'Connect With Us'}</h1>
      <form className="mt-4" >
        <div >
          <div className="form-group">
            <label>{"What's your name?"}</label>

            <input
              id="form_name"
              className='contact_input_style'
              type="text"
              name="name"
              value={dataToBeSend.name}
              placeholder={"Type your name"}
              required="required"
              onChange={(e)=>inputHandler(e.target.value,"name")}
            />
          </div>

          <div className="form-group">
            <label>{"What's your email address?"}</label>
            <input
              id="form_email"
              type="email"
              name="email"
              value={dataToBeSend.email}
              className='contact_input_style'

              placeholder={"Type your Email Address"}
              required="required"
              onChange={(e)=>inputHandler(e.target.value,"email")}
            />
          </div>

          <div className="form-group">
            <label>{"What's up?"}</label>
            <textarea
              id="form_message"
              className='contact_input_style'
              name="message"
              placeholder={"Write your Query here"}
              required="required"
              value={dataToBeSend.message}
              onChange={(e)=>inputHandler(e.target.value,"message")}
                style={{marginTop:'6px'}}
            />
          </div>

          <div className="image-zoom text-center">
            <button disabled={dataToBeSend.name===""||
                              dataToBeSend.message===""||
                              dataToBeSend.email===""
                            }          
              type="submit">{"Send Message"}</button>
          </div>

          {/* <div className="loading-message mt-20" style={{height:"40px"}}>{loading?"Message Sending...":results}</div> */}


        </div>
      </form>

        </div>
        </div>
  )
}

export default ContactUs