import { baseURL } from "../baseURL";

export default function adminUrlMaker(url, type, tenant) {
  let url_name = "";

  if (type === "image") {
    const file_name = url?.split("/thumbnails/")[1];
    url_name = `${baseURL}/clients/${tenant}/media/uploaded_files/${tenant}/thumbnails/${file_name}`;
  } else if (type === "template") {
    const file_name = url?.split("/templates/")[1];
    url_name = `${baseURL}/clients/${tenant}/media/uploaded_files/${tenant}/templates/${file_name}`;
  } else if (type === "canvas") {
    const file_name = url?.split("/canvas/")[1];
    url_name = `${baseURL}/clients/${tenant}/media/uploaded_files/${tenant}/canvas/${file_name}`;
  } else if (type === "support") {
    const file_name = url?.split("/support_page_content/")[1];
    url_name = `${baseURL}/clients/${tenant}/media/uploaded_files/${tenant}/support_page_content/${file_name}`;
  } else {
    const file_name = url?.split("/files/")[1];
    url_name = `${baseURL}/clients/${tenant}/media/uploaded_files/${tenant}/files/${file_name}`;
  }

  return url_name;
}
