import React from "react";
import {
  // Card,
  // CardBody,
  // Row,
  // Col,
  // Container,
  // Form,
  // Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  // FormGroup,
  // Label,
  Spinner,
} from "reactstrap";
import "./WebLinkAddModal.css";
// import axios from "axios";
// import AbaciButton from "../../../components/AbaciButton/AbaciButton";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/error.svg";
import WebPage from "../../../assets/images/webpage.png";
import WebLink from "../../../assets/images/weblinkThumb.png";
import IframeContent from "./IframeContent";
// import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { authAxios } from "../../../axiosInstance";
import { AddNewWebLink } from "../../../store/webLinks";
import { SuccessAlert, Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
export default function WebLinkAddModal(props) {
  const dispatch = useDispatch();
  const [link, setLink] = React.useState("");
  const [linkIsValid, setLinkIsValid] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [loadingOnButton, setLoadingOnButton] = React.useState(false);
  const [title, setTitle] = React.useState(null);
  const [iframe_option, setIframe_option] = React.useState(null);

  // const handleOk = () => {
  //   props.setIsModalVisible(false);
  // };

  const handleCancel = () => {
    // props.setIsModalVisible(false);
    setLink("");
    setLinkIsValid("");
    setErrorMessage(null);
    setTitle(null);
  };
  const inputHandlerOnChange = (event) => {
    setTitle(null);
    setLinkIsValid("");
    setErrorMessage(null);
    setLink(event.target.value);
    setIframe_option(null);
  };
  const inputHandlerOnKeyPress = (event) => {
    setLinkIsValid("");
    if (event.which === 13) {
      setLoading(true);
      const url = "api/signage/linkiframecheck";
      const data = {
        link: link,
      };
      authAxios
        .post(url, data)
        .then((response) => {
          // console.log('>>>>>>>>>>>>>>>>>>>>', response.data)
          setLoading(false);
          setLinkIsValid(" is-valid");
          setTitle(response.data.title);
          setIframe_option(response.data.iframe_option);
          // console.log(response.data.title);
        })
        .catch((error) => {
          // console.log('>>>>>>>>>>>>>>>>>>>>', error.response)

          setLoading(false);
          setLinkIsValid(" is-invalid");
          setErrorMessage("The provided URL is invalid");
          // if (error.response.status === 400) {
          //   setLinkIsValid(" is-invalid");
          //   setErrorMessage("The provided URL is invalid");
          // }
          // // else if (error.response.status === 405) {
          //   setLinkIsValid(" is-valid");
          //   setErrorMessage(
          //     "This website does not support iframe.However It will be displayed on your Digital signage Player"
          //   );
          // }
        });
    }
  };

  const submitHandler = () => {
    setLoadingOnButton(true);
    const url = "api/signage/objectlistcreate";
    const data = {
      name: title,
      file_type: "link",
      object: {
        title: title,
        link: link,
      },
    };
    // console.log(data)
    authAxios
      .post(url, data)
      .then((response) => {
        // console.log(response.data);
        dispatch(AddNewWebLink(response.data));
        setLoadingOnButton(false);
        SuccessAlert("Weblink Added Succesfully");
        // props.setLinkList((prevState) => [...prevState, response.data]);
        props.toggle();
        handleCancel();
      })
      .catch((error) => {
        setLoadingOnButton(false);
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  // const onSubmit = (data) => {

  // };
  return (
    <div>
      <Modal size={"lg"} centered isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
        <ModalBody>
          {/* <Form > */}

          <div className="parent-weblink">
            <div className="div1-weblink">
              <p style={{ marginLeft: "5px", marginBottom: "-1.5px" }}>
                Please provide your URL
              </p>
              {/* <div className='flex-container-web-link'> */}
              <input
                className={"form-control form-control-sm" + linkIsValid}
                type="text"
                style={{ width: "240px", margin: "5px" }}
                value={link}
                onKeyPress={inputHandlerOnKeyPress}
                onChange={inputHandlerOnChange}
                placeholder="http://www.example.com"
              />
              {loading ? (
                <div className="spinner-grow text-info" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}
              {/* </div> */}
              <span style={{}}>* Press Enter to fetch the website</span>
              {errorMessage === null ? null : (
                <div className="error-message-container">
                  <div className="flex-container">
                    <ErrorIcon style={{ width: "20px", height: "20px" }} />
                    {errorMessage}
                  </div>
                </div>
              )}
              <br />
              {title !== null ? (
                <>
                  <p style={{ margin: "5px" }}>Title</p>
                  <input
                    className={"form-control form-control-sm"}
                    type="text"
                    placeholder="Title"
                    style={{ width: "240px", margin: "5px" }}
                    value={title}
                    // onKeyPress={inputHandlerOnKeyPress}
                    onChange={(e) => setTitle(e.target.value)}
                    // placeholder='http://www.example.com'
                  />
                </>
              ) : null}
            </div>
            <div className="div2-weblink">
              {linkIsValid === " is-valid" && iframe_option ? (
                <IframeContent link={link} />
              ) : linkIsValid === " is-invalid" || iframe_option === false ? (
                <div className="weblinkImageWrapper">
                  <img src={WebPage} alt="" />
                  <p style={{ margin: "2rem" }}>
                    This website does not support iframe !<br />
                    However, if the page is live, the same will be shown on your
                    digital signage player.
                  </p>
                </div>
              ) : (
                <div className="weblinkImageWrapper">
                  <img
                    src={WebLink}
                    alt=""
                    style={{ width: "200px", height: "200px" }}
                  />
                  <p style={{ margin: "2rem", textAlign: "center" }}>
                    Please Provide The Url
                    <br />
                    Which has To be Shown As iframe!
                  </p>
                </div>
              )}
            </div>
          </div>

          <ModalFooter>
            <Button
              // type="submit"
              color="primary"
              className="w-xs waves-effect waves-light me-1"
              onClick={submitHandler}
              disabled={linkIsValid !== "" ? false : true}
            >
              {loadingOnButton ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              type="button"
              color="danger"
              className="w-xs waves-effect waves-light me-1"
              onClick={props.toggle}
            >
              Close
            </Button>
          </ModalFooter>
          {/* </Form> */}
        </ModalBody>
      </Modal>
    </div>
  );
}
