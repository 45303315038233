import React from "react";
import {  UncontrolledCarousel } from "reactstrap";
import clock2 from "../../../assets/images/AppImages/clock2.png";
import clock1 from "../../../assets/images/AppImages/clock1.png";
import youtube1 from "../../../assets/images/AppImages/youtube1.png";
import youtube2 from "../../../assets/images/AppImages/youtube2.png";
import youtube3 from "../../../assets/images/AppImages/youtube3.png";
import EmptyL1 from "../../../assets/images/AppImages/clock3.jpg";
import weather1 from "../../../assets/images/AppImages/weather1.png";
import weather2 from "../../../assets/images/AppImages/weather2.png";
import weather3 from "../../../assets/images/AppImages/weather3.png";
import text1 from "../../../assets/images/AppImages/text1.png";
import text2 from "../../../assets/images/AppImages/text2.png";
import text3 from "../../../assets/images/AppImages/text3.png";

const images = [
  [
    {
      src: clock1,
      altText: "clock1 ",
    },
    {
      src: clock2,
      altText: "clock2 ",
    },
  ],
  [
    {
      src: weather2,
      altText: "image",
    },
    {
      src: weather1,
      altText: "image",
    },
    {
      src: weather3,
      altText: "image",
    },
  ],
  [
    {
      src: youtube1,
      altText: "image",
    },
    {
      src: youtube2,
      altText: "image",
    },
    {
      src: youtube3,
      altText: "image",
    },
  ],
  [
    {
      src: text1,
      altText: "image",
    },
    {
      src: text2,
      altText: "image",
    },
    {
      src: text3,
      altText: "image",
    },
  ],
];

const CarouselImage = (props) => (
  <UncontrolledCarousel items={images[props.type]}/>
);

export default CarouselImage;
