import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  user_data: null,
  stock_data: null,
  editValue: null,
};
// const initialState = [];
const weatherSlice = createSlice({
  name: "weatherSlice",
  initialState,
  reducers: {
    setWeather(state, action) {
      state.user_data = action.payload;

      // state.stock_data = action.payload.stock_data;
      // state.user_data = action.payload;
    },
    favouriteWeather(state, action) {
      state.user_data = state.user_data.map((data) =>
        data.id === action.payload.id
          ? { ...data, is_favourite: action.payload.is_favourite }
          : data
      );
    },
    addWeather: (state, action) => {
      state.user_data.push(action.payload);
    },
    editWeather(state, action) {
      state.user_data = state.user_data.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },

    toStoreEditValue(state, action) {
      state.editValue = action.payload;
    },
    setNewWeather: (state, action) => {
      const data = {
        id: action.payload.item.id,
        name: action.payload.item.name,
        Created_by: action.payload.item.Created_by,
        Created_on: action.payload.item.Created_on,
        data: action.payload.data,
      };
      const temp = state.user_data.map((item) =>
        item.id === action.payload.item.id ? data : item
      );
      state.user_data = temp;
    },
    // deleteWeather(state, action) {
    //   state.user_data = [...state.user_data];
    //   const indexToBeDeleted = action.payload;
    //   const tempState = [...state.user_data];
    //   tempState.splice(indexToBeDeleted, 1);
    //   state.user_data = tempState;
    //   console.log(" data from template store", state.user_data);
    // },
    deleteWeather(state, action) {
      state.user_data = state.user_data.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  favouriteWeather,
  setWeather,
  addWeather,
  setNewWeather,
  deleteWeather,
  toStoreEditValue,
  editWeather,
} = weatherSlice.actions;
export default weatherSlice.reducer;
