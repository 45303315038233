import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
// import Error from "../../../helpers/Error";
// import { ErrorAlert, SuccessAlert } from "../../../helpers/Alert";
// import { authAxios } from '../../../axiosInstance';
import { useDispatch } from "react-redux";

// import { addNewHome } from "../../../store/home";
// import { authAxiosFileUpload } from "../../../axiosInstance";
// import urlMaker from "../../../helpers/urlMaker";
import axios from "axios";
import Modals from "../../../../components/Modal";
import { Toastr } from "../../../../helpers/Alert";
import Error from "../../../../helpers/Error";
import { authAxios, authAxiosFileUpload } from "../../../../axiosInstance";
import {
  AddNewHelpCenter,
  EditHelpCenter,
} from "../../../../store/supportSlice";

function EditHeplCenterModal(props) {
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.data) {
      reset(props.data);
    }
  }, [props]);

  const onSubmit = (data) => {
    let formData = new FormData(); //formdata object

    formData.append("heading", data.heading);
    formData.append("sub_heading", data.sub_heading);
    formData.append("description", data.description);
    formData.append("order", data.order);
    if (file !== null) {
      formData.append("file", file[0]);
    }

    setwaitingForAxios(true);

    const url = `/abaciadmin_api/helpcenter/${props.data.id}`;

    authAxiosFileUpload
      .patch(url, formData)
      .then((response) => {
        setwaitingForAxios(false);
        dispatch(EditHelpCenter(response.data));
        Toastr("success", "Content updated succesfully");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };

  return (
    <div>
      <Modals isOpen={props.isOpen} toggle={props.toggle} title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Heading" sm={3}>
              Heading
            </Label>
            <Col sm={9}>
              <input
                placeholder="Heading"
                type="text"
                className="form-control"
                {...register("heading", {
                  required: true,
                })}
              />
              {errors.heading?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Sub Heading" sm={3}>
              Sub Heading
            </Label>
            <Col sm={9}>
              <textarea
                className="form-control"
                rows="5"
                cols="33"
                placeholder="Sub Heading"
                {...register("sub_heading", {
                  required: true,
                })}
              />
              {errors.sub_heading?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Description" sm={3}>
              Description
            </Label>
            <Col sm={9}>
              <textarea
                className="form-control"
                rows="5"
                cols="33"
                placeholder="Description"
                {...register("description", {
                  required: true,
                })}
              />
              {errors.description?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="Order No" sm={3}>
              Order No
            </Label>
            <Col sm={9}>
              <input
                placeholder="Order No"
                type="number"
                min="0"
                onKeyDown={(evt) =>
                  evt.key === "-" || evt.key === "e" || evt.key === "."
                    ? evt.preventDefault()
                    : null
                }
                onWheel={(e) => e.target.blur()}
                className="form-control"
                {...register("order", {
                  required: true,
                })}
              />
              {errors.order?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "10px" }}>
            <Label for="exampleEmail2" sm={3}>
              Media File
            </Label>
            <Col sm={9}>
              <input
                // accept="image/jpeg,video/mp4"
                accept="image/*,video/*"
                className="form-control"
                id="customFile"
                // {...register("file")}
                onChange={(event) => setFile(event.target.files)}
                type="file"
              />
            </Col>
          </FormGroup>

          <ModalFooter>
            <Button
              type="submit"
              disabled={waitingForAxios}
              className="w-xs waves-effect waves-light me-1"
            >
              {waitingForAxios ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              type="button"
              color="danger"
              className="w-xs waves-effect waves-light me-1"
              onClick={props.toggle}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modals>
    </div>
  );
}

export default EditHeplCenterModal;
