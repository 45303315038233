import React, { useState, useEffect } from "react";
import NewTitle from "./NewTitle";
import TitleList from "./List";
import "./Titles.css";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import EmptyList from "../../../../assets/SVG/Search Not Found 2.svg";

import TitleCard from "./TitleCard";
import classnames from "classnames";
import { CustomButton } from "../../../../components/CustomButton";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TitleAddModal from "./TitleAddModal";
import { deleteText, setText } from "../../../../store/TextSlice";
import { authAxios } from "../../../../axiosInstance";
import { Toastr } from "../../../../helpers/Alert";
import InfiniteScroll from "react-infinite-scroller";
import Error from "../../../../helpers/Error";
import TitleEditModal from "./TitleEditModal";
export default function Titles() {
  const textList = useSelector((state) => state.textSlice.user_data);
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);

  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);
  const [searchKeyCount,setSearchKeyCount] = useState(0)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasNextpage, setHasNextPage] = useState(false);
  const [pageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [itemToBeEdited,setItemToBeEdited]=useState(null)
  const [editModalShow,setEditModalShow]=useState(false)
  const [dashboardState, setDashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "Text Instance", link: "#" },
    ],
  });
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  
  const editModalToggle=()=>{
    setEditModalShow(!editModalShow)
  }

  const [titleModalShow, setTitleModalShow] = useState(false);

  const titleModalToggle = () => {
    setTitleModalShow(!titleModalShow);
  };


  useEffect(() => {
  
    setPage(1);
    setHasNextPage(false);
    dispatch(setText(null));

    if(searchKey !== prevSearchKey){
      setPrevSearchKey(searchKey)
    }

    setSearchKeyCount(searchKey !==null ? searchKey.length : 0)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[searchKey])

  useEffect(() => {
    const url = `/api/signage/objectlistcreate?object_type=text&limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}`;

    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);
        if (textList === null) {
          dispatch(setText(response.data.results));
        } else {
          dispatch(setText([...textList, ...response.data.results]));
        }

        setTotalCount(response.data.count);

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        Toastr("error", "Oops!,Something Went Wrong");
      });
  }, [page,prevSearchKey]);

  const loadFunc = () => {
    if (hasNextpage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
    // setTemp(state => [...state, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
  };

  useEffect(() => {
    return () => {
      dispatch(setText(null));
    };
  }, []);
  const deleteHandler = (id) => {
    const url = `api/signage/objectrud/${id}`;
    authAxios
      .delete(url)
      .then((response) => {
        dispatch(deleteText(id));
        Toastr("success", "Text Instance Deleted Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const   editModalHandler=(item)=>{

    setItemToBeEdited(item)
    editModalToggle();
  }

  return (
    <React.Fragment>
      {titleModalShow && (
        <TitleAddModal isOpen={titleModalShow} toggle={titleModalToggle} />
      )}
      {editModalShow &&
      <TitleEditModal isOpen={editModalShow} toggle={editModalToggle} item={itemToBeEdited}/>

      }

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Text Instance"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>
          <Col xl={24}>
            <div style={{ minHeight: "calc(100vh - 300px" }}>
              {textList?.length === 0 ? (
                <div className="weblinkEmptyImage text-center">
                  <img
                    src={EmptyList}
                    alt=""
                    style={{ width: "450px", height: "450px" }}
                  />
                  <h6 style={{}}>No Instance Found !</h6>
                </div>
              ) : (
                <>
                  {textList === null || loader ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                      }}
                    >
                      <Spinner animation="grow" />
                    </div>
                  ) : (
                    <InfiniteScroll
                      pageStart={0}
                      // dataLength={totalCount}
                      // next={loadFunc}
                      loadMore={loadFunc}
                      hasMore={hasNextpage}
                      loader={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          className="loader"
                          key={0}
                        >
                          <Spinner animation="grow" />
                        </div>
                      }
                    >
                      {textList?.map((item, index) => (
                        <TitleCard
                          key={index}
                          item={item}
                          deleteHandler={deleteHandler}
                          editModalHandler={editModalHandler}
                        />
                      ))}
                    </InfiniteScroll>
                  )}
                </>
              )}
            </div>

            {/* {textList?.map((data, index) => (
              <TitleCard key={index} item={data} />
            ))} */}
          </Col>
          <div>
            <CustomButton
              title={"+"}
              onClick={() => {
                titleModalToggle();
              }}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
