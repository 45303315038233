import React, { createContext, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookies from "js-cookie";
import { baseURL } from '../helpers/baseURL';

const WebsocketContext = createContext(null);
const { Provider } = WebsocketContext;

const WebsocketProvider = ({ children }) => {
    const auth = useSelector((state) => state.authSlice);

    const [WebsocketState, setWebsocketState] = useState(null);
    const clientRef = useRef(null);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [screens, setScreens] = useState([]);
    const [messageType,setMessageType]=useState('')
    // const [message, setMessage] = useState(null)
    // const [updatedMessage, setUpdatedMessage] = useState(null)
    const [isOpen, setIsOpen] = useState(false);
    const [onlineScreens,setOnlineScreens] =useState([])
    


    function updateScreen(scrn) {
        // console.log(scrn)
        // setScreens([...screens, ...scrn]);


        const existingObjectIndex = screens.findIndex(
            (obj) => obj.screen_id === scrn.screen_id
          );
      
          if (existingObjectIndex >= 0) {
            const temp = [...screens];
      
            temp[existingObjectIndex] = scrn;
      
            setScreens(temp);
          } else {
            setScreens([...screens, ...scrn]);
          }
      
    }

    useEffect(() => {
        if (auth.loggedIn) {
            const wsUrl = baseURL.replace('http', 'ws')
            const token = Cookies.get("token");
            const tenant = Cookies.get("tenant");
            const url = `${wsUrl}/ws/webapp?token=${token}&tenant=${tenant}`
            if (waitingToReconnect) {
                return;
            }
            // Only set up the websocket once
            if (!clientRef.current) {
                const client = new WebSocket(url);
                clientRef.current = client;
                window.client = client;
                client.onerror = (e) => console.error(e);
                client.onopen = () => {
                    setIsOpen(true);
                    setWebsocketState('ws opened');
                    // client.send('ping');
                };

                client.onclose = () => {
                    if (clientRef.current) {
                        // Connection failed
                        setWebsocketState('ws closed by server');
                    } else {
                        // Cleanup initiated from app side, can return here, to not attempt a reconnect
                        setWebsocketState('ws closed by app component unmount');
                        return;
                    }

                    if (waitingToReconnect) {
                        return;
                    };

                    // Parse event code and log
                    setIsOpen(false);
                    setWebsocketState('ws closed');

                    // Setting this will trigger a re-run of the effect,
                    // cleaning up the current websocket, but not setting
                    // up a new one right away
                    setWaitingToReconnect(true);

                    // This will trigger another re-run, and because it is false,
                    // the socket will be set up again
                    setTimeout(() => setWaitingToReconnect(null), 5000);
                };

                client.onmessage = message => {
                    setWebsocketState('received message');
                    let tempMessage = JSON.parse(message.data)
                    try {


                       if(tempMessage.message.all_screen_status){
                        updateScreen(tempMessage?.message?.all_screen_status)
                        setMessageType('all_screen_status')
                       }
                       else{
                        updateScreen(tempMessage.message.single_screen_status)
                        setMessageType('single_screen_status')

                       }
                    }
                    catch {
                        // pass
                    }
                };
                return () => {
                    setWebsocketState('Cleanup');
                    clientRef.current = null;
                    client.close();
                }
            }
        }
    }, [waitingToReconnect, auth.loggedIn]);
    return (
        <Provider
            value={{
                WebsocketState,
                screens,
                messageType,
            }}>
            {children}
        </Provider>
    );
};

export { WebsocketContext, WebsocketProvider };