import React from "react";
// import { Link } from "gatsby";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Link as ScrollLink } from "react-scroll";
const NavLinks = (props) => {
  const handleDropdownStatus = (e) => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
  };
  return (
    <ul className="main-nav__navigation-box one-page-scroll-menu">
      <li>
        <ScrollLink    onClick={()=>{scroll.scrollToTop();}}>
          Home
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
        style={{textDecoration: 'none'}}
          activeClass="current"
          to="solutions"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Solutions
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
         style={{textDecoration: 'none'}}
          activeClass="current"
          to="screens"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Screens
        </ScrollLink>
      </li>
      {/* <li>
        <ScrollLink
         style={{textDecoration: 'none'}}
          activeClass="current"
          to="testimonials"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Testimonials
        </ScrollLink>
      </li> */}
      <li>
        <ScrollLink
         style={{textDecoration: 'none'}}
          activeClass="current"
          to="AppFeature"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
           Features
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
         style={{textDecoration: 'none'}}
          activeClass="current"
          to="pricing"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
         
        >
          Pricing
        </ScrollLink>
        </li>
        <li>
        <ScrollLink
         style={{textDecoration: 'none'}}
          activeClass="current"
          to="FAQ"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
         
        >
          FAQ
        </ScrollLink>
        </li>
        <li>
        <ScrollLink
         style={{textDecoration: 'none'}}
          activeClass="current"
          to='#'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
         onClick={()=>{ scroll.scrollToBottom();}}
        >
          News
        </ScrollLink>
        </li>
        {/* <button className="dropdown-btn" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-right"></i>
        </button> */}
        <ul className="dropdown-list">
          <li>
            {/* <Link to="/blog">
              News
            </Link> */}
          </li>
          <li>
            {/* <Link to="/blog-details">
              News Details
            </Link> */}
          </li>
        </ul>
      {/* </li> */}
    </ul>
  );
};

export default NavLinks;
