// import "./TemplateCard.css";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
// import Cast from "@mui/icons-material/Cast";
import Swal from "sweetalert2";
import { IconButton, Tooltip } from "@mui/material";
import screen from "../../../../assets/images/screen123.png";

import CastIcon from "@mui/icons-material/Cast";
// import moment from "moment";
// import VirtualTemplate from "../../../../components/VirtualTemplate/VirtualTemplate";
import { Card, Col, CardImg, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

export default function ScreenCard({
  item,
  isScreenGroup,
  cast,
  setSelectedItem,
  selectedItem,
  deleteHandler
}) {
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Col md={4} xl={2} style={{ minWidth: "260px" }}>
      <Card
        className={cast ? "cast-screengroup-card" : "abaci-item-card"}
        style={{
          marginTop: "10px",
          minHeight: "320px",
          border: `${
            selectedItem !== null && cast && item.id === selectedItem.id
              ? "2px solid 	#BEBEBE"
              : "1px solid #DCDADA"
          }`,
        }}
        onClick={() => cast && setSelectedItem(item)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className="d-flex justify-content-end mt-1 "
          style={{ marginRight: "8px" }}
        >
          <i
            className="ri-checkbox-blank-circle-fill"
            style={{ color: item.online_status ? "#60D394" : "#EE6055" }}
          ></i>
        </div>
        <CardBody>
          <CardImg
            className="img-fluid"
            src={screen}
            alt="Screen"
            style={{}}
            onClick={() =>
              cast
                ? setSelectedItem(item)
                : navigate(`/screendetail/${item.id}`)
            }
          />

          <div
            className=" d-flex justify-content-start mt-4 "
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              textTransform: "capitalize",
            }}
          >
            {item?.name}
          </div>

          {/* <div
                  className=" d-flex justify-content-start mt-1 mb-2"
                  style={{ color: "#67818f" }}
                >
                  {item?.screen_screengroup?.length + " Screens"}
                </div> */}

          <div className="text-muted">{item?.manufacturer}</div>

          <div className="text-muted">{item?.model_name}</div>

          <div className="text-muted">{item?.timezone}</div>
          <div style={{ height: "30px", width: "100%" }}>
            {!isScreenGroup ? (
              <div
                className={
                  item.screen_group !== null
                    ? `badge badge-soft-success font-size-12`
                    : item?.orchestration_group
                    ? `badge badge-soft-info font-size-12`
                    : null
                }
                style={{
                  display: `${item.screen_group === null ? "none" : ""}`,
                }}
              >
                {item.screen_group !== null ? `Group : ${item?.screen_group?.name}` : item.orchestration_group !== null ? `Orch : ${item?.orchestration_group?.name}` : null}
              </div>
            ) : null}
          </div>

          {/* <div
                  className="d-flex justify-content-end mt-2"
                  style={{ gap: "3px" }}
                >
                  {item?.online_count !== 0 && (
                    <div
                      className="screenCard_status_item"
                      style={{ backgroundColor: "#96CCAC" }}
                    >
                      <b>{`${item?.online_count} Online`}</b>
                    </div>
                  )}
                  {item?.offline_count !== 0 && (
                    <div
                      className="screenCard_status_item"
                      style={{ backgroundColor: "#EB5F70" }}
                    >
                      <b>{`${item?.offline_count} Offline`}</b>
                    </div>
                  )}
                </div> */}
                {isScreenGroup &&
           <div
            style={{
              marginTop: "-20px",
              width: "100%",
              display: "flex",
              height: "47px",
              justifyContent: "center",
            }}
          >
            {isHovered && (
              <Tooltip arrow title="Remove Screen">
                <IconButton
                 onClick={() => deleteHandler(item)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          }
        </CardBody>
      </Card>
    </Col>
  );
}
