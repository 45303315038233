import youtubePic from "../../src/containers/Pages/Apps/icons/youtube.svg";
import WeatherPic from "../../src/containers/Pages/Apps/icons/weather.svg";
import TextBoard from "../../src/containers/Pages/Apps/icons/textBoard.svg";
import Clockimage from "../../src/containers/Pages/Apps/icons/clock.svg";

export const appThumbnailMaker = (file_type) => {

    let file_image

    if(file_type === 'youtube'){
        file_image=youtubePic
    }
    else if (file_type === 'weather'){
        file_image=WeatherPic
    }
    else if (file_type === 'clock'){
        file_image=Clockimage
    }
    else {
        file_image=TextBoard
    }

    return file_image
}