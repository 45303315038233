import { useSelector } from "react-redux";
import React from "react";
import { Row, Col, Container } from "reactstrap";

const Footer = () => {
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);
  return (
    <React.Fragment>
      <footer className="footer" style={{ position: "fixed",zIndex:'1'}}>
        <Container fluid>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © Abaci.</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                {tenantDetails?.app_type}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
