// import axios from "axios";
import React, { useState, useEffect } from "react";
import TemplateContents from "./TemplateContents";
import "./Templates.css";
import TemplateAddModal from "./TemplateAddModal";
import { useDispatch, useSelector } from "react-redux";
// import AbaciButton from "../../../components/AbaciButton/AbaciButton";
// import AbaciLoader from "../../../components/AbaciLoader/AbaciLoader";
import EmptyList from "../../../assets/SVG/Search Not Found 3.svg";
import { addMoreTemplate, setTemplates } from "../../../store/templateSlice";
// import socialMedia from "../../../assets/icons/social-media.svg";
// import Gallery from "../../../assets/icons/image-gallery.svg";
// import VideoIcon from "../../../assets/icons/video.svg";
// import GridView from "../../../assets/icons/visualization.svg";
// import listView from "../../../assets/icons/list.svg";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  // Button,
  // CardTitle,
  // CardText,
  Row,
  Col,
  Container,
  CardBody,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { authAxios } from "../../../axiosInstance";
import { CustomButton } from "../../../components/CustomButton";
import InfiniteScroll from "react-infinite-scroller";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
export default function Templates() {
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [searchKeyCount,setSearchKeyCount] = useState(0)
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);

  const [hasNextpage, setHasNextPage] = useState(false);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [isGridOrListView, setIsGridOrListView] = useState();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const templateFilesUser = useSelector(
    (state) => state.templateSlice.templates
  );
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  const [hasMore, sethasMore] = useState(false);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "Templates", link: "#" },
    ],
  });

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState(
    localStorage.getItem("templateTabKey") != null
      ? localStorage.getItem("templateTabKey")
      : "1"
  );

  
  useEffect(() => {
  
    setPage(1);
    setHasNextPage(false);
    dispatch(setTemplates(null));

    if(searchKey !== prevSearchKey){
      setPrevSearchKey(searchKey)
    }

    setSearchKeyCount(searchKey !==null ? searchKey.length : 0)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[searchKey])

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
      localStorage.setItem("templateTabKey", tab);
      setLimit(15);
      setTotalCount(0);
      dispatch(setTemplates(null));
    }
  };

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    let url;
    if (tenantDetails?.app_type === "Admin") {
      url = `/stock/api/signage/templateslistcreate?limit=${limit}&offset=${
        limit * (page - 1)
      }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}`;
    } else {
      url = `api/signage/templateslistcreate?limit=${limit}&offset=${
        limit * (page - 1)
      }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}`;
    }

    // if (templateFilesUser === null) {
    if (currentActiveTab === "1") {
      authAxios
        .get(url)
        .then((response) => {
          if (response.data.next) {
            sethasMore(true);
          } else {
            sethasMore(false);
          }

          if (templateFilesUser === null) {
            dispatch(setTemplates(response.data.results));
          } else {
            dispatch(
              setTemplates(...templateFilesUser, ...response.data.results)
            );
          }

          setTotalCount(response.data.count);
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    } else if (currentActiveTab === "2") {
      const stockUrl = `/api/signage/stocktemplateslistcreate/public?limit=${limit}&offset=${
        limit * (page - 1)
      }&search=`;
      authAxios
        .get(stockUrl)
        .then((response) => {
          if (response.data.next) {
            sethasMore(true);
          } else {
            sethasMore(false);
          }

          if (templateFilesUser === null) {
            dispatch(setTemplates(response.data.results));
          } else {
            dispatch(
              setTemplates(...templateFilesUser, ...response.data.results)
            );
          }

          setTotalCount(response.data.count);
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentActiveTab,prevSearchKey]);

  useEffect(() => {
    return () => {
      dispatch(setTemplates(null));
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFunc = () => {
    if (hasNextpage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
  };

  // if (templateFilesUser === null) {
  //   return (
  //     <>
  //       <AbaciLoader />
  //     </>
  //   );
  // } else {

  return (
    <>
      {isModalVisible && (
        <TemplateAddModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}

      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* <div className="appearing-button-container-page-bottom-right">
              <Fab
                color="primary"
                aria-label="add"
                onClick={() => setIsModalVisible(true)}
              >
                <AddIcon />
              </Fab>
            </div> */}
            <Breadcrumbs
              title="Template Management"
              breadcrumbItems={dashboardState.breadcrumbItems}
            ></Breadcrumbs>
            <Col xl={24}>
              <div>
                <Card style={{ minHeight: "70vh" }}>
                  <CardBody>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          // className="cool-link"
                          // style={
                          //   currentActiveTab === "1"
                          //     ? {
                          //         cursor: "pointer",
                          //         borderBottom: " 2px solid #1F2027 ",
                          //         transition: "width 0.3s",
                          //         color: "#1F2027",
                          //       }
                          //     : {}
                          // }
                          className={classnames({
                            active: currentActiveTab === "1",
                          })}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            My Templates
                          </span>

                          {/* <span style={{ fontSize: 14, fontFamily: "Lato" }}>
                        <img
                          src={socialMedia}
                          style={{ width: "15px", marginRight: "0.5rem" }}
                          alt=""
                        />
                        My Templates
                      </span> */}
                        </NavLink>
                      </NavItem>
                      {tenantDetails?.app_type !== "Admin" && (
                        <>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: currentActiveTab === "2",
                              })}
                              onClick={() => {
                                toggle("2");
                              }}
                            >
                              <span className="d-none d-sm-block">
                                {" "}
                                Custom Templates
                              </span>

                              {/* <span style={{ fontSize: 14, fontFamily: "Lato" }}>
                        <img
                          src={Gallery}
                          style={{ width: "15px", marginRight: "0.5rem" }}
                          alt=""
                        />
                        Custom Templates
                      </span> */}
                            </NavLink>
                          </NavItem>
                        </>
                      )}
                    </Nav>

                    <TabContent activeTab={currentActiveTab}>
                      <TabPane tabId="1">
                        {currentActiveTab === "1" && (
                          <>
                            {templateFilesUser === null ? (
                              <>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                  }}
                                >
                                  <Spinner animation="grow" size="sm" />
                                </div>
                              </>
                            ) : templateFilesUser?.length === 0 ? (
                              <>
                                <div className=" text-center">
                                  <img
                                    src={EmptyList}
                                    alt=""
                                    style={{ width: "450px", height: "450px" }}
                                  />
                                  <h6 style={{}}>No Template Found !</h6>
                                </div>
                              </>
                            ) : (
                              <>
                                <InfiniteScroll
                                  pageStart={0}
                                  loadMore={loadFunc}
                                  hasMore={hasMore}
                                  loader={
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                      className="loader"
                                      key={0}
                                    >
                                      <Spinner animation="grow" />
                                    </div>
                                  }
                                >
                                  <Row
                                    style={{
                                      maxHeight: Math.round(windowHeight - 313),
                                      // maxHeight:Math.round( windowHeight > 1250 ? windowHeight * 0.78 :  windowHeight < 900 ? windowHeight * 0.625 :  windowHeight * 0.71),
                                      overflowY: "scroll",
                                      overflowX: "hidden",
                                    }}
                                  >
                                    <Col sm="12">
                                      <div className="assetList-holder">
                                        <TemplateContents
                                          docType="user"
                                          templates={templateFilesUser}
                                          isGridOrListView={isGridOrListView}
                                          setIsGridOrListView={
                                            setIsGridOrListView
                                          }
                                          activeKey={currentActiveTab}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </InfiniteScroll>
                              </>
                            )}
                          </>
                        )}
                      </TabPane>

                      <TabPane tabId="2">
                        {currentActiveTab === "2" && (
                          <>
                            {templateFilesUser === null ? (
                              <>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                  }}
                                >
                                  <Spinner animation="grow" size="sm" />
                                </div>
                              </>
                            ) : templateFilesUser?.length === 0 ? (
                              <>
                                <div className=" text-center">
                                  <img
                                    src={EmptyList}
                                    alt=""
                                    style={{ width: "450px", height: "450px" }}
                                  />
                                  <h6 style={{}}>No Stock Template Found !</h6>
                                </div>
                              </>
                            ) : (
                              <>
                                <InfiniteScroll
                                  pageStart={0}
                                  loadMore={loadFunc}
                                  hasMore={hasMore}
                                  loader={
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                      className="loader"
                                      key={0}
                                    >
                                      <Spinner animation="grow" />
                                    </div>
                                  }
                                >
                                  <Row
                                    style={{
                                      maxHeight: Math.round(windowHeight - 313),
                                      // maxHeight:Math.round( windowHeight > 1250 ? windowHeight * 0.78 :  windowHeight < 900 ? windowHeight * 0.625 :  windowHeight * 0.71),
                                      overflowY: "scroll",
                                      overflowX: "hidden",
                                    }}
                                  >
                                    <Col sm="12">
                                      <div className="assetList-holder">
                                        <TemplateContents
                                          docType="user"
                                          templates={templateFilesUser}
                                          isGridOrListView={isGridOrListView}
                                          setIsGridOrListView={
                                            setIsGridOrListView
                                          }
                                          activeKey={currentActiveTab}
                                          isStock={true}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </InfiniteScroll>
                              </>
                            )}
                          </>
                        )}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </div>
            </Col>
            {currentActiveTab === "1" && (
              <div>
                <CustomButton
                  onClick={() => setIsModalVisible(true)}
                  title={"+"}
                />
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
// }
