import React, { useState } from "react";

// import PreviewModal from '../../../components/PreviewModal/PreviewModal'
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { Toastr } from "../../../helpers/Alert";
import {
  Button,
  // DropdownItem, DropdownMenu, DropdownToggle,
  Spinner,
  //  UncontrolledDropdown 
} from "reactstrap";
// import Cast from "@mui/icons-material/Cast";
// import { IconButton, Tooltip } from "@material-ui/core";
import moment from "moment";
// import EditIcon from "@mui/icons-material/Edit";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import PreviewModal from "../../../components/NewPreviewModal/NewPreviewModal";

export default function SavePreviewAndPublish(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mouseOnTitle, setMouseOnTitle] = useState(false);
  const [name, setName] = useState('')
  const [mouseOnEdit, setMouseOnEdit] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const droppableBagItems = useSelector(state => state.bagItems.droppableBagItems)
  // const [deleteConfirmVisibile, setDeleteConfirmVisibile] = useState(false);
  // const [streamingStatus, setStreamingStatus] = useState(false);
  // const [confirmLoading, setConfirmLoading] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false)
  const [loadingOnButton, setLoadingOnButton] = useState(false);
  // const [virtualHieghtFactor, setVirtualHieghtFactor] = useState(0)
  const [previewData, setPreviewData] = useState(null)

  // useEffect(() => {
  //   if (props?.scheduleDetails !== null) {
  //     setScalingFactorToTheScreen(props?.scheduleDetails?.template_clone?.orientation === "Portrait" ? 4 : 2)
  //     setVirtualHieghtFactor(props?.scheduleDetails?.template_clone?.resolution_height / props?.scheduleDetails?.template_clone?.resolution_width)
  //     setTemplate(props?.scheduleDetails?.template_clone)
  //   }
  // }, [props?.scheduleDetails])

  const finalDataCreator = () => {
    // console.log("droppableBagItems",droppableBagItems)
    let data_for_post = []
    droppableBagItems?.forEach((item, index) => {
      const div_id = item.div_id
      item[item.id].forEach((item, index) => {
        let prop_item
        if (item.file_type === 'image' || item.file_type === 'video') {
          prop_item = {
            schedule: props.scheduleDetails.id,
            file_type: item.file_type,
            div: div_id,
            priority: index + 1,
            scheduled_file: item.schedule_prop_id,
            scheduled_duration: item.scheduled_duration,
            object_fit: item.object_fit,
            animation: item.animation,
            animation_duration: item.animation_duration,
            muted: item.muted,
            start_date: item.start_date,
            end_date: item.end_date,
            start_time: item.start_time,
            end_time: item.end_time,
          }
        }
        else {
          prop_item = {
            schedule: props.scheduleDetails.id,
            file_type: item.file_type,
            div: div_id,
            priority: index + 1,
            scheduled_json: item.schedule_prop_id,
            scheduled_duration: item.scheduled_duration,
            object_fit: item.object_fit,
            animation: item.animation,
            animation_duration: item.animation_duration,
            muted: item.muted,
            start_date: item.start_date,
            end_date: item.end_date,
            start_time: item.start_time,
            end_time: item.end_time,
          }
        }
        data_for_post.push(prop_item)
      })
    })
    return data_for_post
  }
  const saveHandler = () => {
    setLoadingOnButton(true);
    let data_for_post = finalDataCreator()
    // console.log("data_for_post",data_for_post)
    const url = 'api/signage/schedulepropertiessaveview'
    authAxios.post(url, data_for_post).then(response => {
      // Toastr('success', "Schedule saved successfully !!!");
      setLoadingOnButton(false);
    })
      .catch((error) => {
        Toastr('error', "Error !!!");
        setLoadingOnButton(false);

      })
  }
  // const publishHandler = () => {
  //   const data = droppableBagItems.map(item => ({
  //     div: item.div_id
  //   }))
  //   const url = 'api/signage/schedulerpublish'
  //   axios.post(url, data).then(response => {
  //     Toastr('success', "Schedule published successfully !!!");

  //   })
  //     .catch((error) => {
  //       Toastr('error', "Error !!!");


  //     })

  // }
  // const deleteHandler = () => {
  //   setConfirmLoading(true);

  //   const deleteUrl = "api/signage/schedules/" + props.scheduleDetails.id

  //   axios.delete(deleteUrl).then(response => {
  //     setConfirmLoading(false);
  //     navigate('/home/scheduler')

  //   })
  // };
  // const streamingHandler = () => {
  //   const url = "api/signage/schedules_stream/" + props.scheduleDetails.id

  //   if (!streamingStatus) {
  //     const data = {
  //       command: "switch_on_stream"
  //     }
  //     axios.post(url, data).then(response => {
  //       setStreamingStatus(true)
  //     })
  //   }
  //   else {
  //     const data = {
  //       command: "switch_off_stream"
  //     }
  //     axios.post(url, data).then(response => {
  //       setStreamingStatus(false)
  //     })
  //   }

  // }

  const editHandler = () => {
    setEditEnabled(!editEnabled)
  }

  const blurHandler = () => {
    if (name !== '') {
      const url = `/api/signage/schedulesrudview/${props.scheduleDetails.id}`

      authAxios
        .patch(url, { schedule_name: name })
        .then((response) => {
          Toastr("success", "Name Updated Successfully");
          setEditEnabled(false)
          props.setScheduleDetails(response.data)
          setName('')
          // dispatch(EditLink(response.data));
        })
        .catch((error) => {
          setEditEnabled(false)

          const err_msg = Error(error);
          Toastr("error", err_msg);
        });

    }
    else {
      setEditEnabled(false)

    }
  }

  const previewHandler = () => {
    // Here we need to convert the schedule to same as per the schedule which the backend returns
    if (props?.scheduleDetails !== null) {
      const tempScheduleDivclone_templatecloneNew = props?.scheduleDetails?.template_clone.divclone_templateclone?.map(item => {
        let tempscheduleprops_saved_divclone = null
        droppableBagItems.forEach(innerItem => {
          if (Number(innerItem.id) === item.id) {
            tempscheduleprops_saved_divclone = innerItem[item.id]
          }
        })
        return { ...item, scheduleprops_saved_divclone: tempscheduleprops_saved_divclone }
      })


      const tempdivclone_templateclone = { ...props?.scheduleDetails?.template_clone, divclone_templateclone: tempScheduleDivclone_templatecloneNew }
      const tempScheduleDetails = { ...props?.scheduleDetails, template_clone: tempdivclone_templateclone }
      console.log("droppableBagItems", tempScheduleDetails)
      setPreviewData(tempScheduleDetails)
      setIsPreviewVisible(true)
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

      <div style={{ marginTop: "6px" }}>
        <div
          onMouseEnter={() => setMouseOnTitle(true)}
          onMouseLeave={() => setMouseOnTitle(false)}
          style={{
            fontWeight: "900",
            fontSize: "20px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "300px",
            display: "flex",
            flexDirection: "row",
            height: "24px",
            marginBottom: '5px',
            textTransform: 'capitalize'
          }}
        >
          {editEnabled ? (
            <input
              autoFocus
              placeholder={props?.scheduleDetails?.schedule_name}
              style={{
                borderWidth: "0px",
                borderBottomWidth: "2px",
              }}
              onChange={(event) => setName(event.target.value)}
              onBlurCapture={() => blurHandler()}
            ></input>
          ) : (
            <div onClick={editHandler}>
              {props?.scheduleDetails?.schedule_name}
            </div>
          )}
          <div
            style={{ marginLeft: "5px", marginTop: "-3px" }}
            onMouseEnter={() => setMouseOnEdit(true)}
            onMouseLeave={() => setMouseOnEdit(false)}
          >
            {mouseOnTitle && !editEnabled ? (
              <EditOutlined
                fontSize={"6px"}
                color={mouseOnEdit ? "action" : "disabled"}
                onClick={editHandler}
              />
            ) : null}
          </div>
        </div>

        <p style={{ fontSize: '12px' }} className="text-muted">Created by {props?.scheduleDetails?.created_by} on {props?.scheduleDetails?.created_date ? moment(props.scheduleDetails.created_date).format('MMM DD,YYYY,h:mm:ss a') : ''}</p>
      </div>

      <div>
        {/* <Tooltip title="Cast to Screen" arrow>
          <IconButton onClick={() => { }}> */}
        {/* className="far fa-trash-alt"
                                    style={{ cursor: "pointer" }} */}

        {/* <Cast />
          </IconButton>
        </Tooltip> */}
        <Button
          outline
          type="button"
          size="sm"
          color="dark"
          className="w-xs waves-effect waves-light me-1"

          style={{ marginRight: '5px', width: '98px', height: '41px', borderRadius: '8px', fontSize: '14px', fontWeight: '900' }}
          onClick={previewHandler}

        >
          Preview
        </Button>
        <Button
          outline
          type="button"
          size="sm"
          color="dark"
          className="w-xs waves-effect waves-light me-1"
          disabled={loadingOnButton}
          style={{ marginRight: '5px', width: '98px', height: '41px', borderRadius: '8px', fontSize: '14px', fontWeight: '900', backgroundColor: '#fecf00', border: 0 }}
          onClick={saveHandler}

        >
          {loadingOnButton ? (
            <Spinner animation="grow"
              size="sm"
              color="dark"
            />
          ) : (
            "Save"
          )}

        </Button>

        {/* <UncontrolledDropdown group style={{ width: '98px', height: '41px', }} >
          <Button color="light" style={{ borderRadius: '8px 0px 0px 8px', fontSize: '14px', fontWeight: '900', backgroundColor: '#fecf00', border: '0', color: 'black' }}

          >
            Publish
          </Button>
          <DropdownToggle
            caret
            color="light"
            style={{ borderRadius: '0px 8px 8px 0px', backgroundColor: '#fecf00', border: '0' }}
          />
          <DropdownMenu end={true}>
            <DropdownItem
              onClick={saveHandler}>
              Save
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}

      </div>



      {/* </Popconfirm> */}
      {isPreviewVisible ? <PreviewModal
        isPreviewVisible={isPreviewVisible}
        setIsPreviewVisible={setIsPreviewVisible}
        previewData={previewData}
        previewType="schedule"
      // scalingFactorToTheScreen={scalingFactorToTheScreen}
      // template={template}
      /> : null}
    </div>
  );
}
