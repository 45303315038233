import MainEditor from "./tool-panels/fab-editor";
import React, { useContext, useEffect, useState } from "react";
import HeaderComponent from "./Header/HeaderComponent";
import { useParams } from "react-router-dom";
import AbaciLoader from "../../components/AbaciLoader/AbaciLoader";
import Error404 from "../Pages/Error/Error404";
import { authAxios } from "../../axiosInstance";
import { CanvasContext } from "../../context/CanvasContext";
import { useDispatch, useSelector } from "react-redux";
import { setSearchkeyWord } from "../../store/searchSlice";

const Index = () => {
  const params = useParams();
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("loading");
  const [canvasId, setCanvasId] = useState(null);
  const { setCanvasObjFromBackend } = useContext(CanvasContext);

  useEffect(() => {

    dispatch(setSearchkeyWord(null))

    const tempCanvasId = params?.id;
    if (tempCanvasId !== null) {
      setCanvasId(tempCanvasId);

      let url;
      if (tenantDetails?.app_type === "Admin") {
        url = `/stock/api/signage/objectrud/${tempCanvasId}`;
      } else {
        url = `/api/signage/objectrud/${tempCanvasId}`;
      }

      // const url = `api/signage/objectrud/${tempCanvasId}`
      authAxios
        .get(url)
        .then((response) => {
          // console.log(response.data?.object)
          setCanvasObjFromBackend(response.data?.object);
          setLoading("success");
        })
        .catch((error) => {
          setLoading("error");
        });
    }
  }, [params]);
  if (loading === "loading") {
    return <AbaciLoader />;
  } else if (loading === "error") {
    return <Error404 />;
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        <HeaderComponent canvasId={canvasId} />
        <MainEditor canvasId={canvasId} />
      </div>
    );
  }
};

export default Index;
