import React, { useState } from "react";
import { Container } from "react-bootstrap";
import logo from "../../../assets/images/logo-1-1.png";
import bannerShape1 from "../../../assets/images/shapes/banner-1-1.png";
import "./Contact.css";
export default function Contact() {
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const handleSend = () => {
    setName("");
    setNumber("");
    setEmail("");
    setMessage("");
  };
  return (
    <div style={{ height: "100vh" }}>
      <header
        className={`site-header-one stricky site-header-one__fixed-top `}
        style={{ padding: "15px" }}>
        <Container fluid>
          <div className='site-header-one__logo'>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <img src={logo} width='60' alt='' />

              <span
                style={{
                  fontFamily: "aquatico",
                  fontSize: "10px",
                  lineHeight: "10px",
                }}>
                ABACI SIGNAGE
              </span>
            </div>

            <span className='side-menu__toggler'>
              <i className='fa fa-bars'></i>
            </span>
          </div>
          <img
            src={bannerShape1}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              zIndex: "4",
            }}
            alt=''
          />
          <div className='main-nav__main-navigation'>
            <h1
              style={{
                fontFamily: "lato",
                margin: "10px",
                marginLeft: "60px",
                marginTop: "30px",
              }}>
              contact us
            </h1>
          </div>
        </Container>
      </header>
      <div
        className='container'
        style={{ backgroundColor: " #f3f7fc", paddingBottom: "25px" }}>
        <div className='row' style={{ marginTop: "0%", paddingTop: "50px" }}>
          <h5 className='headercontact' style={{ color: "black" }}>
            Get In Touch
          </h5>
        </div>
        <div className='row' style={{ marginTop: "1%" }}>
          <h4 style={{ textAlign: "center", color: "black" }}>
            We'd love to hear from you!
          </h4>
        </div>
        <div className='row input-container' style={{ marginTop: "4%" }}>
          <div className='col-xs-12'>
            <div className='styled-input wide'>
              <input
                type='text'
                required
                className='inputcontact'
                placeholder='Name'
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='styled-input'>
              <input
                type='text'
                required
                className='inputcontact'
                placeholder='Email'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='styled-input' style={{ float: "right" }}>
              <input
                type='text'
                required
                className='inputcontact'
                placeholder='Phone Number'
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='col-xs-12'>
            <div className='styled-input wide'>
              <textarea
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                style={{ minHeight: "15rem" }}
                required
                className='inputcontact'
                placeholder='Message'></textarea>
            </div>
          </div>
          <div className='col-xs-12'>
            <div className='btn-lrg submit-btn' onClick={handleSend}>
              Send Message
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
