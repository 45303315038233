import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
// import Cast from "@mui/icons-material/Cast";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Swal from "sweetalert2";
import { IconButton, Tooltip } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
// import moment from "moment";
import StarringStar from "../../../components/StarringStar";
// import img4 from "../../../assets/images/small/img-4.jpg";
import temp from "./templateThumbnail.jpg";
import {
  // TabContent,
  // TabPane,
  // Nav,
  // NavItem,
  // NavLink,
  Card,
  // Button,
  // CardTitle,
  // CardText,
  // Row,
  Col,
  // Container,
  // CardSubtitle,
  CardImg,
  CardBody,
} from "reactstrap";
import Error from "../../../helpers/Error";
// import { editImage, editVideo, favouriteMedia } from "../../../store/mediaSlice";
import { authAxios } from "../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { Toastr } from "../../../helpers/Alert";
import urlMaker from "../../../helpers/urlMaker";
// import { removeExtension } from "../../../helpers/Filename";
import { favouriteCanvas, updateCanvas } from "../../../store/canvasSlice";
import { Edit } from "@material-ui/icons";

function CanvasCard({
  item,
  deleteHandler,
  editModalHandler,
  modalToggle,
  is_admin,
}) {
  const dispatch = useDispatch();
  const [mouseOnTitle, setMouseOnTitle] = useState(false);
  const [mouseOnEdit, setMouseOnEdit] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  const [name, setName] = useState("");
  const navigate = useNavigate();

  const editHandler = () => {
    setEditEnabled(true);
  };

  const editNameHandler = (id) => {
    if (name !== "") {
      let url;
      if (tenantDetails?.app_type === "Admin") {
        url = `/stock/api/signage/objectrud/${id}`;
      } else {
        url = `/api/signage/objectrud/${id}`;
      }

      // const url = `/api/signage/objectrud/${id}`;

      authAxios
        .patch(url, { name: name })
        .then((response) => {
          // Toastr("success", "File Updated Successfully");

          dispatch(updateCanvas(response.data));

          setEditEnabled(false);
          setName("");
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
          setEditEnabled(false);
          setName("");
        });
    } else {
      setEditEnabled(false);
    }
  };

  const favouriteHandler = (id, fvrt) => {
    dispatch(
      favouriteCanvas({ is_favourite: fvrt === true ? false : true, id })
    );

    const url = `/api/signage/objectrud/${id}`;
    authAxios
      .patch(url, { is_favourite: fvrt === true ? false : true })
      .then((response) => {
        // if (type === "image") {
        //   dispatch(editImage(response.data));
        // } else {
        //   dispatch(editVideo(response.data));
        // }
        // Toastr("success", "File Updated Successfully");
      })
      .catch((error) => {
        const err_msg = Error(error);
        dispatch(favouriteCanvas({ is_favourite: fvrt, id }));
        Toastr("error", err_msg);
      });
  };
  return (
    <Col md={4} xl={2} style={{ minWidth: "200px" }}>
      <Card
        className="abaci-item-card"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardBody>
          {/* <CardTitle className="h4">Card title</CardTitle> */}
          <div>
            <div
              onMouseEnter={() => setMouseOnTitle(true)}
              onMouseLeave={() => setMouseOnTitle(false)}
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "300px",
                display: "flex",
                flexDirection: "row",
                height: "24px",
                textTransform: "capitalize",
              }}
            >
              {editEnabled ? (
                <input
                  autoFocus
                  placeholder={item?.name}
                  style={{
                    fontSize: "14px",
                    borderWidth: "0px",
                    borderBottomWidth: "2px",
                    borderBottomColor: "#E5E4E2",
                    textTransform: "capitalize",
                  }}
                  onBlurCapture={() => editNameHandler(item.id)}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                ></input>
              ) : (
                <h4
                  style={{
                    fontSize: "14px",
                    color: " #8D9091",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={editHandler}
                >
                  {item?.name}
                </h4>
              )}
              <div
                style={{ marginLeft: "5px", marginTop: "-3px" }}
                onMouseEnter={() => setMouseOnEdit(true)}
                onMouseLeave={() => setMouseOnEdit(false)}
              >
                {mouseOnTitle && !editEnabled ? (
                  <EditOutlined
                    style={{ width: "15px", height: "15px" }}
                    color={mouseOnEdit ? "action" : "disabled"}
                    onClick={editHandler}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </CardBody>

        <CardImg
          className="img-fluid mt-2"
          src={
            item.thumbnail ? urlMaker(item.thumbnail, "canvas", is_admin) : temp
          }
          alt="canvas"
          onClick={() => {
            is_admin ? modalToggle({...item,is_stock:true}) :
            modalToggle(item);
          }}
        />
        <CardBody>
          <div
            style={{
              width: "100%",
              display: "flex",
              height: "47px",
              justifyContent: "space-between",
              marginTop: "-10px",
            }}
          >
            {tenantDetails?.app_type !== "Admin" && (
              <div onClick={() => favouriteHandler(item.id, item.is_favourite)}>
                {item.is_favourite || isHovered ? (
                  <StarringStar selected={item.is_favourite} />
                ) : null}
              </div>
            )}
            {isHovered && (
              <>
                <Tooltip title="Edit Canvas" arrow>
                  <IconButton
                    onClick={() => {
                      navigate(`/canvascreator/${item.id}`);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete" arrow>
                  <IconButton
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // deleteClientData(rowData?.id)
                          deleteHandler(item.id);
                        }
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default CanvasCard;
