import React, { useEffect } from "react";
import "./index.css";
import { Spinner } from "reactstrap";

const EditorButton = ({
  onClicked,
  buttText,
  customClass,
  loader,
  setLoader,
}) => {
  useEffect(() => {}, []);
  return (
    <div
      className={`fab-button center-content fab-btn-text ${customClass}`}
      onClick={() => {
        setLoader(true);
        onClicked();
      }}
    >
      {loader ? <Spinner animation="grow" size="sm" /> : buttText}
    </div>
  );
};
export default EditorButton;
