import { padding } from "@mui/system";
import React from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";

function Modals(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      centered={props.NotCentered ? false : true}
      scrollable
      size={props.size ? props.size : "lg"}
      toggle={props.toggle}
      innerRef={props.ref}
      unmountOnClose={true}
      zIndex={"1002"}
    >
      {props.loader ? (
        <div style={{ zIndex: "9999" }}>
          {" "}
          <Spinner />{" "}
        </div>
      ) : null}

      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
      <ModalBody style={{ padding: `${props.padding === 0 && 0}` }}>
        {props.children}
      </ModalBody>
    </Modal>
  );
}

export default Modals;
