import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  user_data: null,
  stock_data: null,
  editValue:null,
};
// const initialState = [];
const clockSlice = createSlice({
  name: "clockSlice",
  initialState,
  reducers: {
    setClockes(state, action) {
      state.user_data = action.payload;

      // state.stock_data = action.payload.stock_data;
      // state.user_data = action.payload;
    },

   
    toStoreEditValue(state, action){
      state.editValue = action.payload;

    },
  
    addClock: (state, action) => {
      state.user_data=[...state.user_data,action.payload]
    },
    editClock: (state, action) => {
    
      state.user_data=state.user_data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
  
    deleteClock(state, action) {
    
      state.user_data=state.user_data.filter((data)=>
      data.id !==action.payload
      )
   
     
    },

    // setNewClock: (state, action) => {
    //   const data = {
    //     id: action.payload.item.id,
    //     name: action.payload.item.name,
    //     Created_by: action.payload.item.Created_by,
    //     Created_on: action.payload.item.Created_on,
    //     data: action.payload.data,
    //   };
    //   const temp = state.user_data.map((item) =>
    //     item.id === action.payload.item.id ? data : item
    //   );
    //   state.user_data = temp;
    // },
    deleteCanvas(state, action) {
      state.user_data = [...state.user_data];
      const indexToBeDeleted = action.payload;
      const tempState = [...state.user_data];
      tempState.splice(indexToBeDeleted, 1);
      state.user_data = tempState;
      console.log(" data from template store", state.user_data);
    },
  },
});

export const { setClockes, addClock, setNewClock, deleteCanvas,toStoreEditValue,editClock ,deleteClock} =
  clockSlice.actions;
export default clockSlice.reducer;
