import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apps: null,
};

const appSlices = createSlice({
  name: "appSlices",
  initialState,
  reducers: {
    setApps(state, action) {
      state.apps = action.payload;
    },
    addCanvas: (state, action) => {
      state.apps.push(action.payload);
    },
    addNewApps(state, action) {
      state.apps = [...state.apps, ...action.payload];
    },
    updateApps: (state, action) => {
      const temp = state.apps.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.apps = temp;
    },
    favouriteApps(state, action) {
      state.apps = state.apps.map((data) =>
        data.id === action.payload.id
          ? { ...data, is_favourite: action.payload.is_favourite }
          : data
      );
    },
    deleteApps(state, action) {
      state.apps = state.apps.filter((data) => data.id !== action.payload);
    },
  },
});

export const { setApps, addNewApps, favouriteApps, deleteApps, updateApps } =
  appSlices.actions;
export default appSlices.reducer;
