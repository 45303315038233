import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  data: []
};

const assetDetailsUpdateSlice = createSlice({
  name: "assetDetails",
  initialState,
  reducers: {
    added(state, action) {
      return ({
        updateToggle: !state.updateToggle,
        data: action.payload
      })
    },
    addAssetDetails: (state, action) => {
      return state.concat(action.payload);
    },
    updateAssetDetails: (state, action) => {
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteAssetDetails: (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    },
  },
});

export const { added, updateAssetDetails, deleteAssetDetails, addAssetDetails } =
  assetDetailsUpdateSlice.actions;
export default assetDetailsUpdateSlice.reducer;
