import { authAxios } from "../../../axiosInstance";
import React, { useState, useEffect } from "react";
import ScheduleContents from "./ScheduleContents";
import "./Schedulers.css";
import ScheduleAddModal from "./ScheduleAddModal";
import { setDroppableBagItems, setCollections } from "../../../store/bagItems";
// import AbaciButton from "../../../components/AbaciButton/AbaciButton";
import { useDispatch, useSelector } from "react-redux";
import { addNewSchedule, setSchedules } from "../../../store/scheduleSlice";
// import { setTemplates } from "../../../store/templateSlice";
import { Container, Spinner } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CustomButton } from "../../../components/CustomButton";
import CastContentModal from "../../../components/CastContentModal";
import { setCurrentPage, setSearchDisabled } from "../../../store/searchSlice";

export default function Schedulers() {
  const [pageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [isCastScreenModalVisible, setIsCastScreenModalVisible] =
    useState(false);
  const [item, setItem] = useState(null);

  const [loader, setLoader] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const toggle = () => {
    setIsModalVisible(!isModalVisible);
  };
  const [schedulerShow, setSchedulerShow] = useState(false);
  const dispatch = useDispatch();
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);
  const [searchKeyCount,setSearchKeyCount] = useState(0)
  const [prevSearchKey, setPrevSearchKey] = useState(searchKey);


  const schedules = useSelector((state) => state.scheduleSlice.data);
  const templates = useSelector((state) => state.templateSlice.user_data);

  
  useEffect(() => {
  
      setPage(1);
      setHasNextPage(false);
      dispatch(setSchedules(null));

      if(searchKey !== prevSearchKey){
        setPrevSearchKey(searchKey)
      }
  
      setSearchKeyCount(searchKey !==null ? searchKey.length : 0)
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchKey])


  // Cleaning redux store
  useEffect(() => {
    dispatch(
      setDroppableBagItems({
        droppableBagItems: [],
      })
    );
    dispatch(setCurrentPage('schedules'))
    dispatch(setSearchDisabled(false))
    // }

    return () => {
      dispatch(setSchedules(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const url = `api/signage/scheduleslistcreateview?limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }&search=${searchKey !==null && searchKey !=='' ? searchKey : ''}`;
    authAxios
      .get(url)
      .then((response) => {
        setLoader(false);
        if (schedules === null) {
          dispatch(setSchedules(response.data.results));
        } else {
          dispatch(addNewSchedule(response.data.results));
        }

        if (response.data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,prevSearchKey]);

  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "Scheduler", link: "#" },
    ],
  });

  const schedulerToggle = () => {
    setSchedulerShow(!schedulerShow);
  };

  // const handlePageChange = (event, value) => {
  //   fetchData(value)
  // }

  const loadFunc = () => {
    if (hasNextPage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
    // setTemp(state => [...state, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
  };
  const castScreenModalToggle = (item) => {
    setItem(item);
    setIsCastScreenModalVisible(!isCastScreenModalVisible);
  };

  return (
    <React.Fragment>
      {schedulerShow && (
        <ScheduleAddModal
          toggle={schedulerToggle}
          isOpen={schedulerShow}
          title={"Add Scheduler"}
        />
      )}

      {isCastScreenModalVisible && (
        <CastContentModal
          isModalVisible={isCastScreenModalVisible}
          setIsModalVisible={setIsCastScreenModalVisible}
          item={item}
        />
      )}

      <div className="page-content">
        <CustomButton onClick={schedulerToggle} title="+" />
        <Container fluid>
          <Breadcrumbs
            title="Schedulers"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>

          {schedules === null || loader ? (
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <Spinner animation="grow" />
            </div>
          ) : (
            <>
              <ScheduleContents
                loadFunc={loadFunc}
                hasMore={hasNextPage}
                castScreenModalToggle={castScreenModalToggle}
              />
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
