import { useNavigate } from "react-router";
import React, { useState, useEffect } from "react";
// import axios from "axios";
import "./TemplateAddModal.css";
// import { Modal, Upload, message, Typography, InputNumber } from "antd";
import { Button, Input, ModalFooter, Spinner } from "reactstrap";
// import swal from "sweetalert";
// import AbaciHorizontalLine from "../../../components/AbaciHorizontalLine/AbaciHorizontalLine";
// import AbaciButton from "../../../components/AbaciButton/AbaciButton";
import { addTemplate } from "../../../store/templateSlice";
import { useDispatch, useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modals from "../../../components/Modal";
import { authAxios } from "../../../axiosInstance";
import { Toastr } from "../../../helpers/Alert";
// import { success } from "toastr";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import Error from "../../../helpers/Error";
export default function TemplateAddModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [templateName, setTemplateName] = useState("");
  const [loadingOnButton, setLoadingOnButton] = React.useState(false);
  // const [isChecked, setIsChecked] = useState("Landscape");
  const [screenOrientation, setScreenOrientation] = useState(true);
  const [screenHeight, setScreenHeight] = useState(1080);
  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenBorder, setScreenBorder] = useState(10);
  const [screenBorderRadius, setScreenBorderRadius] = useState(10);
  const [screenResolutionSelection, setScreenResolutionSelection] = useState(1);
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  // const [templateName, setTemplateName] = useState("Template Name...");
  // const [uploadModal, setUploadModal] = useState(false);
  // const [waitForAxios, setWaitForAxios] = useState(false);
  const gridDivs = ["#ccb439", "#ab3c7b", "#6d49c9", "#1b968c"];

  // const onChangeOrientationHandler = (e) => {
  //   const value = e.target.value;
  //   setIsChecked(value === "true" ? "Landscape" : "Portrait");

  //   if (value === "true") {
  //     // console.log(value)
  //     // case value === "true":
  //     console.log("wrked");
  //     setScreenOrientation(true);
  //     setScreenHeight(screenWidth);
  //     setScreenWidth(screenHeight);
  //     // break;
  //     // case value === "false":
  //   } else if (value === "false") {
  //     setScreenOrientation(false);
  //     setScreenHeight(screenWidth);
  //     setScreenWidth(screenHeight);
  //     // break;
  //   }
  // };

  const onChangeOrientationHandler = (e) => {
    const value = e.target.value;
    if (value !== "false") {
      setScreenOrientation(true);
      setScreenHeight(screenWidth);
      setScreenWidth(screenHeight);
    } else {
      setScreenOrientation(false);
      setScreenHeight(screenWidth);
      setScreenWidth(screenHeight);
    }
    // switch (value) {
    //   case value===:
    //     setScreenOrientation(true);
    //     setScreenHeight(screenWidth);
    //     setScreenWidth(screenHeight);
    //     break;
    //   case false:
    //     setScreenOrientation(false);
    //     setScreenHeight(screenWidth);
    //     setScreenWidth(screenHeight);
    //     break;
    // }
  };

  //   const customResolutionHeightHandler=(e)=>{
  //   const value=e.target.value

  //   setScreenHeight(value)

  // }
  // const customResolutionWidthHandler=(e)=>{
  //   const value=e.target.value
  //   console.log(value)
  //   setScreenWidth(value)

  // }

  const onChangeResolutionHandler = (e) => {
    const value = e.target.value;
    // console.log(value);

    if (value === "1") {
      // console.log("1");
      setScreenResolutionSelection(1);
      screenOrientation ? setScreenWidth(1920) : setScreenWidth(1080);
      screenOrientation ? setScreenHeight(1080) : setScreenHeight(1920);
    } else if (value === "2") {
      // console.log("2");
      setScreenResolutionSelection(2);
      screenOrientation ? setScreenWidth(1280) : setScreenWidth(720);
      screenOrientation ? setScreenHeight(720) : setScreenHeight(1280);
    } else {
      setScreenResolutionSelection(3);
    }

    // switch (value) {
    //   case 1:
    //     setScreenResolutionSelection(1);
    //     screenOrientation ? setScreenWidth(1920) : setScreenWidth(1080);
    //     screenOrientation ? setScreenHeight(1080) : setScreenHeight(1920);
    //     break;
    //   case 2:
    //     setScreenResolutionSelection(2);
    //     screenOrientation ? setScreenWidth(1280) : setScreenWidth(720);
    //     screenOrientation ? setScreenHeight(720) : setScreenHeight(1280);
    //     break;
    //   case 3:
    //     setScreenResolutionSelection(3);
    //     break;
    // }
  };
  const [screenHeightVirtual, setScreenHeightVirtual] = useState(163.125);
  const [screenWidthVirtual, setScreenWidthVirtual] = useState(290);
  useEffect(() => {
    if (screenHeight > screenWidth) {
      setScreenOrientation(false);
    } else {
      setScreenOrientation(true);
    }
    const maxVirtualScreenHeight = 440;
    const maxVirtualScreenWidth = 440;
    if (screenOrientation) {
      // This is landscape
      const scalingFactorTemp = maxVirtualScreenWidth / screenWidth;
      const height = scalingFactorTemp * screenHeight;
      const width = maxVirtualScreenWidth;
      const border = scalingFactorTemp * 10;
      const borderRadius = scalingFactorTemp * 20;
      setScreenHeightVirtual(height);
      setScreenWidthVirtual(width);
      setScreenBorder(border);
      setScreenBorderRadius(borderRadius);
    } else {
      // This is portrait
      const scalingFactorTemp = maxVirtualScreenWidth / screenHeight;
      const height = scalingFactorTemp * screenWidth;
      const width = maxVirtualScreenWidth;
      const border = scalingFactorTemp * 10;
      const borderRadius = scalingFactorTemp * 20;
      setScreenHeightVirtual(height);
      setScreenWidthVirtual(width);
      setScreenBorder(border);
      setScreenBorderRadius(borderRadius);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenHeight, screenWidth]);

  // const templateSaveHandler = () => {
  //   const data = {
  //     template_name: proptemplateName,
  //     orientation: screenOrientation ? "Landscape" : "Portrait",
  //     resolution_width: screenWidth,
  //     resolution_height: screenHeight,
  //   };
  //   setWaitForAxios(true)
  //   const url = 'api/signage/templates/'
  //   axios.post(url, data).then((response)=> {
  //     message.success("Template created successfully !!!")
  //     console.log(history)
  //     history.push({
  //       pathname: '/home/templates/'+response.data.id,
  //       state : response.data
  //     })
  //   })
  // };

  // const templateSaveHandler = () => {
  //   if (templateName === "Template Name...") {
  //     // swal({
  //     //   text: "Please provide a Template name !!!",
  //     //   icon: "error",
  //     // });
  //   } else {
  //     const data = {
  //       template_name: templateName,
  //       orientation: screenOrientation ? "Landscape" : "Portrait",
  //       resolution_width: screenWidth,
  //       resolution_height: screenHeight,
  //     };
  //     setWaitForAxios(true);
  //     const url = "api/signage/templates/";
  //     authAxios.post(url, data).then((response) => {});
  //     // .catch((error)=> {
  //     //   message.error("Template creation failed, please try again !!!")
  //     // })
  //   }
  // };
  const inputHandlerOnChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleCancel = () => {
    props.setIsModalVisible(false);
    setTemplateName("");
  };
  const submitHandler = () => {
    setLoadingOnButton(true);
    let url;
    if (tenantDetails?.app_type === "Admin") {
      url = "/stock/api/signage/templateslistcreate";
    } else {
      url = "/api/signage/templateslistcreate";
    }
    // const url = "/api/signage/templateslistcreate";

    const data = {
      template_name: templateName,
      orientation: screenOrientation ? "Landscape" : "Portrait",
      resolution_width: screenWidth,
      resolution_height: screenHeight,
    };
    // console.log(data);
    authAxios
      .post(url, data)
      .then((response) => {
        // console.log(response.data);
        dispatch(addTemplate(response.data));
        setLoadingOnButton(false);
        handleCancel();
        // Toastr("success", "Template created successfully !!!");

        navigate(`/templatescreator/${response.data.id}`);
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
        setLoadingOnButton(false);
      });
  };

  return (
    <>
      <Modals
        title="Template Details"
        isOpen={props.isModalVisible}
        toggle={() => props.setIsModalVisible(false)}
        footer={null}
        size="xl"
      >
        <div className="parent">
          <div className="div1">
            <p>Please provide the Template name</p>
            <input
              className="form-control"
              type="text"
              style={{ width: "296px", height: "40px" }}
              value={templateName}
              onChange={inputHandlerOnChange}
              placeholder="Template Name"
            />

            <br />
            <strong>Screen Orientation</strong>
            <br />
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={screenOrientation}
              onChange={onChangeOrientationHandler}
            >
              <div className="d-flex justify-content-space-between">
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" color="primary" />}
                  label={<span style={{ fontSize: "14px" }}>Landscape</span>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio size="small" color="primary" />}
                  label={<span style={{ fontSize: "14px" }}>Portrait</span>}
                />
              </div>
            </RadioGroup>

            {/* <div className="d-flex justify-content-space-between mt-2">
              <FormGroup check>
                <Input
                  name="radio2"
                  value="true"
                  type="radio"
                  checked={isChecked === "Landscape" ? true : false}
                  onChange={(event) => {
                    onChangeOrientationHandler(event);
                  }}
                />{" "}
                <Label check>Landscape</Label>
              </FormGroup>
              <FormGroup check style={{ marginLeft: "10px" }}>
                <Input
                  name="radio2"
                  type="radio"
                  value="false"
                  checked={isChecked === "Portrait" ? true : false}
                  onChange={(event) => {
                    onChangeOrientationHandler(event);
                  }}
                />{" "}
                <Label check>Portrait</Label>
              </FormGroup>
            </div> */}

            <strong>Screen Resolution</strong>

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={screenResolutionSelection}
              onChange={onChangeResolutionHandler}
            >
              <FormControlLabel
                value={1}
                control={<Radio size="small" color="primary" />}
                label={
                  <span style={{ fontSize: "14px" }}>
                    {screenOrientation ? "1920 x 1080" : "1080 x 1920"}
                  </span>
                }
              />

              <FormControlLabel
                style={{ marginTop: "-8px" }}
                value={2}
                control={<Radio size="small" color="primary" />}
                label={
                  <span style={{ fontSize: "14px" }}>
                    {screenOrientation ? "1280 x 720" : "720 x 1280"}
                  </span>
                }
              />

              <FormControlLabel
                style={{ marginTop: "-8px" }}
                value={3}
                control={<Radio size="small" color="primary" />}
                label={<span style={{ fontSize: "14px" }}>Custom</span>}
              />
            </RadioGroup>

            {/* <div className="d-flex justify-content-space-between mt-2">
              <FormGroup check>
                <Input
                  name="radio3"
                  type="radio"
                  value={1}
                  onChange={(event) => {
                    onChangeResolutionHandler(event);
                  }}
                />{" "}
                <Label check>
                  {screenOrientation ? "1920 x 1080" : "1080 x 1920"}
                </Label>
              </FormGroup>
              <FormGroup check style={{ marginLeft: "10px" }}>
                <Input
                  name="radio3"
                  type="radio"
                  value={2}
                  onChange={(event) => {
                    onChangeResolutionHandler(event);
                  }}
                />{" "}
                <Label check>
                  {screenOrientation ? "1280 x 720" : "720 x 1280"}
                </Label>
              </FormGroup>
              <FormGroup check style={{ marginLeft: "10px" }}>
                <Input
                  name="radio3"
                  type="radio"
                  value={3}
                  onChange={(event) => {
                    onChangeResolutionHandler(event);
                  }}
                />{" "}
                <Label check>Custom</Label>
              </FormGroup>
            </div> */}

            {screenResolutionSelection === 3 ? (
              <>
                <table className="mt-1">
                  <tr>
                    <td>Width (Pixels)</td>
                    <td>
                      <Input
                        size="small"
                        type="number"
                        min={480}
                        defaultValue={screenOrientation ? 1920 : 1080}
                        onChange={(event) => setScreenWidth(event.target.value)}
                        // onChange={customResolutionWidthHandler}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Height (Pixels)</td>
                    <td style={{ paddingTop: "8px" }}>
                      <Input
                        size="small"
                        type="number"
                        min={480}
                        defaultValue={screenOrientation ? 1080 : 1920}
                        onChange={(event) =>
                          setScreenHeight(event.target.value)
                        }
                        // onChange={customResolutionHeightHandler}
                      />
                    </td>
                  </tr>
                </table>

                <br />
              </>
            ) : null}
          </div>
          <div className="div2" style={{ width: "752px" }}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="demo-screen-container">
              {true ? (
                <div
                  className={
                    screenOrientation ? "demo-screen" : "demo-screen rotate"
                  }
                  style={{
                    width: screenWidthVirtual + screenBorder,
                    height: screenHeightVirtual + screenBorder,
                    borderRadius: screenBorderRadius,
                  }}
                >
                  <div
                    className="demo-screen-content"
                    style={{
                      height: screenHeightVirtual,
                      width: screenWidthVirtual,
                      border: String(screenBorder) + "px solid #474e5d",
                    }}
                  >
                    {gridDivs.map((element, index) => (
                      <div className={"demo-div" + String(index)} key={index}>
                        <div
                          className="demo-div-color-box"
                          style={{ backgroundColor: element }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="demo-screen">
                  <div className="demo-screen-content">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        position: "absolute",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "65px",
                          fontWeight: 500,
                          color: "red",
                          fontSize: "1.5rem",
                        }}
                      >
                        Wrong resolution!!!
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ModalFooter>
          <div className="button-container-modal">
            <Button
              color="danger"
              style={{ width: "100px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
          <div className="button-container-modal">
            <Button
              color="primary"
              style={{ width: "100px" }}
              disabled={templateName === "" ? true : false}
              // loading={loadingOnButton}
              onClick={submitHandler}
            >
              {loadingOnButton ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </ModalFooter>
      </Modals>
    </>
  );
}
