import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateToggle: false,
  user_data: null,
  stock_data: null,
  editValue: null,
};
// const initialState = [];
const textSlice = createSlice({
  name: "textSlice",
  initialState,
  reducers: {
    setText(state, action) {
      state.user_data = action.payload;

      // state.stock_data = action.payload.stock_data;
      // state.user_data = action.payload;
    },
    addText: (state, action) => {
      state.user_data.push(action.payload);
    },
    toStoreEditValue(state, action) {
      state.editValue = action.payload;
    },
    editText(state, action) {
      state.user_data = state.user_data.map((data) =>
        data.id === action.payload.id ? action.payload : data
      );
    },
    
    setNewText: (state, action) => {
      const data = {
        id: action.payload.item.id,
        name: action.payload.item.name,
        Created_by: action.payload.item.Created_by,
        Created_on: action.payload.item.Created_on,
        data: action.payload.data,
      };
      const temp = state.user_data.map((item) =>
        item.id === action.payload.item.id ? data : item
      );
      state.user_data = temp;
    },
    deleteText(state, action) {
      state.user_data = state.user_data.filter((data) => data.id != action.payload)
    },
  },
});

export const { setText, addText, setNewText, deleteText ,editText} =
  textSlice.actions;
export default textSlice.reducer;
