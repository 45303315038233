import React from "react";
import "./AbaciButton.css";
// Component of ABACI Technologies Pvt Ltd
// API Format
{
  /* <AbaciButton 
      width = "100px" 
      height = "100px" 
      textcolor = "white" 
      color = "red" 
      waveColor = "purple"
      disabled = true
      loading = true
      /> */
}

export default function AbaciButton(props) {
  const bttn = React.useRef();
  // Button child will be dynamically added to create the wave effect
  const [buttonChild, setButtonChild] = React.useState(null);
  const [styleForCirle, setStyleForCirle] = React.useState("circle");
  const waveEffectHandler = (event) => {
    let currentTargetRect = event.currentTarget.getBoundingClientRect();
    const clickedX = event.pageX;
    const clickedY = event.pageY;
    const buttonX = currentTargetRect.left;
    const buttonY = currentTargetRect.top;
    setButtonChild(
      <div
        className={styleForCirle}
        style={{
          top: String(clickedY - buttonY) + "px",
          left: String(clickedX - buttonX) + "px",
          backgroundColor: props.waveColor,
        }}></div>
    );
    setTimeout(function () {
      setStyleForCirle("circle anim");
    }, 15);
    setTimeout(function () {
      setButtonChild(null);
    }, 350);
  };
  return (
    <div style={props.style}>
      <button
        ref={bttn}
        className='abaci-button'
        style={{
          width: props.width !== null? props.width: "100px",
          height: props.height,
          backgroundColor: props.color,
          color: props.textcolor,
          padding:'5px'
        }}
        disabled={props.disabled | props.loading}
        onMouseDown={waveEffectHandler}
        onClick={props.onClick}>
        {buttonChild}
        <div style={{ zIndex: 10 }}>
          {props.children}
          {props.loading ? 
          <>
          <span
          className='spinner-grow spinner-grow-sm'
          role='status'
          aria-hidden='true'
          style = {{marginLeft:"3px"}}
          ></span>
        <span className='sr-only'>Loading...</span>
        </>
      :
      null}
          
        </div>
      </button>
    </div>
  );
}
