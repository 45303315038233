import { useNavigate } from "react-router";
import React from "react";
// import axios from "axios";
import "./Templates.css";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { initialData } from "./initialData";
// import TemplateAddModal from "./TemplateAddModal";
// import VirtualTemplate from "../../../components/VirtualTemplate/VirtualTemplate";
import { useDispatch, useSelector } from "react-redux";
import { deleteTemplateNew } from "../../../store/templateSlice";
import { authAxios } from "../../../axiosInstance";
import { Row } from "reactstrap";

import TemplateCard from "./TemplateCard/TemplateCard";
import { Toastr } from "../../../helpers/Alert";
import StockCard from "./TemplateCard/StockCard";
import Error from "../../../helpers/Error";

export default function Template(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  // class Css {
  //   constructor(cssObj, virtualizationFactor) {
  //     this.cssObj = cssObj;
  //     this.virtualizationFactor = virtualizationFactor;
  //   }
  //   convertToOriginal = () => ({
  //     position: this.cssObj.position,
  //     marginTop: this.cssObj.marginTop / this.virtualizationFactor,
  //     marginLeft:
  //       screenWidth / 2 + this.cssObj.marginLeft / this.virtualizationFactor,
  //     height: this.cssObj.height / this.virtualizationFactor,
  //     width: this.cssObj.width / this.virtualizationFactor,
  //     backgroundColor: this.cssObj.backgroundColor,
  //     zIndex: this.cssObj.zIndex,
  //   });

  //   convertToVirtual = () => ({
  //     position: this.cssObj.position,
  //     marginTop: this.cssObj.marginTop * this.virtualizationFactor,
  //     marginLeft: this.cssObj.marginLeft * this.virtualizationFactor,
  //     height: this.cssObj.height * this.virtualizationFactor,
  //     width: this.cssObj.width * this.virtualizationFactor,
  //     backgroundColor: this.cssObj.backgroundColor,
  //     border:
  //       this.cssObj.border === 0
  //         ? "1px solid"
  //         : String(this.cssObj.border) + "px solid",
  //     borderRadius: this.cssObj.borderRadius,
  //     borderColor:
  //       this.cssObj.border === 0 ? "light grey" : this.cssObj.borderColor,
  //     zIndex: this.cssObj.zIndex,
  //   });
  // }

  // const [screenOrientation, setScreenOrientation] = useState(true);
  // const [screenHeight, setScreenHeight] = useState(1080);
  // const [screenWidth, setScreenWidth] = useState(1920);
  // const [screenResolutionSelection, setScreenResolutionSelection] = useState(1);
  // const [templateName, setTemplateName] = useState("Template Name...");
  // const [uploadModal, setUploadModal] = useState(false);
  // const [waitForAxios, setWaitForAxios] = useState(false);
  // const gridDivs = ["#ccb439", "#ab3c7b", "#6d49c9", "#1b968c"];
  // const templateClickHandler = (card) => {
  //   console.log("template clicked", card);
  // };

  //Template Copy Handler
  const copyTemplateHandler = (id) => {
    console.log(id);
    const url = `/api/signage/copystocktemplatescreate?tenant=public&stockfile_id=${id}`;
    authAxios
      .post(url)
      .then((response) => {
        Toastr("success", "Template Copied Successfully!");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  const clickHandler = (id) => {
    navigate(`/templates/${id}`);
  };
  const deleteHandler = (card, index) => {
    // console.log(index)
    let url;
    if (tenantDetails?.app_type === "Admin") {
      url = `/stock/api/signage/templatesrud/${card}`;
    } else {
      url = `api/signage/templatesrud/${card}`;
    }

    authAxios
      .delete(url)
      .then((response) => {
        dispatch(deleteTemplateNew(card));

        Toastr("success", "Template Deleted Successfully!");
      })
      .catch((error) => {
        const err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    // <div className="card-flex-container">
    //     {
    //       props?.templates?.map((card, index) => (
    //         <VirtualTemplateNew
    //           key={index}
    //           card={card}
    //           clickHandler={() => clickHandler(card.id)}
    //           deleteHandler={() => {
    //             deleteHandler(card, index);
    //           }}
    //           docType={props.docType}
    //         />
    <>
      {props?.isStock ? (
        <Row style={{ display: "flex", justifyContent: "start" }}>
          {props?.templates?.map((item, index) => (
            <StockCard
              key={index}
              item={item}
              copyTemplateHandler={copyTemplateHandler}
            />
          ))}
        </Row>
      ) : (
        <Row style={{ display: "flex", justifyContent: "start" }}>
          {props?.templates?.map((item, index) => (
            <TemplateCard
              key={index}
              card={item}
              clickHandler={() => clickHandler(item.id)}
              deleteHandler={deleteHandler}
              docType={props.docType}
            />
          ))}
        </Row>
      )}
    </>
  );
}
