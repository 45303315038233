import React from 'react'

export default function AbaciHorizontalLine() {
    return (
        <hr
        style={{
          margin: 0,
          color: "lightgrey",
          backgroundColor: "lightgrey",
          height: "1px",
        }}
      />
    )
}
