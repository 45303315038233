import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Button, Container } from "reactstrap";
// Redux
// import IconButton from "@mui/material/Button";
// import GoogleIcon from "@mui/icons-material/Google";
// import FacebookIcon from "@mui/icons-material/Facebook";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// import animationData from "./lottie.json";
// import { FaFacebook } from "react-icons/fa";
// import google from "../../../assets/images/google.png";
// import { Player } from "@lottiefiles/react-lottie-player";
import "./Registration.css";
import logodark from "../../../assets/images/signage logo dark.png";
// import axios from "axios";
import { publicAxios } from "../../../axiosInstance";
// import Lottie from "react-lottie";
// import MultipleSelectPlaceholder from "../../../components/SelectBox/selectBox";
import { Toastr } from "../../../helpers/Alert";
import { country_list } from "../../../helpers/CountriesList";
import { industryList } from "../../../helpers/IndustryList";
import CustomizedCheckbox from "../../../components/CustomCheckBox";
// import { display } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import Error from "../../../helpers/Error";
// import SelectBox from "../../../components/SelectBox";

export default function RegistrationGoogle() {
  let navigateto = useNavigate();

  const [searchParams] = useSearchParams();
  const [dataFromRedirect, setDataFromRedirect] = useState({});

  const [waitingForNewPasswordAxios, setWaitingForNewPasswordAxios] =
    useState(false);
  const [navigate] = useState({ otp: false, password: false });
  // const [newPassword] = useState("");
  // const [confirmNewPassword] = useState("");
  // const [allClear, setallClear] = useState(false);
  // const [errorMessage, seterrorMessage] = useState(null);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checkValidData, setCheckValidData] = useState({});
  const [isAllOk, setIsAllOk] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    try {
      const error = searchParams.get("error");
      if (error === "true") {
        const error_type = searchParams.get("error_type");
        Toastr("error", error_type);
        navigateto("/register");
      } else {
        const code = searchParams.get("cde");
        const email = searchParams.get("email");
        if (code !== null && email !== null) {
          setDataFromRedirect({
            otp: code,
            email: email,
          });
        } else {
          Toastr("error", "Error occurred,please try again");
          navigateto("/register");
        }
      }
    } catch {
      Toastr("error", "Error occurred,please try again");
      navigateto("/register");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.onpopstate = (event) => {
      event.preventDefault();
      // console.log(event);
    };
  });

  useEffect(() => {
    reset({ password: "", password1: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate.password]);

  // useEffect(() => {
  //   if (newPassword) {
  //     const re = new RegExp(
  //       `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`
  //     );
  //     const isOk = re.test(newPassword);

  //     if (isOk) {
  //       seterrorMessage("Passwords should match");
  //       if (newPassword === confirmNewPassword) {
  //         seterrorMessage(null);
  //       } else {
  //         setallClear(false);
  //       }
  //     } else {
  //       seterrorMessage(
  //         "The password should contain minimum 8 characters with a mix of alphanumeric,atleast 1 Uppercase letter and special characters"
  //       );
  //     }
  //   } else {
  //     seterrorMessage(null);
  //   }
  // }, [newPassword, confirmNewPassword]);
  // email verification handler

  const checkBoxHandleChange1 = (event) => {
    setChecked1(event.target.checked);
  };
  const checkBoxHandleChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const handleFormData = (e) => {
    // console.log("wrked");
    setCheckValidData((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    if (
      checkValidData.name_of_organization &&
      checkValidData.country &&
      checkValidData.industry &&
      checked1 &&
      checked2
    ) {
      setIsAllOk(false);
    } else {
      setIsAllOk(true);
    }
  }, [checkValidData, checked1, checked2]);
  const submitHandler = (data) => {
    setWaitingForNewPasswordAxios(true);

    const dataToBeSend = {
      is_social_signup: true,
      social_type: "google",
      organization: data.name_of_organization,
      address: data.address_of_organization,
      industry: data.industry,
      country: data.country,
      otp: dataFromRedirect.otp,
      email: dataFromRedirect.email,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      acceptance_to_updates: checked1 ? true : false,
      acceptance_to_tc: checked2 ? true : false,
    };
    // console.log(data)
    const url = "/public_api/tenant_creation";

    publicAxios
      .post(url, dataToBeSend)

      .then((response) => {
        // console.log("Worked");
        setWaitingForNewPasswordAxios(false);
        navigateto("/login");
        Toastr("success", "Account created Successfully");
      })
      .catch((error) => {
        setWaitingForNewPasswordAxios(false);
        let err_msg = Error(error);
        Toastr("error", err_msg);
      });
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={12}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={4}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="#" className="logo">
                              <img
                                src={logodark}
                                style={{   height: "100px", marginTop: "10px",marginBottom:'20px' }}
                                alt="logo"
                              />
                            </Link>
                          </div>
                        </div>

                        <div className="  d-flex justify-content-center p-2 mt-4">
                          <form
                            className="form-horizontal"
                            onSubmit={handleSubmit(submitHandler)}
                          >
                            <p
                              className="text-muted text-center   mb-4"
                              style={{ marginTop: "-30px" }}
                            >
                              Tell us a little more about your organization so
                              that we can <br /> help tailor your trial
                              experience to your business needs.
                            </p>
                            <div className="d-flex justify-content-center mb-1">
                              {/* <i className="ri-building-fill auti-custom-input-icon"></i> */}
                              {/* <Label htmlFor="Confirm password" className="font-size-12">Name of your Organization</Label> */}
                              <input
                                style={{ height: "40px", width: "370px" }}
                                className="form-control"
                                name="Name of your Organization"
                                // disabled={waitingForNewPasswordAxios}
                                placeholder="Name of your Organization"
                                type="text"
                                {...register("name_of_organization", {
                                  required: true,
                                  onChange: (event) => {
                                    handleFormData(event);
                                  },
                                })}
                              />
                            </div>
                            {errors.name_of_organization?.type ===
                              "required" && (
                              <span className="last-page-error">
                                This field is required
                              </span>
                            )}
                            <div className="d-flex justify-content-center mt-3 mb-1">
                              {/* <i className="ri-building-fill auti-custom-input-icon"></i> */}
                              {/* <Label htmlFor="Confirm password" className="font-size-12">Name of your Organization</Label> */}
                              <input
                                style={{ height: "40px", width: "370px" }}
                                className="form-control"
                                // disabled={waitingForNewPasswordAxios}
                                placeholder="Address of your Organization"
                                type="address_of_organization"
                                {...register("address_of_organization", {
                                  required: true,
                                  onChange: (event) => {
                                    handleFormData(event);
                                  },
                                })}
                              />
                            </div>
                            {errors.address_of_organization?.type ===
                              "required" && (
                              <span className="last-page-error">
                                This field is required
                              </span>
                            )}

                            <div className="d-flex justify-content-center mt-3  mb-1">
                              {/* <i className="ri-arrow-down-s-fill auti-custom-input-icon"></i> */}
                              {/* <Label htmlFor="Confirm password" className="font-size-12">Select Industry</Label> */}

                              <select
                                className="select_box"
                                style={{ height: "40px", width: "370px" }}
                                {...register("industry", {
                                  required: true,
                                  onChange: (event) => {
                                    handleFormData(event);
                                  },
                                })}
                              >
                                <option value="">Select Industry</option>
                                {industryList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.industry?.type === "required" && (
                              <span className="last-page-error">
                                This field is required
                              </span>
                            )}
                            <div className="d-flex justify-content-center mt-3  mb-1">
                              {/* <i className="ri-arrow-down-s-fill auti-custom-input-icon"></i> */}
                              {/* <Label htmlFor="Confirm password">Select Country</Label> */}

                              <select
                                className="select_box "
                                style={{ width: "370px", height: "40px" }}
                                {...register("country", {
                                  required: true,
                                  onChange: (event) => {
                                    handleFormData(event);
                                  },
                                })}
                              >
                                <option value="">Select Country </option>
                                {country_list.map((item, index) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.country?.type === "required" && (
                              <span className="last-page-error">
                                This field is required
                              </span>
                            )}

                            <div
                              className="d-flex"
                              style={{ marginTop: "20px" }}
                            >
                              {" "}
                              <CustomizedCheckbox
                                checked={checked1}
                                onChange={checkBoxHandleChange1}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              <p
                                style={{
                                  marginRight: "0px",
                                  marginTop: "7px",
                                }}
                              >
                                Accept terms and conditions
                              </p>
                            </div>

                            <div className="d-flex">
                              {" "}
                              <CustomizedCheckbox
                                checked={checked2}
                                onChange={checkBoxHandleChange2}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              <p
                                style={{
                                  marginRight: "0px",
                                  marginTop: "7px",
                                }}
                              >
                                Accept updates
                              </p>
                            </div>

                            <div className=" text-center">
                              {/* <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Reset"}</Button> */}
                              <Button
                                style={{
                                  textTransform: "none",
                                  marginTop: "40px",
                                  backgroundColor: "#5664d2",
                                }}
                                className="w-md waves-effect waves-light"
                                type="submit"
                                disabled={isAllOk || waitingForNewPasswordAxios}
                              >
                                {" "}
                                {waitingForNewPasswordAxios ? (
                                  <Spinner animation="grow" size="sm" />
                                ) : (
                                  "Create your digital signage account "
                                )}
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
