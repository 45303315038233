import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
// import Swal from "sweetalert2";
// import { Edit } from "@material-ui/icons";
import MaterialTable from "material-table";
// import { useDispatch } from "react-redux";
// import Tooltip from "@mui/material/Tooltip";
import { setCurrentPage, setSearchDisabled, setSearchkeyWord } from "../../../store/searchSlice";
import { useDispatch } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { Link } from "react-router-dom";
import { APP_NAME } from "../../../helpers/Constants";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../helpers/MaterialIcons";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import Moments from "../../../helpers/Moment";
// import { CustomButton } from "../../../components/CustomButton";
// import { PlanExpiredModal } from "../../../components/PlanExpiredModal";
import DownloadIcon from '@mui/icons-material/Download';
import axios from "axios";
export default function UsersInvoiceList() {
  const dispatch=useDispatch();

  const [editModalShow, setEditModalShow] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  const [urlBackup, setUrlBackup] = useState("");
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [tenant_name] = useState(useLocation()?.state);
  const [filterEnabled] = useState(false);
  const tableRef = useRef();
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Invoices", link: "#" },
    ],
  });

  useEffect(() => {
    dispatch(setCurrentPage('customer'))
    dispatch(setSearchDisabled(true))
    dispatch(setSearchkeyWord(null))


    return () => {
      dispatch(setSearchDisabled(false))
    }

  }, [])

  const downloadFile = (data) => {
    let file_url="media/"+data;
    axios.get(file_url,{responsetype: 'blob'})
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');
        link.href = url;
        link.download = file_url.split('/invoices/')[1];

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const columns = [
    { title: "Invoice Number", field: "invoice_num" },
    { title: "Invoice Date", field: "invoice_date" },
    { title: "Paid Date", field: "paid_date" },
    { title: "Customer Name ", field: "customer" ,
  render: rowData => <>{rowData?.customer?.name}</>
  },
    // { title: " Number of Screens  ", field: "" },
    { title: "Invoice Total", field: "Invoice_value" },

    { title: " Invoice Status", field: "invoice_status" },
  ];

  // for handling delete activate deactivate

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <CustomButton onClick={editModalToggle} /> */}
        <Container fluid>
          <Breadcrumbs
            title="Invoices"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>

          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    title=" "
                    columns={columns}
                    onChangeRowsPerPage={setPageSize}
                    actions={
                      [
                        (rowData) => ({
                          icon: DownloadIcon,
                          tooltip: "Download Invoice",
                          onClick: (event, rowData) => {
                            // console.log(rowData)
                            // setEditClientModalShow(true)
                            downloadFile(rowData.invoice_file_path)
                          },
                          hidden:rowData.invoice_file_path === null
                        }),
                      
                      ]
                    }
                    // isLoading={loader}
                    options={{
                      exportFileName: "Customers List",
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                      filtering: filterEnabled,
                      pageSize: pageSize,
                    }}
                    icons={tableIcons}
                    tableRef={tableRef}
                    data={(query) => {
                      // console.log(query)
                      return new Promise((resolve, reject) => {
                        let orderBy = "";
                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }

                        let url = `/abaciadmin_api/invoices?limit=${
                          query.pageSize
                        }&offset=${query.pageSize * query.page}&search=${
                          query.search
                        }${orderBy}`;

                        // if (Object.keys(date).length !== 0) {
                        //   url += `&start_date=${date.start_date}&end_date=${date.end_date}`;
                        // }
                        setUrlBackup(url);
                        authAxios
                          .get(url)
                          .then((response) => {
                            setTotalRecordsCount(response.data?.count);
                            // console.log(response.data.results);
                            // tableRef.current.dataManager.changePageSize(query.pageSize)
                            // console.log(response.data.results)
                            const InvoiceList = response.data?.results;
                            const TempData = InvoiceList.map((data) => ({
                              ...data,
                              invoice_no: data?.invoice_no
                                ? data.invoice_no
                                : "No Data",
                              invoice_date: data?.invoice_date
                                ? Moments(data.invoice_date)
                                : "No Data",
                              paid_date: data?.paid_date
                                ? Moments(data.paid_date)
                                : "No Data",
                            
                            }));
                            resolve({
                              data: TempData,
                              page:
                                response.data?.results.length === 0
                                  ? 0
                                  : query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            let err_msg = Error(error);
                            Toastr("error", err_msg);
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          });
                      });
                    }}
                  />
                </div>

                {/* </Row> */}
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
}
