import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
// import { Link } from "react-router-dom";
import { authAxios, authAxiosFileUpload } from "../../../axiosInstance";
// import { Toastr } from "../../../helpers/Alert";
import { addNewImage, addNewVideo, editImage, editVideo } from "../../../store/mediaSlice";
import VideoImage from "../../../assets/images/Video.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "./lottie.json";
import { useDispatch, useSelector } from "react-redux";

// import { ImportExport } from "@material-ui/icons";
import "./DropZone.css";
function Index({ file }) {
  const tenantDetails = useSelector((state) => state.authSlice.tenantDetails);

  const [percentage, setPercentage] = useState(0);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLottiLoading, setIsLottiLoading] = useState(false);

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      // console.log(percent);
      if (percent < 100) {
        setPercentage(percent);
      }
    },
  };

  const fetchThumbnail = ((id) => {
    let url
    if (tenantDetails?.app_type === "Admin"){
      url=`/stock/api/signage/filesrudview/${id}`
    }
    else{
      url=`/api/signage/filesrudview/${id}`
    }
    authAxios.get(url)
    .then((res) => {

      if(res.data.thumbnail === null){
        setTimeout(() => {
          fetchThumbnail(res.data.id)
        }, 2000);
      }
      else{
        if (res.data.file_type === "image") {
          dispatch(editImage(res.data));
        } else {
          dispatch(editVideo(res.data));
        }
      }
    })
  })

  const fileSendHandler = (file) => {

    // console.log(file)
    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data',
    //   },
    // };

  

    setIsLoading(true);
    let url
    if (tenantDetails?.app_type === "Admin"){
      url=`/stock/api/signage/fileslistcreateview`
    }
    else{
      url=`/api/signage/fileslistcreateview`
    }

    let data = new FormData();
    data.append("file", file);
    authAxiosFileUpload.post(url, data, options).then((res) => {
      setPercentage(100);
      setIsLoading(false);
      setIsLottiLoading(true);
      // setTimeout(setIsLottiLoading(false), 1000);
      // Toastr("success", "File Uploaded SuccessFully");
      if (res.data.file_type === "image") {
        dispatch(addNewImage(res.data));
      } else {
        dispatch(addNewVideo(res.data));
      }

      if(res.data.thumbnail === null){
        setTimeout(() => {
          fetchThumbnail(res.data.id)
        }, 2000);
      }
    });
  };
  useEffect(() => {
    if (file !== null) {
      fileSendHandler(file);
      // console.log(file.type.slice(0, 5));
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

 

  return (
    // <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
    <Card
      className="mt-1 mb-3 shadow-none dz-card"
      style={{
        borderRadius: "10px",
        marginLeft: "10px",
        boxShadow: "10px",
        objectFit: "cover",
      }}
    >
      <div className="container1">
        <img
          data-dz-thumbnail=""
          // alt={NoImage}
          alt='Stroke'
          src={file.type.slice(0, 5) !== "image" ? VideoImage : file.preview}
          className="dz-upload-image"
          style={
            isLoading
              ? { objectFit: "cover", opacity: "0.2" }
              : { objectFit: "cover", opacity: "1" }
          }
        />

        <div className="dz-file-name">
          <p className="text-center dz-name">{file.name}</p>
        </div>

        {isLoading ? (
          <div className="centered">
            <CircularProgress
              style={{ color: "#C1BBBB" }}
              variant="determinate"
              value={percentage}
            />
          </div>
        ) : isLottiLoading ? (
          <div className="centered">
            <Player
              autoplay
              src={animationData}
              style={{ height: "100px", width: "100px" }}
            ></Player>
          </div>
        ) : null}

        {/* <Row className="align-items-center">
          <Col className="col-auto">
           
          </Col>
          <Col>
            <Link to="#" className="text-muted font-weight-bold">
              {file.name}
            </Link>
            <p className="mb-0">
              <strong>{file.formattedSize}</strong>
            </p>
            <Progress
              className="mb-4"
              striped
              color="primary"
              value={percentage}
            ></Progress>
          </Col>
        </Row> */}
      </div>
    </Card>
  );
}

export default Index;
