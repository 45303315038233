import React, { useState } from "react";
import temp from "./templateThumbnail.jpg";
import {
  // TabContent,
  // TabPane,
  // Nav,
  // NavItem,
  // NavLink,
  Card,
  // Button,
  // CardTitle,
  // CardText,
  // Row,
  Col,
  // Container,
  // CardSubtitle,
  CardImg,
  CardBody,
} from "reactstrap";
import urlMaker from "../../../helpers/urlMaker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@material-ui/core";

function CanvasCard({ item, modalToggle, is_admin, copyCanvasHandler }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Col md={4} xl={2} style={{ minWidth: "200px" }}>
      <Card
        className="abaci-item-card"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardBody>
          {/* <CardTitle className="h4">Card title</CardTitle> */}
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "300px",
                display: "flex",
                flexDirection: "row",
                height: "24px",
                textTransform: "capitalize",
              }}
            >
              <h4
                style={{
                  fontSize: "14px",
                  color: " #8D9091",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                //   onClick={editHandler}
              >
                {item?.name}
              </h4>
            </div>
          </div>
        </CardBody>

        <CardImg
          className="img-fluid mt-2"
          src={item.thumbnail ? urlMaker(item.thumbnail, "canvas", true) : temp}
          alt="canvas"
          onClick={() => {
            modalToggle({ ...item, is_stock: true });
          }}
        />
        <CardBody>
          <div
            style={{
              width: "100%",
              display: "flex",
              height: "47px",
              justifyContent: "center",
              marginTop: "-10px",
            }}
          >
            {isHovered && (
              <Tooltip title="Copy">
                <IconButton onClick={() => copyCanvasHandler(item.id)}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default CanvasCard;
