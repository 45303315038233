export const byteToGb = (total_size_bytes) => {
  const total_size_gb =
    Math.round(
      (total_size_bytes / (1024 * 1024 * 1024)) * 100 + Number.EPSILON
    ) / 100;
  return total_size_gb + " GB ";
};

export const PercentageCalculator = (usedValue, totalValue) => {
  if (totalValue === 0) {
    return 0; // Avoid division by zero error
  }

  const percentage = (usedValue / totalValue) * 100;
  // console.log(percentage);
  return Math.round(percentage * 100 + Number.EPSILON) / 100; // Round the result to the nearest whole number
};
