import React, { useState } from "react";
import { Card } from "reactstrap";
import { ReactComponent as LinkIcon } from "../../../assets/icons/link.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import Cast from "@mui/icons-material/Cast";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Swal from "sweetalert2";
import { IconButton, Tooltip } from "@material-ui/core";
// import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import StarringStar from "../../../components/StarringStar";
import EditIcon from "@mui/icons-material/Edit";
import { Toastr } from "../../../helpers/Alert";
import { authAxios } from "../../../axiosInstance";
import { EditLink, favouriteWeblink } from "../../../store/webLinks";
import Error from "../../../helpers/Error";
function Index({
  castScreenModalToggle,
  item,
  deleteHandler,
  editModalHandler,
}) {
  const [mouseOnTitle, setMouseOnTitle] = useState(false);
  const [mouseOnEdit, setMouseOnEdit] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const editHandler = () => {
    setEditEnabled(true);
  };

  const editNameHandler = (data, event) => {
    if (name !== "") {
      const linkData = {
        name: name,
        object: { ...data.object, title: name },
      };
      const url = `/api/signage/objectrud/${data.id}`;
      authAxios
        .patch(url, linkData)
        .then((response) => {
          // Toastr("success", "Link Updated Successfully");
          dispatch(EditLink(response.data));
          setEditEnabled(false);
          setName("");
        })
        .catch((error) => {
          const err_msg = Error(error);
          setEditEnabled(false);
          setName("");
          Toastr("error", err_msg);
        });
    } else {
      setEditEnabled(false);
    }
  };
  const makeFavourite = (fev, id) => {
    dispatch(
      favouriteWeblink({
        is_favourite: fev === true ? false : true,
        id,
      })
    );

    const url = `/api/signage/objectrud/${id}`;
    authAxios
      .patch(url, { is_favourite: fev === true ? false : true })
      .then((response) => {
        // Toastr("success", "Link Updated Successfully");
        // dispatch(EditLink(response.data));
      })
      .catch((error) => {
        const err_msg = Error(error);
        dispatch(favouriteWeblink({ is_favourite: fev, id }));
        Toastr("error", err_msg);
      });
  };

  // useEffect(() => {
  //   console.log(mouseOnTitle)
  // }, [mouseOnTitle])

  return (
    <div>
      <Card
        className="abaci-table-item-card"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="weblink-card-content1">
          <div
            style={{
              width: "60px",
              height: "60px",
              backgroundColor: item.object.color_code,
              borderRadius: "5px",
              marginRight: "20px",
            }}
          >
            <LinkIcon
              style={{
                margin: "20px",
                fill: item.object.light_or_dark,
                width: "20px",
                height: "20px",
              }}
            />
          </div>
          <div style={{ marginTop: "6px" }}>
            <div
              onMouseEnter={() => setMouseOnTitle(true)}
              onMouseLeave={() => setMouseOnTitle(false)}
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "300px",
                display: "flex",
                flexDirection: "row",
                height: "24px",
                textTransform: "capitalize",
                color: "#8D9091",
              }}
            >
              {editEnabled ? (
                <input
                  autoFocus
                  placeholder={item.name}
                  style={{
                    borderWidth: "0px",
                    borderBottomWidth: "2px",
                    borderBottomColor: item.object.color_code,
                  }}
                  onChange={(event) => setName(event.target.value)}
                  onBlurCapture={() => editNameHandler(item)}
                ></input>
              ) : (
                <h4
                  style={{
                    fontSize: "14px",
                    color: " #8D9091",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={editHandler}
                >
                  {item?.name}
                </h4>
              )}
              <div
                style={{ marginLeft: "5px", marginTop: "-3px" }}
                onMouseEnter={() => setMouseOnEdit(true)}
                onMouseLeave={() => setMouseOnEdit(false)}
              >
                {mouseOnTitle && !editEnabled ? (
                  <EditOutlined
                    fontSize={"6px"}
                    color={mouseOnEdit ? "action" : "disabled"}
                    onClick={editHandler}
                    style={{ zIndex: "9999" }}
                  />
                ) : null}
              </div>
            </div>

            <div
              style={{
                fontWeight: "normal",
                fontSize: "13px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "300px",
                color: "#adadad",
              }}
            >
              {item?.object?.link}
            </div>
          </div>
        </div>

        <div className="weblink-card-content2" style={{ marginTop: "12px" }}>
          <div
            style={{
              fontWeight: "normal",
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              color: "#adadad",
            }}
          >
            {"Added by: " + item?.created_by}
          </div>
          <div
            style={{
              fontWeight: "normal",
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              color: "#adadad",
            }}
          >
            {"Added on: " + moment(item.created_date).format("MMM Do YY")}
          </div>
        </div>

        <div
          className="weblink-card-actions"
          style={{ marginTop: "10px", width: "200px" }}
        >
          {/* <div onClick={() => makeFavourite(item.id, item.is_favourite)}> */}
          <span onClick={() => makeFavourite(item.is_favourite, item.id)}>
            {item.is_favourite || isHovered ? (
              <StarringStar selected={item.is_favourite} />
            ) : null}
          </span>
          {/* </div> */}
          {isHovered && (
            <>
              <Tooltip title="Set to Screen" arrow>
                <IconButton onClick={() => castScreenModalToggle(item)}>
                  <Cast />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" arrow>
                <IconButton
                  onClick={() => {
                    editModalHandler(item);
                  }}
                >
                  {/* className="far fa-trash-alt"
                                    style={{ cursor: "pointer" }} */}

                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" arrow>
                <IconButton
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        // deleteClientData(rowData?.id)
                        deleteHandler(item.id);
                      }
                    });
                  }}
                >
                  {/* className="far fa-trash-alt"
                                    style={{ cursor: "pointer" }} */}

                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
        {/* </Col> */}

        {/* </Col> */}
        {/* </Row> */}
      </Card>
    </div>
  );
}

export default Index;
