import React from 'react'
// import "./TestPage1.css"
import Iframe from 'react-iframe'
export default function AbaciIFrame(props) {
    const height = String((1920/Number(props.divOriginalWidth))*1080)+'px'
  const frame = React.useRef()
  const percentage = String(props.width/1920)
  React.useEffect(()=> {
    frame.current.style.overflow = 'hidden';
    frame.current.style.position = 'relative';
    frame.current.style.width = '1920px';
    frame.current.style.height = height;
    frame.current.style.border = '0';
    frame.current.style.TransformOrigin='0 0';
    frame.current.style.MsTransformOrigin='0 0';
    frame.current.style.webkitTransformOrigin='0 0';
    frame.current.style.MozTransformOrigin='0 0';
    frame.current.style.oTransformOrigin='0 0';
    frame.current.style.MsTransform='scale('+percentage+')';
    frame.current.style.MozTransform='scale('+percentage+')';
    frame.current.style.oTransform='scale('+percentage+')';
    frame.current.style.webkitTransform='scale('+percentage+')';
    frame.current.style.Transform='scale('+percentage+')';
    // frame.current.style.transition= "all ease-in-out 0.5s";
  })
  return (
     <div>
       <iframe ref = {frame} src={props.url} scrolling="no"></iframe>
    </div>
  )
}

