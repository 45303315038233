import axios from "axios";
import { baseURL } from './helpers/baseURL';
import Cookies from "js-cookie";

export const publicAxios = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: { "content-type": "application/json", accept: 'application/json' },
  withCredentials: true
});

const getAuthBaseUrl = (tenant) => {
  if (tenant === "public") {
    return baseURL;
  } else {
    return baseURL + `/clients/${tenant}`;
  }
};

let token;
let tenant;

const authAxios = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "content-type": "application/json",
    accept: 'application/json',
  },
  withCredentials: true
});

const authAxiosFileUpload = axios.create({
  baseURL: baseURL,
  headers: {
    "content-type": "multipart/form-data",
    accept: 'application/json',
  },
  withCredentials: true
});

export const updateToken = (newToken, newTenant) => {

  token = newToken;
  tenant = newTenant;
  authAxios.defaults.headers.Authorization = "Bearer " + newToken;
  authAxiosFileUpload.defaults.headers.Authorization = "Bearer " + newToken;
  authAxios.defaults.baseURL = getAuthBaseUrl(tenant);
  authAxiosFileUpload.defaults.baseURL = getAuthBaseUrl(tenant);
};

export const setInitialToken = () => {
  token = Cookies.get("token");
  tenant = Cookies.get("tenant");
  authAxios.defaults.headers.Authorization = "Bearer " + token;
  authAxiosFileUpload.defaults.headers.Authorization = "Bearer " + token;
  authAxios.defaults.baseURL = getAuthBaseUrl(tenant);
  authAxiosFileUpload.defaults.baseURL = getAuthBaseUrl(tenant);
};

setInitialToken();

export {  authAxios, authAxiosFileUpload, };