export const animationDictionary = {
    fadeIn: "Fade in" , 
    fadeInRight:"Fade in right",
    fadeInLeft:"Fade in Left",
    fadeOutRight:"Fade out right",
    fadeOutLeft: "Fade out Left",
    fadeInUp: "Fade in up",
    fadeOutup:"Fade out up",
    fadeInDown:"Fade in down",
    fadeOutDown:"Fade out down",
    zoomIn:"Zoom in",
    zoomOut:"Zoom out"
  }

  export const animationTypeArray = [
    {					
        type: "fadeIn",
        description: "Fade in",
    },
    {
        type: "fadeInRight",
        description: "Fade in right",
    },
    {
        type: "fadeInLeft",
        description: "Fade in Left",
    },
    // {
    //     type: "fadeOutRight",
    //     description: "Fade out right",
    // },
    // {
    //     type: "fadeOutLeft",
    //     description: "Fade out Left",
    // },
    {
        type: "fadeInUp",
        description: "Fade in up",
    },
    // {
    //     type: "fadeOutup",
    //     description: "Fade out up",
    // },
    {
        type: "fadeInDown",
        description: "Fade in down",
    },
    // {
    //     type: "fadeOutDown",
    //     description: "Fade out down",
    // },
    {
        type: "zoomIn",
        description: "Zoom in",
    },
    // {
    //     type: "zoomOut",
    //     description: "Zoom out",
    // }					
  ]
