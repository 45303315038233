import React, { useEffect, useContext } from "react";
import { fabric } from 'fabric';
import './fab-editor.css'
import { getCanvas } from '../../../../helpers/utils'
import 'fabric-history';
import clsx from "clsx";
import FabEditorRight from '../right-panel/RightPanel'
import { useDispatch } from "react-redux";
// import { setCanvas, setObjectsState } from "../../../../store/fabricCanvasSlice";
import WebFont from 'webfontloader'
import CanvasZoom from "./canvasZoom";
import LeftTabMenu from "../left-panel/left-tab-menu/LeftTabMenu";
import ToolBaar from "../../tool-baar/ToolBaar";
import { CanvasContext } from "../../../../context/CanvasContext";

let canvas, canvasDimensions, pitchContainerBox, containerDimensions;
const arrayFonts = ["Acme", "Akshar", "Artifika", "Comic Neue", "Courier Prime", "EB Garamond", "Just Another Hand",
    "Black Han Sans", "Montserrat", "Playball", "Poppins", " Ultra", "Smythe", " Rock Salt", "Brush Script MT", "Times New Roman", 'Roboto']
const FabEditor = () => {
    // const dispatch = useDispatch()
    const { setCanvas, setObjectsState, canvasObjFromBackend } = useContext(CanvasContext)
    fabric.util.object.extend(fabric.Object.prototype, {
        setBoundingBox(prop, val) {
            const bbox = this.getBoundingRect(1, 1);
            this.set(prop, (this[prop] - bbox[prop]) + val);
            this.setCoords();
        },
    });
    useEffect(() => {
        pitchContainerBox = document.querySelector(".canvas-main-wrapper")
        if (!pitchContainerBox) return;
        containerDimensions = {
            width: canvasContainerHeight(pitchContainerBox).width,
            height: canvasContainerHeight(pitchContainerBox).height,
        }
        window.addEventListener('resize', updateWindowDimensions, true);
        canvas = getCanvas(containerDimensions);
        window.canvas = canvas;
        setCanvas(canvas)
        stopEvents()
        canvas._historyInit();
        startEvents();
        canvas.loadFromJSON(canvasObjFromBackend.canvasObject);
        canvas.renderAll()
        loadGoogleFonts(arrayFonts)
    }, []);

    const loadGoogleFonts = (fontFamily) => {
        WebFont.load({
            google: {
                families: fontFamily
            }
        });
    }
    const startEvents = () => {
        canvas.on({
            'selection:created': selectionCreated,
            'selection:updated': selectionUpdated,
            'selection:cleared': selectionCleared,
            'object:added': objectAdded,
            'object:modified': objectModified,
            'history:undo': historyUndo,
            'history:redo': historyRedo,
            'history:append': historyAppend
        })
    }
    const stopEvents = () => {
        canvas.__eventListeners = {}
    }

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = '#95c5ee';
    fabric.Object.prototype.cornerStyle = 'circle';
    const updateWindowDimensions = () => {
        setCanvasDimensionsProps()
        updateObjectsScale(canvas)
        canvas._objects.forEach(obj => {
            updateCanvasResize(obj)
        })
        canvas.renderAll()
    };
    const setCanvasDimensionsProps = () => {
        const outerCanvasContainer = pitchContainerBox
        const windowWidth = window.innerWidth
        const ratio = canvas.getWidth() / canvas.getHeight();
        let containerWidth = canvasContainerHeight(outerCanvasContainer).width
        const scale = containerWidth / canvas.getWidth();
        const zoom = canvas.getZoom() * scale;

        canvasDimensions = {
            windowWidth: windowWidth,
            containerWidth: containerWidth,
            ratio: ratio,
            scale: scale,
            zoom: zoom,
            zoom_val: canvas.getZoom()
        }
    }
    const updateObjectsScale = (canvasEl) => {
        canvasEl.setDimensions({
            width: canvasDimensions.containerWidth,
            height: canvasDimensions.containerWidth / canvasDimensions.ratio
        });
        canvasEl.setViewportTransform([canvasDimensions.zoom, 0, 0, canvasDimensions.zoom, 0, 0]);
    }
    const updateCanvasResize = (obj) => {
        switch (obj.name) {
            case "image":
                setObjectPadding(obj, 5, 2)
                break;
            case "text":
                setObjectPadding(obj, 5, 2)
                break;
        }
    }
    const setObjectPadding = (obj, val, val2) => {
        if (window.innerWidth < 1100) {
            obj.set("padding", val)
        } else {
            obj.set("padding", val2)
        }
        canvas.renderAll()
    }
    const canvasContainerHeight = (el) => {
        let width
        let height
        if ((window.innerWidth / window.innerHeight) > canvasObjFromBackend.canvasWidth / canvasObjFromBackend.canvasHeight) {
            const tempHeight = window.innerHeight - 250
            height = tempHeight
            width = tempHeight / canvasObjFromBackend.canvasHeight * canvasObjFromBackend.canvasWidth
        }
        else {
            const tempWidth = window.innerWidth - 450
            width = tempWidth
            height = tempWidth / canvasObjFromBackend.canvasWidth * canvasObjFromBackend.canvasHeight
        }
        return {
            width: width,
            height: height,
        }
    }
    const historyUndo = (e) => {
    }
    const historyRedo = (e) => {
    }
    const historyAppend = (e) => {
    }

    const objectModified = (e) => {
    }
    const objectAdded = (e) => {
    }
    const selectionCreated = (e) => {
        let object = e.selected[0]
        if (!object) return
        updateObjectsStates(object)
    }
    const selectionUpdated = (e) => {
        // console.log(e)
        updateObjectsStates(e.selected[0])
    }
    const selectionCleared = (e) => {
        updateObjectsStates({ name: "clear" })
    }
    const updateObjectsStates = (object) => {
        let obj;
        if (object?.name === "text") {
            obj = {
                shape: false,
                image: false,
                objectActive: true,
                text: true,
                activeSelection: false
            }
        } else if (object?.name === "clear") {
            obj = {
                shape: false,
                image: false,
                objectActive: false,
                text: false,
                activeSelection: false
            }
        } else if (object?.name === "image") {
            obj = {
                shape: false,
                image: true,
                objectActive: true,
                text: false,
                activeSelection: false
            }
        } else if (object?.name === "shape" || object.type === 'path') {
            obj = {
                shape: true,
                image: false,
                objectActive: true,
                text: false,
                activeSelection: false
            }
        } else {
            obj = {
                shape: false,
                image: false,
                objectActive: true,
                text: false,
                activeSelection: false
            }
        }

        setObjectsState(obj)
    }

    return (
        <div className="fabric-editor-container">
            <div className="editor-main-wrapper">
                <LeftTabMenu />
                <div className="canvas-editor-wrapper">
                    <div className={clsx("canvas-main-wrapper")}>
                        <canvas id="canvas" width={1000} height={600} />
                    </div>
                    <div className='canvas-props'>
                        <ToolBaar />
                    </div>
                </div>
                <FabEditorRight />
            </div>
        </div>
    );
}
export default FabEditor;
