import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody } from "reactstrap";
import "./screenManagement.scss";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Col,
  Container,
} from "reactstrap";
import classnames from "classnames";
import MonitorIcon from "@mui/icons-material/Monitor";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import AddIcon from "@mui/icons-material/Add";
import { Toastr, topRightToastr } from "../../../../helpers/Alert";
import { authAxios } from "../../../../axiosInstance";
import ScreenTab from "./ScreenTab";
import ScreenGroupTab from "./ScreenGroupTab";
import OrchestartionTab from "./OrchestrationTab";
import {
  screenStatusUpdater,
  setScreenGroup,
  setScreens,
} from "../../../../store/screenSlice";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ScreenAddModalTv from "../ScreenAddTVModal";
import ScreenAddGroupModal from "../ScreenGroupAddModal";
import { WebsocketContext } from "../../../../context/WebsocketContext";
import Error from "../../../../helpers/Error";
function ScreenManagementTest() {
  const screenDetails = useContext(WebsocketContext);

  const screenList = useSelector((state) => state.screenSlice.screens);
  const screenGroupList = useSelector((state) => state.screenSlice.screenGroup);
  const searchKey = useSelector((state) => state.searchSlice.searchKeyWord);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const dispatch = useDispatch();
  const [uploadActive, setUploadActive] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState(
    localStorage.getItem("screenTab") != null
      ? localStorage.getItem("screenTab")
      : "0"
  );
  const [onlineStatus,setOnlineStatus] = useState('')
  const [totalCount, setTotalCount] = useState(0);
  const [hasNextpage, setHasNextPage] = useState(false);

  const [page, setPage] = useState(1);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: "Signage", link: "#" },
      { title: "Screen managment", link: "#" },
    ],
  });

  const [limit] = useState(20);
  const [unmounted, setUnMounted] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [screenAddModalShow, setScreenAddModalShow] = useState(false);
  const [screenGroupModalShow, setScreenGroupModalShow] = useState(false);
  const [isOnlineActive, setIsOnlineActive] = useState(false);
  const [isOfflineActive, setIsOfflineActive] = useState(false);
  useEffect(() => {
    // console.log(screenDetails)
    // dispatch(updateScreenStatus(screenDetails.screens))
    if (screenList?.length !== 0) {
      if (screenDetails?.messageType === "single_screen_status") {
        let screen_id = screenDetails?.screens[0].screen_id;
        if (screenList?.some((item) => item.screen_id === screen_id)) {
          dispatch(screenStatusUpdater(screenDetails?.screens[0]));

          if (screenDetails?.screens[0]?.online_status) {
            topRightToastr(
              "success",
              `${screenDetails?.screens[0].screen_name} is Online !!`
            );
          } else {
            topRightToastr(
              "error",
              `${screenDetails?.screens[0].screen_name} is Offline !!`
            );
          }
        }
      }
      // dataFetcher()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenDetails]);

  useEffect(() => {
    console.log(searchKey);
    if (searchKey !== "" && searchKey !== null) {
      setIsSearchActive(true);
      setPage(1);
      setTotalCount(0);
      setHasNextPage(false);

      if (activeTabJustify === "0") {
        dispatch(setScreens(null));
      } else if (activeTabJustify === "1") {
        //inorder to clear the user video and stock video
        dispatch(setScreenGroup(null));
      }
    } else if (searchKey === null) {
      setIsSearchActive(false);
      setPage(1);
      setTotalCount(0);
    }
  }, [searchKey]);

  const tabData = [
    {
      url: `api/signage/screenslistcreateview?limit=${limit}`,
      reduxFunction: setScreens,
      state: screenList,
    },
    {
      url: `/api/signage/screengrouplistcreateview?limit=${limit}`,
      reduxFunction: setScreenGroup,
      state: screenGroupList,
    },
    {
      url: ``,
      reduxFunction: "",
      state: "",
    },
  ];
  const tooltipOption = [
    {
      title: "Add Screen",
      state: screenAddModalShow,
      changeFunction: setScreenAddModalShow,
    },
    {
      title: "Add Group Screen",
      state: screenGroupModalShow,
      changeFunction: setScreenGroupModalShow,
    },
    { title: "Add Orchestration", state: "", changeFunction: "" },
  ];

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  // useEffect(() => {
  //   if (onlineActive && offlineActive) {
  //     // Both buttons are active, so send an empty status to the backend
  //     setOnlineStatus('');
  //   } else if (onlineActive) {
  //     setOnlineStatus(true);
  //   } else if (offlineActive) {
  //     setOnlineStatus(false);
  //   }
  // }, [isOnlineActive,isOfflineActive])
  

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    let url =
      tabData[activeTabJustify].url +
      `&offset=${limit * (page - 1)}&search=${
        searchKey !== null && searchKey !== "" ? searchKey : ""
      }&online_status=${onlineStatus}`;

    authAxios
      .get(url)
      .then((response) => {
        // if(page === 1){
        if (!unmounted) {
          // flag for making call to backend if there is more data to be loaded,
          // if not state is made to false
          if (response.data.next !== null && response.data.length !== 0) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }

          // condition written for loading data on first load ,after first load if there is more data
          // the offset is changed and next set of data are loaded then the else condition is used
          // inorder to append data the already existing datas

          //here tabData Array Consist of the url,state holding values and redux dispatch function needed by each tab to pass data
          //we destructure the array using the active tab value and set and updates value as needed
          if (
            tabData[activeTabJustify].state === null ||
            tabData[activeTabJustify].state === undefined
          ) {
            dispatch(
              tabData[activeTabJustify].reduxFunction(response.data.results)
            );
          } else {
            dispatch(
              tabData[activeTabJustify].reduxFunction([
                ...tabData[activeTabJustify].state,
                ...response.data.results,
              ])
            );
          }

          setTotalCount(response.data.count);
        }
      })
      .catch((error) => {
        if (!unmounted) {
          Toastr("error", "Oops!,Something Went Wrong");
          dispatch(tabData[activeTabJustify].reduxFunction([]));
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabJustify, page, isSearchActive,onlineStatus]);

  useEffect(() => {
    return () => {
      // done inorder to clear the redux stores and to remove event listener on when component unmounts
      dispatch(setScreenGroup(null));
      dispatch(setScreens(null));
      setUnMounted(true);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      localStorage.setItem("screenTab", tab);
      setActiveTabJustify(tab);
      setPage(1);
      setTotalCount(0);
      setHasNextPage(false);

      //clearing redux store when the tab changes
      // dispatch(tabData[tab].reduxFunction(null))

      if (tab === "0") {
        dispatch(setScreens(null));
      } else if (tab === "1") {
        //inorder to clear the user video and stock video
        dispatch(setScreenGroup(null));
      }
    }
  };

  // function used inorder to change page and load more data upon user scrolling if there is more data
  const loadFunc = () => {
    if (hasNextpage) {
      setTimeout(() => {
        setPage(page + 1);
      }, 1500);
    }
  };

  const modalChecker = () => {
    //destructuring an array with respect to activetab inorder to access the state value and setstate function
    tooltipOption[activeTabJustify].changeFunction(
      !tooltipOption[activeTabJustify].state
    );
  };

  const filterHandler = (type) => {
    if (type === "online") {
      setIsOnlineActive(!isOnlineActive);
    } else {
      setIsOfflineActive(!isOfflineActive);
    }
  };

  useEffect(() => {
    if (isOfflineActive || isOnlineActive) {
      let url = `api/signage/screenslistcreateview?limit=${limit}`;

      authAxios
        .get(
          url +
            `&online_status=${isOnlineActive ? true : false}&offset=${
              limit * (page - 1)
            }&search=${searchKey !== null && searchKey !== "" ? searchKey : ""}`
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          const err_msg = Error(error);
          Toastr("error", err_msg);
        });
    }
  }, [isOfflineActive, isOnlineActive, isSearchActive, page]);

  return (
    <>
      {screenAddModalShow && (
        <ScreenAddModalTv
          isModalVisible={screenAddModalShow}
          setIsModalVisible={setScreenAddModalShow}
        />
      )}
      {screenGroupModalShow && (
        <ScreenAddGroupModal
          isModalVisible={screenGroupModalShow}
          setIsModalVisible={setScreenGroupModalShow}
        />
      )}

      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Screen Managment"
              breadcrumbItems={dashboardState.breadcrumbItems}
            ></Breadcrumbs>
            <Col xl={24}>
              <Card style={{ minHeight: "70vh" }}>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified" fill>
                    <NavItem style={{ color: "#1F2027" }}>
                      <NavLink
                        className={classnames({
                          active: activeTabJustify === "0",
                        })}
                        onClick={() => {
                          toggleCustomJustified("0");
                        }}
                      >
                        <span className=" d-sm-block">Screens</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={classnames({
                          active: activeTabJustify === "1",
                        })}
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className="d-sm-block">Screen Groups</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTabJustify === "2",
                        })}
                        onClick={() => {
                          toggleCustomJustified("2");
                        }}
                      >
                        <span className="d-sm-block">Orchestration</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTabJustify}>
                    <TabPane tabId="0" className="p-3">
                      {activeTabJustify === "0" && (
                        <>
                          <ScreenTab
                            loadFunc={loadFunc}
                            hasNextpage={hasNextpage}
                            windowHeight={windowHeight}
                          />
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId="1" className="p-3">
                      {activeTabJustify === "1" && (
                        <>
                          <ScreenGroupTab
                            loadFunc={loadFunc}
                            hasNextpage={hasNextpage}
                            windowHeight={windowHeight}
                          />
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId="2" className="p-3">
                      {activeTabJustify === "2" && (
                        <>
                          <OrchestartionTab
                            loadFunc={loadFunc}
                            hasNextpage={hasNextpage}
                            windowHeight={windowHeight}
                          />
                        </>
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>

            <div
              style={{
                position: "fixed",
                bottom: "70px",
                right: "24px",
                zIndex: "1",
              }}
            >
              <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ right: -10, zIndex: "1" }}
                icon={
                  // <SpeedDialIcon
                  //   openIcon={<MoreHorizIcon />}
                  //   closeIcon={<MoreHorizIcon />}
                  // />
                  <MoreHorizIcon />
                }
                direction="up"
                // hidden={isGroupModalVisible}
                // onClick={(event) => console.log(event)}
                FabProps={{
                  sx: {
                    bgcolor: "rgb(255,209,56)",
                    "&:hover": {
                      bgcolor: "rgb(255,209,56,.9)",
                    },
                  },
                }}
              >
                <SpeedDialAction
                  icon={<AddIcon style={{ color: "white" }} />}
                  onClick={modalChecker}
                  tooltipTitle={`${tooltipOption[activeTabJustify]?.title}`}
                  // sx={}
                  FabProps={{
                    sx: {
                      bgcolor: "rgb(86,100,210)",
                      "&:hover": {
                        bgcolor: "rgb(86,100,210,.9)",
                      },
                      width: 60,
                      height: 60,
                    },
                  }}
                />
                <SpeedDialAction
                  icon={
                    <DesktopMacIcon
                      style={{ color: isOfflineActive ? "white" : "#EE6055" }}
                    />
                  }
                  tooltipTitle={"Offline Screens"}
                  onClick={() => filterHandler("offline")}
                  FabProps={{
                    sx: {
                      bgcolor: isOfflineActive
                        ? "rgb(238, 96, 85)"
                        : "rgb(244, 244, 244)",
                      "&:hover": {
                        bgcolor: isOfflineActive
                          ? "rgb(238, 96, 85,.9)"
                          : "rgb(244, 244, 244,.9)",
                      },
                      width: 60,
                      height: 60,
                    },
                  }}
                ></SpeedDialAction>
                <SpeedDialAction
                  icon={
                    <DesktopMacIcon
                      style={{ color: isOnlineActive ? "white" : "#60D394" }}
                    />
                  }
                  tooltipTitle={"Online Screens"}
                  onClick={() => filterHandler("online")}
                  FabProps={{
                    sx: {
                      bgcolor: isOnlineActive
                        ? "rgb(96, 211, 148)"
                        : "rgb(244, 244, 244)",
                      "&:hover": {
                        bgcolor: isOnlineActive
                          ? "rgb(96, 211, 148,.9)"
                          : "rgb(244, 244, 244,.9)",
                      },
                      width: 60,
                      height: 60,
                    },
                  }}
                ></SpeedDialAction>
              </SpeedDial>
              {/* <div className="offline-online-filter1"></div>
              <div
                style={{
                  height: "200px",
                  width: "200px",
                  position: "fixed",
                  bottom: "300px",
                  right: "100px",
                  backgroundColor: "black",
                }}
                className="filter-screen1"
              ></div> */}

              {/* <CustomButton onClick={screenModalToggle} title={"+"} /> */}
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}

export default ScreenManagementTest;
