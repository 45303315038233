import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
// import Swal from "sweetalert2";
import { Edit } from "@material-ui/icons";
import MaterialTable from "material-table";
// import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { APP_NAME } from "../../../../helpers/Constants";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../../helpers/MaterialIcons";
import { authAxios } from "../../../../axiosInstance";
import { EditCustomer } from "./EditCustomer";
import Error from "../../../../helpers/Error";
import { Toastr } from "../../../../helpers/Alert";
import { setCurrentPage, setSearchDisabled, setSearchkeyWord } from "../../../../store/searchSlice";
import { useDispatch } from "react-redux";

export default function Customer() {

  const dispatch=useDispatch();

  const navigate = useNavigate();
  const [editModalShow, setEditModalShow] = useState(false);
  const [itemToBeEdited, setItemToBeEdited] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [urlBackup, setUrlBackup] = useState("");
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);

  const [filterEnabled] = useState(false);
  const tableRef = useRef();
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Customer", link: "#" },
    ],
  });
  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };

  useEffect(() => {
    dispatch(setCurrentPage('customer'))
    dispatch(setSearchDisabled(true))
    dispatch(setSearchkeyWord(null))


    return () => {
      dispatch(setSearchDisabled(false))
    }

  }, [])
  

  const columns = [
    {
      title: "Customer Name",
      field: "name",

      render: (rowData) => (
        <>
          <Tooltip arrow title={`View Details of ${rowData.name}`}>
            <Link
              to={`/customerdetails/${rowData.id}`}
              state={{ ...rowData }}
              className="link_tag"
            >
              {rowData.name ? rowData.name : "No Data"}
            </Link>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Created Date",
      field: "created_on",
    },
    {
      title: "Industry",
      field: "industry",
    },
    {
      title: "Country",
      field: "country",
    },
    {
      title: "Time Zone",
      field: "timezone",
    },
    {
      title: "On Trial",
      field: "trail_days",
    },
    // { title: "description", field: "description" },
    {
      title: "Last Payment",
      field: "last_payment",
    },
    {
      title: "Price",
      field: "base_price",
    },
    {
      title: "Paid Upto",
      field: "paid_upto",
    },
    {
      title: "App Type",
      field: "app_type",
    },
    {
      title: "Discount",
      field: "discount",
    },

    { title: "Payment Status", field: "payment_status" },
    { title: "Status", field: "status" },
  ];

  // for handling delete activate deactivate

  return (
    <React.Fragment>
      {editModalShow && (
        <EditCustomer
          isOpen={editModalShow}
          toggle={editModalToggle}
          title={"Edit Customer "}
          item={itemToBeEdited}
          tableRef={tableRef}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Customers"
            breadcrumbItems={dashboardState.breadcrumbItems}
          ></Breadcrumbs>

          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    title=" "
                    columns={columns}
                    onChangeRowsPerPage={setPageSize}
                    actions={[
                      (rowData) => ({
                        icon: Edit,
                        tooltip: "Edit Customer",
                        onClick: (event, rowData) => {
                          // console.log(rowData)
                          // setEditClientModalShow(true)
                          setItemToBeEdited(rowData);
                          editModalToggle();
                        },
                      }),
                      (rowData) => ({
                        icon: VisibilityIcon,
                        tooltip: "Customer Details",
                        onClick: (event, rowData) => {
                          navigate(`/customerdetails/${rowData.id}`, {
                            state: { ...rowData },
                          });
                        },
                      }),
                    ]}
                    // isLoading={loader}
                    options={{
                      exportFileName: "Customers List",
                      exportAllData: true,
                      // actionsColumnIndex: -1,
                      exportButton: true,
                      filtering: filterEnabled,
                      pageSize: pageSize,
                    }}
                    icons={tableIcons}
                    tableRef={tableRef}
                    data={(query) => {
                      // console.log(query)
                      return new Promise((resolve, reject) => {
                        let orderBy = "";
                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }

                        let url = `/abaciadmin_api/tenant_list?limit=${
                          query.pageSize
                        }&offset=${query.pageSize * query.page}&search=${
                          query.search
                        }${orderBy}`;

                        // if (Object.keys(date).length !== 0) {
                        //   url += `&start_date=${date.start_date}&end_date=${date.end_date}`;
                        // }
                        setUrlBackup(url);
                        authAxios
                          .get(url)
                          .then((response) => {
                            setTotalRecordsCount(response.data?.count);
                            // console.log(response.data);
                            // tableRef.current.dataManager.changePageSize(query.pageSize)
                            // console.log(response.data.results)
                            const CustomerList = response.data?.results;
                            // dispatch(addGtcc(response.data.results))
                            const TempData = CustomerList.map((data) => ({
                              ...data,
                              country: data?.country ? data.country : "No Data",
                              discount: data?.discount
                                ? data.discount
                                : "No Data",
                              trail_days: data?.number_of_trial_days
                                ? data.number_of_trial_days
                                : "No Data",
                              description: data?.description
                                ? data.description
                                : "No Data",
                              base_price: data?.base_price
                                ? data.base_price
                                : "No Data",
                              industry: data?.industry
                                ? data.industry
                                : "No Data",
                              created_date: data?.created_on
                                ? data.created_on
                                : "No Data",
                              timezone: data?.client_timezone
                                ? data.client_timezone
                                : "No Data",
                              paid_upto: data?.paid_upto
                                ? data.paid_upto
                                : "No Data",
                              last_payment: data?.last_payment
                                ? data.last_payment
                                : "No Data",
                              app_type: data?.app_type
                                ? data.app_type
                                : "No Data",
                            }));
                            resolve({
                              data: TempData,
                              page:
                                response.data?.results.length === 0
                                  ? 0
                                  : query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            let err_msg = Error(error);
                            Toastr("error", err_msg);
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          });
                      });
                    }}
                  />
                </div>

                {/* </Row> */}
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
}
