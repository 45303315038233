import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { Player } from "@lottiefiles/react-lottie-player";
import Expired from "../assets/lottie/expired.json";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { setLogOut } from "../store/auth";
import { authAxios } from "../axiosInstance";
import Error from "../helpers/Error";
import { Toastr } from "../helpers/Alert";
export const PlanExpiredModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const logoutHandler = () => {
    dispatch(setLogOut());
    Cookies.remove("token");
    navigate("/login");
  };

  const makePaymentHandler = () => {
    setLoader(true);
    const url = "api/signage/make_payment";

    authAxios
      .post(url)
      .then((res) => {
        setLoader(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoader(false);
        let err_msg = Error(err);
        Toastr("error", err_msg);
      });
  };
  return (
    <Modal size={"xl"} centered isOpen={props.isOpen}>
      {/* <ModalHeader>{props.title}</ModalHeader> */}
      <ModalBody>
        <Player
          autoplay
          loop
          src={Expired}
          style={{ height: "250px", width: "250px", marginTop: "30px" }}
        ></Player>
        <div className="text-center" style={{ height: "250px" }}>
          <h4 className="mt-1" style={{ color: "#6B788F" }}>
            Your Free trail has expired
          </h4>
          <p style={{ color: "#6B788F" }}>
            Please recharge your plan to continue our services
          </p>
          <Button
            style={{
              backgroundColor: "#5664D2",
              borderColor: "#5664D2",
              marginTop: "10px",
            }}
            onClick={makePaymentHandler}
            disabled={loader}
          >
            {loader ? <Spinner animation="grow" size="sm" /> : "Make Payment"}
          </Button>
          <Button
            color="danger"
            style={{
              marginTop: "10px",
              marginLeft: "10px",
            }}
            onClick={logoutHandler}
            disabled={loader}
          >
            Logout
          </Button>
        </div>
        {/* <div className="d-flex justify-content-end">
          <p
            onClick={props.toggle}
            style={{ marginRight: "20px", cursor: "pointer", color: "#5664D2" }}
            className="skip_text"
          >
            Skip
          </p>
        </div> */}
      </ModalBody>
    </Modal>
  );
};
